import { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { parseJwt } from 'utils/parseJwt';
import { AppPaths } from 'urls/frontend';
import { useAuth } from 'context/Auth';
import { CircularProgress } from '@mui/material';
import { Box } from 'components/common/Box';

export const AutoLogin: FC = () => {
  const { token } = useParams<{ token: string }>();
  const [isProcessing, setIsProcessing] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const processToken = async () => {
      if (!token) {
        setIsProcessing(false);
        return;
      }

      try {
        const decodedToken = atob(decodeURIComponent(token));

        const [accessToken, refreshToken] = decodedToken.split(':');

        if (!accessToken || !refreshToken) {
          console.error('Invalid token format');
          setIsProcessing(false);
          return;
        }

        try {
          const tokenData = parseJwt(accessToken);
          if (!tokenData || !tokenData.exp) {
            throw new Error('Invalid token data');
          }

          if (tokenData.exp < Date.now() / 1000) {
            throw new Error('Token expired');
          }

          localStorage.setItem('token', JSON.stringify(accessToken));
          localStorage.setItem('refresh', JSON.stringify(refreshToken));
          localStorage.setItem('user', JSON.stringify(tokenData));

          if (auth) {
            auth.setUser(tokenData);
            auth.setRedirectPath(AppPaths.main);
            setIsValid(true);
          }
        } catch (error) {
          console.error('Error processing token:', error);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }

      setIsProcessing(false);
    };

    processToken();
  }, [token, auth]);

  if (isProcessing) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return <Navigate to={isValid ? AppPaths.main : AppPaths.login} />;
};
