import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.watchLists};
  }
`;

export const StyledSearchContainer = styled(Box)`
  border-top: 1px solid ${COLORS.stroke.primary};
  border-bottom: 1px solid ${COLORS.stroke.primary};
  padding: 0 14px;
`;

export const StyledContent = styled(Box)`
  display: grid;
  grid-template-columns: minmax(222px, 1fr) minmax(510px, 1fr);
  min-height: calc(-58.4px + 66vh);
`;

export const StyledLeftContainer = styled(Box)`
  border-right: 1px solid ${COLORS.stroke.primary};
  padding: 32px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledMyWatchlistsTypography = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: ${COLORS.typography.main};
`;

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 12px;
  min-height: 56vh;
`;
