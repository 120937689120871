import {
  TableCell,
  TableHead,
  styled as MUIstyled,
  TableRow,
} from '@mui/material';
import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledTableHead = MUIstyled(TableHead)({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  backgroundColor: 'unset',
  '& th': {
    '&:first-child': {
      zIndex: 3,
    },
    '&:last-child': {
      paddingRight: 0,
    },
    '&:nth-child(2)': {
      position: 'sticky',
      left: 244,
      zIndex: 3,
      paddingLeft: '8px',
    },
  },
});

export const StyledHeaderRow = MUIstyled(TableRow)({
  height: '15px',
  backgroundColor: COLORS.accent.disabled,
  '& th': {
    padding: '0 4px',
    height: '15px',
  },
});

export const StyledSelectedFundsCell = MUIstyled(TableCell)({
  width: 234,
  position: 'sticky',
  left: 0,
  zIndex: 2,
  backgroundColor: COLORS.background.secondary,
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  border: `1px solid ${COLORS.stroke.primary}`,
  borderBottom: 0,
  verticalAlign: 'top',
  paddingTop: '22px',
  marginRight: '4px',
});

export const StyledFirstCell = MUIstyled(TableCell)({
  width: 234,
  position: 'sticky',
  left: 0,
  zIndex: 2,
  backgroundColor: COLORS.accent.disabled,
  border: `1px solid ${COLORS.accent.disabled}`,
  borderBottom: 0,
  verticalAlign: 'top',
  paddingTop: '22px',
  marginRight: '4px',
});

export const StyledTableCell = MUIstyled(TableCell)({
  backgroundColor: 'unset',
  fontWeight: 'bold',
  padding: '0 4px',
  borderBottom: 0,
  width: 325,
});

export const StyledTableCellContainer = styled(Box)`
  background-color: ${COLORS.background.secondary};
  height: 76px;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid ${COLORS.stroke.primary};
  border-bottom: 0;

  position: relative;
`;

export const StyledFirstTableCellContainer = styled(Box)`
  background-color: ${COLORS.accent.disabled};
  height: 15px;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid ${COLORS.accent.disabled};
  border-bottom: 0;

  position: relative;
`;
