import { FC } from 'react';
import { PaginationProps } from './Pagination.d';
import { Box } from '../Box';
import { StyledContent, StyledIconButton } from './Pagination.styled';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow-up.svg';
import { COLORS } from 'theme/colors';
import { TableSelect } from '../TableSelect';
import { useLocation } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

const PAGE_SIZE_OPTiONS = [10, 20, 30, 40, 50];

export const Pagination: FC<PaginationProps> = ({
  pagesNumber,
  defaultPageSize = 10,
}) => {
  const { pathname, search } = useLocation();
  const { customNavigate } = useNavigation();
  const query = new URLSearchParams(search);
  const currentPage = parseInt(query.get('page') || '1', 10);
  const pageSize = parseInt(
    query.get('page-size') || defaultPageSize.toString(),
    10,
  );
  const isLastPage = currentPage === pagesNumber;
  const isFirstPage = currentPage === 1;
  const arrayWithExistPages = Array.from(
    { length: pagesNumber },
    (_, index) => index + 1,
  );

  const goToPreviousPage = () => {
    if (!isFirstPage) {
      const nextPage = currentPage > 1 ? currentPage - 1 : 1;
      const searchParams = new URLSearchParams(search);
      searchParams.set('page', nextPage.toString());
      if (pageSize !== defaultPageSize) {
        searchParams.set('page-size', pageSize.toString());
      }
      customNavigate(`${pathname}?${searchParams.toString()}`);
    }
  };
  const goToNextPage = () => {
    if (!isLastPage) {
      const searchParams = new URLSearchParams(search);
      const nextPage = currentPage + 1;
      searchParams.set('page', nextPage.toString());
      if (pageSize !== defaultPageSize) {
        searchParams.set('page-size', pageSize.toString());
      }
      customNavigate(`${pathname}?${searchParams.toString()}`);
    }
  };

  const goToPage = (event: SelectChangeEvent<any>) => {
    const newPage = +event.target.value;
    const searchParams = new URLSearchParams(search);
    searchParams.set('page', newPage.toString());
    if (pageSize !== defaultPageSize) {
      searchParams.set('page-size', pageSize.toString());
    }
    customNavigate(`${pathname}?${searchParams.toString()}`);
  };

  const updatePageSize = (event: SelectChangeEvent<any>) => {
    const newPageSize = +event.target.value;
    const searchParams = new URLSearchParams(search);
    searchParams.delete('page');
    if (newPageSize !== defaultPageSize) {
      searchParams.set('page-size', newPageSize.toString());
    } else {
      searchParams.delete('page-size');
    }
    customNavigate(`${pathname}?${searchParams.toString()}`);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" gap={1.5}>
        <StyledContent>Fonds pro Seite:</StyledContent>
        <TableSelect
          value={pageSize}
          options={PAGE_SIZE_OPTiONS}
          onChange={updatePageSize}
        />
      </Box>
      <Box display="flex" alignItems="center" gap={4.5}>
        <Box display="flex" alignItems="center" gap={1.5}>
          <TableSelect
            value={+currentPage}
            options={arrayWithExistPages}
            onChange={goToPage}
          />
          <StyledContent>Seiten {pagesNumber}</StyledContent>
        </Box>
        <Box display="flex" alignItems="center" gap={4} mr={1}>
          <StyledIconButton disabled={isFirstPage} onClick={goToPreviousPage}>
            <ArrowIcon
              fill={COLORS.typography.primary}
              style={{ transform: 'rotate(180deg)' }}
            />
          </StyledIconButton>
          <StyledIconButton disabled={isLastPage} onClick={goToNextPage}>
            <ArrowIcon fill={COLORS.typography.primary} />
          </StyledIconButton>
        </Box>
      </Box>
    </Box>
  );
};
