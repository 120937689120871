import { FC } from 'react';
import { DashboardCardItemProps } from './DashboardCardItem.d';
import { Box } from 'components/common/Box';
import {
  StyledBorderLinearProgress,
  StyledFundName,
  StyledValue,
} from './DashboardCardItem.styled';

export const DashboardCardItem: FC<DashboardCardItemProps> = ({
  name,
  value,
  isWarning,
  maxValue,
}) => {
  const getLinearProgressValue = () => {
    if (value === null) {
      return 0;
    }

    if (isWarning && value > 0) {
      return 0;
    }

    if (!isWarning && value < 0) {
      return 0;
    }

    return (100 * Math.abs(value)) / maxValue;
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <StyledFundName variant="body">{name || '...'}</StyledFundName>
      <Box display="flex" alignItems="center" gap={1}>
        <StyledBorderLinearProgress
          isWarning={isWarning}
          variant="determinate"
          value={getLinearProgressValue()}
        />
        <StyledValue variant="body">{value ?? '-'}</StyledValue>
      </Box>
    </Box>
  );
};
