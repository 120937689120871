import { FC, useEffect, useRef, useState } from 'react';
import { Table, TableHead, TableBody } from '@mui/material';
import {
  EmptyColumn,
  FixedColumn,
  FixedHeadColumn,
  HeaderWithIcon,
  HighlightedColumn,
  ScrollableBodyColumn,
  ScrollableHeadColumn,
  ScrollableSubHeadColumn,
  ScrollPlaceholder,
  StyledArrowIcon,
  StyledBodyTableRow,
  StyledIconContainer,
  StyledInfoIcon,
  StyledTableRow,
  TableContainer,
  TableWrapper,
  TopScrollContainer,
} from './PortfolioCollapseTable.styled';
import { COLORS } from 'theme/colors';
import {
  PortfolioCollapseTableProps,
  RowProps,
} from './PortfolioCollapseTable.d';
import { CustomTooltip } from '../Tooltip';
import { SelectCell } from './SelectCell';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ReactComponent as UnsortedIcon } from 'assets/Icons/unsorted.svg';
import { ReactComponent as AscendingIcon } from 'assets/Icons/ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/Icons/descending.svg';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { LoadingSkeleton } from './LoadingSkeleton';

const ROWS_THRESHOLD_FOR_SCROLLING = 7;

export const PortfolioCollapseTable: FC<PortfolioCollapseTableProps> = ({
  tableData,
  loading,
  selectedRows,
  sortModel,
  isLastColumnsFixed = true,
  bottomPadding = 20,
  onSortModelChange,
  setSelectedRows,
}) => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { columns, rows } = tableData;
  const isSelectAll = selectedRows.length !== 0;
  const [expandedColumns, setExpandedColumns] = useState<
    Record<string, boolean>
  >(Object.fromEntries(columns.map((col) => [col.id, false])));
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);
  const topScrollRef = useRef<HTMLDivElement>(null);
  const mainScrollRef = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState(800);

  const syncScroll = (primary: HTMLDivElement, secondary: HTMLDivElement) => {
    secondary.scrollLeft = primary.scrollLeft;
  };

  const calculateHeight = () => {
    if (containerRef.current) {
      const windowHeight = window.innerHeight;
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const fixedHeaderHeight = 150;

      const availableHeight = windowHeight - containerTop - bottomPadding;

      const minimumHeight = Math.min(windowHeight - fixedHeaderHeight, 600);

      const newHeight = Math.min(availableHeight, minimumHeight);

      setContainerHeight(newHeight);
    }
  };

  const updateTableWidth = () => {
    const table = mainScrollRef.current?.querySelector('table');
    if (table) {
      setTableWidth(table.scrollWidth);
    }
  };

  useEffect(() => {
    calculateHeight();

    const handleResize = () => {
      calculateHeight();
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [bottomPadding, tableData, searchParams]);

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateHeight();
    }, 200);

    return () => clearTimeout(timer);
  }, [searchParams]);

  useEffect(() => {
    const timer = setTimeout(updateTableWidth, 100);
    return () => clearTimeout(timer);
  }, [tableData, expandedColumns]);

  const toggleSubColumns = (columnId: string) => {
    setExpandedColumns((prev) => ({ ...prev, [columnId]: !prev[columnId] }));
  };

  const updateSelectedRows = (e: React.BaseSyntheticEvent, id: string) => {
    e.stopPropagation();
    const index = selectedRows.indexOf(id);
    const newSelectedRows: string[] =
      index === -1
        ? [...selectedRows, id]
        : selectedRows.filter((_, i) => i !== index);
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAllRows = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    setSelectedRows(isSelectAll ? [] : rows.map((row) => row.id));
  };

  const handleSortClick = (field: string, sortKey?: string) => {
    if (sortModel && onSortModelChange && sortModel.sort_by === field) {
      if (sortModel.sort_order === 'asc') {
        const newSearchParams = new URLSearchParams(search);
        newSearchParams.set('sort', `-${field}`);
        newSearchParams.delete('page');
        setSearchParams(newSearchParams);
        onSortModelChange({ sort_by: sortKey || field, sort_order: 'desc' });
        return;
      }
      if (sortModel.sort_order === 'desc') {
        const newSearchParams = new URLSearchParams(search);
        onSortModelChange(undefined);
        newSearchParams.delete('sort');
        newSearchParams.delete('page');
        setSearchParams(newSearchParams);
        return;
      }
    }
    if (!!onSortModelChange) {
      const newSearchParams = new URLSearchParams(search);
      newSearchParams.set('sort', field);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
      onSortModelChange({ sort_by: sortKey || field, sort_order: 'asc' });
    }
  };

  const getSortIcon = (field: string, color?: string) => {
    if (sortModel && onSortModelChange && sortModel.sort_by === field) {
      if (sortModel.sort_order === 'asc') {
        return <AscendingIcon />;
      }
      if (sortModel.sort_order === 'desc') {
        return <DescendingIcon />;
      }
    }
    return (
      !!onSortModelChange && (
        <UnsortedIcon stroke={color || COLORS.typography.placeholder} />
      )
    );
  };

  const handleDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button !== 0) return;

    let isDragging = false;
    let startX = e.clientX;
    const tableContainer = e.currentTarget.querySelector('div');

    const dragTimeout = setTimeout(() => {
      const selection = window.getSelection();
      if (!selection || selection.toString().length === 0) {
        isDragging = true;
      }
    }, 100);

    const onMouseMove = (moveEvent: MouseEvent) => {
      if (!isDragging || !tableContainer) return;
      const dx = moveEvent.clientX - startX;
      startX = moveEvent.clientX;
      tableContainer.scrollLeft -= dx;
    };

    const onMouseUp = () => {
      clearTimeout(dragTimeout);
      isDragging = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const fixedLeftColumn = columns[0];
  const fixedRightColumn = columns[columns.length - 1];

  const getColumns = () => {
    if (isLastColumnsFixed) {
      return columns.slice(1, -1);
    }
    return columns.slice(1);
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <TableWrapper
      onMouseDown={handleDrag}
      ref={containerRef}
      maxHeight={containerHeight || undefined}
    >
      {rows.length > ROWS_THRESHOLD_FOR_SCROLLING && (
        <TopScrollContainer
          ref={topScrollRef}
          onScroll={() =>
            topScrollRef.current &&
            mainScrollRef.current &&
            syncScroll(topScrollRef.current, mainScrollRef.current)
          }
        >
          <ScrollPlaceholder style={{ minWidth: `${tableWidth}px` }} />
        </TopScrollContainer>
      )}
      <TableContainer
        isLastColumnsFixed={isLastColumnsFixed}
        ref={mainScrollRef}
        onScroll={() =>
          mainScrollRef.current &&
          topScrollRef.current &&
          syncScroll(mainScrollRef.current, topScrollRef.current)
        }
      >
        <Table>
          <TableHead>
            <StyledTableRow>
              <EmptyColumn
                isLastColumnsFixed={isLastColumnsFixed}
                colSpan={3}
              />
              {getColumns().map((col) => {
                const visibleSubColumnsNumber =
                  col.visibleSubColumnsNumber || 3;
                const visibleSubColumns = expandedColumns[col.id]
                  ? col.subColumns
                  : col.subColumns.slice(0, visibleSubColumnsNumber);
                return (
                  <ScrollableHeadColumn
                    isLastSubColumn
                    rowExpandalbe={
                      col.subColumns.length > visibleSubColumnsNumber
                    }
                    key={col.id}
                    align="center"
                    colSpan={visibleSubColumns.length}
                    rowExpanded={!!expandedColumns[col.id]}
                    onClick={() =>
                      col.subColumns.length >= visibleSubColumnsNumber
                        ? toggleSubColumns(col.id)
                        : undefined
                    }
                  >
                    <HeaderWithIcon>
                      {col.subColumns.length > visibleSubColumnsNumber && (
                        <>
                          {expandedColumns[col.id] ? (
                            <StyledArrowIcon
                              fill={COLORS.info.selectedBorder}
                              style={{ transform: 'rotate(90deg)' }}
                            />
                          ) : (
                            <StyledArrowIcon fill={COLORS.typography.chip} />
                          )}
                        </>
                      )}
                      <span>{col.label}</span>
                      {col.tooltip && (
                        <CustomTooltip title={col.tooltip} placement="top">
                          <StyledInfoIcon
                            stroke={COLORS.typography.placeholder}
                          />
                        </CustomTooltip>
                      )}
                    </HeaderWithIcon>
                  </ScrollableHeadColumn>
                );
              })}
              {isLastColumnsFixed && (
                <EmptyColumn
                  isLastColumnsFixed={isLastColumnsFixed}
                  width={fixedRightColumn.width}
                  style={{ maxWidth: fixedRightColumn.width }}
                />
              )}
            </StyledTableRow>
            <StyledTableRow>
              <HighlightedColumn
                highlightColor={COLORS.background.module}
                isChecked={selectedRows.length === tableData.rows.length}
              />
              <SelectCell
                variant="table-head"
                isChecked={selectedRows.length === tableData.rows.length}
                isSelectAll={
                  isSelectAll && selectedRows.length !== tableData.rows.length
                }
                isOpen={false}
                setIsChecked={handleSelectAllRows}
              />
              <FixedHeadColumn
                isLastColumnsFixed={isLastColumnsFixed}
                onClick={() =>
                  fixedLeftColumn.sortable
                    ? handleSortClick(fixedLeftColumn.id)
                    : undefined
                }
              >
                <Box display="flex" gap={1} justifyContent="flex-start">
                  {fixedLeftColumn.label}
                  {fixedLeftColumn.sortable && (
                    <StyledIconContainer>
                      {getSortIcon(fixedLeftColumn.id)}
                    </StyledIconContainer>
                  )}
                </Box>
              </FixedHeadColumn>
              {getColumns().flatMap((col) => {
                const visibleSubColumnsNumber =
                  col.visibleSubColumnsNumber || 3;
                const visibleSubColumns = expandedColumns[col.id]
                  ? col.subColumns
                  : col.subColumns.slice(0, visibleSubColumnsNumber);
                return visibleSubColumns.map((subCol, index) => {
                  const isLastSubColumn =
                    index === visibleSubColumns.length - 1;
                  return (
                    <ScrollableSubHeadColumn
                      key={subCol.id}
                      align={subCol.headAlign}
                      isLastSubColumn={isLastSubColumn}
                      onClick={() =>
                        subCol.sortable
                          ? handleSortClick(subCol.id, subCol.sortKey)
                          : undefined
                      }
                      sx={{
                        ...(subCol.maxWidth
                          ? {
                              width: subCol.maxWidth,
                            }
                          : {}),
                        ...(subCol.minWidth
                          ? {
                              minWidth: subCol.minWidth,
                            }
                          : {}),
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={0.4}
                      >
                        {subCol.tooltip && (
                          <CustomTooltip
                            title={
                              subCol.tooltip2 ? (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={1}
                                >
                                  <Typography
                                    variant="body"
                                    color={COLORS.background.secondary}
                                    style={{ lineHeight: '17px' }}
                                  >
                                    {subCol.tooltip}
                                  </Typography>
                                  <Typography
                                    variant="body"
                                    color={COLORS.background.secondary}
                                    style={{ lineHeight: '17px' }}
                                  >
                                    {subCol.tooltip2}
                                  </Typography>
                                </Box>
                              ) : (
                                <>{subCol.tooltip}</>
                              )
                            }
                            placement="top"
                          >
                            <StyledInfoIcon
                              stroke={COLORS.typography.placeholder}
                            />
                          </CustomTooltip>
                        )}
                        {subCol.label}
                        {subCol.sortable && (
                          <StyledIconContainer>
                            {getSortIcon(subCol.id)}
                          </StyledIconContainer>
                        )}
                      </Box>
                    </ScrollableSubHeadColumn>
                  );
                });
              })}
              {isLastColumnsFixed && (
                <FixedHeadColumn
                  isLastColumnsFixed={isLastColumnsFixed}
                  width={fixedRightColumn.width}
                  style={{
                    maxWidth: fixedRightColumn.width,
                  }}
                >
                  {fixedRightColumn.label}
                </FixedHeadColumn>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledBodyTableRow key={`${row.source}-${row.id}`}>
                <HighlightedColumn
                  isChecked={selectedRows.includes(row.id)}
                  highlightColor={row.highlightColor}
                >
                  {row.highlightTooltip && (
                    <CustomTooltip
                      title={row.highlightTooltip}
                      placement="right"
                    >
                      <span> </span>
                    </CustomTooltip>
                  )}
                </HighlightedColumn>
                <SelectCell
                  highlightColor={row.highlightColor || undefined}
                  gradientColor={row.gradientColor || undefined}
                  isChecked={selectedRows.includes(row.id)}
                  isOpen={false}
                  setIsChecked={(e) => updateSelectedRows(e, row.id)}
                />
                <FixedColumn
                  highlightColor={row.highlightColor || undefined}
                  isLastColumnsFixed={isLastColumnsFixed}
                  selected={selectedRows.includes(row.id)}
                  gradientColor={row.gradientColor}
                >
                  {fixedLeftColumn.renderCell
                    ? fixedLeftColumn.renderCell({
                        row,
                        value: row[fixedLeftColumn.id as keyof RowProps],
                      })
                    : row[fixedLeftColumn.id as keyof RowProps]}
                </FixedColumn>
                {getColumns().flatMap((col) => {
                  const visibleSubColumnsNumber =
                    col.visibleSubColumnsNumber || 3;
                  const visibleSubColumns = expandedColumns[col.id]
                    ? col.subColumns
                    : col.subColumns.slice(0, visibleSubColumnsNumber);
                  return visibleSubColumns.map((subCol, index) => {
                    const isLastSubColumn =
                      index === visibleSubColumns.length - 1;
                    return (
                      <ScrollableBodyColumn
                        isLastSubColumn={isLastSubColumn}
                        key={subCol.id}
                        align={subCol.cellAlign}
                        selected={selectedRows.includes(row.id)}
                      >
                        {subCol.renderCell
                          ? subCol.renderCell({ row, value: row[subCol.id] })
                          : row[subCol.id]}
                      </ScrollableBodyColumn>
                    );
                  });
                })}
                {isLastColumnsFixed && (
                  <FixedColumn
                    highlightColor={row.highlightColor || undefined}
                    isLastColumnsFixed={isLastColumnsFixed}
                    width={fixedRightColumn.width}
                    style={{
                      maxWidth: fixedRightColumn.width,
                      textAlign: fixedRightColumn.textAlign || 'center',
                    }}
                  >
                    {fixedRightColumn.renderCell
                      ? fixedRightColumn.renderCell({
                          row,
                          value: row[fixedRightColumn.id as keyof RowProps],
                        })
                      : row[fixedRightColumn.id as keyof RowProps]}
                  </FixedColumn>
                )}
              </StyledBodyTableRow>
            ))}
            {!loading && tableData.rows.length === 0 && (
              <tr>
                <td
                  colSpan={tableData.columns.length - 1}
                  style={{
                    padding: '32px',
                  }}
                >
                  <Typography variant="body">Keine Fonds gefunden</Typography>
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};
