export const permissions = {
  user: {
    mlp_depots_access: 'users.mlp_depots_access',
    invite_users: 'users.invite_users',
    plansecur_admin_access: 'users.plansecur_admin_access',
    plansecur_fonds_pate_access: 'users.plansecur_fonds_pate_access',
  },
  customerOverview: {
    plansecurMessageReadAccess:
      'customer_overview.plansecur_message_read_access',
    plansecurMessageWriteAccess:
      'customer_overview.plansecur_message_write_access',
    fondsconsultMessageReadAccess:
      'customer_overview.fondsconsult_message_read_access',
    fondsconsultMessageWriteAccess:
      'customer_overview.fondsconsult_message_write_access',
  },
};
