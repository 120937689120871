import { Chip } from 'components/common/Chip';
import { ArrowIconButton } from 'components/common/IconButtons/ArrowIconButton';
import { Typography } from 'components/common/Typography';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledLinkProps } from './OverviewItem.d';

export const StyledArrowIcon = styled(ArrowIconButton)`
  background-color: ${COLORS.background.secondary};

  & svg {
    stroke: ${COLORS.typography.primary};
  }
`;

export const StyledExternalArrowIcon = styled.div`
  background-color: ${COLORS.background.secondary};
  border-radius: 50%;
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledChip = styled(Chip)`
  & span {
    font-size: 11px;
  }
`;
export const StyledTypography = styled(Typography)``;

export const StyledContainer = styled(Link)<StyledLinkProps>`
  text-decoration: none;
  background-color: ${({ backgroundColor, disabled }) =>
    disabled
      ? COLORS.background.grey
      : backgroundColor ?? COLORS.background.grey};
  color: ${COLORS.typography.primary};
  border-radius: 16px;
  transition-duration: 0.4s;
  display: flex;
  flex-direction: ${({ fullWidth }) => (fullWidth ? 'row-reverse' : 'column')};

  justify-content: space-between;

  padding: ${({ fullWidth }) => (fullWidth ? '5px 32px' : '32px')};
  flex: ${({ fullWidth }) => (fullWidth ? '1 1 100%' : '1 1 39%')};

  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled, hoverImageLink }) =>
    !disabled &&
    css`
      &:hover {
        ${hoverImageLink && `background-image: url(${hoverImageLink})`};
        background-size: cover;

        ${StyledArrowIcon} {
          background-color: white;

          svg {
            stroke: ${COLORS.info.selectedBorder};
          }
        }

        ${StyledExternalArrowIcon} {
          background-color: white;

          svg {
            stroke: ${COLORS.info.selectedBorder};
          }
        }

        ${StyledChip} {
          color: ${COLORS.background.secondary};
          border-color: transparent;
          background-color: rgba(255, 255, 255, 0.2);
        }

        ${StyledTypography} {
          color: ${COLORS.background.grey};
        }
      }
    `}
`;

export const LabelContainer = styled.div`
  width: 80%;
  display: flex;
`;
