import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { StyledCommentContainerProps } from './CommentItem.d';

export const StyledCommentContainer = styled(Box)<StyledCommentContainerProps>`
  ${({ isLast }) => !isLast && `border-left: 1px solid ${COLORS.stroke.main};`}
  margin-left: ${SIZES.spacing(2)};
  margin-top: ${SIZES.spacing(2.1)};
  padding: ${SIZES.spacing(1.5)} 0;
`;

export const StyledComment = styled(Box)`
  border-radius: 8px;
  background-color: ${COLORS.background.module};
  padding: ${SIZES.spacing(2.5)};
  margin-left: ${SIZES.spacing(5)};

  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: ${COLORS.typography.description};
`;

export const StyledName = styled(Typography)`
  font-size: 18px;
  line-height: 15px;
`;

export const StyledDate = styled(Typography)`
  font-size: 12px;
  line-height: 10px;
  color: ${COLORS.typography.info};
  text-transform: capitalize;
`;
