import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import {
  SlideOutModalProps,
  StyledCurrentVersionProps,
  StyledDateItemProps,
  StyledVersionHistoryProps,
} from './VersionHistoryModal.d';
import { Box } from 'components/common/Box';
import ArrowIcon from 'assets/Icons//version-arrow.svg';
import VersionIcon from 'assets/Icons/version.svg';
import CurrentVersionIcon from 'assets/Icons/point.svg';

export const StyledSlideOutModal = styled.div<SlideOutModalProps>`
  position: fixed;
  top: 0;
  right: -325px;
  width: 320px;
  height: 100vh;
  background-color: ${COLORS.background.secondary};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.4s ease;
  z-index: 20;
  display: flex;
  flex-direction: column;
  ${({ isShown }) =>
    isShown &&
    `
    right: 0;
  `}
`;

export const StyledModalHeader = styled.div`
  padding: 12.5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.stroke.primary};
`;

export const StyledCloseButton = styled.button`
  width: 20px;
  height: 20px;
  background-color: unset;
  border: none;
  justify-self: end;

  &:hover {
    cursor: pointer;

    svg {
      stroke: ${COLORS.typography.caption};
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledModalContent = styled.div<StyledVersionHistoryProps>`
  overflow-y: auto;
  ${({ noData }) => !noData && 'flex: 1;'}
  padding: 22px 20px 0px;

  display: flex;
`;

export const StyledVersionsContainer = styled(Box)`
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid ${COLORS.stroke.primary};
`;

export const StyledCurrentVersion = styled.div<StyledCurrentVersionProps>`
  position: relative;

  font-size: 14px;
  line-height: 17px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;

  padding: 7.5px 16px 7.5px;

  &:hover {
    ${({ isDateSelected }) => !isDateSelected && 'cursor: pointer;'}
  }

  &::before {
    content: '';
    position: absolute;
    left: -32px;
    top: 0;
    width: 32px;
    height: 32px;
    background-image: url(${({ isDateSelected }) =>
      isDateSelected ? CurrentVersionIcon : VersionIcon});
  }
`;

export const StyledVersions = styled.div<StyledVersionHistoryProps>`
  position: relative;

  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  padding: 7.5px 16px 7.5px;

  ${({ noData }) => noData && 'padding-bottom: 0;'}

  &::before {
    content: '';
    position: absolute;
    left: -32px;
    ${({ noData }) => noData && 'bottom: 0;'}
    width: 32px;
    height: 32px;
    background-image: url(${ArrowIcon});

    ${({ noData }) => noData && 'transform: rotate(-180deg);'}
  }
`;

export const StyledDate = styled.div<StyledDateItemProps>`
  position: relative;

  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-left: 16px;
  padding: 8.5px 16px 8.5px;
  border-radius: 4px;

  ${({ selected }) =>
    selected &&
    css`
      color: ${COLORS.info.selectedBorder};
      background-color: ${COLORS.background.neutral};
    `}

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        cursor: pointer;
        background-color: ${COLORS.background.grey};
      `}
  }

  ${({ selected }) =>
    selected &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: -48px;
        top: 0;
        width: 32px;
        height: 32px;
        background-image: url(${CurrentVersionIcon});
      }
    `}
`;
