import { FC } from 'react';
import {
  BodyTableCell,
  GroupHeaderCell,
  HeaderCell,
  HeaderGroupsCell,
  StyledContainer,
  StyledIconContainer,
  StyledInfoIcon,
  StyledPaper,
  StyledSubLabel,
  StyledTable,
  StyledTableContainer,
} from './FundVolumeAndNetInflowsTable.styled';
import { TableBody, TableHead, TableRow } from '@mui/material';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import {
  FundVolumeAndNetInflowsTableProps,
  GroupColumn,
  RegularColumn,
} from './FundVolumeAndNetInflowsTable.d';
import { ReactComponent as UnsortedIcon } from 'assets/Icons/unsorted.svg';
import { ReactComponent as AscendingIcon } from 'assets/Icons/ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/Icons/descending.svg';
import { useLocation, useSearchParams } from 'react-router-dom';

export const FundVolumeAndNetInflowsTable: FC<
  FundVolumeAndNetInflowsTableProps
> = ({
  data = [],
  columns = [],
  headerGroups = [],
  primaryColumns = [],
  title = 'Generic table',
  sortModel,
  onSortModelChange,
  emptyValue = '-',
}) => {
  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const handleSortClick = (field: string, sortKey?: string) => {
    if (
      sortModel &&
      onSortModelChange &&
      sortModel.sort_by === (sortKey || field)
    ) {
      if (sortModel.sort_order === 'asc') {
        const newSearchParams = new URLSearchParams(search);
        newSearchParams.set('sort', `-${sortKey || field}`);
        newSearchParams.delete('page');
        setSearchParams(newSearchParams);
        onSortModelChange({ sort_by: sortKey || field, sort_order: 'desc' });
        return;
      }
      if (sortModel.sort_order === 'desc') {
        const newSearchParams = new URLSearchParams(search);
        onSortModelChange(undefined);
        newSearchParams.delete('sort');
        newSearchParams.delete('page');
        setSearchParams(newSearchParams);
        return;
      }
    }
    if (!!onSortModelChange) {
      const newSearchParams = new URLSearchParams(search);
      newSearchParams.set('sort', sortKey || field);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
      onSortModelChange({ sort_by: sortKey || field, sort_order: 'asc' });
    }
  };

  const getSortIcon = (field: string, sortKey?: string) => {
    if (
      sortModel &&
      onSortModelChange &&
      sortModel.sort_by === (sortKey || field)
    ) {
      if (sortModel.sort_order === 'asc') {
        return <AscendingIcon />;
      }
      if (sortModel.sort_order === 'desc') {
        return <DescendingIcon />;
      }
    }
    return (
      !!onSortModelChange && (
        <UnsortedIcon stroke={COLORS.typography.placeholder} />
      )
    );
  };

  const calculateHeaderRows = () => {
    const hasGroups = headerGroups.length > 0;

    if (!hasGroups) {
      return (
        <TableRow>
          {columns.map((col) => (
            <HeaderCell key={col.id} align={col.align}>
              {col.label}
            </HeaderCell>
          ))}
        </TableRow>
      );
    }

    const rows = [];

    const subgroupRows = headerGroups.filter(
      (group) => group.subgroups && group.subgroups.length > 0,
    ).length;
    const totalHeaderRows = 1 + subgroupRows + 1;

    const topRow = (
      <TableRow key="top-row">
        {primaryColumns.map((colId) => {
          const col = columns.find((c) => c.id === colId);
          return col ? (
            <HeaderCell
              key={col.id}
              rowSpan={totalHeaderRows}
              align={col.align}
              onClick={() =>
                col.sortable ? handleSortClick(col.id, col.sortKey) : undefined
              }
            >
              <Box
                display="flex"
                gap={0.5}
                justifyContent={!!col.subLabel ? 'flex-end' : 'flex-start'}
              >
                <Box display="flex" flexDirection="column" gap={0.5}>
                  {col.label}
                  {!!col.subLabel && (
                    <StyledSubLabel>{col.subLabel}</StyledSubLabel>
                  )}
                </Box>
                {col.sortable && (
                  <StyledIconContainer ml={0.5}>
                    {getSortIcon(col.id, col.sortKey)}
                  </StyledIconContainer>
                )}
              </Box>
            </HeaderCell>
          ) : null;
        })}

        {headerGroups.map((group) => (
          <HeaderGroupsCell key={group.id} colSpan={group.colspan}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1.2}
            >
              {group.label}
              {!!group.tooltip && (
                <CustomTooltip title={group.tooltip} placement="top">
                  <StyledInfoIcon stroke={COLORS.stroke.main} />
                </CustomTooltip>
              )}
            </Box>
          </HeaderGroupsCell>
        ))}
      </TableRow>
    );
    rows.push(topRow);

    headerGroups.forEach((group) => {
      if (group.subgroups && group.subgroups.length > 0) {
        const subgroupRow = (
          <TableRow key={`subgroup-${group.id}`}>
            {group.subgroups.map((subgroup) => (
              <GroupHeaderCell
                key={subgroup.id}
                colSpan={subgroup.colspan}
                align={subgroup.align}
              >
                {subgroup.label}
              </GroupHeaderCell>
            ))}
          </TableRow>
        );
        rows.push(subgroupRow);
      }
    });

    const leafColumns = columns.filter(
      (col) => !primaryColumns.includes(col.id),
    );
    const leafRow = (
      <TableRow key="leaf-row">
        {leafColumns.map((col) => (
          <HeaderCell
            key={col.id}
            align={col.align}
            onClick={() =>
              col.sortable ? handleSortClick(col.id, col.sortKey) : undefined
            }
            style={{ ...(col.subLabel ? { padding: '10px 16px' } : {}) }}
          >
            <Box display="flex" gap={0.5} justifyContent="flex-end">
              <Box display="flex" flexDirection="column" gap={0.2}>
                {col.label}
                {!!col.subLabel && (
                  <StyledSubLabel>{col.subLabel}</StyledSubLabel>
                )}
              </Box>
              {col.sortable && (
                <StyledIconContainer ml={0.5}>
                  {getSortIcon(col.id, col.sortKey)}
                </StyledIconContainer>
              )}
            </Box>
          </HeaderCell>
        ))}
      </TableRow>
    );
    rows.push(leafRow);

    return rows;
  };

  const renderCellValue = (row: any, column: RegularColumn | GroupColumn) => {
    let value;

    if (column.accessor) {
      value = column.accessor(row);
    } else {
      const path = column.id.split('.');
      value = path.reduce(
        (obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined),
        row,
      );
    }

    if (value === undefined || value === null) {
      return emptyValue;
    }

    return column.formatter ? column.formatter(value, row) : value;
  };

  return (
    <StyledContainer>
      <StyledTableContainer as={StyledPaper}>
        <StyledTable aria-label={title}>
          <TableHead>{calculateHeaderRows()}</TableHead>{' '}
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.id || index}>
                {columns.map((column) => (
                  <BodyTableCell
                    key={`${index}-${column.id}`}
                    align={column.align}
                  >
                    {renderCellValue(row, column)}
                  </BodyTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>{' '}
      </StyledTableContainer>
    </StyledContainer>
  );
};
