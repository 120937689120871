import styled from 'styled-components';
import { TableCell, TableRow, styled as MUIStyled, Table } from '@mui/material';
import { COLORS } from 'theme/colors';
import {
  FixedColumnProps,
  ScrollableColumnProps,
  StyledHighlightedColumnProps,
  StyledTableProps,
  TableWrapperProps,
} from './PortfolioCollapseTable.d';
import { SIZES } from 'theme/sizes';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { ReactComponent as ArrowIcon } from 'assets/Icons/filled-arrow.svg';
import { Box } from '../Box';

export const TableWrapper = styled.div<TableWrapperProps>`
  border-radius: 0 0 16px 16px;
  border: 1px solid ${COLORS.stroke.main};
  border-top: none;
  display: grid;
  grid-template-rows: auto 1fr;
  max-height: ${({ maxHeight }) =>
    maxHeight ? `calc(${maxHeight}px + 15vh)` : 'auto'};

  cursor: default;
  user-select: text;

  &:active {
    cursor: grabbing;
  }

  td,
  th {
    cursor: text;
  }
`;

export const TableContainer = styled.div<StyledTableProps>`
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
  min-height: 0;

  & table {
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;
    min-width: 800px;
    table-layout: auto;
  }

  & tbody tr:last-child td {
    border-bottom: 0 !important;
  }

  & thead tr:nth-of-type(2) > th:first-of-type {
    top: 56px;
    z-index: 2;
  }

  & td:nth-last-child(2),
  & th:nth-last-child(2) {
    ${({ isLastColumnsFixed }) => isLastColumnsFixed && 'border-right: 0;'}
  }
`;

export const StyledTableRow = styled(TableRow)({
  height: 56,
  display: 'table-row',
});

export const StyledBodyTableRow = MUIStyled(TableRow)(() => ({
  background: COLORS.background.secondary,
  height: 80,
}));

export const HighlightedColumn = styled(
  TableCell,
)<StyledHighlightedColumnProps>`
  background-color: ${({ highlightColor, isChecked }) =>
    isChecked
      ? COLORS.background.neutral
      : highlightColor || COLORS.background.secondary};
  position: sticky;
  padding: 0 !important;
  border-right: 0;
  left: 0;
  z-index: 1;
  min-width: 3px;
  max-width: 3px;
  width: 3px;
  height: inherit;

  & span {
    color: ${({ highlightColor, isChecked }) =>
      isChecked
        ? COLORS.background.neutral
        : highlightColor || COLORS.background.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: help;
  }
`;

export const EmptyColumn = styled(TableCell)<StyledTableProps>`
  position: sticky;
  z-index: 1;
  background-color: ${COLORS.background.module};
  padding: 13px !important;

  &:first-of-type {
    border-right: 1px solid ${COLORS.stroke.main};
    left: 0;
    top: 0;
    min-width: 228px;
    z-index: 2;
  }
  &:last-of-type {
    ${({ isLastColumnsFixed }) => isLastColumnsFixed && 'right: 0;'}
    ${({ isLastColumnsFixed }) => isLastColumnsFixed && 'top: 0;'}
    ${({ isLastColumnsFixed }) => isLastColumnsFixed && 'z-index: 2;'}
    ${({ isLastColumnsFixed }) =>
      isLastColumnsFixed && `border-left: 1px solid ${COLORS.stroke.main};`}
    ${({ isLastColumnsFixed }) => isLastColumnsFixed && 'min-width: 40px;'}
  }

  ${SIZES.media.largeDesktop} {
    &:first-of-type {
      min-width: 268px;
    }
  }

  ${SIZES.media.ultraWideDesktop} {
    &:first-of-type {
      min-width: 368px;
    }
  }

  ${SIZES.media.extraLargeDesktop} {
    &:first-of-type {
      min-width: 468px;
    }
  }
`;

export const FixedColumn = styled(TableCell)<FixedColumnProps>`
  position: sticky;
  background-color: white;
  z-index: 1;
  min-width: 120px;
  padding: 13px !important;
  &:first-of-type {
    left: 0;
    ${({ selected }) =>
      selected && `background-color: ${COLORS.background.neutral} !important;`}
  }
  &:nth-of-type(2) {
    left: 3px;
    ${({ gradientColor }) =>
      gradientColor && `background: ${gradientColor} !important;`}
    ${({ selected }) =>
      selected && `background-color: ${COLORS.background.neutral} !important;`}
  }
  &:nth-of-type(3) {
    left: 49px;
    border-right: 1px solid ${COLORS.stroke.main};
    padding: 13px 0 !important;
    ${({ gradientColor }) =>
      gradientColor &&
      `background: linear-gradient(90deg, ${gradientColor} 0%, #ffffff 83%) !important;`}
    ${({ selected }) =>
      selected && `background: ${COLORS.background.neutral} !important;`}
  }
  &:last-of-type {
    ${({ isLastColumnsFixed }) => isLastColumnsFixed && 'right: 0;'}
    ${({ isLastColumnsFixed }) =>
      isLastColumnsFixed && `border-left: 1px solid ${COLORS.stroke.main};`}
    ${({ isLastColumnsFixed }) => isLastColumnsFixed && 'min-width: 40px;'}
  }

  ${SIZES.media.largeDesktop} {
    &:nth-of-type(3) {
      min-width: 160px;
    }
  }

  ${SIZES.media.ultraWideDesktop} {
    &:nth-of-type(3) {
      min-width: 260px;
    }
  }

  ${SIZES.media.extraLargeDesktop} {
    &:nth-of-type(3) {
      min-width: 360px;
    }
  }
`;

export const FixedHeadColumn = MUIStyled(TableCell)<StyledTableProps>(
  ({ isLastColumnsFixed }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    minWidth: 120,
    padding: '13px !important',
    backgroundColor: COLORS.background.module,
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: 0,
    color: COLORS.typography.description,
    '&:first-of-type': {
      left: 0,
    },
    '&:nth-of-type(2)': {
      left: 3,
      top: 56,
      zIndex: 2,
    },
    '&:nth-of-type(3)': {
      top: 56,
      left: 49,
      zIndex: 2,
      borderRight: `1px solid ${COLORS.stroke.main}`,
      padding: '13px 0 !important',
    },
    ...(isLastColumnsFixed
      ? {
          '&:last-of-type': {
            top: 56,
            zIndex: 2,
            right: 0,
            borderLeft: `1px solid ${COLORS.stroke.main}`,
            minWidth: 40,
            textAlign: 'right',
          },
        }
      : {}),
  }),
);

export const ScrollableHeadColumn = styled(TableCell)<ScrollableColumnProps>`
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: ${COLORS.background.module};
  text-align: center;
  padding: 13px !important;

  ${({ isLastSubColumn }) =>
    isLastSubColumn && `border-right: 1px solid ${COLORS.stroke.main};`}

  & span {
    color: ${({ rowExpanded, rowExpandalbe }) =>
      rowExpandalbe && rowExpanded
        ? COLORS.info.selectedBorder
        : COLORS.typography.chip};
  }

  &:hover {
    ${({ rowExpandalbe }) => rowExpandalbe && 'cursor: pointer;'}

    ${({ rowExpanded, rowExpandalbe }) =>
      rowExpandalbe &&
      !rowExpanded &&
      `background-color: ${COLORS.background.neutral};
      span {
        color: ${COLORS.background.hover};
      }
      ${StyledArrowIcon} {
        fill: ${COLORS.background.hover};
      }`}
  }
`;

export const ScrollableSubHeadColumn = MUIStyled(
  TableCell,
)<ScrollableColumnProps>(({ isLastSubColumn, align }) => ({
  position: 'sticky',
  zIndex: 1,
  top: 56,
  backgroundColor: COLORS.background.module,
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 600,
  letterSpacing: 0,
  color: COLORS.typography.description,
  textAlign: align || 'center',
  padding: '16px !important',
  whiteSpace: 'pre-line',
  ...(isLastSubColumn
    ? { borderRight: `1px solid ${COLORS.stroke.main}` }
    : {}),
}));

export const ScrollableBodyColumn = MUIStyled(TableCell)<ScrollableColumnProps>(
  ({ isLastSubColumn, align, selected }) => ({
    position: 'relative',
    textAlign: align || 'center',
    padding: '2.5px 16px !important',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 0,
    color: COLORS.typography.main,
    ...(isLastSubColumn
      ? { borderRight: `1px solid ${COLORS.stroke.main}` }
      : {}),
    ...(selected ? { backgroundColor: COLORS.background.neutral } : {}),
  }),
);

export const HeaderWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SIZES.spacing(1)};

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;

  & svg {
    flex-shrink: 0;
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)``;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      stroke: ${COLORS.typography.primary};
    }
  }
`;

export const TopScrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  height: 15px;
`;

export const ScrollPlaceholder = styled.div`
  height: 1px;
  width: 100%;
  min-width: 800px;
`;
