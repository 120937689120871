import { Box } from 'components/common/Box';
import { FC, useEffect, useState } from 'react';
import {
  StyledButtonContainer,
  StyledForm,
  StyledHeadline,
  StyledLink,
} from './ProfileForm.styled';
import { Input } from 'components/common/Input';
import { useForm } from 'react-hook-form';
import { ProfileFormProps } from './ProfileForm.d';
import { profileSchema } from './validators';
import { isValidForm } from 'utils/isValidForm';
import { PhoneInput } from 'components/common/PhoneInput';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import de from 'react-phone-number-input/locale/de.json';
import { Button } from 'components/common/Buttons/Button';
import { Typography } from 'components/common/Typography';
import { AppPaths } from 'urls/frontend';
import { COLORS } from 'theme/colors';
import { useProfile } from 'context/Profile';
import { fetchUpdateProfile } from 'services/Profile';
import { raiseToast } from 'components/common/Toast/raiseToast';

const defaultCountryCallingCode = {
  value: '+49',
  name: 'Deutschland +49',
  country: 'DE',
};

export const ProfileForm: FC = () => {
  const profile = useProfile();
  const [countryCallingCode, setCountryCallingCode] = useState(
    defaultCountryCallingCode,
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
    trigger,
  } = useForm<ProfileFormProps>({
    resolver: yupResolver(profileSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (profile?.profileInfo) {
      const phoneNumber = parsePhoneNumber(
        profile.profileInfo.phone_number ?? '',
      );
      const country = phoneNumber?.country;
      if (phoneNumber && country) {
        setCountryCallingCode({
          country,
          value: `+${phoneNumber.countryCallingCode}`,
          name: `${de[country]} +${getCountryCallingCode(country)}`,
        });
      }
      reset({
        first_name: profile.profileInfo.name ?? '',
        last_name: profile.profileInfo.last_name ?? '',
        company: profile.profileInfo.company ?? undefined,
        position: profile.profileInfo.position ?? '',
        phone_number: phoneNumber ? phoneNumber.nationalNumber : '',
        phone_number_code: phoneNumber
          ? `+${phoneNumber.countryCallingCode}`
          : '',
      });
    }
  }, [profile, reset]);

  const handleCountryCallingCodeChange = (newCountry: string) => {
    const [newSelectedCountry] = getCountries().filter(
      (country) => country === newCountry,
    );
    if (newSelectedCountry) {
      setCountryCallingCode({
        value: `+${getCountryCallingCode(newSelectedCountry)}`,
        name: `${de[newSelectedCountry]} +${getCountryCallingCode(
          newSelectedCountry,
        )}`,
        country: newSelectedCountry,
      });
      setValue('phone_number_code', countryCallingCode.value, {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    setValue('phone_number_code', countryCallingCode.value, {
      shouldDirty: true,
    });
    trigger('phone_number_code');
  }, [countryCallingCode]);

  const getCountryCodesOptions = () => {
    return getCountries().map((country) => ({
      value: `+${getCountryCallingCode(country)}`,
      name: `${de[country]} +${getCountryCallingCode(country)}`,
      country,
    }));
  };

  const onSubmit = async (data: ProfileFormProps) => {
    const { phone_number_code, ...formData } = data;
    const dataToSend = {
      ...formData,
      name: data.first_name,
      phone_number: `${countryCallingCode.value}${data.phone_number}`,
    };
    const response = await fetchUpdateProfile(dataToSend);
    if (response.ok) {
      raiseToast.success('Profil erfolgreich aktualisiert');
    }
  };

  return (
    <>
      <Box pb={4}>
        <StyledHeadline variant="h2">Kontoinformationen</StyledHeadline>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Vorname"
            placeholder="Vorname"
            {...register('first_name', {
              required: true,
            })}
            error={!isValidForm(errors) && errors.first_name?.message}
          />
          <Input
            label="Nachname"
            placeholder="Nachname"
            {...register('last_name', {
              required: true,
            })}
            error={!isValidForm(errors) && errors.last_name?.message}
          />
          <Input
            label="Unternehmen"
            placeholder="Unternehmen"
            {...register('company', {
              required: true,
            })}
            error={!isValidForm(errors) && errors.company?.message}
          />
          <Input
            label="Position"
            placeholder="Position"
            {...register('position', {
              required: true,
            })}
            error={!isValidForm(errors) && errors.position?.message}
          />
          <PhoneInput
            label="Telefonnummer"
            {...register('phone_number', {
              required: true,
            })}
            phoneCodeName="phone_number_code"
            placeholder="Telefonnummer"
            codeValue={countryCallingCode}
            setCodeValue={handleCountryCallingCodeChange}
            options={getCountryCodesOptions()}
            error={!isValidForm(errors) && errors.phone_number?.message}
            codeError={
              !isValidForm(errors) && errors.phone_number_code?.message
            }
          />
          <Input
            label="E-Mail-Adresse"
            placeholder="E-Mail-Adresse"
            value={profile?.profileInfo?.email}
            readOnly
            helperText={
              <Box
                pt={0.5}
                display="flex"
                gap={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="body"
                  color={COLORS.typography.description}
                >
                  Möchten Sie Ihre E-Mail-Adresse ändern?
                </Typography>
                <StyledLink to={AppPaths.contuctUs}>
                  Kontaktieren Sie uns
                </StyledLink>
              </Box>
            }
          />
          <StyledButtonContainer>
            <Button fullWidth type="submit" disabled={!isDirty}>
              Änderungen speichern
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </Box>
    </>
  );
};
