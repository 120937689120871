import ConsultantImage1 from 'assets/Consultants/consultant-1.svg';
import ConsultantImage2 from 'assets/Consultants/consultant-2.svg';
import ConsultantImage3 from 'assets/Consultants/consultant-3.svg';
import ConsultantImage4 from 'assets/Consultants/consultant-4.svg';
import ConsultantImage5 from 'assets/Consultants/consultant-5.svg';

export const network = [
  {
    imageLink: ConsultantImage1,
    fullName: 'Jan Richter',
    position: 'Senior Analyst',
  },
  {
    imageLink: ConsultantImage2,
    fullName: 'Michael Wimmer',
    position: 'Senior Analyst',
  },
  {
    imageLink: ConsultantImage5,
    fullName: 'Barbara Claus',
    position: 'Senior Analystin',
  },
  {
    imageLink: ConsultantImage3,
    fullName: 'Maximilian Wellner',
    position: 'Analyst',
  },
  {
    imageLink: ConsultantImage4,
    fullName: 'Dominik Wagner',
    position: 'Junior Analyst',
  },
];
