import {
  ADD_MULTIPLE_FUNDS_TO_WATCHLIST_PLANSECUR,
  CHANGE_FUND_PORTFOLIO_STATUS,
  FONDSCONSULT_FUND_CHAT_MESSAGE,
  KOMPASS_DATA_SNAPSHOTS,
  KOMPASS_DESIGN,
  KOMPASS_EDIT_ADDITIONAL_INFO,
  KOMPASS_FILTER_CHOICES,
  KOMPASS_FUND,
  PLANSECUR_DASHBOARD,
  PLANSECUR_FUND,
  PLANSECUR_FUND_CHAT_MESSAGE,
  PLANSECUR_FUND_FILTER_CHOICES,
  PORTFOLIOS,
  PORTFOLIOS_FILTER_CHOICES,
  REQUEST_FUND_COMPETITIVE_ANALYSIS,
  REQUEST_FUND_OTHER,
  REQUEST_FUND_PORTRAIT,
  REQUEST_FUND_UPDATE,
} from 'urls/api';
import fetchAPI from './fetchApi';
import {
  DashboardType,
  PlansecurCommentItemProps,
  PlansecurFilterChoicesProps,
  PlansecurFundsProps,
  PlansecurKompassDesignItemProps,
} from 'types/Plansecur.d';
import { capitalizeWords } from 'utils/common';

export const fetchUpdateFundPortfolioStatus = (id: string) =>
  fetchAPI(CHANGE_FUND_PORTFOLIO_STATUS(id), {
    method: 'PATCH',
  });

interface AddRemoveFundToKompassRequest {
  fund: string;
  notes: string;
  type: 'Addition' | 'Removal';
}

export const fetchAddRemoveFundToCompassStatus = ({
  fund,
  notes,
  type,
}: AddRemoveFundToKompassRequest) =>
  fetchAPI(`${KOMPASS_DESIGN}/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fund, notes, type }),
  });

export const fetchWithdrawRequestToAddRemoveFromKompass = (id: string) =>
  fetchAPI(`${KOMPASS_DESIGN}/${id}/`, {
    method: 'DELETE',
  });

export const fetchUpdateFundCompassStatus = (id: string) =>
  fetchAPI(CHANGE_FUND_PORTFOLIO_STATUS(id), {
    method: 'PATCH',
  });

export const sendRequestFundPortfolio = (id: string) =>
  fetchAPI(REQUEST_FUND_PORTRAIT(id), {
    method: 'PATCH',
  });

export const sendRequestFundUpdate = (id: string) =>
  fetchAPI(REQUEST_FUND_UPDATE(id), {
    method: 'PATCH',
  });

export const sendRequestCompetitiveAnalysis = (id: string) =>
  fetchAPI(REQUEST_FUND_COMPETITIVE_ANALYSIS(id), {
    method: 'PATCH',
  });

export const sendOtherFundRequest = (id: string, message: string) =>
  fetchAPI(REQUEST_FUND_OTHER(id), {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ message }),
  });

export const sendPlansecurChatMessage = (id: string, message: string) =>
  fetchAPI(`${PLANSECUR_FUND_CHAT_MESSAGE}?fund_id=${id}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ message }),
  });

export const sendFondsconsultChatMessage = (id: string, message: string) =>
  fetchAPI(`${FONDSCONSULT_FUND_CHAT_MESSAGE}?fund_id=${id}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ message }),
  });

export const getFondsconsultChatMessages = (id: string) =>
  fetchAPI<PlansecurCommentItemProps[]>(
    `${FONDSCONSULT_FUND_CHAT_MESSAGE}?fund_id=${id}`,
  );

export const getPlansecurChatMessages = (id: string) =>
  fetchAPI<PlansecurCommentItemProps[]>(
    `${PLANSECUR_FUND_CHAT_MESSAGE}?fund_id=${id}`,
  );

export interface PlansecurFundRequest {
  [key: string]: string | number | string[];
}

interface PlansecurFundResponse extends Response {
  results: PlansecurFundsProps;
  count: number;
}

export const fetchPlansecurFund = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  filter_package,
  asset_class,
  ...restValues
}: PlansecurFundRequest) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', String(search));
  if (filter_package) params.append('filter_package', String(filter_package));
  if (asset_class) params.append('asset_class', String(asset_class));

  Object.entries(restValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        params.append(key, capitalizeWords(String(v)));
      });
    } else {
      params.append(key, capitalizeWords(String(value)));
    }
  });

  const queryString = params.toString();
  return fetchAPI<PlansecurFundResponse>(
    `${PLANSECUR_FUND}/${queryString ? `?${queryString}` : ''}`,
  );
};

export const fetchKompassFund = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: PlansecurFundRequest) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', String(search));

  Object.entries(restValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        params.append(key, capitalizeWords(String(v)));
      });
    } else {
      params.append(key, capitalizeWords(String(value)));
    }
  });

  const queryString = params.toString();
  return fetchAPI<PlansecurFundResponse>(
    `${KOMPASS_FUND}/${queryString ? `?${queryString}` : ''}`,
  );
};

interface KompassDesignResponse extends Response {
  results: { requests: PlansecurKompassDesignItemProps[] };
  count: number;
}

export const fetchKompassDesign = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
}: PlansecurFundRequest) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', String(search));

  const queryString = params.toString();
  return fetchAPI<KompassDesignResponse>(
    `${KOMPASS_DESIGN}/${queryString ? `?${queryString}` : ''}`,
  );
};

export const fetchPortfolios = ({
  id,
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: PlansecurFundRequest) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', String(search));

  Object.entries(restValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        params.append(key, capitalizeWords(String(v)));
      });
    } else {
      params.append(key, capitalizeWords(String(value)));
    }
  });

  const queryString = params.toString();
  return fetchAPI<PlansecurFundResponse>(
    `${PORTFOLIOS(id as string)}/${queryString ? `?${queryString}` : ''}`,
  );
};

interface AcceptRequestToChangeCompassRequest {
  id: string;
  status: 'Pending' | 'Rejected' | 'Accepted';
  reviewer_notes: string;
  allocation?: string;
}

export const fetchAcceptRequestToChangeCompass = ({
  id,
  status,
  reviewer_notes,
  allocation,
}: AcceptRequestToChangeCompassRequest) =>
  fetchAPI(`${KOMPASS_DESIGN}/${id}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ status, reviewer_notes, allocation }),
  });

export const fetchAddFundsToWatchlist = (ids: string[]) =>
  fetchAPI(ADD_MULTIPLE_FUNDS_TO_WATCHLIST_PLANSECUR, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ funds: ids }),
  });

export const getPlansecurFundFilterChoices = () =>
  fetchAPI<PlansecurFilterChoicesProps>(PLANSECUR_FUND_FILTER_CHOICES);

interface EditAdditionalInformationRequest {
  fundId: string;
  broker_notes?: string;
  best_in_class_approach?: string;
  norm_based_screening?: string;
  trail_commission?: string;
}

export const fetchEditAdditionalInformation = ({
  fundId,
  broker_notes,
  best_in_class_approach,
  norm_based_screening,
  trail_commission,
}: EditAdditionalInformationRequest) =>
  fetchAPI(`${KOMPASS_EDIT_ADDITIONAL_INFO(fundId)}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      broker_notes,
      best_in_class_approach,
      norm_based_screening,
      trail_commission,
    }),
  });

export const getCompassFilterChoices = () =>
  fetchAPI<PlansecurFilterChoicesProps>(KOMPASS_FILTER_CHOICES);

export const getPortfoliosFilterChoices = (id: string) =>
  fetchAPI<PlansecurFilterChoicesProps>(PORTFOLIOS_FILTER_CHOICES(id));

export const getCompassDataSnapshots = () =>
  fetchAPI<string[]>(KOMPASS_DATA_SNAPSHOTS);

export const getDashboardData = () =>
  fetchAPI<DashboardType>(PLANSECUR_DASHBOARD);
