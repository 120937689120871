import {
  ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS,
  ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING,
  CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS,
  CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING,
  FAVORITES_LIST,
  FEEDBACK_PRIVATE_MARKETS,
  FEEDBACK_SMART_BENCHMARKING,
  PRIVATE_MARKETS,
  REPORT_ERROR_SMART_BENCHMARKING,
  REQUEST_ATTRIBUTION_SMART_BENCHMARKING,
  REQUEST_PRODUCT_PRESENTATION_SMART_BENCHMARKING,
  REQUEST_REPORT_PRIVATE_MARKETS,
  REQUEST_REPORT_SMART_BENCHMARKING,
  REQUEST_VOTE_SMART_BENCHMARKING,
  SMART_BENCHMARKING,
} from 'urls/api';
import fetchAPI from './fetchApi';
import {
  PrivateMarketsFunds,
  SmartFundBenchmarkingItemProps,
} from 'types/Modules.d';
import { capitalizeWords } from 'utils/common';

export const fetchUpdateFundListStatusPrivateMarkets = (
  fundId: string,
  watchlistId: string,
) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS(fundId), {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ watchlist_id: watchlistId }),
  });

export const sendRequestReportPrivateMarkets = (id: string) =>
  fetchAPI(REQUEST_REPORT_PRIVATE_MARKETS(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistPrivateMarkets = (
  watchlist_id: string,
  ids: string[],
) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ watchlist_id, funds: ids }),
  });

export const fetchUpdateFundListStatusSmartBenchmarking = (
  fundId: string,
  watchlistId: string,
) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING(fundId), {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ watchlist_id: watchlistId }),
  });

export const sendRequestReportSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_REPORT_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const sendRequestProductPresentationSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_PRODUCT_PRESENTATION_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const sendRequestAttributionSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_ATTRIBUTION_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const sendErrorReportRequest = (id: string, text: string) =>
  fetchAPI(REPORT_ERROR_SMART_BENCHMARKING(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text }),
  });

export const sendRequestVoteSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_VOTE_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistSmartBenchmarking = (
  watchlist_id: string,
  ids: string[],
) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ watchlist_id, funds: ids }),
  });

interface FeedbackWithRelevanceLevelRequest {
  id: string;
  text: string;
  relevance_level: string;
}

export const fetchSendFundFeedbackPrivateMarkets = ({
  id,
  text,
  relevance_level,
}: FeedbackWithRelevanceLevelRequest) =>
  fetchAPI(FEEDBACK_PRIVATE_MARKETS(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, relevance_level }),
  });

export const fetchSendFundFeedbackSmartBenchmarking = ({
  id,
  text,
  relevance_level,
}: FeedbackWithRelevanceLevelRequest) =>
  fetchAPI(FEEDBACK_SMART_BENCHMARKING(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, relevance_level }),
  });

interface PrivateMarketsResponse extends Response {
  results: PrivateMarketsFunds;
  count: number;
}

interface PrivateMarketsRequest {
  watchlistId?: string;
  page?: number;
  page_size?: number;
  vote?: string;
  risk?: string;
  credibility_min?: string;
  credibility_max?: string;
  minimum_investment_min?: string;
  minimum_investment_max?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
}

export const fetchPrivateMarkets = ({
  page,
  page_size,
  vote,
  risk,
  credibility_min,
  credibility_max,
  minimum_investment_min,
  minimum_investment_max,
  sort_by,
  sort_order,
  search,
}: PrivateMarketsRequest) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (vote) params.append('vote', vote);
  if (risk) params.append('risk', risk);
  if (minimum_investment_min)
    params.append('minimum_investment_min', minimum_investment_min);
  if (minimum_investment_max)
    params.append('minimum_investment_max', minimum_investment_max);
  if (credibility_min) params.append('credibility_min', credibility_min);
  if (credibility_max) params.append('credibility_max', credibility_max);
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', search);

  const queryString = params.toString();
  const url = `${PRIVATE_MARKETS}/${queryString ? `?${queryString}` : ''}`;

  return fetchAPI<PrivateMarketsResponse>(url);
};

export const fetchFavoritesPrivateMarkets = ({
  watchlistId,
  page,
  page_size,
  vote,
  risk,
  credibility_min,
  credibility_max,
  minimum_investment_min,
  minimum_investment_max,
  sort_by,
  sort_order,
  search,
}: PrivateMarketsRequest) => {
  const params = new URLSearchParams();

  params.append('module', 'private_market');
  if (watchlistId) params.append('watchlist_id', String(watchlistId));
  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (vote) params.append('vote', vote);
  if (risk) params.append('risk', risk);
  if (minimum_investment_min)
    params.append('minimum_investment_min', minimum_investment_min);
  if (minimum_investment_max)
    params.append('minimum_investment_max', minimum_investment_max);
  if (credibility_min) params.append('credibility_min', credibility_min);
  if (credibility_max) params.append('credibility_max', credibility_max);
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', search);

  const queryString = params.toString();
  const url = `${FAVORITES_LIST}?${queryString}`;

  return fetchAPI<PrivateMarketsResponse>(url);
};

interface SmartBenchmarkingRequest {
  [key: string]: string | number | string[];
}

type SmartBenchmarkingFunds = {
  funds: SmartFundBenchmarkingItemProps[];
};

interface SmartBenchmarkingResponse extends Response {
  results: SmartBenchmarkingFunds;
  count: number;
}

export const fetchSmartBenchmarking = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: SmartBenchmarkingRequest) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', String(search));

  Object.entries(restValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        params.append(key, capitalizeWords(String(v)));
      });
    } else {
      params.append(key, capitalizeWords(String(value)));
    }
  });

  const queryString = params.toString();

  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${SMART_BENCHMARKING}/${queryString ? `?${queryString}` : ''}`,
  );
  return fetchSFB;
};

export const fetchFavoritesSmartBenchmarking = ({
  watchlistId,
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: SmartBenchmarkingRequest) => {
  const params = new URLSearchParams();

  params.append('module', 'smart_benchmarking');
  if (watchlistId) params.append('watchlist_id', String(watchlistId));
  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', String(search));

  Object.entries(restValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        params.append(key, capitalizeWords(String(v)));
      });
    } else {
      params.append(key, capitalizeWords(String(value)));
    }
  });

  const queryString = params.toString();
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${FAVORITES_LIST}?${queryString}`,
  );
  return fetchSFB;
};
