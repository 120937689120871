export enum ButtonVariant {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
  TEXT = 'text',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export enum ButtonSize {
  SMALL = 'small',
  LARGE = 'large',
}
