import { forwardRef, useEffect, useState } from 'react';
import { AddFundToExactWatchlistModalProps } from './AddFundToExactWatchlistModal.d';
import { StyledContainer } from './AddFundToExactWatchlistModal.styled';
import { Modal } from 'components/common/Modal/Modal';
import { Button } from 'components/common/Buttons/Button';
import { Box } from 'components/common/Box';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Typography } from 'components/common/Typography';
import {
  fetchAddFundsToWatchlistPrivateMarkets,
  fetchAddFundsToWatchlistSmartBenchmarking,
  fetchUpdateFundListStatusPrivateMarkets,
  fetchUpdateFundListStatusSmartBenchmarking,
} from 'services/Modules';
import {
  fetchAddFundsToWatchlistEsg,
  fetchUpdateFundListStatusESG,
} from 'services/ESG';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { WatchlistItem } from 'types/Watchlist';
import { Option } from './MyWatchlistsButton/MyWatchlistsButton.d';
import { SelectChangeEvent } from '@mui/material';
import { addWatchlist, getWatchlists } from 'services/Watchlists';
import { MyWatchlistsButton } from './MyWatchlistsButton';
import { ButtonWithPlus } from 'components/common/Buttons/ButtonWithPlus';
import { CreateWatchlistModal } from '../CreateWatchlistModal';
import { Toast } from 'components/common/Toast';

export const AddFundToExactWatchlistModal = forwardRef<
  HTMLDivElement,
  AddFundToExactWatchlistModalProps
>(
  (
    {
      isShown,
      toggle,
      currentFund,
      variant,
      funds,
      setSelectedRows,
      getTableData,
    },
    ref,
  ) => {
    const { name } = currentFund;
    const [isCreateWatchlistModalShown, toggleCreateWatchlistModal] =
      useState(false);
    const [selectedWatchlist, setSelectedWatchlist] = useState<Option | null>();
    const [watchlistsOptions, setWatchlistsOptions] = useState<
      (WatchlistItem & { disabled: boolean })[] | null
    >(null);

    const addFondToWatchlist = async () => {
      let response;
      if (selectedWatchlist) {
        switch (variant) {
          case 'smart-benchmarking':
            if (!!funds?.length) {
              response = await fetchAddFundsToWatchlistSmartBenchmarking(
                selectedWatchlist?.value,
                funds,
              );
            } else {
              response = await fetchUpdateFundListStatusSmartBenchmarking(
                currentFund.id,
                selectedWatchlist?.value,
              );
            }
            break;
          case 'esg':
            if (!!funds?.length) {
              response = await fetchAddFundsToWatchlistEsg(
                selectedWatchlist?.value,
                funds,
              );
            } else {
              response = await fetchUpdateFundListStatusESG(
                currentFund.id,
                selectedWatchlist?.value,
              );
            }
            break;
          case 'private-markets':
            if (!!funds?.length) {
              response = await fetchAddFundsToWatchlistPrivateMarkets(
                selectedWatchlist?.value,
                funds,
              );
            } else {
              response = await fetchUpdateFundListStatusPrivateMarkets(
                currentFund.id,
                selectedWatchlist?.value,
              );
            }
            break;
          default:
            break;
        }
        if (response && response.ok) {
          toggle();
          if (funds?.length && setSelectedRows && getTableData) {
            setSelectedRows([]);
            getTableData();
          }
          raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={
                !!funds?.length
                  ? `${funds.length} Fonds wurden zu Ihrer Fondsliste hinzugefügt.`
                  : `"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`
              }
            />,
          );
        }
      }
    };

    const onWatchlistChange = (newValue: Option | null) => {
      newValue && setSelectedWatchlist(newValue);
    };

    const fetchWatchlists = async () => {
      const response = await getWatchlists();
      if (response.ok) {
        const data = response.parsedBody;
        setWatchlistsOptions(
          data.map(({ id, name, count }) => ({
            id,
            name,
            fundsNumber: count ?? 0,
            disabled:
              currentFund.watchlists?.some(
                (watchlistId) => watchlistId.id === id,
              ) ?? false,
          })),
        );
      }
    };

    const createNewWatchlist = async (name: string) => {
      const response = await addWatchlist(name);
      if (response.ok) {
        const newWatchlist = response.parsedBody;
        toggleCreateWatchlistModal(false);
        toggle();
        raiseToast.success('Fondsliste erfolgreich erstellt.');
        fetchWatchlists();
        setSelectedWatchlist({
          value: newWatchlist.id,
          name: newWatchlist.name,
        });
      }
    };

    useEffect(() => {
      fetchWatchlists();
    }, [currentFund]);

    useEffect(() => {
      !isShown && setSelectedWatchlist(null);
    }, [isShown]);

    return (
      <>
        <CreateWatchlistModal
          isShown={isCreateWatchlistModalShown}
          toggle={() => {
            toggleCreateWatchlistModal(false);
            toggle();
          }}
          onSubmit={createNewWatchlist}
        />
        <Modal
          modalRef={ref}
          isShown={isShown}
          onClose={toggle}
          withCloseButton
          withHelpBox
          title="Fonds hinzufügen"
          subtitle={name}
          p={4}
        >
          <StyledContainer pt={4}>
            <Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                {watchlistsOptions && (
                  <MyWatchlistsButton
                    placeholder="Fondslistsen"
                    value={selectedWatchlist || null}
                    action={(event: SelectChangeEvent<any>) =>
                      onWatchlistChange(event.target.value)
                    }
                    options={watchlistsOptions.map(
                      ({ id, name, disabled }) => ({
                        name,
                        value: id,
                        disabled,
                      }),
                    )}
                  />
                )}
                {watchlistsOptions && (
                  <Typography variant="body">oder</Typography>
                )}
                <ButtonWithPlus
                  variant="primary"
                  label="Liste erstellen"
                  onClick={() => {
                    toggleCreateWatchlistModal(true);
                    toggle();
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" gap={2} pt={4}>
                <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
                  Zurück
                </Button>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  onClick={addFondToWatchlist}
                  disabled={!selectedWatchlist}
                >
                  Abschicken
                </Button>
              </Box>
            </Box>
          </StyledContainer>
        </Modal>
      </>
    );
  },
);
