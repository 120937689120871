export enum TableChipVariant {
  VIBRANT_GREEN = 'vibrant-green',
  GREEN = 'green',
  GREY = 'grey',
  RED = 'red',
  VIBRANT_RED = 'vibrant-red',
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze',
  BLACK = 'black',
}
