import React, { useRef, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { Chip } from '@mui/material';
import { MultiSelectProps, Option } from './MultiSelect.d';
import {
  StyledFormControl,
  StyledSelect,
  StyledMenuItemContainer,
  StyledInput,
} from './MultiSelect.styled';
import { Box } from 'components/common/Box';

export const MultiSelect: React.FC<MultiSelectProps> = ({
  disabled = false,
  value,
  name,
  onChange,
  options,
  required = false,
  placeholder,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');

  const handleMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    event.stopPropagation();
    const selectedValues =
      typeof event.target.value === 'string'
        ? event.target.value.split(',')
        : (event.target.value as string[]);

    const selectedOptions = options.filter((option) =>
      selectedValues.includes(option.value),
    );
    onChange(selectedOptions);
  };

  const handleDelete =
    (optionToDelete: Option) => (event: React.MouseEvent) => {
      event.stopPropagation();
      const newValue = value.filter((v) => v.value !== optionToDelete.value);
      onChange(newValue);
    };

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    inputRef.current?.focus();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setSearch(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(search.toLowerCase()),
  );

  const renderValue = (selected: unknown) => (
    <Box display="flex" flexWrap="wrap" gap={0.5}>
      {value.map((option) => (
        <Chip
          key={option.value}
          label={option.name}
          size="small"
          onDelete={handleDelete(option)}
          onMouseDown={(e) => e.stopPropagation()}
        />
      ))}
      <StyledInput
        ref={inputRef}
        value={search}
        onChange={handleInputChange}
        onClick={handleInputClick}
        onMouseDown={(e) => e.stopPropagation()}
        placeholder={value.length === 0 ? placeholder : ''}
      />
    </Box>
  );

  return (
    <StyledFormControl fullWidth>
      <StyledSelect
        multiple
        disabled={disabled}
        value={value.map((v) => v.value)}
        onChange={handleChange}
        renderValue={renderValue}
        placeholder={placeholder}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
      >
        {filteredOptions.map((option) => (
          <StyledMenuItemContainer
            key={option.value}
            value={option.value}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            {option.name}
          </StyledMenuItemContainer>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};
