import styled from 'styled-components';
import {
  FormControl,
  styled as MUIstyled,
  MenuItem,
  OutlinedInput,
  Select,
  outlinedInputClasses,
  selectClasses,
} from '@mui/material';
import { COLORS } from '../../../theme/colors';
import { StyledInputProps } from './Select.d';

export const StyledSelect = MUIstyled(Select)<StyledInputProps>(
  ({ selectVariant }) => ({
    [`& .${selectClasses.select}`]: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      color:
        selectVariant === 'filled'
          ? COLORS.background.secondary
          : COLORS.typography.description,
      fontSize: '14px',
      padding: '12px 16px',
      paddingRight: `42px !important`,
      backgroundColor:
        selectVariant === 'filled'
          ? `${COLORS.info.selectedBorder} !important`
          : 'none',
      borderRadius: '4px 0px 0px 4px',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },

    [`& .${selectClasses.icon}`]: {
      right: 18,
      top: 18,
    },
  }),
);

export const StyledInput = MUIstyled(OutlinedInput)<StyledInputProps>(
  ({ selectVariant }) => ({
    height: 48,
    [`& .${outlinedInputClasses.root}`]: {
      height: 48,
      boxSizing: 'border-box',
      '&::placeholder': {
        color: COLORS.typography.caption,
      },
    },
    [`& .${outlinedInputClasses.input}`]: {
      '&:focus': {
        borderRadius:
          selectVariant === 'filled'
            ? '4px 0px 0px 4px !important'
            : '4px !important',
      },
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: '1px !important',
      borderRightWidth:
        selectVariant === 'filled' ? '0px !important' : '1px !important',
      borderColor: `${COLORS.stroke.primary} !important`,
      borderRadius:
        selectVariant === 'filled'
          ? '4px 0px 0px 4px !important'
          : '4px !important',
    },
  }),
);
export const StyledFormControl = MUIstyled(FormControl)(() => ({
  position: 'relative',
}));

export const StyledMenuItemContainer = styled(MenuItem)(() => ({
  minHeight: '36px',
  height: 'auto',
  padding: '8px 16px',
  whiteSpace: 'initial',
  '&:hover': {
    backgroundColor: `${COLORS.background.neutral} !important`,
    color: COLORS.accent.primary,
  },
}));

export const StyledPlaceholder = styled.p<StyledInputProps>`
  position: absolute;
  left: 16px;
  top: 1px;
  z-index: 1;
  ${({ selectVariant }) => selectVariant === 'filled' && 'padding-left: 8px;'}

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: ${({ selectVariant }) =>
    selectVariant === 'filled' ? '600' : '400'};
  color: ${({ selectVariant }) =>
    selectVariant === 'filled'
      ? COLORS.background.secondary
      : COLORS.typography.caption};
`;

export const StyledMenuItem = styled.div<{ selected: boolean }>`
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 4px 0px;
  line-height: 1.4;
  white-space: initial;
  color: ${({ selected }) =>
    selected ? COLORS.accent.primary : COLORS.typography.description};
`;

export const StyledSpan = styled.span<StyledInputProps>`
  color: ${({ selectVariant }) =>
    selectVariant === 'filled'
      ? COLORS.background.secondary
      : COLORS.typography.body};
  font-size: 14px;
  font-weight: ${({ selectVariant }) =>
    selectVariant === 'filled' ? '600' : '400'};
  margin: 0px;
  padding-bottom: 0px;
  ${({ selectVariant }) => selectVariant === 'filled' && 'padding-left: 8px;'}
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  width: 100%;
`;

export const HiddenFirstMenuItem = MUIstyled(MenuItem)({
  display: 'none',
});
