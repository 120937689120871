import { FC } from 'react';
import { RequestStatusColumnProps } from './RequestStatusColumn.d';
import { Box } from 'components/common/Box';
import { StyledPoint, StyledStatus } from './RequestStatusColumn.styled';
import { STATUS_TYPE_MAPPING, statusOptions } from './config';

export const RequestStatusColumn: FC<RequestStatusColumnProps> = ({
  status,
  type,
}) => {
  const combinedStatus =
    status && type ? STATUS_TYPE_MAPPING[type]?.[status] : null;

  const currentStatus = combinedStatus
    ? statusOptions.find(
        (option) => option.value.toLowerCase() === combinedStatus.toLowerCase(),
      )
    : undefined;

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      {combinedStatus && currentStatus && (
        <StyledPoint color={currentStatus.color} />
      )}
      {combinedStatus && (
        <StyledStatus variant="body">{combinedStatus}</StyledStatus>
      )}
    </Box>
  );
};
