import styled from 'styled-components';
import { SlideOutModalProps } from './SlideOutFilterModal.d';
import { COLORS } from 'theme/colors';
import { Button } from 'components/common/Buttons/Button';

export const StyledSlideOutModal = styled.div<SlideOutModalProps>`
  position: fixed;
  top: 0;
  right: ${({ variant }) => (variant === 'medium' ? '-475px' : '-485px')};
  width: ${({ variant }) => (variant === 'medium' ? '470px' : '480px')};
  height: 100vh;
  background-color: ${COLORS.background.secondary};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.4s ease;
  z-index: 20;
  display: flex;
  flex-direction: column;
  ${({ isShown }) =>
    isShown &&
    `
    right: 0;
  `}
`;

export const StyledModalHeader = styled.div`
  padding: 12.5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.stroke.primary};
`;

export const StyledCloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: unset;
  border: none;
  justify-self: end;

  &:hover {
    cursor: pointer;

    svg {
      stroke: ${COLORS.typography.caption};
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledModalContent = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const StyledFooter = styled.div`
  padding: 19.5px 20px;
  background-color: ${COLORS.background.neutral};
  border-top: 1px solid ${COLORS.stroke.primary};
  display: flex;
  justify-content: space-between;
`;

export const StyledResultsNumber = styled.span`
  height: 24px;
  padding: 0 8px;
  background-color: ${COLORS.background.secondary};
  color: ${COLORS.accent.primary};
  border-radius: 100px;
  margin-left: 8px;

  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSubmitButton = styled(Button)`
  padding: 10px 24px;
  align-items: center;
`;
