import { FC, useEffect, useRef, useState } from 'react';
import {
  CommentFormProps,
  CommentItemProps,
  CommentsModalProps,
} from './CommentsModal.d';
import {
  StyledCloseButton,
  StyledFooter,
  StyledIsin,
  StyledModalContent,
  StyledModalHeader,
  StyledSlideOutModal,
} from './CommentsModal.styled';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Button } from 'components/common/Buttons/Button';
import { useClickOutside } from 'hooks/useClickOutside';
import { Typography } from 'components/common/Typography';
import { Box } from 'components/common/Box';
import { useForm } from 'react-hook-form';
import { commentFormValidationSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { Textarea } from 'components/common/Textarea';
import { CommentItem } from './CommentItem';
import { raiseToast } from 'components/common/Toast/raiseToast';
import {
  getFondsconsultChatMessages,
  getPlansecurChatMessages,
  sendFondsconsultChatMessage,
  sendPlansecurChatMessage,
} from 'services/Plansecur';
import { useProfile } from 'context/Profile';

export const CommentsModal: FC<CommentsModalProps> = ({
  isShown,
  variant,
  fundName,
  fundId,
  isin,
  closeModal,
}) => {
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const isPlansecurVariant = variant === 'plansecur';
  const isPlansecurMessageReadAccess =
    profileInfo?.plansecur_message_read_access;
  const isPlansecurMessageWriteAccess =
    profileInfo?.plansecur_message_write_access;
  const isFondsconsultMessageReadAccess =
    profileInfo?.fondsconsult_message_read_access;
  const isFondsconsultMessageWriteAccess =
    profileInfo?.fondsconsult_message_write_access;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [comments, setComments] = useState<CommentItemProps[] | null>(null);

  const { handleSubmit, register, reset } = useForm<CommentFormProps>({
    resolver: yupResolver(commentFormValidationSchema),
    mode: 'onSubmit',
  });

  const sendPlansecurComment = async (comment: string) => {
    const response = await sendPlansecurChatMessage(fundId, comment);
    if (response.ok) {
      raiseToast.send('Der Kommentar wurde erfolgreich gesendet.');
      reset();
      getPlansecurComments();
    }
  };

  const sendFondsconsultComment = async (comment: string) => {
    const response = await sendFondsconsultChatMessage(fundId, comment);
    if (response.ok) {
      raiseToast.send('Der Kommentar wurde erfolgreich gesendet.');
      reset();
      getFondsconsultComments();
    }
  };

  const onSubmit = async (feedbackFormData: CommentFormProps) => {
    if (isPlansecurVariant) {
      await sendPlansecurComment(feedbackFormData.comment);
    } else {
      await sendFondsconsultComment(feedbackFormData.comment);
    }
  };

  const getFondsconsultComments = async () => {
    if (isFondsconsultMessageReadAccess) {
      const response = await getFondsconsultChatMessages(fundId);
      if (response.ok) {
        const newComments = response.parsedBody;
        setComments(
          newComments.map(({ id, user, created_at, message }) => ({
            id,
            name: `${user.name} ${user.last_name}`,
            comment: message || '',
            date: created_at,
          })),
        );
      }
    }
  };

  const getPlansecurComments = async () => {
    if (isPlansecurMessageReadAccess) {
      const response = await getPlansecurChatMessages(fundId);
      if (response.ok) {
        const newComments = response.parsedBody;
        setComments(
          newComments.map(({ id, user, created_at, message }) => ({
            id,
            name: `${user.name} ${user.last_name}`,
            comment: message || '',
            date: created_at,
          })),
        );
      }
    }
  };

  useEffect(() => {
    if (isPlansecurVariant && fundId) {
      getPlansecurComments();
    } else {
      getFondsconsultComments();
    }
  }, [fundId, isPlansecurVariant]);

  useClickOutside(wrapperRef, () => {
    closeModal();
  });

  return (
    <StyledSlideOutModal isShown={isShown} ref={wrapperRef}>
      <StyledModalHeader>
        <StyledCloseButton onClick={closeModal}>
          <CloseIcon stroke={COLORS.typography.placeholder} />
        </StyledCloseButton>
      </StyledModalHeader>
      <StyledModalContent>
        <Box display="flex" flexDirection="column" gap={1} pb={5}>
          <Typography variant="h5">{fundName}</Typography>
          <StyledIsin variant="body">{isin}</StyledIsin>
        </Box>
        {((isFondsconsultMessageWriteAccess && !isPlansecurVariant) ||
          (isPlansecurMessageWriteAccess && isPlansecurVariant)) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Textarea
              placeholder="Dein Kommentar..."
              {...register('comment')}
            />
            <Box display="flex" justifyContent="flex-end" pt={2}>
              <Button type="submit">Kommentar hinzufügen</Button>
            </Box>
          </form>
        )}
        {comments?.length !== 0 && (
          <Box display="flex" flexDirection="column" gap={2.25}>
            {comments?.map((comment, index) => (
              <CommentItem
                key={comment.id}
                isLast={index === comments.length - 1}
                {...comment}
              />
            ))}
          </Box>
        )}
      </StyledModalContent>
      <StyledFooter>
        <Button variant={ButtonVariant.OUTLINED} onClick={closeModal}>
          Abbrechen
        </Button>
      </StyledFooter>
    </StyledSlideOutModal>
  );
};
