import { FC } from 'react';
import { FundManagementMixedProps } from './FundManagementMixed.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import {
  StyledContainer,
  StyledInfoItem,
  StyledLoyaltyToStyle,
  StyledTypography,
} from './FundManagementMixed.styled';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';
import { getFilterByValue } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/utils';
import { assetConcentrationOptions, options } from './config';
import {
  managementAffiliationOptions,
  trackingErrorOptions,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';

export const FundManagementMixed: FC<FundManagementMixedProps> = ({
  description,
  shareQuota,
  managementAffiliation,
  trackingError,
  averageCreditRating,
  yeldToMaturity,
  averageRemainingTerm,
  modifiedDuration,
  marketCapitalization,
  marketCapitalizationConsistency,
  assetConcentration,
  valueToGrowthAbs,
  valueToGrowthConsistency,
}) => {
  const leftTableData = [
    {
      label: 'Aktienquote',
      value: shareQuota ? (
        <TableChip
          size="small"
          label={shareQuota?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(shareQuota.toString(), options)?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'Managementzugehörigkeit',
      value: managementAffiliation ? (
        <TableChip
          size="small"
          label={managementAffiliation?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(
              managementAffiliation.toString(),
              managementAffiliationOptions,
            )?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'Tracking Error',
      value: trackingError ? (
        <TableChip
          size="small"
          label={trackingError?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(trackingError.toString(), trackingErrorOptions)
              ?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
  ];

  const middleTableData = [
    {
      label: 'Anlagestil (Fokus)',
      value: valueToGrowthConsistency ? (
        <TableChip
          size="small"
          label={valueToGrowthConsistency?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(valueToGrowthConsistency.toString(), options)
              ?.chipVariant
          }
        />
      ) : (
        '-'
      ),
      subtitle: valueToGrowthAbs,
    },
    {
      label: 'Marktkapitalisierung (Fokus)',
      value: marketCapitalizationConsistency ? (
        <TableChip
          size="small"
          label={marketCapitalizationConsistency?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(
              marketCapitalizationConsistency.toString(),
              options,
            )?.chipVariant
          }
        />
      ) : (
        '-'
      ),
      subtitle: marketCapitalization,
    },
    {
      label: 'Assetkonzentration (Top 10)',
      value: assetConcentration ? (
        <TableChip
          size="small"
          label={assetConcentration?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(
              assetConcentration.toString(),
              assetConcentrationOptions,
            )?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
  ];

  const rightTableData = [
    {
      label: 'Durchschnittliches Kredit-Rating',
      value: averageCreditRating ? (
        <TableChip
          size="small"
          label={averageCreditRating?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(averageCreditRating.toString(), options)
              ?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'Rendite auf Verfall (YTM)',
      value: yeldToMaturity ? (
        <TableChip
          size="small"
          label={yeldToMaturity?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(yeldToMaturity.toString(), options)?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'Durchschnittliche Restlaufzeit',
      value: averageRemainingTerm ? (
        <TableChip
          size="small"
          label={averageRemainingTerm?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(averageRemainingTerm.toString(), options)
              ?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'Modified Duration',
      value: modifiedDuration ? (
        <TableChip
          size="small"
          label={modifiedDuration?.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(modifiedDuration.toString(), options)?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer pt={3} display="grid" gridAutoFlow="column">
        <BoxWithBookmark title="Allgemeine Kriterien" variant="secondary">
          <Box px={3} pb={3}>
            {leftTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
                {item.value}
              </StyledInfoItem>
            ))}
          </Box>
        </BoxWithBookmark>
        <BoxWithBookmark title="Aktienspezifische Kriterien">
          <Box px={3} pb={3}>
            {middleTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {item.subtitle ? (
                  <>
                    <Box>
                      <StyledTypography variant="body" weight="semibold">
                        {item.label}
                      </StyledTypography>
                      <StyledLoyaltyToStyle
                        variant="body"
                        color={COLORS.typography.description}
                        weight="semibold"
                      >
                        Stiltreue
                      </StyledLoyaltyToStyle>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={2}
                    >
                      <StyledTypography variant="body" textAlign="right">
                        {item.subtitle}
                      </StyledTypography>
                      {item.value}
                    </Box>
                  </>
                ) : (
                  <>
                    <StyledTypography variant="body" weight="semibold">
                      {item.label}
                    </StyledTypography>
                    {item.value}
                  </>
                )}
              </StyledInfoItem>
            ))}
          </Box>
        </BoxWithBookmark>
        <BoxWithBookmark
          title="Anleihenspezifische Kriterien"
          variant="secondary"
        >
          <Box px={3} pb={3}>
            {rightTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
                {item.value}
              </StyledInfoItem>
            ))}
          </Box>
        </BoxWithBookmark>
      </StyledContainer>
    </Box>
  );
};
