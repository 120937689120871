export const HOST_URL =
  process.env.REACT_APP_HOST_URL || 'http://127.0.0.1:8000';

export const AI_TOOL_URL =
  process.env.REACT_APP_AI_TOOL_URL || 'https://fondsconsult.idego.ai';

export const AI_TOOL_AUTOLOGIN = (token: string) =>
  `${AI_TOOL_URL}/autologin/${token}`;

export const API_URL = HOST_URL + '/api/';

export const USERS_URL = `${API_URL}users/`;

export const LOGIN_URL = `${USERS_URL}login/`;
export const REFRESH_URL = `${USERS_URL}refresh/`;
export const LOGUT_URL = `${USERS_URL}logout/`;
export const PROFILE_URL = `${USERS_URL}profile/`;

export const RESET_PASSWORD_REQUEST_URL = `${USERS_URL}reset_password_request/`;
export const RESET_PASSWORD_URL = `${USERS_URL}reset_password_action/`;
export const CHANGE_PASSWORD_URL = `${USERS_URL}change_password/`;

export const ACCEPT_INVITATION = `${USERS_URL}accept_invitation/`;

export const CONTACT_US_URL = `${API_URL}contact/`;
export const WEBSITE_CONTACT_US_URL = `${CONTACT_US_URL}website/`;

export const ESG_AND_ACTION_SCORE = `${API_URL}action_score`;
export const CHANGE_FUND_LIST_STATUS_ESG_AND_ACTION_SCORE = (
  id: string,
): string => `${ESG_AND_ACTION_SCORE}/${id}/favourite/`;

export const REQUEST_REPORT_ESG_AND_ACTION_SCORE = (id: string): string =>
  `${ESG_AND_ACTION_SCORE}/${id}/report_request/`;

export const ADD_FUNDS_TO_FAVORITES_ESG = `${ESG_AND_ACTION_SCORE}/favourites/`;

export const FEEDBACK_ESG_AND_ACTION_SCORE = (id: string): string =>
  `${ESG_AND_ACTION_SCORE}/${id}/feedback/`;

export const ESG_AND_ACTION_SCORE_FILTER_PRESET = `${ESG_AND_ACTION_SCORE}/filter_preset/`;

export const REQUEST_PRODUCT_PRESENTATION_ESG = (id: string): string =>
  `${ESG_AND_ACTION_SCORE}/${id}/product_presentation_request/`;

export const REQUEST_ATTRIBUTION_ESG = (id: string): string =>
  `${ESG_AND_ACTION_SCORE}/${id}/attribution_request/`;

export const REPORT_ERROR_ESG = (id: string): string =>
  `${ESG_AND_ACTION_SCORE}/${id}/error_report/`;

export const FAVORITES_LIST = `${API_URL}favourites/`;

export const PRIVATE_MARKETS = `${API_URL}private_market`;
export const CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/favourite/`;

export const REQUEST_REPORT_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/report_request/`;

export const ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS = `${PRIVATE_MARKETS}/favourites/`;

export const FEEDBACK_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/feedback/`;

export const REQUEST_PRODUCT_PRESENTATION_PRIVATE_MARKETS = (
  id: string,
): string => `${PRIVATE_MARKETS}/${id}/product_presentation_request/`;

export const REQUEST_ATTRIBUTION_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/attribution_request/`;

export const REPORT_ERROR_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/error_report/`;

export const SMART_BENCHMARKING = `${API_URL}smart_benchmarking`;
export const CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING = (
  id: string,
): string => `${SMART_BENCHMARKING}/${id}/favourite/`;

export const REQUEST_REPORT_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/report_request/`;

export const REQUEST_PRODUCT_PRESENTATION_SMART_BENCHMARKING = (
  id: string,
): string => `${SMART_BENCHMARKING}/${id}/product_presentation_request/`;

export const REQUEST_ATTRIBUTION_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/attribution_request/`;

export const REPORT_ERROR_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/error_report/`;

export const REQUEST_VOTE_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/vote_request/`;

export const FEEDBACK_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/feedback/`;

export const ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING = `${SMART_BENCHMARKING}/favourites/`;

export const SMART_BENCHMARKING_FUND_DETAILS = (id: string) =>
  `${SMART_BENCHMARKING}/${id}`;

export const SMART_BENCHMARKING_PEER_GROUPS = `${SMART_BENCHMARKING}/peer_groups`;

export const SMART_BENCHMARKING_PEER_GROUP = (id: string) =>
  `${SMART_BENCHMARKING_PEER_GROUPS}/${id}`;

export const SMART_BENCHMARKING_FILTER_PRESET = `${SMART_BENCHMARKING}/filter_preset/`;

export const RECOMMENDATIONS_FOR_ACTION = `${SMART_BENCHMARKING}/recommendations`;

export const INVITE_USERS = `${USERS_URL}invite/`;

export const INVITATION = `${USERS_URL}invitation/`;

export const STUDIES = `${API_URL}studies/`;

export const REQUEST_STUDIES = (id: string): string =>
  `${STUDIES}${id}/study_request/`;

export const COMPARISON_LIST = `${SMART_BENCHMARKING}/comparison_list/`;

export const CHANGE_COMPARISON_LIST_STATUS_SMART_BENCHMARKING = (
  id: string,
): string => `${SMART_BENCHMARKING}/${id}/comparison/`;

export const COMPARISON_LIST_SEARCH = `${COMPARISON_LIST}funds`;

export const CUSTOMER_OVERVIEW = `${API_URL}customer_overview/`;

export const PLANSECUR_FUND = `${CUSTOMER_OVERVIEW}plansecur-fund`;
export const KOMPASS_FUND = `${CUSTOMER_OVERVIEW}kompass-fund`;
export const KOMPASS_DESIGN = `${CUSTOMER_OVERVIEW}kompass-request`;
export const PORTFOLIOS = (id: string) =>
  `${CUSTOMER_OVERVIEW}portfolio-pate/${id}`;

export const CHANGE_FUND_PORTFOLIO_STATUS = (id: string): string =>
  `${PLANSECUR_FUND}/${id}/watchlist_switch/`;

export const REQUEST_FUND_PORTRAIT = (id: string): string =>
  `${CUSTOMER_OVERVIEW}profile-request/${id}/`;

export const REQUEST_FUND_UPDATE = (id: string): string =>
  `${CUSTOMER_OVERVIEW}update-request/${id}/`;

export const REQUEST_FUND_COMPETITIVE_ANALYSIS = (id: string): string =>
  `${CUSTOMER_OVERVIEW}analyst-report-request/${id}/`;

export const REQUEST_FUND_OTHER = (id: string): string =>
  `${CUSTOMER_OVERVIEW}other-request/${id}/`;

export const ADD_MULTIPLE_FUNDS_TO_WATCHLIST_PLANSECUR = `${PLANSECUR_FUND}/watchlist_add_multiple/`;

export const PLANSECUR_FUND_CHAT_MESSAGE = `${CUSTOMER_OVERVIEW}plansecur-fund-chat-message/`;

export const FONDSCONSULT_FUND_CHAT_MESSAGE = `${CUSTOMER_OVERVIEW}fondsconsult-fund-chat-message/`;

export const WATCHLISTS = `${FAVORITES_LIST}watchlists/`;

export const PLANSECUR_FUND_FILTER_CHOICES = `${PLANSECUR_FUND}/filter_choices`;
export const KOMPASS_FILTER_CHOICES = `${KOMPASS_FUND}/filter_choices`;
export const PORTFOLIOS_FILTER_CHOICES = (id: string) =>
  `${PORTFOLIOS(id)}/filter_choices`;

export const KOMPASS_EDIT_ADDITIONAL_INFO = (id: string): string =>
  `${KOMPASS_FUND}/${id}/edit_additional_information/`;

export const KOMPASS_DATA_SNAPSHOTS = `${KOMPASS_FUND}/historic_data_snapshots`;

export const PLANSECUR_DASHBOARD = `${CUSTOMER_OVERVIEW}dashboard`;
