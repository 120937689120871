import { FC } from 'react';
import { RequestedByColumnProps } from './RequestedByColumn.d';
import { Box } from 'components/common/Box';
import { ReactComponent as UserIcon } from 'assets/Icons/profile-circle-grey.svg';
import { Typography } from 'components/common/Typography';
import { StyledRole } from './RequestedByColumn.styled';

export const RequestedByColumn: FC<RequestedByColumnProps> = ({
  name,
  role,
}) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <UserIcon style={{ width: 20 }} />
      <Box display="flex" flexDirection="column" gap={0.2}>
        <Typography variant="body" style={{ lineHeight: '17px' }}>
          {name}
        </Typography>
        {role && <StyledRole variant="body">{role}</StyledRole>}
      </Box>
    </Box>
  );
};
