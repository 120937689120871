import styled from 'styled-components';
import { styled as materialUIStyled } from '@mui/material/styles';
import {
  TableCell,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
} from '@mui/material';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';

export const BlankTableCell = materialUIStyled(TableCell)({
  border: 0,
  backgroundColor: COLORS.background.module,
});

export const StyledToggleButtonGroup = materialUIStyled(ToggleButtonGroup)(
  () => ({
    backgroundColor: hexToRGB(COLORS.background.secondary, '0.2'),
    padding: '2px',
    borderRadius: '100px',
    gap: '4px',
    '& .MuiToggleButtonGroup-grouped': {
      margin: '0 2px',
      borderRadius: '100px !important',
      border: 'none',
      '&:not(:first-of-type)': {
        borderRadius: '100px !important',
        margin: 0,
        borderLeft: 'none',
      },
      '&:first-of-type': {
        borderRadius: '100px !important',
        margin: 0,
      },
      '&:last-of-type': {
        borderRadius: '100px !important',
        margin: 0,
      },
    },
  }),
);

export const StyledToggleButton = materialUIStyled(ToggleButton)(
  ({ selected }) => ({
    fontFamily: '"Inter", sans-serif',
    fontSize: '12px',
    fontWeight: 600,
    color: COLORS.background.secondary,
    letterSpacing: 0,
    textTransform: 'none',
    borderRadius: '100px',
    border: 0,
    padding: '6px 16.5px',
    //   [`& .${toggleButtonClasses.root}`]: {
    //     [`& .${toggleButtonClasses.root}`]: {
    //       color: 'red',
    //     },
    //     backgroundColor: COLORS.background.module,
    //     color: COLORS.info.selectedBorder,
    //     '&:hover': {
    //       backgroundColor: COLORS.background.module,
    //     },
    //   },

    '&.Mui-selected': {
      color: COLORS.info.selectedBorder,
      backgroundColor: COLORS.background.secondary,
      '&:hover': {
        color: COLORS.info.selectedBorder,
        backgroundColor: COLORS.background.secondary,
      },
    },
  }),
);
