import { LinearProgress, linearProgressClasses } from '@mui/material';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { styled as MUIStyled } from '@mui/material/styles';
import { StyledBorderLinearProgressProps } from './DashboardCardItem.d';

export const StyledFundName = styled(Typography)`
  font-size: 15px;
  line-height: 13px;
  color: ${COLORS.typography.description};
`;

export const StyledValue = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;

  min-width: 38px;
  text-align: right;
`;

export const StyledBorderLinearProgress = MUIStyled(
  LinearProgress,
)<StyledBorderLinearProgressProps>(({ isWarning }) => ({
  height: 10,
  width: '100%',
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: COLORS.background.module,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: isWarning
      ? COLORS.error.background
      : COLORS.success.bulletPoint,
  },
}));
