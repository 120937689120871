import { WATCHLISTS } from 'urls/api';
import fetchAPI from './fetchApi';
import { WatchlistItem } from 'types/Watchlist.d';

export const addWatchlist = (name: string) =>
  fetchAPI<WatchlistItem>(WATCHLISTS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });

export const getWatchlists = () => fetchAPI<WatchlistItem[]>(WATCHLISTS);

export const editWatchlist = (id: string, newName: string) =>
  fetchAPI(`${WATCHLISTS}${id}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name: newName }),
  });

export const deleteWatchlist = (id: string) =>
  fetchAPI(`${WATCHLISTS}${id}/`, {
    method: 'DELETE',
  });
