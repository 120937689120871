import { FC, useEffect, useState } from 'react';
import { Layout } from 'components/common/Layout/Layout';
import { Box } from 'components/common/Box';
import {
  StyledContainer,
  LeftContainer,
  RightContainer,
  StyledHeadlineContainer,
} from './MainPage.styled';
import { OverviewDetailProps, RecommendationItem } from './MainPage.d';
import { OverviewItem } from 'components/Overview/OverviewItem';
import { AnalystServiceDeskSection } from 'components/Overview/AnalystServiceDeskSection';
import { Typography } from 'components/common/Typography';
import { Recommendations } from 'components/Overview/Recommendations';
import PrivateMarketsBackgroundImage from 'assets/Backgrounds/private-markets-background.png';
import ESGCredibilityBackgroundImage from 'assets/Backgrounds/esg-credibility-action-score-background.png';
import FCFundPeergroupBackgroundImage from 'assets/Backgrounds/fc-fund-peergroup-background.png';
import FCStudiesBackgroundImage from 'assets/Backgrounds/fc-studies-background.png';
import AIToolBackgroundImage from 'assets/Backgrounds/ai-tool.png';
import { AppPaths } from 'urls/frontend';
import { fetchRecommendationsForAction } from 'services/SmartBenchmarking';
import { COLORS } from 'theme/colors';
import { AI_TOOL_AUTOLOGIN } from 'urls/api';
import { ALLOWED_DOMAINS } from './config';

export const MainPage: FC = () => {
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const isProduction = env === 'production';
  const [recommendations, setRecommendations] = useState<RecommendationItem[]>(
    [],
  );

  const isAllowedDomain = (url: string): boolean => {
    try {
      const urlObj = new URL(url);
      return ALLOWED_DOMAINS.some(
        (domain) =>
          urlObj.hostname === domain || urlObj.hostname.endsWith(`.${domain}`),
      );
    } catch {
      return false;
    }
  };

  const redirectToAIToolWithToken = () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh');
    if (!token || !refreshToken) return;

    const parsedToken = JSON.parse(token);
    const parsedRefreshToken = JSON.parse(refreshToken);

    const combinedToken = `${parsedToken}:${parsedRefreshToken}`;

    const encodedToken = btoa(combinedToken);

    const redirectUrl = AI_TOOL_AUTOLOGIN(encodeURIComponent(encodedToken));

    if (redirectUrl && isAllowedDomain(redirectUrl)) {
      window.location.href = redirectUrl;
    } else {
      console.error('Invalid or disallowed redirect URL:', redirectUrl);
    }
  };

  const overviewDetails: OverviewDetailProps[] = [
    ...(isProduction
      ? [
          {
            id: 0,
            label: 'Fonds AI Companion',
            link: '#',
            chips: ['Demnächst verfügbar'],
            hoverImageLink: AIToolBackgroundImage,
            backgroundColor: COLORS.background.neutral,
            onClick: () => redirectToAIToolWithToken(),
            chipStyles: {
              textColor: COLORS.background.secondary,
              backgroundColor: COLORS.info.selectedBorder,
              borderColor: COLORS.info.selectedBorder,
            },
            fullWidth: true,
            externalLink: true,
            disabled: true,
          },
        ]
      : []),
    {
      id: 1,
      label: 'Private Markets & Real Estate',
      link: AppPaths.privateMarketsRealEstate,
      chips: ['Private Equity', 'Private Debt', 'Real Estate', 'Infrastruktur'],
      hoverImageLink: PrivateMarketsBackgroundImage,
      backgroundColor: COLORS.background.neutral,
      chipStyles: {
        textColor: COLORS.typography.primary,
        backgroundColor: COLORS.stroke.chip,
        borderColor: COLORS.stroke.chip,
      },
    },
    {
      id: 2,
      label: 'ESG Credibility & Action Score',
      link: AppPaths.esgCredibilityActionScore,
      chips: ['ESG-Ansatz'],
      hoverImageLink: ESGCredibilityBackgroundImage,
      backgroundColor: COLORS.success.light,
      chipStyles: {
        textColor: COLORS.success.typography,
        backgroundColor: COLORS.success.chip,
        borderColor: COLORS.success.chip,
      },
    },
    {
      id: 3,
      label: 'FC Smart Fund Benchmarking',
      link: AppPaths.fcSmartFundBenchmarking,
      chips: ['Aktiv', 'Passiv', '>150 Peergruppen'],
      hoverImageLink: FCFundPeergroupBackgroundImage,
      backgroundColor: COLORS.background.neutral,
      chipStyles: {
        textColor: COLORS.typography.primary,
        backgroundColor: COLORS.stroke.chip,
        borderColor: COLORS.stroke.chip,
      },
    },
    {
      id: 4,
      label: 'FondsConsult Studien',
      link: AppPaths.fondsConsultStudies,
      chips: [
        'Stiftungsfonds',
        'Robo-Advisor',
        'Künstliche Intelligenz',
        'Nachhaltigkeit',
        'Markttrends',
      ],
      hoverImageLink: FCStudiesBackgroundImage,
      backgroundColor: COLORS.background.neutral,
      chipStyles: {
        textColor: COLORS.typography.primary,
        backgroundColor: COLORS.stroke.chip,
        borderColor: COLORS.stroke.chip,
      },
    },
  ];

  const getRecommendationsData = async () => {
    const response = await fetchRecommendationsForAction({ page: 1 });
    if (response && response.ok) {
      const data = response.parsedBody.results.funds;
      setRecommendations(
        data.map((item) => ({
          label: item.recommendations_for_action
            ? item.recommendations_for_action?.vote
            : null,
          title: item.name,
          content: [
            ...(item.peer_group.name ? [item.peer_group.name] : []),
            ...(item.asset_class ? [item.asset_class] : []),
          ],
        })),
      );
    }
  };

  useEffect(() => {
    getRecommendationsData();
  }, []);

  return (
    <Layout>
      <StyledContainer display="flex" px={5} pt={4} pb={3}>
        <LeftContainer>
          <Box>
            <StyledHeadlineContainer>
              <Typography variant="h1">
                Mehrwert durch unabhängige Fondsanalyse
              </Typography>
            </StyledHeadlineContainer>
            <Recommendations recommendations={recommendations} />
          </Box>
          <AnalystServiceDeskSection />
        </LeftContainer>
        <RightContainer>
          {overviewDetails.map((overviewDetail) => (
            <OverviewItem key={overviewDetail.id} {...overviewDetail} />
          ))}
        </RightContainer>
      </StyledContainer>
    </Layout>
  );
};
