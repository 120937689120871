import { forwardRef, useEffect, useState } from 'react';
import {
  CommentFormProps,
  MaintainAdditionalInfoModalProps,
} from './MaintainAdditionalInfoModal.d';
import { Modal } from 'components/common/Modal/Modal';
import {
  StyledContainer,
  StyledIsin,
} from './MaintainAdditionalInfoModal.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Textarea } from 'components/common/Textarea';
import { useForm } from 'react-hook-form';
import { commentFormValidationSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { fetchEditAdditionalInformation } from 'services/Plansecur';

export const MaintainAdditionalInfoModal = forwardRef<
  HTMLDivElement,
  MaintainAdditionalInfoModalProps
>(
  (
    {
      isShown,
      toggle,
      title,
      subtitle,
      fundName,
      isin,
      fundId,
      initialValues,
      isWarning = false,
      confirmButtonText = 'Ja',
      cancelButtonText = 'Abbrechen',
      handleSubmitSend,
    },
    ref,
  ) => {
    const [formChanged, setFormChanged] = useState(false);
    const { handleSubmit, register, reset, setValue, watch } =
      useForm<CommentFormProps>({
        resolver: yupResolver(commentFormValidationSchema),
        mode: 'onSubmit',
        defaultValues: initialValues,
      });

    const watchedValues = watch();

    const onSubmit = async (data: CommentFormProps) => {
      const response = await fetchEditAdditionalInformation({
        fundId,
        ...data,
      });
      if (response.ok) {
        raiseToast.send(
          `Die Zusatzinfos für den Fonds "${fundName}" wurden erfolgreich gepflegt.`,
        );
        reset();
        toggle();
        handleSubmitSend();
      }
    };

    useEffect(() => {
      if (initialValues) {
        setValue('broker_notes', initialValues.broker_notes);
        setValue(
          'best_in_class_approach',
          initialValues.best_in_class_approach,
        );
        setValue('norm_based_screening', initialValues.norm_based_screening);
        setValue('trail_commission', initialValues.trail_commission);
      }
    }, [initialValues]);

    useEffect(() => {
      if (initialValues) {
        const hasChanges =
          watchedValues.broker_notes !== initialValues.broker_notes ||
          watchedValues.best_in_class_approach !==
            initialValues.best_in_class_approach ||
          watchedValues.norm_based_screening !==
            initialValues.norm_based_screening ||
          watchedValues.trail_commission !== initialValues.trail_commission;

        setFormChanged(hasChanges);
      }
    }, [watchedValues, initialValues]);

    useEffect(() => {
      if (!isShown) {
        reset();
      }
    }, [isShown]);

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        title={title}
        px={4}
        pb={5}
        pt={4}
      >
        <StyledContainer pt={2.5}>
          {!!subtitle?.length && (
            <Typography variant="body" color={COLORS.typography.description}>
              {subtitle}
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            pt={!!subtitle ? 5 : 2}
            pb={2.5}
          >
            <Typography variant="h5">{fundName}</Typography>
            <StyledIsin variant="body">{isin}</StyledIsin>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" gap={5}>
              <Textarea
                label="Hinweis für Vermittler"
                placeholder="Ihr Kommentar ..."
                {...register('broker_notes')}
              />
              <Textarea
                label="B-i-C-/B-o-C-Ansatz"
                placeholder="Ihr Kommentar ..."
                {...register('best_in_class_approach')}
              />
              <Textarea
                label="Normbasiertes Screening"
                placeholder="Ihr Kommentar ..."
                {...register('norm_based_screening')}
              />
              <Textarea
                label="Bestands-prov."
                placeholder="Ihr Kommentar ..."
                {...register('trail_commission')}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
              <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
                {cancelButtonText}
              </Button>
              <Button
                variant={
                  isWarning ? ButtonVariant.WARNING : ButtonVariant.PRIMARY
                }
                type="submit"
                disabled={!formChanged}
              >
                {confirmButtonText}
              </Button>
            </Box>
          </form>
        </StyledContainer>
      </Modal>
    );
  },
);
