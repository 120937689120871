import { FC } from 'react';
import { ButtonSize, ButtonVariant } from '../Button/Button.enums';
import { StyledButton, StyledPlusIconContainer } from './ButtonWithPlus.styled';
import { ButtonWithPlusProps } from './ButtonWithPlus.d';
import { ReactComponent as PlusIcon } from 'assets/Icons/plus.svg';

export const ButtonWithPlus: FC<ButtonWithPlusProps> = ({
  variant,
  label,
  ...props
}) => {
  const isPrimary = variant === 'primary';
  return (
    <StyledButton
      {...props}
      isPrimary={isPrimary}
      variant={ButtonVariant.OUTLINED}
      size={ButtonSize.SMALL}
      rightIcon={
        <StyledPlusIconContainer isPrimary={isPrimary}>
          <PlusIcon strokeWidth={1.2} />
        </StyledPlusIconContainer>
      }
    >
      {label}
    </StyledButton>
  );
};
