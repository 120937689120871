import { Option } from 'components/common/Select/Select.d';
import { COLORS } from 'theme/colors';
import { CreatedColumnComparisonList } from '../VerticalCollapseTable.d';

export const getPriorityByValue = (value: string, options: Option[]) => {
  const priority = options?.find((option) => option.value === value);
  return priority?.priority || 0;
};

const compareNumericValues = (
  pinnedColumn: CreatedColumnComparisonList,
  value: string,
  category: string,
  options: 'number+' | 'number-',
) => {
  const numberValue = parseFloat(value);
  const isNumberPlus = options === 'number+';
  const pinnedColumnValue = parseFloat(
    pinnedColumn[category as keyof CreatedColumnComparisonList]?.toString() ??
      '0',
  );

  if (numberValue === pinnedColumnValue) {
    return COLORS.background.secondary;
  } else if (
    (isNumberPlus && numberValue < pinnedColumnValue) ||
    (!isNumberPlus && numberValue > pinnedColumnValue)
  ) {
    return COLORS.error.hover;
  } else if (
    (isNumberPlus && numberValue > pinnedColumnValue) ||
    (!isNumberPlus && numberValue < pinnedColumnValue)
  ) {
    return COLORS.success.background;
  }
  return COLORS.background.secondary;
};

const comparePriorityValues = (
  pinnedColumn: CreatedColumnComparisonList,
  value: string,
  category: string,
  options: Option[],
) => {
  const pinnedColumnValue =
    pinnedColumn[category as keyof CreatedColumnComparisonList]?.toString() ||
    '';
  const pinnedColumnPriority = getPriorityByValue(pinnedColumnValue, options);
  const valuePriority = getPriorityByValue(value, options);

  if (valuePriority === pinnedColumnPriority) {
    return COLORS.background.secondary;
  } else if (valuePriority < pinnedColumnPriority) {
    return COLORS.error.hover;
  } else {
    return COLORS.success.background;
  }
};

export const getRowColor = (
  value: string,
  category: string,
  pinnedColumn: CreatedColumnComparisonList,
  options?: 'number+' | 'number-' | Option[],
) => {
  const pinnedColumnValue =
    pinnedColumn[category as keyof CreatedColumnComparisonList]?.toString() ||
    '';

  if (value === '-' || pinnedColumnValue === '-') {
    return COLORS.background.secondary;
  }
  if (typeof options === 'string' && options.includes('number')) {
    return compareNumericValues(pinnedColumn, value, category, options);
  } else {
    return comparePriorityValues(
      pinnedColumn,
      value,
      category,
      options as Option[],
    );
  }
};
