import { FC } from 'react';
import { FundItemProps } from './FundItem.d';
import {
  StyledContainer,
  StyledName,
  StyledPlusIconContainer,
} from './FundItem.styled';
import { ReactComponent as PlusIcon } from 'assets/Icons/plus.svg';

export const FundItem: FC<FundItemProps> = ({ name, id, onItemClick }) => {
  return (
    <StyledContainer>
      <StyledName variant="body">{name}</StyledName>
      <StyledPlusIconContainer onClick={() => onItemClick(id, name)}>
        <PlusIcon />
      </StyledPlusIconContainer>
    </StyledContainer>
  );
};
