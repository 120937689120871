import { FC } from 'react';
import { Skeleton, TableCell } from '@mui/material';
import { Table, TableBody, TableHead } from '@mui/material';
import {
  StyledTableRow,
  TableContainer,
  TableWrapper,
} from '../PortfolioCollapseTable.styled';
import { StyledTableCell } from './LoadingSkeleton.styled';
import { LoadingSkeletonProps } from './LoadingSkeleton.d';

export const LoadingSkeleton: FC<LoadingSkeletonProps> = ({
  rowsCount = 10,
}) => {
  return (
    <TableWrapper>
      <TableContainer isLastColumnsFixed={false}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <TableCell width="3px">
                <Skeleton height={24} />
              </TableCell>
              <StyledTableCell width="219px">
                <Skeleton height={24} />
              </StyledTableCell>
              <StyledTableCell width="330px">
                <Skeleton height={24} />
              </StyledTableCell>
              <StyledTableCell width="300px">
                <Skeleton height={24} />
              </StyledTableCell>
              <StyledTableCell align="center">
                <Skeleton height={24} />
              </StyledTableCell>
              <StyledTableCell width="90px" align="right">
                <Skeleton height={24} />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell width="3px">
                <Skeleton height={34} />
              </TableCell>
              <StyledTableCell width="219px">
                <Skeleton height={34} />
              </StyledTableCell>
              <StyledTableCell width="330px">
                <Skeleton height={34} />
              </StyledTableCell>
              <StyledTableCell width="300px">
                <Skeleton height={34} />
              </StyledTableCell>
              <StyledTableCell align="center">
                <Skeleton height={34} />
              </StyledTableCell>
              <StyledTableCell width="90px" align="right">
                <Skeleton height={34} />
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {[...Array(rowsCount)].map((_, index) => (
              <StyledTableRow key={index}>
                <TableCell width="3px">
                  <Skeleton height={48} />
                </TableCell>
                <StyledTableCell width="219px">
                  <Skeleton height={48} />
                </StyledTableCell>
                <StyledTableCell width="330px">
                  <Skeleton height={48} />
                </StyledTableCell>
                <StyledTableCell width="300px">
                  <Skeleton height={48} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Skeleton height={48} />
                </StyledTableCell>
                <StyledTableCell width="90px" align="right">
                  <Skeleton height={48} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};
