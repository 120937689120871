import { FC } from 'react';
import { PlansecurFiltersModalProps } from './PlansecurFiltersModal.d';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SlideOutFilterModal } from 'components/common/Modals/SlideOutFilterModal';
import { Box } from 'components/common/Box';
import { FilterAccordion } from '../FilterAccordion';
import { RangeFilters } from '../RangeFilters';
import { Option } from 'components/common/Select/Select.d';
import { ChipFilters } from '../ChipFilters';
import { YesNoFilters } from '../YesNoFilters';
import { ChipArticleFilters } from '../ChipArticleFilters';
import { CheckboxFilters } from '../CheckboxFilters';
import { TrendFilters } from '../TrendFilters';
import { CheckboxRegionsFilters } from '../CheckboxRegionsFilters';
import { SearchFilters } from '../SearchFilters';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { StarRatingFilters } from '../StarRatingFilters';
import {
  FilterItem,
  FilterType,
} from 'components/Modules/CustomerOverview/CustomerOverview';
import { SelectFilters } from '../SelectFilters/SelectFilters';
import { DateRangeFilters } from '../DateRangeFilters';

export const PlansecurFiltersModal: FC<PlansecurFiltersModalProps> = ({
  filters,
  selectedFilters,
  isShow,
  isLoading = false,
  clearFilters,
  closeModal,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { customNavigate } = useNavigation();

  const handleClearAllFilters = () => {
    const newSearchParams = new URLSearchParams();
    const filtersData = filters.map(({ data }) => data).flat();
    filtersData.forEach(({ id }) => newSearchParams.delete(id.toString()));
    setSearchParams(newSearchParams);
    clearFilters();
    closeModal();
    customNavigate(pathname + '?' + newSearchParams.toString());
  };

  const getFiltersNumber = (currentCategory: string) => {
    const filtered = selectedFilters.filter(({ category }) =>
      category.includes(currentCategory),
    );

    return filtered.length > 0 ? filtered.length : undefined;
  };

  const getInnerFiltersNumber = (currentId: string) => {
    const filtered = selectedFilters.filter(({ id }) => currentId === id);
    return filtered.length > 0 ? filtered.length : undefined;
  };

  const getFilterByType = (
    type: FilterType,
    options: Option[],
    id: string,
    maxSelected?: number,
  ) => {
    switch (type) {
      case 'chips':
        return (
          <ChipFilters id={id} options={options} maxSelected={maxSelected} />
        );
      case 'chips-rating':
        return (
          <ChipFilters id={id} options={options} maxSelected={maxSelected} />
        );
      case 'chips-article':
        return (
          <ChipArticleFilters
            id={id}
            options={options}
            maxSelected={maxSelected}
          />
        );
      case 'trend':
        return (
          <TrendFilters id={id} options={options} maxSelected={maxSelected} />
        );
      case 'checkboxes':
        return (
          <CheckboxFilters
            id={id}
            options={options}
            maxSelected={maxSelected}
          />
        );
      case 'checkboxes-others':
        return (
          <CheckboxFilters
            id={id}
            options={options}
            maxSelected={maxSelected}
          />
        );
      case 'checkboxes-regions':
        return (
          <CheckboxRegionsFilters
            id={id}
            options={options}
            maxSelected={maxSelected}
          />
        );
      case 'search':
        return <SearchFilters id={id} />;
      case 'yes-no':
        return <YesNoFilters id={id} options={options} />;
      case 'range':
        return <RangeFilters id={id} options={options} />;
      case 'date-range':
        return <DateRangeFilters id={id} options={options} />;
      case 'star-rating':
        return <StarRatingFilters id={id} />;
      case 'multi-select':
        return <SelectFilters options={options} id={id} />;
      default:
        return <ChipFilters id={id} options={options} />;
    }
  };

  const renderInnerAccordion = (
    data: FilterItem[],
    isLargeVariant: boolean,
  ) => (
    <FilterAccordion
      isLoading={isLoading}
      variant={isLargeVariant ? 'large' : 'small'}
      tabs={data.map(
        ({ innerCategoryName, id, options, maxSelected, type }) => ({
          label: innerCategoryName,
          id: innerCategoryName,
          disabled: false,
          content: getFilterByType(type, options, id.toString(), maxSelected),
          count: getInnerFiltersNumber(id.toString()),
        }),
      )}
    />
  );

  return (
    <SlideOutFilterModal
      isShown={isShow}
      closeModal={closeModal}
      clearFilters={handleClearAllFilters}
      applyFilters={closeModal}
      variant="large"
    >
      <Box px={2.5} pb={1}>
        {filters.map(({ categoryName, data }) =>
          categoryName.length > 0 ? (
            <FilterAccordion
              isLoading={isLoading}
              tabs={[
                {
                  label: categoryName,
                  id: categoryName,
                  disabled: false,
                  count: getFiltersNumber(categoryName),
                  content: renderInnerAccordion(data as FilterItem[], false),
                },
              ]}
            />
          ) : (
            renderInnerAccordion(data as FilterItem[], data.length <= 1)
          ),
        )}
      </Box>
    </SlideOutFilterModal>
  );
};
