import { FC, useEffect, useRef, useState } from 'react';
import { VersionHistoryModalProps } from './VersionHistoryModal.d';
import {
  StyledCloseButton,
  StyledCurrentVersion,
  StyledDate,
  StyledModalContent,
  StyledModalHeader,
  StyledSlideOutModal,
  StyledVersions,
  StyledVersionsContainer,
} from './VersionHistoryModal.styled';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';
import { useClickOutside } from 'hooks/useClickOutside';
import { Typography } from 'components/common/Typography';
import { Box } from 'components/common/Box';
import { getCompassDataSnapshots } from 'services/Plansecur';
import { convertDateFormat } from 'utils/common';

export const VersionHistoryModal: FC<VersionHistoryModalProps> = ({
  isShown,
  selectedDate,
  setSelectDate,
  closeModal,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dates, setDates] = useState<string[]>([]);

  useClickOutside(wrapperRef, () => {
    closeModal();
  });

  const getVersionText = (count: number): string => {
    if (count === 0) {
      return 'Keine automatischen sicherungsversionen';
    } else if (count === 1) {
      return '1 automatische sicherungsversion';
    } else {
      return `${count} automatische sicherungsversionen`;
    }
  };

  const getDates = async () => {
    const response = await getCompassDataSnapshots();
    if (response.ok) {
      setDates(response.parsedBody);
    }
  };

  useEffect(() => {
    getDates();
  }, []);

  return (
    <StyledSlideOutModal isShown={isShown} ref={wrapperRef}>
      <StyledModalHeader>
        <Typography variant="h4">Versionsverlauf</Typography>
        <StyledCloseButton onClick={closeModal}>
          <CloseIcon stroke={COLORS.typography.placeholder} />
        </StyledCloseButton>
      </StyledModalHeader>
      <StyledModalContent noData={dates.length === 0}>
        <StyledVersionsContainer />
        <Box display="flex" flexDirection="column" gap={3}>
          <StyledCurrentVersion
            isDateSelected={!selectedDate}
            onClick={() => setSelectDate(null)}
          >
            Aktuelle version
          </StyledCurrentVersion>
          <StyledVersions noData={dates.length === 0}>
            {getVersionText(dates.length)}
          </StyledVersions>
          {!!dates.length && (
            <Box display="flex" flexDirection="column" gap={2}>
              {dates.map((date, index) => (
                <StyledDate
                  key={date}
                  selected={date === selectedDate}
                  onClick={() => setSelectDate(date)}
                >
                  {convertDateFormat(date)}
                </StyledDate>
              ))}
            </Box>
          )}
        </Box>
      </StyledModalContent>
    </StyledSlideOutModal>
  );
};
