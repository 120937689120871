import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { pxToRem } from 'utils/common';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.info.selectedBorder};
  height: ${pxToRem(56)};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: unset;
  border: none;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.background.secondary};

  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    color: ${COLORS.stroke.main};

    svg {
      fill: ${COLORS.stroke.main};
    }
  }
`;

export const StyledIconContainer = styled.div`
  width: 24px;
  height: 24px;
  color: ${COLORS.info.selectedBorder};
`;

export const StyledButtonsContainer = styled(Box)`
  border-right: 1px solid ${COLORS.background.secondary};
  height: 24px;
`;
