import { FC } from 'react';
import { PeerGroupProps } from './PeerGroup.d';
import {
  StyledContainer,
  StyledInfoContainer,
  StyledInfoItem,
  StyledItemContainer,
  StyledLabel,
  StyledRowContainer,
  StyledTypography,
} from './PeerGroup.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { periods } from './config';
import { convertToMillions, formatNumberWithComma } from 'utils/common';
import { Typography } from 'components/common/Typography';

export const PeerGroup: FC<PeerGroupProps> = ({
  description,
  name,
  alpha,
  funds_num,
  net_flows_6m,
  net_flows_6m_avg,
  return_pct,
  total_volume,
  track_record_avg,
  volatility,
  volume_avg,
}) => {
  const leftTableData = [
    { label: 'Anzahl Fonds', value: funds_num },
    {
      label: 'Gesamtvolumen in Mio. EUR',
      value: convertToMillions(total_volume),
    },
    {
      label: 'Summe NMZ der letzten 6 Monate in Mio. EUR',
      value: convertToMillions(net_flows_6m),
    },
  ];
  const middleTableData = [
    {
      categoryName: 'Rendite',
      values: {
        year_1: formatNumberWithComma(return_pct.year_1),
        year_3: formatNumberWithComma(return_pct.year_3),
        year_5: formatNumberWithComma(return_pct.year_5),
      },
    },
    {
      categoryName: 'Volatilität',
      values: {
        year_1: formatNumberWithComma(volatility.year_1),
        year_3: formatNumberWithComma(volatility.year_3),
        year_5: formatNumberWithComma(volatility.year_5),
      },
    },
    ...(alpha
      ? [
          {
            categoryName: 'Alpha',
            values: {
              year_1: formatNumberWithComma(alpha?.year_1) || null,
              year_3: formatNumberWithComma(alpha?.year_3) || null,
              year_5: formatNumberWithComma(alpha?.year_5) || null,
            },
          },
        ]
      : []),
  ];
  const rightTableData = [
    {
      label: 'Fondsvolumen in Mio. Euro',
      value: volume_avg ? convertToMillions(volume_avg) : '-',
    },
    {
      label: 'Track Record in Jahren',
      value: track_record_avg ? formatNumberWithComma(track_record_avg) : '-',
    },
    {
      label: 'NMZ der letzten 6 Monate in Mio. EUR',
      value: net_flows_6m_avg ? convertToMillions(net_flows_6m_avg) : '-',
    },
  ];

  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer
        pt={5}
        display="grid"
        gridAutoFlow="column"
        alignItems="end"
      >
        {leftTableData.length !== 0 && (
          <StyledInfoContainer display="flex" flexDirection="column">
            <Box
              key="peer-group-name"
              display="flex"
              justifyContent="space-between"
              gap={1}
            >
              <Typography variant="body" weight="semibold">
                {name}
              </Typography>
            </Box>
            {leftTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
              >
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
                <StyledTypography variant="body">
                  {item.value || ''}
                </StyledTypography>
              </StyledInfoItem>
            ))}
          </StyledInfoContainer>
        )}
        <Box>
          <StyledRowContainer
            py={1}
            display="flex"
            justifyContent="flex-end"
            gap={1}
          >
            <Box display="flex" justifyContent="flex-end" gap={6.5}>
              {periods.map((period) => (
                <StyledItemContainer
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <StyledLabel
                    variant="body"
                    weight="semibold"
                    color={COLORS.typography.description}
                  >
                    {period.columnName}
                  </StyledLabel>
                </StyledItemContainer>
              ))}
            </Box>
          </StyledRowContainer>
          {middleTableData.length !== 0 &&
            middleTableData.map((item, index) => (
              <StyledRowContainer
                pt={1}
                pb={index === middleTableData.length - 1 ? 0 : 1}
                display="flex"
                justifyContent="space-between"
                gap={1}
              >
                <StyledLabel variant="body" weight="semibold">
                  {item.categoryName}
                </StyledLabel>
                <Box display="flex" justifyContent="flex-end" gap={6}>
                  {Object.values(item.values).map((value) => (
                    <StyledItemContainer>
                      <StyledTypography variant="body">
                        {value ? `${value}%` : '-'}
                      </StyledTypography>
                    </StyledItemContainer>
                  ))}
                </Box>
              </StyledRowContainer>
            ))}
        </Box>
        {rightTableData.length !== 0 && (
          <StyledInfoContainer display="flex" flexDirection="column">
            <StyledInfoItem
              key="average"
              display="flex"
              justifyContent="flex-end"
            >
              <StyledTypography
                variant="body"
                weight="semibold"
                color={COLORS.typography.description}
              >
                Durchschnitt
              </StyledTypography>
            </StyledInfoItem>
            {rightTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
              >
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
                <StyledTypography variant="body">
                  {item.value || ''}
                </StyledTypography>
              </StyledInfoItem>
            ))}
          </StyledInfoContainer>
        )}
      </StyledContainer>
    </Box>
  );
};
