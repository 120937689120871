import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { StarRatingFiltersProps } from './StarRatingFilters.d';
import { StyledRating } from './StarRatingFilters.styled';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { ReactComponent as StarIcon } from 'assets/Icons/rating-star.svg';
import { COLORS } from 'theme/colors';

export const StarRatingFilters: FC<StarRatingFiltersProps> = ({ id }) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams, _] = useSearchParams();
  const filterValue = searchParams.get(id);
  const [value, setValue] = useState(filterValue || '');

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number | null,
  ) => {
    event.preventDefault();
    setValue(newValue?.toString() || '');
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const currentFilterValue = params.get(id);

    params.delete(id);

    if (!!value) {
      params.append(id, value.toLowerCase());
    }
    const isFilterChanged = currentFilterValue !== value.toString();

    if (isFilterChanged) {
      params.delete('page');
    }

    customNavigate(pathname + '?' + params.toString());
  }, [value, id]);

  useEffect(() => {
    const newValue = searchParams.get(id);
    setValue(newValue || '');
  }, [searchParams, id]);

  return (
    <StyledRating
      value={Number(value)}
      onChange={handleChange}
      name="rating"
      precision={1}
      icon={<StarIcon width={24} height={24} fill={COLORS.warning.primary} />}
      emptyIcon={
        <StarIcon width={24} height={24} fill={COLORS.background.module} />
      }
      size="large"
    />
  );
};
