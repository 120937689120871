import { Tabs } from 'components/common/Tabs';
import { FC, useEffect, useRef, useState } from 'react';
import { FCSmartFundBenchmarkingTable } from '../Tables/FCSmartFundBenchmarkingTable';
import { ESGCredibilityActionScoreTable } from '../Tables/ESGCredibilityActionScoreTable';
import { PrivateMarketsRealEstateTable } from '../Tables/PrivateMarketsRealEstateTable';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ReactComponent as AddIcon } from 'assets/Icons/add-fund-outlined.svg';
import { ReactComponent as ArrowIcon } from 'assets/Icons/collapse-arrow.svg';
import { COLORS } from 'theme/colors';
import { AddFundToWatchlistModal } from 'components/common/Modals/AddFundToWatchlistModal';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { getWatchlists } from 'services/Watchlists';
import { WatchlistItem } from 'types/Watchlist';
import { ItemsListModal } from 'components/common/Modals/ItemsListModal/ItemsListModal';
import { ReactComponent as MyWatchlistsIcon } from 'assets/Icons/comparison-list-outlined-grey.svg';
import {
  addWatchlist,
  deleteWatchlist,
  editWatchlist,
} from 'services/Watchlists';

export const MyWatchlistContent: FC = () => {
  const [selectedWatchlist, setSelectedWatchlist] =
    useState<WatchlistItem | null>(null);
  const [isMyWatchlistsModalOpen, setIsMyWatchlistsModalOpen] = useState(false);
  const [isAddFundModalOpen, setIsAddFundModalOpen] = useState(false);
  const [watchlists, setWatchlists] = useState<WatchlistItem[] | null>(null);
  const modalRef = useRef(null);

  const tabs = [
    {
      label: 'FC Smart Fund Benchmarking',
      content: (
        <FCSmartFundBenchmarkingTable
          variant="watchlist"
          selectedWatchlist={selectedWatchlist}
        />
      ),
    },
    {
      label: 'ESG Credibility & Action Score',
      content: (
        <ESGCredibilityActionScoreTable
          variant="watchlist"
          selectedWatchlist={selectedWatchlist}
        />
      ),
    },
    {
      label: 'Private Markets',
      content: (
        <PrivateMarketsRealEstateTable
          variant="watchlist"
          selectedWatchlist={selectedWatchlist}
        />
      ),
    },
  ];

  const fetchWatchlists = async () => {
    const response = await getWatchlists();
    if (response.ok) {
      const data = response.parsedBody;
      setWatchlists(
        data.map(({ id, name, count }) => ({
          id,
          name,
          fundsNumber: count ?? 0,
        })),
      );
    }
  };

  useEffect(() => {
    fetchWatchlists();
  }, []);

  useEffect(() => {
    if (!selectedWatchlist && watchlists && watchlists.length > 0) {
      setSelectedWatchlist(watchlists[0]);
    }
    if (
      watchlists &&
      selectedWatchlist &&
      !watchlists.some((watchlist) => watchlist.id === selectedWatchlist.id)
    ) {
      setSelectedWatchlist(watchlists[0]);
    }
  }, [watchlists]);

  return (
    <Box>
      <ItemsListModal
        ref={modalRef}
        isShown={isMyWatchlistsModalOpen}
        toggle={() => setIsMyWatchlistsModalOpen(!isMyWatchlistsModalOpen)}
        items={watchlists}
        selectedItem={selectedWatchlist}
        setSelectedItem={setSelectedWatchlist}
        getItems={fetchWatchlists}
        icon={<MyWatchlistsIcon />}
        modalTitle="Fondslistsen"
        listTitle="Meine Fondslistsen"
        createButtonLabel="Liste erstellen"
        searchPlaceholder="Suchlisten"
        emptyStateMessage="Keine Daten gefunden"
        createItemTitle="Fondsliste erstellen"
        deleteItemTitle="Fondsliste löschen"
        deleteItemSubtitle="Sind Sie sicher, dass Sie löschen möchten"
        confirmButtonText="Ja, Entfernen"
        createSuccessMessage="Fondsliste erfolgreich erstellt."
        editSuccessMessage="Fondsliste erfolgreich bearbeitet."
        deleteSuccessMessage="Fondsliste erfolgreich gelöscht."
        addItem={addWatchlist}
        editItem={editWatchlist}
        deleteItem={deleteWatchlist}
      />
      {selectedWatchlist && (
        <AddFundToWatchlistModal
          isShown={isAddFundModalOpen}
          selectedWatchlist={selectedWatchlist}
          toggle={() => setIsAddFundModalOpen(!isAddFundModalOpen)}
          onAddFund={() => fetchWatchlists()}
        />
      )}
      <Box
        px={2.5}
        pb={2.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {
          <Button
            variant={ButtonVariant.OUTLINED}
            rightIcon={
              <ArrowIcon
                stroke={COLORS.typography.main}
                style={{ transform: 'rotate(90deg)' }}
              />
            }
            onClick={() => setIsMyWatchlistsModalOpen(true)}
          >
            <Box pr={1}>
              {selectedWatchlist
                ? selectedWatchlist.name
                : 'Fondsliste erstellen'}
            </Box>
          </Button>
        }
        {selectedWatchlist && (
          <Button
            rightIcon={<AddIcon />}
            onClick={() => setIsAddFundModalOpen(true)}
          >
            Guthaben hinzufügen
          </Button>
        )}
      </Box>
      <Tabs tabs={tabs} />
    </Box>
  );
};
