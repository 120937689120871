import { FC, useEffect } from 'react';
import { Modal } from 'components/common/Modal/Modal';
import { Box } from 'components/common/Box';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { CreateItemFields, CreateItemModalProps } from './CreateItemModal.d';
import { useForm } from 'react-hook-form';
import { isValidForm } from 'utils/isValidForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { createWatchlistSchema } from '../../CreateWatchlistModal/validators';
import { StyledContainer } from './CreateItemModal.styled';

export const CreateItemModal: FC<CreateItemModalProps> = ({
  isShown,
  toggle,
  onSubmit,
  title = 'Create item',
  inputLabel = 'Name',
  submitButtonText = 'Speichern',
  cancelButtonText = 'Abbrechen',
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateItemFields>({
    resolver: yupResolver(createWatchlistSchema),
    mode: 'onBlur',
  });

  const onCreateWatchlist = ({ name }: CreateItemFields) => {
    onSubmit(name);
  };

  useEffect(() => {
    if (!isShown) {
      reset();
    }
  }, [isShown]);

  return (
    <Modal
      isShown={isShown}
      onClose={toggle}
      withCloseButton
      title={title}
      px={4}
      pb={5}
      pt={4}
      borderRadius="8px"
    >
      <StyledContainer pt={4}>
        <form onSubmit={handleSubmit(onCreateWatchlist)}>
          <Input
            label={inputLabel}
            placeholder="Benutzerdefinierten Titel eingeben"
            {...register('name')}
            error={!isValidForm(errors) && errors.name?.message}
          />

          <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
            <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
              {cancelButtonText}
            </Button>
            <Button type="submit">{submitButtonText}</Button>
          </Box>
        </form>
      </StyledContainer>
    </Modal>
  );
};
