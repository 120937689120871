import { FC } from 'react';
import { VersionButtonProps } from './VersionButton.d';
import {
  StyledArrowIcon,
  StyledButton,
  StyledDate,
  StyledRefreshIcon,
} from './VersionButton.styled';
import { Box } from 'components/common/Box';
import { convertDateFormat } from 'utils/common';

export const VersionButton: FC<VersionButtonProps> = ({ date, ...props }) => {
  return (
    <StyledButton {...props}>
      <StyledRefreshIcon />
      <Box>
        {date ? (
          <>
            Version <StyledDate>{convertDateFormat(date)}</StyledDate>
          </>
        ) : (
          'Aktuelle version'
        )}
      </Box>
      <StyledArrowIcon />
    </StyledButton>
  );
};
