import { useState, FC, useEffect } from 'react';
import { TabsWithDividerProps } from './TabsWithDivider.d';
import {
  TabDivider,
  TabItem,
  TabSeparator,
  TabsList,
} from './TabsWithDivider.styled';
import { slugify } from 'utils/common';
import { Box } from 'components/common/Box';
import { useLocation } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const TabsWithDivider: FC<TabsWithDividerProps> = ({
  tabs,
  onTabChange,
  searchParamItem = 'tab',
}) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [activeTab, setActiveTab] = useState(slugify(tabs[0].label));

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    newActiveTab: string,
  ) => {
    e.preventDefault();
    setActiveTab(slugify(newActiveTab));
    onTabChange && onTabChange(newActiveTab);
  };

  useEffect(() => {
    const originalSearchParams = new URLSearchParams(search);

    const newSearchParams = new URLSearchParams();
    if (originalSearchParams.get(searchParamItem) !== activeTab) {
      originalSearchParams.delete(searchParamItem);

      newSearchParams.append(searchParamItem, activeTab);
    }

    originalSearchParams.forEach((value, key) => {
      newSearchParams.append(key, value);
    });

    customNavigate(pathname + '?' + newSearchParams.toString());
  }, [activeTab]);

  return (
    <>
      <TabsList>
        {tabs.map((tab, index) => {
          const { label } = tab;
          const active = slugify(label) === activeTab;
          return (
            <TabItem
              key={label}
              onClick={(e) => handleClick(e, label)}
              active={slugify(label) === activeTab}
            >
              <Box display="flex" gap={3}>
                <Box display="flex" flexDirection="column">
                  {label}
                  <TabSeparator mt={1} active={active} />
                </Box>
                {index !== tabs.length - 1 && <TabDivider />}
              </Box>
            </TabItem>
          );
        })}
      </TabsList>
      {tabs.map((tab) => {
        if (slugify(tab.label) === activeTab)
          return (
            <Box pt={2.3} pb={3} key={tab.label}>
              {tab.content}
            </Box>
          );
      })}
    </>
  );
};
