import { FC, ReactNode, useEffect, useState } from 'react';
import { ComparisonListChartProps, FilterProps } from './ComparisonListChart.d';
import {
  StyledBarContainer,
  StyledContainer,
  StyledLabel,
  StyledPoint,
  StyledSelectContainer,
  StyledLegendContainer,
} from './ComparisonListChart.styled';
import { CustomSelect } from 'components/common/Select';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { backgroundColors, chartLabels } from './config';
import { COLORS } from 'theme/colors';
import { SelectChangeEvent } from '@mui/material';
import { Box } from 'components/common/Box';
import { formatNumberWithComma } from 'utils/common';
import { Switch } from 'components/common/Switch';

export const ComparisonListChart: FC<ComparisonListChartProps> = ({ data }) => {
  const [showAveragePeerGroup, setShowAveragePeerGroup] = useState(false);
  const colors = [
    ...(showAveragePeerGroup ? [COLORS.typography.main] : []),
    ...backgroundColors,
  ];
  const [pinnedFundData] = data;
  const pgReturnPct = {
    periodsWithScore: pinnedFundData.pg_return_pct,
    fundName: pinnedFundData.peer_group_name || '',
  };
  const pgVolatility = {
    periodsWithScore: pinnedFundData.pg_volatility,
    fundName: pinnedFundData.peer_group_name || '',
  };
  const [currentFilter, setCurrentFilter] = useState<FilterProps>({
    value: 'Rendite',
    name: 'Rendite',
    data: [
      ...data.map((item) => ({
        periodsWithScore: item.return_pct,
        fundName: item.name || '',
      })),
    ],
  });
  const selectOptions: FilterProps[] = [
    {
      value: 'Rendite',
      name: 'Rendite',
      data: [
        ...data.map((item) => ({
          periodsWithScore: item.return_pct,
          fundName: item.name || '',
        })),
      ],
    },
    {
      value: 'Volatilität',
      name: 'Volatilität',
      data: [
        ...data.map((item) => ({
          periodsWithScore: item.volatility,
          fundName: item.name || '',
        })),
      ],
    },
  ];

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAveragePeerGroup(event.target.checked);
  };

  const options: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          drawTicks: false,
          display: false,
        },
        border: {
          color: COLORS.stroke.primary,
        },
        ticks: {
          padding: 16,
          color: COLORS.typography.main,
          font: {
            size: 12,
            weight: 600,
            family: 'Inter',
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawTicks: false,
          color: COLORS.stroke.primary,
        },
        border: {
          display: false,
          dash: [4, 6],
        },
        ticks: {
          stepSize: 2,
          padding: 18,
          color: COLORS.typography.placeholder,
          font: {
            size: 12,
            family: 'Inter',
          },
        },
        title: {
          display: true,
          text: currentFilter.name,
          padding: 12,
          color: COLORS.typography.main,
          font: {
            size: 12,
            weight: 600,
            family: 'Inter',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        padding: { left: 14, right: 14, top: 8, bottom: 8 },
        yAlign: 'bottom',
        caretPadding: 8,
        cornerRadius: 4,
        backgroundColor: COLORS.typography.main,
        usePointStyle: true,
        callbacks: {
          label: (context) => context.dataset.label,
          labelColor: (context) => {
            return {
              borderColor: context.dataset.backgroundColor as string,
              backgroundColor: context.dataset.backgroundColor as string,
              borderWidth: 0,
              borderRadius: 2,
            };
          },
          title: () => '',
          footer: (context) => `Zeitraum: ${context[0].label}`,
          afterFooter: (context) => {
            const value = context[0].raw as number;
            return `${currentFilter.name}: ${formatNumberWithComma(value)}%`;
          },
          labelPointStyle: (context) => {
            return {
              pointStyle: 'rectRounded',
              rotation: 0,
            };
          },
        },
      },
    },
  };

  const chartData: ChartData<'bar', number[]> = {
    labels: chartLabels,
    datasets: currentFilter.data.map((item, index) => ({
      label: item.fundName,
      data: [
        item.periodsWithScore?.month_6 || 0,
        item.periodsWithScore?.year_1 || 0,
        item.periodsWithScore?.year_3 || 0,
        item.periodsWithScore?.year_5 || 0,
        item.periodsWithScore?.year_7 || 0,
        item.periodsWithScore?.year_10 || 0,
      ],
      backgroundColor: colors[index],
      borderRadius: 2,
      categoryPercentage: 0.35,
    })),
  };

  const handleSelectChange = (
    event: SelectChangeEvent<any>,
    _child: ReactNode,
  ) => {
    const newValue = event.target.value.value;
    const [newFilter] = selectOptions.filter((item) => item.value === newValue);
    const isReturn = currentFilter.value === 'Rendite';
    newFilter &&
      setCurrentFilter({
        ...newFilter,
        data: [
          ...(showAveragePeerGroup
            ? [isReturn ? pgReturnPct : pgVolatility]
            : []),
          ...newFilter.data,
        ],
      });
  };

  useEffect(() => {
    setCurrentFilter(selectOptions[0]);
  }, [data]);

  useEffect(() => {
    const newFilter = selectOptions.find(
      (item) => item.value === currentFilter.value,
    );
    const isReturn = currentFilter.value === 'Rendite';
    newFilter &&
      setCurrentFilter({
        ...newFilter,
        data: [
          ...(showAveragePeerGroup
            ? [isReturn ? pgReturnPct : pgVolatility]
            : []),
          ...newFilter.data,
        ],
      });
  }, [showAveragePeerGroup]);

  return (
    <StyledContainer>
      <StyledSelectContainer>
        <CustomSelect
          selectVariant="filter"
          value={{ value: currentFilter.value, name: currentFilter.name }}
          options={selectOptions.map(({ value, name }) => ({
            value,
            name,
          }))}
          action={handleSelectChange}
          placeholder={currentFilter.name}
        />
        <Switch
          checked={showAveragePeerGroup}
          onChange={handleSwitchChange}
          label="Durchschnitt Peergruppe des Vergleichsfonds anzeigen"
        />
      </StyledSelectContainer>
      <StyledBarContainer>
        <Bar options={options} data={chartData} />
      </StyledBarContainer>
      <StyledLegendContainer>
        {currentFilter.data.map((item, index) => (
          <Box display="flex" gap={1} alignItems="center">
            {colors[index] && <StyledPoint color={colors[index]} />}
            <StyledLabel variant="body">{item.fundName}</StyledLabel>
          </Box>
        ))}
      </StyledLegendContainer>
    </StyledContainer>
  );
};
