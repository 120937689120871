import { FC, useState } from 'react';
import { ExpandableTextProps } from './ExpandableText.d';
import { Box } from '../Box';
import { COLORS } from 'theme/colors';
import { ReactComponent as ArrowCollapseIcon } from 'assets/Icons/collapse-arrow.svg';
import { ButtonVariant } from '../Buttons/Button/Button.enums';
import {
  StyledShowMoreButton,
  StyledTypography,
} from './ExpandableText.styled';

const MAX_TEXT_LENGTH = 200;

export const ExpandableText: FC<ExpandableTextProps> = ({
  showMoreButtonText,
  text,
}) => {
  const [showMore, setShowMore] = useState(false);
  const isLongText = text.length > MAX_TEXT_LENGTH;
  const truncatedText = text.slice(0, MAX_TEXT_LENGTH);
  const handleShowMoreToggle = () => setShowMore(!showMore);

  return (
    <Box display="flex" flexDirection="column" gap={1} py={1}>
      {isLongText ? (
        <>
          <StyledTypography variant="body">
            {showMore ? text : truncatedText}
          </StyledTypography>
          <StyledShowMoreButton
            variant={ButtonVariant.TEXT}
            rightIcon={
              <ArrowCollapseIcon
                stroke={
                  showMore ? COLORS.accent.primary : COLORS.typography.main
                }
                style={{
                  transform: showMore ? 'rotate(-90deg)' : 'rotate(90deg)',
                }}
              />
            }
            onClick={handleShowMoreToggle}
          >
            {showMore
              ? 'Weniger anzeigen'
              : showMoreButtonText ?? 'Mehr anzeigen'}
          </StyledShowMoreButton>
        </>
      ) : (
        <StyledTypography variant="body">{text}</StyledTypography>
      )}
    </Box>
  );
};
