import { FC, useEffect, useState } from 'react';
import { StyledContainer, StyledPoint } from './FundVolumeAndNetInflows.styled';
import { FundVolumeAndNetInflowsTable } from './FundVolumeAndNetInflowsTable';
import {
  RegularColumn,
  GroupColumn,
  CreatedRow,
} from './FundVolumeAndNetInflowsTable/FundVolumeAndNetInflowsTable.d';
import { Typography } from 'components/common/Typography';
import { formatNumberWithComma } from 'utils/common';
import { COLORS } from 'theme/colors';
import {
  StyledFondName,
  StyledIsin,
  StyledNameColumnContainer,
} from '../Dashboard/Dashboard.styled';
import { Box } from 'components/common/Box';
import { SortModelProps } from '../DashboardCardWithTable/DashboardCardWithTable.d';
import { PlansecurFundProps } from 'types/Plansecur';
import { fetchKompassFund } from 'services/Plansecur';

const ITEMS_TO_SHOW_COUNT = 10;

export const FundVolumeAndNetInflows: FC = () => {
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [rows, setRows] = useState<CreatedRow[]>([]);

  const createRow = (data: PlansecurFundProps): CreatedRow => {
    return {
      id: data.id,
      name: data.name,
      isin: data.isin,
      volume: data.volume_in_million_eur,
      net_flows: {
        net_flows_3_months: {
          absolute: data.abs_net_flow_in_million_eur_3_months,
          relative: data.relative_net_flow_percentage_3_months,
        },
        net_flows_6_months: {
          absolute: data.abs_net_flow_in_million_eur_6_months,
          relative: data.relative_net_flow_percentage_6_months,
        },
        net_flows_1_year: {
          absolute: data.abs_net_flow_in_million_eur_1_year,
          relative: data.relative_net_flow_percentage_1_year,
        },
      },
    };
  };

  const getTableData = async () => {
    const response = await fetchKompassFund({
      page: 1,
      page_size: ITEMS_TO_SHOW_COUNT,
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
    });
    if (response.ok) {
      const data = response.parsedBody.results.funds;
      setRows(data.map((row) => createRow(row)));
    }
  };

  useEffect(() => {
    getTableData();
  }, [sortModel]);

  const getFundVolumeStyle = (volume: number | undefined | null) => {
    if (volume === undefined || volume === null) {
      return {
        color: COLORS.background.module,
        weight: 'regular' as const,
      };
    }

    if (volume < 30) {
      return {
        color: COLORS.error.background,
        weight: 'medium' as const,
      };
    } else if (volume < 50) {
      return {
        color: COLORS.warning.primary,
        weight: 'medium' as const,
      };
    }

    return {
      color: COLORS.background.module,
      weight: 'regular' as const,
    };
  };

  const columns: (RegularColumn | GroupColumn)[] = [
    {
      id: 'name',
      label: 'Fondsname / ISIN',
      type: 'regular' as const,
      align: 'left',
      sortable: false,
      formatter: (value, row) => (
        <StyledNameColumnContainer
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={0.3}
        >
          <StyledFondName variant="subtitle" weight="semibold">
            {value}
          </StyledFondName>
          <StyledIsin variant="body">{row.isin}</StyledIsin>
        </StyledNameColumnContainer>
      ),
    },
    {
      id: 'volume',
      label: 'Fonds-Volumen',
      subLabel: '[Mio. EUR]',
      type: 'regular' as const,
      align: 'right',
      sortable: true,
      sortKey: 'volume_in_million_eur',
      formatter: (value) => {
        const volumeValue = value ? Number(value) : null;
        const { color, weight } = getFundVolumeStyle(volumeValue);

        return (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            justifyContent="flex-end"
          >
            <Typography variant="body" weight={weight}>
              {value ? formatNumberWithComma(Number(value)) : '-'}
            </Typography>
            <StyledPoint color={color} />
          </Box>
        );
      },
    },
    {
      id: 'net_flows.net_flows_3_months.absolute',
      label: 'Absolut',
      subLabel: '[Mio. EUR]',
      type: 'regular' as const,
      align: 'right',
      sortable: true,
      sortKey: 'abs_net_flow_in_million_eur_3_months',
      formatter: (value) => (
        <Typography
          variant="body"
          weight="medium"
          color={value > 0 ? COLORS.success.bulletPoint : COLORS.error.main}
        >
          {`${value > 0 ? '+' : ''}${formatNumberWithComma(value)}`}
        </Typography>
      ),
    },
    {
      id: 'net_flows.net_flows_3_months.relative',
      label: 'Relativ',
      type: 'regular' as const,
      align: 'right',
      sortable: true,
      sortKey: 'relative_net_flow_percentage_3_months',
      formatter: (value) => (
        <Typography
          variant="body"
          weight="medium"
          color={value > 0 ? COLORS.success.bulletPoint : COLORS.error.main}
        >
          {`${value > 0 ? '+' : ''}${formatNumberWithComma(value)}%`}
        </Typography>
      ),
    },
    {
      id: 'net_flows.net_flows_6_months.absolute',
      label: 'Absolut',
      subLabel: '[Mio. EUR]',
      type: 'regular' as const,
      align: 'right',
      sortable: true,
      sortKey: 'abs_net_flow_in_million_eur_6_months',
      formatter: (value) => (
        <Typography
          variant="body"
          weight="medium"
          color={value > 0 ? COLORS.success.bulletPoint : COLORS.error.main}
        >
          {`${value > 0 ? '+' : ''}${formatNumberWithComma(value)}`}
        </Typography>
      ),
    },
    {
      id: 'net_flows.net_flows_6_months.relative',
      label: 'Relativ',
      type: 'regular' as const,
      align: 'right',
      sortable: true,
      sortKey: 'relative_net_flow_percentage_6_months',
      formatter: (value) => (
        <Typography
          variant="body"
          weight="medium"
          color={value > 0 ? COLORS.success.bulletPoint : COLORS.error.main}
        >
          {`${value > 0 ? '+' : ''}${formatNumberWithComma(value)}%`}
        </Typography>
      ),
    },
    {
      id: 'net_flows.net_flows_1_year.absolute',
      label: 'Absolut',
      subLabel: '[Mio. EUR]',
      type: 'regular' as const,
      align: 'right',
      sortable: true,
      sortKey: 'abs_net_flow_in_million_eur_1_year',
      formatter: (value) => (
        <Typography
          variant="body"
          weight="medium"
          color={value > 0 ? COLORS.success.bulletPoint : COLORS.error.main}
        >
          {`${value > 0 ? '+' : ''}${formatNumberWithComma(value)}`}
        </Typography>
      ),
    },

    {
      id: 'net_flows.net_flows_1_year.relative',
      label: 'Relativ',
      type: 'regular' as const,
      align: 'right',
      sortable: true,
      sortKey: 'relative_net_flow_percentage_1_year',
      formatter: (value) => (
        <Typography
          variant="body"
          weight="medium"
          color={value > 0 ? COLORS.success.bulletPoint : COLORS.error.main}
        >
          {`${value > 0 ? '+' : ''}${formatNumberWithComma(value)}%`}
        </Typography>
      ),
    },
  ];

  interface HeaderGroup {
    id: string;
    label: string;
    colspan: number;
    subgroups?: Array<{ id: string; label: string; colspan: number }>;
    tooltip?: string;
  }

  const headerGroups: HeaderGroup[] = [
    {
      id: 'net_flows',
      label: 'Nettomittelzuflüsse',
      colspan: 6,
    },
  ];

  const subgroups = [
    { id: 'net_flows_3_months', label: '3 Monate', colspan: 2, align: 'right' },
    { id: 'net_flows_6_months', label: '6 Monate', colspan: 2, align: 'right' },
    { id: 'net_flows_1_year', label: '1 Jahr', colspan: 2, align: 'right' },
  ];

  headerGroups[0].subgroups = subgroups;

  return (
    <StyledContainer>
      <FundVolumeAndNetInflowsTable
        data={rows}
        columns={columns}
        headerGroups={headerGroups}
        primaryColumns={['name', 'volume']}
        title="Fund volume and net inflows table"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
      />
    </StyledContainer>
  );
};
