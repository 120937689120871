import { Layout } from 'components/common/Layout';
import { FC, useEffect, useState } from 'react';
import { StyledContainer, StyledHeadline } from './CustomerOverview.styles';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ChatCategory,
  ChatCloseAction,
  ChatOpenAction,
} from 'utils/google-analytics/events/chat';
import { Tabs } from 'components/Modules/CustomerOverview/Tabs/Tabs';
import { Dashboard } from 'components/Modules/CustomerOverview/Dashboard';
import { Box } from 'components/common/Box';
import { customerOverviewLogoIcons } from './config';
import { ReactComponent as PlansecurIcon } from 'assets/Icons/plansecur.svg';
import { ReactComponent as RefreshIcon } from 'assets/Icons/refresh.svg';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { FundSearch } from 'components/Modules/CustomerOverview/FundSearch';
import { Compass } from 'components/Modules/CustomerOverview/Compass';
import { CompassDesign } from 'components/Modules/CustomerOverview/CompassDesign';
import { Portfolios } from 'components/Modules/CustomerOverview/Portfolios';
import { useSearchParams } from 'react-router-dom';
import { VersionButton } from 'components/common/Buttons/VersionButton';
import { VersionHistoryModal } from 'components/Modules/CustomerOverview/Modals/VersionHistoryModal';

const LAST_UPDATE_DATE = '13.01.2025';

export const CustomerOverview: FC = () => {
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const isProduction = env === 'production';
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [dataState, setDataState] = useState(LAST_UPDATE_DATE);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') || '';
  const isKompassTab = tab === 'Kompass';
  const [isVersionHistoryModalOpen, setIsVersionHistoryModalOpen] =
    useState(false);
  const [kompassHistoricalDate, setKompassHistoricalDate] = useState<
    string | null
  >(null);
  const tabs = [
    { label: 'Dashboard', content: <Dashboard /> },
    {
      label: 'Voranalyse',
      content: <FundSearch setDataState={setDataState} />,
    },
    {
      label: 'Kompass',
      content: (
        <Compass
          selectedDate={kompassHistoricalDate}
          setSelectedDate={setKompassHistoricalDate}
        />
      ),
    },
    {
      label: 'Kompass Entwurf',
      content: <CompassDesign setDataState={setDataState} />,
    },
    {
      label: 'Watchlisten Fondspaten',
      content: <Portfolios setDataState={setDataState} />,
    },
  ];

  const onTidioChatApiReady = () => {
    window.tidioChatApi.hide();
    setIsChatOpen(false);
  };

  useEffect(() => {
    if (isProduction && window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
      window.tidioChatApi.on('close', onTidioChatApiReady);
    }
  }, [window.tidioChatApi]);

  const toggleChat = () => {
    if (isChatOpen) {
      window.tidioChatApi.hide();
      setIsChatOpen(false);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatCloseAction,
        window.location.pathname,
      );
    } else {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
      setIsChatOpen(true);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatOpenAction,
        window.location.pathname,
      );
    }
  };

  const closeVersionHistoryModal = () => {
    isVersionHistoryModalOpen && setIsVersionHistoryModalOpen(false);
  };

  const openVersionHistoryModal = () => {
    setIsVersionHistoryModalOpen(true);
  };

  return (
    <Layout headerVariant="secondary">
      <VersionHistoryModal
        isShown={isVersionHistoryModalOpen}
        selectedDate={kompassHistoricalDate}
        setSelectDate={setKompassHistoricalDate}
        closeModal={closeVersionHistoryModal}
      />
      <StyledContainer
        m={2.5}
        p={2.5}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <StyledHeadline variant="h1">Plansecur Produktkompass</StyledHeadline>
          <Box display="flex" gap={1.5} alignItems="center">
            <Box display="flex" gap={0.3}>
              {customerOverviewLogoIcons.map((letter) => letter)}
            </Box>
            <PlansecurIcon />
          </Box>
        </Box>
        <Tabs
          tabs={tabs}
          rightHeaderChildren={
            <>
              {isKompassTab ? (
                <VersionButton
                  date={kompassHistoricalDate}
                  onClick={openVersionHistoryModal}
                />
              ) : (
                <Box display="flex" alignItems="center" gap={1}>
                  <RefreshIcon stroke={COLORS.typography.placeholder} />
                  <Typography
                    variant="body"
                    color={COLORS.typography.placeholder}
                  >
                    Letzte Aktualisierung
                  </Typography>
                  <Typography variant="body">
                    {dataState || LAST_UPDATE_DATE}
                  </Typography>
                </Box>
              )}
            </>
          }
        />
        {/* <StyledIconContainer key="chat" display="flex" justifyContent="center">
          <ChatIconButton onClick={toggleChat} />
        </StyledIconContainer> */}
      </StyledContainer>
    </Layout>
  );
};
