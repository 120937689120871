import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { GradeColorProps, StyledContainerProps } from './LetterBadge.d';
import { defaultGradeColors } from './config';

const getColorsByValue = (
  value: string,
  gradeColors: GradeColorProps[] | undefined,
): GradeColorProps | undefined => {
  return (gradeColors || defaultGradeColors).find(
    ({ grade }) => grade === value,
  );
};

const getSize = (size?: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return '30px';
    case 'medium':
      return '35px';
    case 'large':
      return '40px';
    default:
      return '40px';
  }
};

const getFontSize = (size?: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return '20px';
    case 'medium':
      return '24px';
    case 'large':
      return '13px';
    default:
      return '13px';
  }
};

export const StyledContainer = styled(Box)<StyledContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ value, backgroundColor, gradeColors }) =>
    backgroundColor || getColorsByValue(value, gradeColors)?.backgroundColor};
  color: ${({ value, color, gradeColors }) =>
    color || getColorsByValue(value, gradeColors)?.textColor};
  border: 1px solid
    ${({ value, borderColor, gradeColors }) =>
      borderColor || getColorsByValue(value, gradeColors)?.borderColor};
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  font-family: 'Inter', sans-serif;
  font-size: ${({ size }) => getFontSize(size)};
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;
