import { FC } from 'react';
import { SFBFiltersModalProps } from './SFBFiltersModal.d';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SlideOutFilterModal } from 'components/common/Modals/SlideOutFilterModal';
import { Box } from 'components/common/Box';
import { FilterAccordion } from '../FilterAccordion';
import {
  FilterItem,
  FilterType,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/FCSmartFundBenchmarkingTable.d';
import { RangeFilters } from '../RangeFilters';
import { Option } from 'components/common/Select/Select.d';
import { ChipFilters } from '../ChipFilters';
import { YesNoFilters } from '../YesNoFilters';
import { ChipArticleFilters } from '../ChipArticleFilters';
import { CheckboxFilters } from '../CheckboxFilters';
import { TrendFilters } from '../TrendFilters';
import { CheckboxRegionsFilters } from '../CheckboxRegionsFilters';
import { SearchFilters } from '../SearchFilters';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

const GENERAL_FILTERS = 'general';

export const SFBFiltersModal: FC<SFBFiltersModalProps> = ({
  filters,
  resultsNumber,
  selectedFilters,
  isShow,
  clearFilters,
  closeModal,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();

  const handleClearAllFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();
    const filtersData = filters.map(({ data }) => data).flat();
    filtersData.forEach(({ id }) => newSearchParams.delete(id.toString()));

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    setSearchParams(newSearchParams);
    clearFilters();
    closeModal();
    customNavigate(pathname + '?' + newSearchParams.toString());
  };

  const getFiltersNumber = (currentType: string) => {
    const filtered = selectedFilters.filter(({ category }) =>
      category.includes(currentType),
    );
    return filtered.length > 0 ? filtered.length : undefined;
  };

  const getInnerFiltersNumber = (currentId: string, categoryType: string) => {
    const filtered = selectedFilters.filter(
      ({ id, category }) => currentId === id && categoryType === category,
    );
    return filtered.length > 0 ? filtered.length : undefined;
  };

  const getFilterByType = (
    type: FilterType,
    options: Option[],
    id: string,
    maxSelected?: number,
  ) => {
    switch (type) {
      case 'chips':
        return (
          <ChipFilters id={id} options={options} maxSelected={maxSelected} />
        );
      case 'chips-article':
        return (
          <ChipArticleFilters
            id={id}
            options={options}
            maxSelected={maxSelected}
          />
        );
      case 'trend':
        return (
          <TrendFilters id={id} options={options} maxSelected={maxSelected} />
        );
      case 'checkboxes':
        return (
          <CheckboxFilters
            id={id}
            options={options}
            maxSelected={maxSelected}
          />
        );
      case 'checkboxes-regions':
        return (
          <CheckboxRegionsFilters
            id={id}
            options={options}
            maxSelected={maxSelected}
          />
        );
      case 'search':
        return <SearchFilters id={id} />;
      case 'yes-no':
        return <YesNoFilters id={id} options={options} />;
      case 'range':
        return <RangeFilters id={id} options={options} />;
      default:
        return <ChipFilters id={id} options={options} />;
    }
  };

  const isTabDisabled = (currentType: string) => {
    const isAssetType = !currentType.includes(GENERAL_FILTERS);

    if (!isAssetType) return false;

    const assetFilters = selectedFilters.filter(
      ({ category }) => !category.includes(GENERAL_FILTERS),
    );

    return assetFilters.some(({ category }) => currentType !== category);
  };

  const renderInnerAccordion = (data: FilterItem[], categoryType: string) => (
    <FilterAccordion
      variant="small"
      tabs={data.map(
        ({ innerCategoryName, id, options, maxSelected, type }) => ({
          label: innerCategoryName,
          id: innerCategoryName,
          disabled: isTabDisabled(categoryType),
          content: getFilterByType(type, options, id.toString(), maxSelected),
          count: getInnerFiltersNumber(id.toString(), categoryType),
        }),
      )}
    />
  );

  return (
    <SlideOutFilterModal
      isShown={isShow}
      closeModal={closeModal}
      clearFilters={handleClearAllFilters}
      applyFilters={closeModal}
      resultsNumber={resultsNumber}
      variant="large"
    >
      <Box px={2.5} pb={1}>
        {filters.map(({ categoryName, data, categoryType }) =>
          categoryName.length > 0 ? (
            <FilterAccordion
              tabs={[
                {
                  label: categoryName,
                  id: categoryName,
                  disabled: isTabDisabled(categoryType),
                  count: getFiltersNumber(categoryType),
                  content: renderInnerAccordion(data, categoryType),
                },
              ]}
            />
          ) : (
            renderInnerAccordion(data, categoryType)
          ),
        )}
      </Box>
    </SlideOutFilterModal>
  );
};
