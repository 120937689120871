import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.requestWithComment};
  }
`;

export const StyledIsin = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.typography.placeholder};
`;

export const StyledContent = styled(Box)`
  border-radius: 8px;
  background-color: ${COLORS.background.module};
  padding: ${SIZES.spacing(2.5)};
`;

export const StyledHeadline = styled(Typography)`
  letter-spacing: 0.1em;
  color: ${COLORS.typography.description};
`;

export const StyledComment = styled(Typography)`
  line-height: 20px;
  color: ${COLORS.typography.description};
  font-style: italic;
`;

export const StyledDate = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.typography.info};
`;

export const StyledSeparator = styled(Box)`
  height: 12px;
  width: 1px;
  transition-duration: 0.2s;
  background-color: ${COLORS.typography.info};
`;

export const StyledError = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.error.main};
`;
