import { FC } from 'react';
import { CheckStatusIndicatorProps } from './CheckStatusIndicator.d';
import { ReactComponent as CheckIcon } from 'assets/Icons/tick-square.svg';
import { ReactComponent as CrossIcon } from 'assets/Icons/close-square.svg';

export const CheckStatusIndicator: FC<CheckStatusIndicatorProps> = ({
  value,
}) => {
  if (value === null) return <>-</>;
  return <>{!!value ? <CheckIcon /> : <CrossIcon />}</>;
};
