import {
  Table,
  TableCell,
  TableContainer,
  styled as MUIstyled,
  Paper,
} from '@mui/material';
import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.secondary};
  border-radius: 16px;
  height: 100%;
`;

export const StyledTableContainer = MUIstyled(TableContainer)(() => ({
  maxWidth: '100%',
  overflowX: 'auto',
}));

export const StyledTable = MUIstyled(Table)(() => ({
  minWidth: 650,
  borderCollapse: 'separate',
  borderSpacing: '0',

  '& thead tr:first-child th:first-child': {
    borderTopLeftRadius: 8,
    borderBottom: 0,
  },
  '& thead tr:first-child th': {
    borderBottom: 0,
  },
  '& thead tr:first-child th:nth-child(2)': {
    paddingBottom: '11px',
  },
  '& thead tr:last-child th': {
    borderBottom: 0,
  },
  '& tbody tr:last-child td:first-child': {
    borderBottomLeftRadius: 8,
  },
  '& tbody tr:last-child td:last-child': {
    borderBottomRightRadius: 8,
  },
}));

export const StyledPaper = MUIstyled(Paper)(() => ({
  borderRadius: 8,
  boxShadow: 'none',
  padding: 0,
}));

export const HeaderCell = MUIstyled(TableCell)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '17px',
  backgroundColor: COLORS.background.module,
  color: COLORS.typography.description,
  border: `1px solid ${COLORS.stroke.primary}`,
  borderRight: 0,
  padding: '19px 16px',
  verticalAlign: 'bottom',
  '&:last-child': {
    borderRight: `1px solid ${COLORS.stroke.primary}`,
  },
}));

export const HeaderGroupsCell = MUIstyled(TableCell)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '17px',
  backgroundColor: COLORS.info.selectedBorder,
  color: COLORS.background.secondary,
  textAlign: 'center',
  padding: '7px',
  borderBottom: 0,
}));

export const GroupHeaderCell = MUIstyled(TableCell)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '17px',
  color: COLORS.typography.description,
  backgroundColor: COLORS.background.module,
  textAlign: 'center',
  border: 0,
  borderLeft: `1px solid ${COLORS.stroke.primary}`,
  padding: '15.5px',

  '&:last-child': {
    borderRight: `1px solid ${COLORS.stroke.primary}`,
  },
}));

export const BodyTableCell = MUIstyled(TableCell)(() => ({
  borderLeft: `1px solid ${COLORS.stroke.primary}`,
  borderBottom: `1px solid ${COLORS.stroke.primary}`,
  borderRight: 0,
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  '&:last-child': {
    borderRight: `1px solid ${COLORS.stroke.primary}`,
  },
}));

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-right: 4px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledSubLabel = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.typography.placeholder};
`;

export const StyledIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      stroke: ${COLORS.typography.primary};
    }
  }
`;
