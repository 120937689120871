import { TableCell } from '@mui/material';
import styled from '@emotion/styled';
import { COLORS } from 'theme/colors';

export const StyledTableCell = styled(TableCell)`
  border-bottom: 1px solid ${COLORS.stroke.main};
  border-right: 1px solid ${COLORS.stroke.main} !important;

  &:last-child {
    border-right: none !important;
  }
`;
