import { Paper, styled, TableCell } from '@mui/material';
import { COLORS } from 'theme/colors';

export const StyledPaper = styled(Paper)(() => ({
  boxShadow: 'none',
  borderRadius: 0,
}));

export const StyledHeadTableCell = styled(TableCell)(() => ({
  padding: '32px 8px 20px 9px',
  fontSize: 12,
  lineHeight: '33px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  color: COLORS.typography.placeholder,
  borderBottom: 0,
  '&:first-child': {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  '&:last-child': {
    padding: '32px 16px 20px 9px',
  },
}));
