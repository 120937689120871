import styled, { css } from 'styled-components';
import BackgroundHelpBoxImage from 'assets/Backgrounds/analyst-desk-helper-box.jpeg';
import { Box } from '../Box';
import { SIZES } from 'theme/sizes';
import { COLORS } from 'theme/colors';
import { Button } from '../Buttons/Button';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';
import { StyledItemProps } from './HelpBox.d';

export const HelpBoxWrapper = styled(Box)<StyledItemProps>`
  max-height: calc(100vh - 65px);

  ${({ isSmallVariant }) =>
    isSmallVariant
      ? css`
          padding: ${SIZES.spacing(3)};
          background-image: url(${BackgroundHelpBoxImage});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 16px;
        `
      : css`
          padding: ${SIZES.spacing(1.5)} ${SIZES.spacing(2.5)};
          background-color: ${COLORS.background.neutral};
          border-radius: 8px;
          border: 4px solid ${COLORS.background.secondary};
        `};

  ${SIZES.media.tablet} {
    max-height: calc(100vh - 200px);
  }
`;

export const StyledLeftContainer = styled(Box)`
  width: 55%;
`;

export const StyledAnalystDeskButton = styled(Button)<StyledItemProps>`
  &:enabled {
    background-color: ${({ isSmallVariant }) =>
      isSmallVariant
        ? COLORS.background.secondary
        : COLORS.info.selectedBorder};
    color: ${({ isSmallVariant }) =>
      isSmallVariant
        ? COLORS.info.selectedBorder
        : COLORS.background.secondary};
  }

  svg {
    stroke: ${({ isSmallVariant }) =>
      isSmallVariant
        ? COLORS.info.selectedBorder
        : COLORS.background.secondary};
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${COLORS.accent.primary};
  width: 18px;
  height: 18px;
  transform: rotate(135deg);
  margin-right: 3px;
  margin-top: 3px;
`;

export const StyledIconContainer = styled(Box)`
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: -16px;
`;
