import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { StyledPointProps } from './FundVolumeAndNetInflows.d';

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(1)};
  background-color: ${COLORS.background.secondary};
  border-radius: 16px;
  height: 100%;
`;

export const StyledPoint = styled(Box)<StyledPointProps>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${({ color }) => color};
`;
