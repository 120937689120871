import { useRef } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { MultiSelect } from '../../../common/Select/MultiSelect/MultiSelect';
import { Option } from '../../../common/Select/MultiSelect/MultiSelect.d';
import { SelectFiltersProps } from './SelectFilters.d';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const SelectFilters: React.FC<SelectFiltersProps> = ({
  options,
  id,
  maxSelected = Infinity,
}) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const prevSelectedRef = useRef<Option[]>([]);

  const getCurrentSelectedOptions = (): Option[] => {
    const selectedValues = searchParams.getAll(id);
    return options.filter((option) =>
      selectedValues.includes(option.value.toLowerCase()),
    );
  };

  const handleChange = (selectedOptions: Option[]) => {
    const params = new URLSearchParams(searchParams);
    params.delete(id);

    selectedOptions.forEach((option) => {
      params.append(id, option.value.toLowerCase());
    });

    const isFilterChanged =
      JSON.stringify(prevSelectedRef.current) !==
      JSON.stringify(selectedOptions);

    if (isFilterChanged) {
      params.delete('page');
    }

    customNavigate(pathname + '?' + params.toString());
    prevSelectedRef.current = selectedOptions;
  };

  return (
    <MultiSelect
      value={getCurrentSelectedOptions()}
      onChange={handleChange}
      options={options}
      disabled={false}
      placeholder="Select options..."
    />
  );
};
