import { FC } from 'react';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { ItemsListTableProps } from './ItemsListTable.d';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Row } from './Row';
import { StyledHeadTableCell, StyledPaper } from './ItemsListTable.styled';

export const ItemsListTable: FC<ItemsListTableProps> = ({
  rows,
  selectedItem,
  itemsCountHeader,
  listsHeader = 'Erstellte Listen',
  openConfirmationModal,
  handleItemEdit,
  handleSelectedItemChange,
}) => {
  return (
    <TableContainer component={StyledPaper}>
      <Table aria-label="items-list-table">
        <TableHead>
          <TableRow>
            <StyledHeadTableCell align="left" width={360}>
              {listsHeader}
            </StyledHeadTableCell>
            {itemsCountHeader && (
              <StyledHeadTableCell align="left" width={40}>
                {itemsCountHeader}
              </StyledHeadTableCell>
            )}
            <StyledHeadTableCell align="center" width={30} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!!rows.length ? (
            rows.map((row) => (
              <Row
                key={row.id}
                selected={selectedItem && row.id === selectedItem.id}
                openConfirmationModal={openConfirmationModal}
                onRowEdit={handleItemEdit}
                onRowSelect={handleSelectedItemChange}
                {...row}
              />
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <Box
                  px={2}
                  py={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="body"
                    color={COLORS.typography.placeholder}
                  >
                    Keine Daten gefunden
                  </Typography>
                </Box>
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
