import { COLORS } from 'theme/colors';

export const statusOptions = [
  { value: 'Streichung beantragt', color: COLORS.warning.primary },
  { value: 'Aufnahme beantragt', color: COLORS.warning.primary },
  { value: 'Streichung bestätigt', color: COLORS.success.bulletPoint },
  { value: 'Aufnahme bestätigt', color: COLORS.success.bulletPoint },
  { value: 'Streichung abgelehnt', color: COLORS.error.background },
  { value: 'Aufnahme abgelehnt', color: COLORS.error.background },
];

export const STATUS_TYPE_MAPPING = {
  Addition: {
    Pending: 'Aufnahme beantragt',
    Accepted: 'Aufnahme bestätigt',
    Rejected: 'Aufnahme abgelehnt',
  },
  Removal: {
    Pending: 'Streichung beantragt',
    Accepted: 'Streichung bestätigt',
    Rejected: 'Streichung abgelehnt',
  },
};
