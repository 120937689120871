import { forwardRef, useEffect } from 'react';
import {
  AcceptRequestWithCommentModalProps,
  CommentFormProps,
} from './AcceptRequestWithCommentModal.d';
import { Modal } from 'components/common/Modal/Modal';
import {
  StyledComment,
  StyledContainer,
  StyledContent,
  StyledDate,
  StyledError,
  StyledHeadline,
  StyledIsin,
  StyledSeparator,
} from './AcceptRequestWithCommentModal.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { parseDateTime } from 'utils/common';
import { fetchAcceptRequestToChangeCompass } from 'services/Plansecur';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Textarea } from 'components/common/Textarea';
import { yupResolver } from '@hookform/resolvers/yup';
import { commentFormValidationSchema } from './validators';
import { useForm } from 'react-hook-form';
import { isValidForm } from 'utils/isValidForm';
import { Radio } from 'components/common/Radio';
import { allocationOptions } from './config';

export const AcceptRequestWithCommentModal = forwardRef<
  HTMLDivElement,
  AcceptRequestWithCommentModalProps
>(
  (
    {
      isShown,
      variant,
      toggle,
      requestId,
      fundName,
      isin,
      isWarning = false,
      requestedBy,
      requestDate,
      comment,
      onSubmit,
    },
    ref,
  ) => {
    const isAcceptAddingVariant = variant === 'accept-adding';
    const formattedDate = parseDateTime(requestDate).date;
    const formattedTime = parseDateTime(requestDate).time;

    const {
      handleSubmit,
      register,
      reset,
      watch,
      setValue,
      trigger,
      setError,
      getValues,
      formState: { errors },
    } = useForm<CommentFormProps>({
      resolver: yupResolver(commentFormValidationSchema),
      mode: 'onSubmit',
    });

    const allocation = watch('allocation');

    const sendRequest = async (
      { comment, allocation }: CommentFormProps,
      event?: React.BaseSyntheticEvent,
      status?: 'Pending' | 'Rejected' | 'Accepted',
    ) => {
      const requestStatus =
        status ||
        (((event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement)
          ?.value as 'Pending' | 'Rejected' | 'Accepted');

      setValue('status', requestStatus);
      const isValid = await trigger();

      if (
        isAcceptAddingVariant &&
        requestStatus === 'Accepted' &&
        !allocation
      ) {
        setError('allocation', {
          type: 'manual',
          message: 'Bitte Zuordnung festlegen.',
        });
        return;
      }

      if (isValid) {
        const response = await fetchAcceptRequestToChangeCompass({
          id: requestId,
          status: requestStatus,
          reviewer_notes: comment,
          ...(allocation ? { allocation } : {}),
        });

        if (response.ok) {
          onSubmit();
          reset();
          if (requestStatus === 'Accepted') {
            raiseToast.send(
              isAcceptAddingVariant
                ? 'Die Anfrage, den Fonds zum Kompass hinzuzufügen, wurde akzeptiert.'
                : 'Die Anfrage, den Fonds aus dem Kompass zu entfernen, wurde akzeptiert.',
            );
          } else {
            raiseToast.error(
              isAcceptAddingVariant
                ? 'Die Anfrage, den Fonds zum Kompass hinzuzufügen, wurde abgelehnt.'
                : 'Die Anfrage, den Fonds aus dem Kompass zu entfernen, wurde abgelehnt.',
            );
          }
        }
      }
    };

    useEffect(() => {
      if (isShown) {
        reset();
      }
    }, [isShown]);

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        title={
          isAcceptAddingVariant
            ? 'Antrag zur Aufnahme in den Kompass'
            : 'Antrag zur Streichung vom Kompass'
        }
        px={4}
        pb={5}
        pt={4}
      >
        <StyledContainer pt={2.5}>
          <StyledContent>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h5">{fundName}</Typography>
              <StyledIsin variant="body">{isin}</StyledIsin>
            </Box>
            <Box display="flex" flexDirection="column" gap={4} pt={4}>
              <Box display="flex" flexDirection="column" gap={2.5}>
                <StyledHeadline variant="overline">
                  Angefordert von
                </StyledHeadline>
                <Box display="flex" alignItems="center" gap={2}>
                  <LetterBadge
                    value={requestedBy[0]}
                    size="small"
                    color={COLORS.background.secondary}
                    backgroundColor={COLORS.info.selectedBorder}
                    borderColor={COLORS.info.selectedBorder}
                  />
                  <Box flexDirection="column" gap={1}>
                    <Typography variant="body" weight="bold">
                      {requestedBy}
                    </Typography>
                    <Box display="flex" gap={0.7} alignItems="center">
                      <StyledDate variant="body">{formattedDate}</StyledDate>
                      <StyledSeparator />
                      <StyledDate variant="body">{formattedTime}</StyledDate>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={2.5}>
                <StyledHeadline variant="overline">Kommentar</StyledHeadline>
                {comment && (
                  <StyledComment variant="body">{comment}</StyledComment>
                )}
              </Box>
            </Box>
          </StyledContent>
          <form onSubmit={handleSubmit(sendRequest)}>
            <Box pt={2.5}>
              {isAcceptAddingVariant && (
                <Box display="flex" flexDirection="column" gap={1} mb={4}>
                  <Typography variant="h5">Zuordnung</Typography>
                  <Box display="flex" flexDirection="column" gap={1.5} mt={1}>
                    {allocationOptions.map((level) => (
                      <Radio
                        key={level.label}
                        {...register('allocation')}
                        value={level.value}
                        label={level.label}
                        selectedValue={allocation || ''}
                      />
                    ))}
                  </Box>
                  {!isValidForm(errors) && errors.allocation?.message && (
                    <StyledError variant="body">
                      {errors.allocation?.message}
                    </StyledError>
                  )}
                </Box>
              )}
              <Textarea
                placeholder="Ihr Kommentar ..."
                required
                error={!isValidForm(errors) && errors.comment?.message}
                {...register('comment')}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={2} py={4}>
              <Button
                variant={ButtonVariant.OUTLINED}
                type="submit"
                name="action"
                value="Rejected"
              >
                {isAcceptAddingVariant
                  ? 'Antrag zur Aufnahme in den Kompass ablehnen'
                  : 'Antrag zur Streichung vom Kompass ablehnen'}
              </Button>
              <Button
                variant={
                  isWarning ? ButtonVariant.WARNING : ButtonVariant.SUCCESS
                }
                type="submit"
                name="action"
                value="Accepted"
                onClick={async (e) => {
                  e.preventDefault();
                  const data = getValues();
                  await sendRequest(data, e, 'Accepted');
                }}
              >
                {isAcceptAddingVariant
                  ? 'Antrag annehmen und Fonds in den Kompass aufnehmen'
                  : 'Antrag annehmen und Fonds aus dem Kompass streichen'}
              </Button>
            </Box>
          </form>
        </StyledContainer>
      </Modal>
    );
  },
);
