import { FC, useEffect, useRef, useState } from 'react';
import { CheckboxFiltersProps } from './CheckboxFilters.d';
import { ReactComponent as CheckBoxIcon } from 'assets/Icons/table-checkbox.svg';
import { ReactComponent as CheckedCheckBoxIcon } from 'assets/Icons/checked-checkbox.svg';
import {
  StyledCheckbox,
  StyledFormControlLabel,
  StyledFormGroup,
  StyledTypography,
} from './CheckboxFilters.styled';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const CheckboxFilters: FC<CheckboxFiltersProps> = ({
  id,
  options,
  maxSelected,
}) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams, _] = useSearchParams();
  const filterValue = searchParams.getAll(id);
  const [selected, setSelected] = useState<string[]>(filterValue);
  const prevSelectedRef = useRef<string[]>([]);

  const handleCheckboxChange = (value: string) => {
    if (selected.includes(value.toLowerCase())) {
      setSelected(selected.filter((item) => item !== value.toLowerCase()));
    } else if (maxSelected && selected.length < maxSelected) {
      setSelected([...selected, value]);
    } else if (!maxSelected) {
      setSelected([...selected, value]);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);

    params.delete(id);

    if (!!selected.length) {
      selected.forEach((item) => params.append(id, item.toLowerCase()));
    }

    const prevSelected = prevSelectedRef.current;
    const isFilterChanged =
      selected.length !== prevSelected.length ||
      selected.some(
        (item, index) => item !== prevSelected[index].toLowerCase(),
      );

    if (isFilterChanged) {
      params.delete('page');
    }

    customNavigate(pathname + '?' + params.toString());
    prevSelectedRef.current = selected;
  }, [selected, id]);

  useEffect(() => {
    const newSelectedChips = searchParams.getAll(id);
    setSelected(newSelectedChips);
  }, [searchParams, id]);

  return (
    <>
      {!!maxSelected && (
        <StyledTypography variant="body">{`Wähle maximal ${maxSelected} Optionen`}</StyledTypography>
      )}
      <StyledFormGroup itemsCount={options.length}>
        {options.map(({ value, name }) => (
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                icon={<CheckBoxIcon />}
                checkedIcon={<CheckedCheckBoxIcon />}
                checked={selected.includes(value.toLowerCase())}
                onChange={() => handleCheckboxChange(value)}
                disabled={
                  maxSelected
                    ? selected.length >= maxSelected &&
                      !selected.includes(value.toLowerCase())
                    : false
                }
              />
            }
            label={name}
          />
        ))}
      </StyledFormGroup>
    </>
  );
};
