import { GradeColorProps } from 'components/common/Icons/LetterBadge/LetterBadge.d';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';

export const quantileGradeColors: GradeColorProps[] = [
  {
    grade: '5',
    backgroundColor: hexToRGB(COLORS.success.bulletPoint, '0.15'),
    borderColor: hexToRGB(COLORS.success.dark, '0.15'),
    textColor: COLORS.success.dark,
  },
  {
    grade: '4',
    backgroundColor: hexToRGB(COLORS.success.bulletPoint, '0.15'),
    borderColor: hexToRGB(COLORS.success.dark, '0.15'),
    textColor: COLORS.success.dark,
  },
  {
    grade: '3',
    backgroundColor: hexToRGB(COLORS.warning.primary, '0.2'),
    borderColor: hexToRGB(COLORS.warning.primary, '0.2'),
    textColor: COLORS.warning.chip,
  },
  {
    grade: '2',
    backgroundColor: hexToRGB(COLORS.error.background, '0.2'),
    borderColor: hexToRGB(COLORS.error.background, '0.2'),
    textColor: COLORS.error.dark,
  },
  {
    grade: '1',
    backgroundColor: hexToRGB(COLORS.error.background, '0.2'),
    borderColor: hexToRGB(COLORS.error.background, '0.2'),
    textColor: COLORS.error.dark,
  },
];
