import { styled as materialUIStyled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { StyledLabelProps, StyledTableCellProps } from './EnhancedTableHead.d';
import { Box } from 'components/common/Box';

export const StyledTableHead = materialUIStyled(TableHead)({
  backgroundColor: COLORS.background.module,
});

export const StyledTableCell = materialUIStyled(
  TableCell,
)<StyledTableCellProps>(({ sortable }) => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  lineHeight: '17px',
  color: COLORS.typography.description,
  whiteSpace: 'normal',
  borderBottom: 'none',
  padding: '11px 0',
  height: '34px',
  top: 67,
  backgroundColor: COLORS.background.module,

  '&:hover': {
    cursor: sortable ? 'pointer' : 'default',
  },
}));

export const StyledSpan = styled.span<StyledLabelProps>`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: ${({ color }) => color || COLORS.typography.placeholder};
  ${({ hasLineBreak, maxTextWidth }) =>
    maxTextWidth &&
    css`
      word-wrap: break-word;
      max-width: ${maxTextWidth || '80px'};
    `}
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-right: 4px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const HighlightedCell = materialUIStyled(TableCell)({
  textAlign: 'center',
  fontFamily: 'Random Grotesque Standard, sans-serif',
  fontWeight: 600,
  lineHeight: '17px',
  color: COLORS.background.secondary,
  backgroundColor: COLORS.accent.primary,
  padding: '4px 0',
  height: '10px',
  borderTopRightRadius: 16,
  borderTopLeftRadius: 16,
  border: 0,
});

export const StyledIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      stroke: ${COLORS.typography.primary};
    }
  }
`;
