import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { commonColumns, createRow, getDefaultFilterValue } from '../config';
import {
  fetchAcceptRequestToChangeCompass,
  fetchKompassDesign,
} from 'services/Plansecur';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { RequestedByColumn } from 'components/Modules/RequestedByColumn';
import { useProfile } from 'context/Profile';
import { AcceptRequestWithCommentModal } from '../Modals/AcceptRequestWithCommentModal';
import { parseDateTime } from 'utils/common';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { CommentsModal } from '../Modals/CommentsModal';
import { COLORS } from 'theme/colors';
import { Rank } from 'components/Modules/Rank';
import {
  activeManagementOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  volumeOptions,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';
import { StyledTableChipContainer } from '../CustomerOverview.styled';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { Score } from 'components/Modules/Score';
import { CompassDesignProps } from './CompassDesign.d';
import { getMaxDataState } from 'views/Modules/CustomerOverview/config';

export const CompassDesign: FC<CompassDesignProps> = ({ setDataState }) => {
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const isPlansecurMessageReadAccess =
    profileInfo?.plansecur_message_read_access;
  const isPlansecurMessageWriteAccess =
    profileInfo?.plansecur_message_write_access;
  const isFondsconsultMessageReadAccess =
    profileInfo?.fondsconsult_message_read_access;
  const isFondsconsultMessageWriteAccess =
    profileInfo?.fondsconsult_message_write_access;
  const { search } = useLocation();
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '50', 10);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [isAcceptRequestAddToCompassOpen, setIsAcceptRequestAddToCompassOpen] =
    useState(false);
  const [
    isAcceptRequestRemoveFromCompassOpen,
    setIsAcceptRequestRemoveFromCompassOpen,
  ] = useState(false);
  const [isCommentsPlansecurModalOpen, setIsCommentsPlansecurModalOpen] =
    useState(false);
  const [isCommentsFondsconsultModalOpen, setIsCommentsFondsconsultModalOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    isin: '',
    user__name: '',
    created_at: '',
    comment: '',
    request_id: '',
  });

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const requestedByColumn: ColumnProps = {
    id: 'requests',
    label: 'Anträge zur Aufnahme / Streichung',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'user__name',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Beantragt von',
        sortable: true,
        minWidth: '170px',
        maxWidth: '180px',
        renderCell: ({ value }) => <RequestedByColumn name={value} />,
      },
      {
        id: 'created_at',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Datum',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <>{parseDateTime(value).date}</>,
      },
      {
        id: 'requested_by_comment',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Kommentar Fondspate',
        sortable: false,
        minWidth: '285px',
        maxWidth: '285px',
      },
    ],
  };

  const sfbColumn: ColumnProps = {
    id: 'fonds_consult_daten',
    label: 'FondsConsult Daten',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'sfb_related_fund__peer_group',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'FCR Peergroup',
        sortable: true,
        minWidth: '148px',
        maxWidth: '148px',
      },
      {
        id: 'sfb_related_fund__rank__rank',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rang',
        sortable: true,
        sortKey: 'sfb_related_fund__rank__rank',
        tooltip:
          'Im Rahmen unserer proprietären Quant-Auswertung erzielter Rang des Fonds innerhalb seiner Vergleichsgruppe. Die Platzierung basiert auf diversen Rendite-/Risikokennzahlen über einen Zeitraum von einem bzw. drei Jahren.',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ row }) => (
          <Rank
            value={row.sfb_related_fund__rank__rank}
            maxValue={row.nr_of_funds}
          />
        ),
      },
      {
        id: 'sfb_related_fund__volume',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondsvolumen',
        sortable: true,
        tooltip:
          'Fondsvolumen eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Hoch (Top 30%), Mittel, Niedrig (Untere 30%).',
        minWidth: '156px',
        maxWidth: '156px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, volumeOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__track_record',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Track Record',
        sortable: true,
        tooltip:
          'Laufzeit eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Lang (Top 30%), Mittel, Kurz (Untere 30%).',
        minWidth: '115px',
        maxWidth: '115px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, trackRecordOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__return_score__total_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rendite',
        sortable: true,
        tooltip:
          'Historisch erzielte Rendite des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, returnOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_risk_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Risiko',
        sortable: true,
        tooltip:
          'Gemessen über die Volatilität, den max. Verlust, das Beta sowie die eigens entwickelte Downside Protection des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht, Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, riskOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_active_management_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Aktives Management',
        sortable: true,
        tooltip:
          'Gemessen über das zur Benchmark erzielte Alpha, sowie die Information und Appraisal Ratio des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            activeManagementOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__esg_scores__credibility_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Credibility',
        sortable: true,
        tooltip:
          'Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen wird.',
        minWidth: '127px',
        maxWidth: '127px',
        renderCell: ({ value }) => <Score value={value} />,
      },
      {
        id: 'esg_actions',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Actions (Fonds)',
        tooltip:
          'Bewertung der auf Fondsebene tatsächlich umgesetzten Nachhaltigkeitskriterien (u.a. PAIs). Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Berücksichtigung nachhaltiger Kriterien gesprochen wird. Mehr Informationen dazu finden Sie im Bereich ESG Credibility & Action Score.',
        sortable: true,
        minWidth: '127px',
        maxWidth: '127px',
      },
    ],
  };

  const showConfirmRequestModal = (
    requestType: 'Addition' | 'Removal',
    id: string,
    name: string,
    isin: string,
    user__name: string,
    created_at: string,
    comment: string,
    requestId: string,
  ) => {
    setCurrentFund({
      id,
      name,
      isin,
      user__name,
      created_at,
      comment,
      request_id: requestId,
    });
    requestType === 'Addition'
      ? setIsAcceptRequestAddToCompassOpen(true)
      : setIsAcceptRequestRemoveFromCompassOpen(true);
  };

  const toggleConfirmRequestModal = () => {
    isAcceptRequestAddToCompassOpen
      ? setIsAcceptRequestAddToCompassOpen(!isAcceptRequestAddToCompassOpen)
      : setIsAcceptRequestRemoveFromCompassOpen(
          !isAcceptRequestRemoveFromCompassOpen,
        );
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    ...(isAdmin || isPlansecurAdmin
      ? [
          {
            id: 'actions',
            label: '',
            tooltip: 'Actions',
            renderCell: ({ row }: { row: RowProps }) => (
              <Actions
                justifyContent="center"
                mr={0}
                requestTypeInCompassDesign={row.requestType}
                handlers={{
                  onChangeRequestStatusInCompassDesign: isPlansecurAdmin
                    ? (
                        e: React.BaseSyntheticEvent,
                        requestType: 'Addition' | 'Removal',
                      ) => {
                        e.stopPropagation();
                        showConfirmRequestModal(
                          requestType,
                          row.id,
                          row.name,
                          row.isin,
                          row.user__name,
                          row.created_at,
                          row.requested_by_comment ?? '-',
                          row.requestId ?? '',
                        );
                      }
                    : undefined,
                  ...(isPlansecurMessageReadAccess ||
                  isPlansecurMessageWriteAccess ||
                  isFondsconsultMessageWriteAccess ||
                  isFondsconsultMessageReadAccess
                    ? {
                        onPlansecurCommentsHandle:
                          isPlansecurMessageReadAccess ||
                          isPlansecurMessageWriteAccess
                            ? () => {
                                setIsCommentsPlansecurModalOpen(true);
                                setCurrentFund({
                                  id: row.id,
                                  name: row.name,
                                  isin: row.isin,
                                  user__name: row.user__name,
                                  created_at: row.created_at,
                                  comment: row.requested_by_comment ?? '-',
                                  request_id: row.requestId ?? '',
                                });
                              }
                            : undefined,
                        onFundUpdateHandle:
                          isFondsconsultMessageWriteAccess ||
                          isFondsconsultMessageReadAccess
                            ? () => {
                                setIsCommentsFondsconsultModalOpen(true);
                                setCurrentFund({
                                  id: row.id,
                                  name: row.name,
                                  isin: row.isin,
                                  user__name: row.user__name,
                                  created_at: row.created_at,
                                  comment: row.requested_by_comment ?? '-',
                                  request_id: row.requestId ?? '',
                                });
                              }
                            : {},
                      }
                    : {}),
                }}
              />
            ),
            width: '120px',
            subColumns: [],
          },
        ]
      : []),
  ];

  const getTableData = async () => {
    const response = await fetchKompassDesign({
      page,
      page_size: itemsPerPage,
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? {
            sort_by:
              sortModel.sort_by === 'created_at' ||
              sortModel.sort_by === 'user__name'
                ? sortModel.sort_by
                : `fund__${sortModel.sort_by}`,
            sort_order: sortModel.sort_order,
          }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    });
    if (response.ok) {
      const tableData = response.parsedBody.results.requests.map((item) => ({
        user__name: `${item.user.name} ${item.user.last_name}`,
        created_at: item.created_at,
        requested_by_comment: item.notes,
        requestType: item.type,
        request_id: item.id,
        highlightColor:
          item.type === 'Addition'
            ? COLORS.success.bulletPoint
            : COLORS.error.background,
        gradientColor:
          item.type === 'Addition' ? COLORS.success.light : COLORS.error.hover,

        ...item.fund,
      }));
      setResultsNumber(response.parsedBody.count);
      setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
      const maxDataState = getMaxDataState(tableData);
      setDataState(maxDataState);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [itemsPerPage, searchValue, sortModel, searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toggleCommentsModal = () => {
    isCommentsPlansecurModalOpen && setIsCommentsPlansecurModalOpen(false);
    isCommentsFondsconsultModalOpen &&
      setIsCommentsFondsconsultModalOpen(false);
  };

  const handleSubmitAcceptRequestModal = () => {
    getTableData();
    isAcceptRequestAddToCompassOpen &&
      setIsAcceptRequestAddToCompassOpen(false);
    isAcceptRequestRemoveFromCompassOpen &&
      setIsAcceptRequestRemoveFromCompassOpen(false);
  };

  return (
    <>
      {currentFund.id && (
        <AcceptRequestWithCommentModal
          isShown={
            isAcceptRequestAddToCompassOpen ||
            isAcceptRequestRemoveFromCompassOpen
          }
          variant={
            isAcceptRequestAddToCompassOpen
              ? 'accept-adding'
              : 'accept-removing'
          }
          isWarning={isAcceptRequestRemoveFromCompassOpen}
          fundName={currentFund.name}
          requestId={currentFund.request_id}
          isin={currentFund.isin}
          toggle={toggleConfirmRequestModal}
          onSubmit={handleSubmitAcceptRequestModal}
          requestedBy={currentFund.user__name}
          requestDate={currentFund.created_at}
          comment={currentFund.comment}
        />
      )}
      <CommentsModal
        isShown={
          isCommentsPlansecurModalOpen || isCommentsFondsconsultModalOpen
        }
        variant={isCommentsPlansecurModalOpen ? 'plansecur' : 'fondsconsult'}
        fundName={currentFund.name}
        isin={currentFund.isin}
        fundId={currentFund.id}
        closeModal={toggleCommentsModal}
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={0}
        onSubmitSearch={onSubmitSearch}
        // onToggleFilters={toggleFiltersSection}
        onExportClick={() => {}}
      >
        {/* <SelectedFilters filters={[]} /> */}
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
            />
          )}
          <PortfolioCollapseTable
            isLastColumnsFixed={isAdmin || isPlansecurAdmin}
            loading={loading}
            tableData={{
              rows,
              columns: [
                columns[0],
                requestedByColumn,
                sfbColumn,
                ...columns.slice(1),
              ],
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination pagesNumber={getPagesNumber()} />
      </ModuleLayout>
    </>
  );
};
