import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { Option } from 'components/common/Select/Select.d';
import { commonColumns, createRow, getDefaultFilterValue } from '../config';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import {
  fetchPortfolios,
  fetchUpdateFundPortfolioStatus,
  fetchWithdrawRequestToAddRemoveFromKompass,
  getPortfoliosFilterChoices,
  PlansecurFundRequest,
  sendRequestCompetitiveAnalysis,
  sendRequestFundPortfolio,
  sendRequestFundUpdate,
} from 'services/Plansecur';
import { ConfirmModal } from 'components/common/Modals/ConfirmModal';
import { ConfirmWithCommentModal } from '../Modals/ConfirmWithCommentModal';
import { CommentsModal } from '../Modals/CommentsModal/CommentsModal';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { useProfile } from 'context/Profile';
import { TableChip } from 'components/common/TableChip';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { StyledTableChipContainer } from '../CustomerOverview.styled';
import { COLORS } from 'theme/colors';
import { Rank } from 'components/Modules/Rank';
import {
  activeManagementOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  volumeOptions,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';
import { Score } from 'components/Modules/Score';
import { formatGermanDateTime, toTitleCase } from 'utils/common';
import { PlansecurFiltersModal } from 'components/Modules/Filters/PlansecurFiltersModal/PlansecurFiltersModal';
import { createPlansecurFilters, plansecurFilters } from '../filters.config';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { FilterProps } from 'components/Modules/Filters/SelectedFilters/SelectedFilters.d';
import { PlansecurFiltersProps } from '../CustomerOverview';
import { PortfoliosProps } from './Portfolios.d';
import { getMaxDataState } from 'views/Modules/CustomerOverview/config';

const STATUS_REQUESTED = 'beantragt';

export const Portfolios: FC<PortfoliosProps> = ({ setDataState }) => {
  const { search, pathname } = useLocation();
  const profile = useProfile();
  const { customNavigate } = useNavigation();
  const profileInfo = profile?.profileInfo;
  const userEmail = profileInfo?.email;
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const isPlansecurMessageReadAccess =
    profileInfo?.plansecur_message_read_access;
  const isPlansecurMessageWriteAccess =
    profileInfo?.plansecur_message_write_access;
  const isFondsconsultMessageReadAccess =
    profileInfo?.fondsconsult_message_read_access;
  const isFondsconsultMessageWriteAccess =
    profileInfo?.fondsconsult_message_write_access;
  const assignedPortfolioPate = profile?.profileInfo?.assigned_portfolio_pate;
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '50', 10);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const portfoliosOptions =
    assignedPortfolioPate
      ?.map(({ id, name }) => ({ value: id, name }))
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  const [selectedPortfolio, setSelectedPortfolio] = useState<Option | null>(
    assignedPortfolioPate && assignedPortfolioPate[0]
      ? {
          value: assignedPortfolioPate[0].id,
          name: assignedPortfolioPate[0]?.name,
        }
      : null,
  );
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [
    isConfirmRemoveFromPortfolioOpen,
    setIsConfirmRemoveFromPortfolioOpen,
  ] = useState(false);
  const [isConfirmAddToCompassOpen, setIsConfirmAddToCompassOpen] =
    useState(false);
  const [isConfirmRemoveFromCompassOpen, setIsConfirmRemoveFromCompassOpen] =
    useState(false);
  const [
    isConfirmRequestFundPortraitOpen,
    setIsConfirmRequestFundPortraitOpen,
  ] = useState(false);
  const [isConfirmSendOtherFundRequest, seetIsConfirmSendOtherFundRequest] =
    useState(false);
  const [isCommentsPlansecurModalOpen, setIsCommentsPlansecurModalOpen] =
    useState(false);
  const [isCommentsFondsconsultModalOpen, setIsCommentsFondsconsultModalOpen] =
    useState(false);
  const [isConfirmWithdrawKompassRequestOpen, setIsConfirmWithdrawRequestOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    isin: '',
    present_in_kompass: false,
    request_id: '',
  });
  const [filtersConfig, setFiltersConfig] = useState(plansecurFilters);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const showConfirmRemoveFromPortfolioModal = (
    name: string,
    id: string,
    isin: string,
    present_in_kompass: boolean,
  ) => {
    setIsConfirmRemoveFromPortfolioOpen(true);
    setCurrentFund({ id, name, isin, present_in_kompass, request_id: '' });
  };

  const changeFundPortfolioStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundPortfolioStatus(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromPortfolioOpen &&
        setIsConfirmRemoveFromPortfolioOpen(false);
      isConfirmRemoveFromPortfolioOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds wurde von Ihrer Watchlist entfernt"
              content={`"${name}" wurde von Ihrer Watchlist entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds wurde zu Ihrer Watchlist hinzugefügt"
              content={`"${name}" wurde zu Ihrer Watchlist hinzugefügt.`}
            />,
          );
    }
  };

  const onChangeCompasStatusHandle = ({
    id,
    name,
    isin,
    present_in_kompass,
    status_email,
    status_request_id,
  }: RowProps) => {
    const isMyRequest = status_email === userEmail;
    isMyRequest
      ? setIsConfirmWithdrawRequestOpen(true)
      : present_in_kompass
      ? setIsConfirmRemoveFromCompassOpen(true)
      : setIsConfirmAddToCompassOpen(true);

    setCurrentFund({
      id,
      name,
      isin,
      present_in_kompass: present_in_kompass ?? false,
      request_id: status_request_id || '',
    });
  };

  const sendRequestFundPortrait = async (id: string) => {
    const response = await sendRequestFundPortfolio(id);
    if (response.ok) {
      isConfirmRequestFundPortraitOpen &&
        setIsConfirmRequestFundPortraitOpen(false);
      raiseToast.send(
        'Die Anfrage für das Fondsporträt wurde erfolgreich gesendet.',
      );
    }
  };

  const sendFundUpdateRequest = async (id: string) => {
    const response = await sendRequestFundUpdate(id);
    if (response.ok) {
      raiseToast.requestUpdate(
        'Die Anfrage für ein Fondsnews wurde erfolgreich gesendet.',
      );
    }
  };

  const sendCompetitiveAnalysisRequest = async (id: string) => {
    const response = await sendRequestCompetitiveAnalysis(id);
    if (response.ok) {
      raiseToast.requestAnalysis(
        'Die Anfrage für eine Wettbewerbsanalyse wurde erfolgreich gesendet.',
      );
    }
  };

  const withdrawKompassRequest = async (id: string) => {
    const response = await fetchWithdrawRequestToAddRemoveFromKompass(id);
    if (response.ok) {
      isConfirmWithdrawKompassRequestOpen &&
        setIsConfirmWithdrawRequestOpen(false);
      raiseToast.success('Die Anfrage wurde erfolgreich zurückgezogen.');
      getTableData();
    }
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const statusColumn: ColumnProps = {
    id: 'status_column',
    label: 'Status',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'source',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quelle',
        sortable: false,
        minWidth: '130px',
        maxWidth: '130px',
        renderCell: ({ value }) => {
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer style={{ width: 130 }}>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={TableChipVariant.GREY}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'status',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Status',
        sortable: false,
        minWidth: '190px',
        maxWidth: '190px',
        renderCell: ({ value, row }) => {
          const formattedDate =
            row.status_timestamp && formatGermanDateTime(row.status_timestamp);

          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              {`${value} ${formattedDate ?? ''}
            `}
            </Box>
          );
        },
      },
    ],
  };

  const sfbColumn: ColumnProps = {
    id: 'fonds_consult_daten',
    label: 'FondsConsult Daten',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'sfb_related_fund__peer_group',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'FCR Peergroup',
        sortable: true,
        minWidth: '148px',
        maxWidth: '148px',
      },
      {
        id: 'sfb_related_fund__rank__rank',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rang',
        sortable: true,
        sortKey: 'sfb_related_fund__rank__rank',
        tooltip:
          'Im Rahmen unserer proprietären Quant-Auswertung erzielter Rang des Fonds innerhalb seiner Vergleichsgruppe. Die Platzierung basiert auf diversen Rendite-/Risikokennzahlen über einen Zeitraum von einem bzw. drei Jahren.',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ row }) => (
          <Rank
            value={row.sfb_related_fund__rank__rank}
            maxValue={row.nr_of_funds}
          />
        ),
      },
      {
        id: 'sfb_related_fund__volume',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondsvolumen',
        sortable: true,
        tooltip:
          'Fondsvolumen eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Hoch (Top 30%), Mittel, Niedrig (Untere 30%).',
        minWidth: '156px',
        maxWidth: '156px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, volumeOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__track_record',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Track Record',
        sortable: true,
        tooltip:
          'Laufzeit eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Lang (Top 30%), Mittel, Kurz (Untere 30%).',
        minWidth: '115px',
        maxWidth: '115px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, trackRecordOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__return_score__total_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rendite',
        sortable: true,
        tooltip:
          'Historisch erzielte Rendite des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, returnOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_risk_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Risiko',
        sortable: true,
        tooltip:
          'Gemessen über die Volatilität, den max. Verlust, das Beta sowie die eigens entwickelte Downside Protection des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht, Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, riskOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_active_management_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Aktives Management',
        sortable: true,
        tooltip:
          'Gemessen über das zur Benchmark erzielte Alpha, sowie die Information und Appraisal Ratio des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            activeManagementOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__esg_scores__credibility_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Credibility',
        sortable: true,
        tooltip:
          'Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen wird.',
        minWidth: '127px',
        maxWidth: '127px',
        renderCell: ({ value }) => <Score value={value} />,
      },
      {
        id: 'esg_actions',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Actions (Fonds)',
        tooltip:
          'Bewertung der auf Fondsebene tatsächlich umgesetzten Nachhaltigkeitskriterien (u.a. PAIs). Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Berücksichtigung nachhaltiger Kriterien gesprochen wird. Mehr Informationen dazu finden Sie im Bereich ESG Credibility & Action Score.',
        sortable: true,
        minWidth: '127px',
        maxWidth: '127px',
      },
    ],
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    ...(isFondsPateUser
      ? [
          {
            id: 'actions',
            label: '',
            tooltip: 'Actions',
            renderCell: ({ row }: { row: RowProps }) => (
              <Actions
                isInMyPortfolio={row.is_watchlisted}
                isPresentInKompass={row.present_in_kompass}
                isAllowToWithdrawRequest={
                  row.status_email === userEmail &&
                  row.status?.includes(STATUS_REQUESTED)
                }
                disabledToRemoveFromPortfolio={
                  row.status?.includes(STATUS_REQUESTED) || !row.is_watchlisted
                }
                disabledToAddRemoveToCompass={
                  row.status && row.status?.length > 1
                }
                handlers={{
                  onChangePortfolioHandle: (e: React.BaseSyntheticEvent) => {
                    e.stopPropagation();
                    showConfirmRemoveFromPortfolioModal(
                      row.name,
                      row.id,
                      row.isin,
                      row.present_in_kompass ?? false,
                    );
                  },
                  onChangeCompasStatusHandle: isFondsPateUser
                    ? () => onChangeCompasStatusHandle(row)
                    : undefined,
                  onFundUpdateHandle:
                    isFondsconsultMessageWriteAccess ||
                    isFondsconsultMessageReadAccess
                      ? () => {
                          setIsCommentsFondsconsultModalOpen(true);
                          setCurrentFund({
                            id: row.id,
                            name: row.name,
                            isin: row.isin,
                            present_in_kompass: row.present_in_kompass ?? false,
                            request_id: '',
                          });
                        }
                      : undefined,
                  onRequestFundUpdateHandle: () =>
                    sendFundUpdateRequest(row.id),
                  onPlansecurCommentsHandle:
                    isPlansecurMessageReadAccess ||
                    isPlansecurMessageWriteAccess
                      ? () => {
                          setIsCommentsPlansecurModalOpen(true);
                          setCurrentFund({
                            id: row.id,
                            name: row.name,
                            isin: row.isin,
                            present_in_kompass: row.present_in_kompass ?? false,
                            request_id: '',
                          });
                        }
                      : undefined,
                  onFundPortraitHandle: () => {
                    setIsConfirmRequestFundPortraitOpen(true);
                    setCurrentFund({
                      id: row.id,
                      name: row.name,
                      isin: row.isin,
                      present_in_kompass: row.present_in_kompass ?? false,
                      request_id: '',
                    });
                  },
                  onRequestCompetitiveAnalysisHandle: () =>
                    sendCompetitiveAnalysisRequest(row.id),
                  onOtherRequestHandle: () => {
                    seetIsConfirmSendOtherFundRequest(true);
                    setCurrentFund({
                      id: row.id,
                      name: row.name,
                      isin: row.isin,
                      present_in_kompass: row.present_in_kompass ?? false,
                      request_id: '',
                    });
                  },
                }}
              />
            ),
            width: '120px',
            subColumns: [],
          },
        ]
      : []),
  ];

  const formatDataToSend = () => {
    const filters: PlansecurFundRequest = {};
    for (const filterCategory of plansecurFilters) {
      for (const filter of filterCategory.data) {
        if (!!filter.id) {
          const filterValues = searchParams.getAll(filter.id.toString());
          if (!!filterValues.length) {
            if (filter.type === 'range') {
              const [min, max] = filterValues[0].split('_');
              filters[`${String(filter.id)}_min`] = min;
              filters[`${String(filter.id)}_max`] = max;
            } else if (filter.type === 'date-range') {
              const [after, before] = filterValues[0].split('_');
              if (after && after.length > 0)
                filters[`${String(filter.id)}_after`] = after;
              if (before && before.length > 0)
                filters[`${String(filter.id)}_before`] = before;
            } else if (filter.type === 'chips-article') {
              filters[String(filter.id)] = filterValues.map(
                (item) => `Artikel ${item}`,
              );
            } else if (filter.type === 'chips-rating') {
              filters[String(filter.id)] = filterValues.map((item) =>
                item.toUpperCase(),
              );
            } else if (filter.type === 'checkboxes-others') {
              filterValues.forEach((item) => {
                filters[item] = 'true';
              });
            } else {
              filters[String(filter.id)] = filterValues;
            }
          }
        }
      }
    }
    return filters;
  };

  const getTableData = async () => {
    if (selectedPortfolio) {
      const filters = formatDataToSend();
      const data = {
        id: selectedPortfolio?.value,
        page,
        page_size: itemsPerPage,
        ...(sortModel && sortModel.sort_by && sortModel.sort_order
          ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
          : {}),
        ...(searchValue ? { search: searchValue } : {}),
      };
      const response = await fetchPortfolios({ ...data, ...filters });
      if (response.ok) {
        const tableData = response.parsedBody.results.funds.map((item) => ({
          ...item,
          highlightColor: item.present_in_kompass
            ? COLORS.info.selectedBorder
            : COLORS.background.secondary,
        }));
        const maxDataState = getMaxDataState(response.parsedBody.results.funds);
        setDataState(maxDataState);
        setResultsNumber(response.parsedBody.count);
        setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [itemsPerPage, searchValue, sortModel, searchParams, selectedPortfolio]);

  useEffect(() => {
    const loadFilterOptions = async () => {
      try {
        if (selectedPortfolio) {
          setIsLoadingFilters(true);
          const response = await getPortfoliosFilterChoices(
            selectedPortfolio?.value,
          );
          const options = response.parsedBody;
          setFiltersConfig(createPlansecurFilters(options));
        }
      } catch (error) {
        console.error('Failed to load filter options:', error);
      } finally {
        setIsLoadingFilters(false);
      }
    };

    loadFilterOptions();
  }, []);

  useEffect(() => {
    if (searchParams.has('page')) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    }

    setSortModel(undefined);
    setSearchValue('');

    const currentSearchParams = new URLSearchParams(search);
    const tabSearchParam = currentSearchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    customNavigate(
      `${pathname}${
        tabSearchParam || selectedPortfolio?.value ? `?${newSearchParams}` : ''
      }`,
    );
  }, [selectedPortfolio]);

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toogleConfirmModal = () => {
    isConfirmRemoveFromPortfolioOpen &&
      setIsConfirmRemoveFromPortfolioOpen(!isConfirmRemoveFromPortfolioOpen);
    isConfirmRemoveFromCompassOpen &&
      setIsConfirmRemoveFromCompassOpen(!isConfirmRemoveFromCompassOpen);
    isConfirmRequestFundPortraitOpen &&
      setIsConfirmRequestFundPortraitOpen(!isConfirmRequestFundPortraitOpen);
    isConfirmWithdrawKompassRequestOpen &&
      setIsConfirmWithdrawRequestOpen(!isConfirmWithdrawKompassRequestOpen);
  };

  const handleSubmitConfirmModal = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      changeFundPortfolioStatus(currentFund.id, currentFund.name);
      return;
    }
    if (isConfirmRequestFundPortraitOpen) {
      sendRequestFundPortrait(currentFund.id);
      return;
    }
    if (isConfirmWithdrawKompassRequestOpen) {
      currentFund.request_id && withdrawKompassRequest(currentFund.request_id);
      return;
    }
  };

  const getConfirmModalTitle = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      return 'Von der Watchlist löschen';
    }
    if (isConfirmRemoveFromCompassOpen) {
      return 'Aus dem Kompass entfernen';
    }
    if (isConfirmWithdrawKompassRequestOpen) {
      return 'Anfrage zurückziehen';
    }
    return 'Fondsporträt';
  };

  const getConfirmModalSubtitle = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      return `Sind Sie sicher, dass Sie ${currentFund?.name} von der Watchlist löschen möchten?`;
    }
    if (isConfirmRemoveFromCompassOpen) {
      return `Sind Sie sicher, dass Sie ${currentFund?.name} aus dem Kompass entfernen möchten?`;
    }
    if (isConfirmWithdrawKompassRequestOpen) {
      return currentFund.present_in_kompass
        ? `Sind Sie sicher, dass Sie die Anfrage zum Entfernen von ${currentFund?.name} aus dem Kompass zurückziehen möchten?`
        : `Sind Sie sicher, dass Sie die Anfrage zum Hinzufügen von ${currentFund?.name} zum Kompass zurückziehen möchten?`;
    }
    return 'Leider ist der Fondsbericht derzeit nicht verfügbar. Möchten Sie ihn anfordern? Lassen Sie es uns wissen, und wir senden ihn Ihnen, sobald er verfügbar ist!';
  };

  const toogleConfirmWithCommentModal = () => {
    isConfirmAddToCompassOpen && setIsConfirmAddToCompassOpen(false);
    isConfirmRemoveFromCompassOpen && setIsConfirmRemoveFromCompassOpen(false);
    isConfirmSendOtherFundRequest && seetIsConfirmSendOtherFundRequest(false);
  };

  const getConfirmWithCommentModalTitle = () => {
    if (isConfirmAddToCompassOpen) {
      return 'Antrag auf Aufnahme in den Kompass stellen';
    }
    if (isConfirmRemoveFromCompassOpen) {
      return 'Antrag auf Streichung vom Kompass stellen';
    }
    return 'Sonstige Anfragen/Anmerkungen';
  };

  const getConfirmWithCommentModalSubtitle = () => {
    if (isConfirmAddToCompassOpen) {
      return 'Sind Sie sicher, dass Sie die Anfrage senden möchten, um diesen Fonds zum Kompass hinzuzufügen?';
    }
    if (isConfirmRemoveFromCompassOpen) {
      return 'Sind Sie sicher, dass Sie die Anfrage zum Entfernen dieses Fonds aus dem Kompass senden möchten?';
    }
    return undefined;
  };

  const getConfirmWithCommentModalButton = () => {
    if (isConfirmAddToCompassOpen) {
      return 'Ja, Anfrage zum Hinzufügen senden';
    }
    if (isConfirmRemoveFromCompassOpen) {
      return 'Ja, Entfernungsanfrage einreichen';
    }
    return 'Senden';
  };

  const getConfirmWithCommentModalVariant = () => {
    if (isConfirmAddToCompassOpen) {
      return 'send-request-to-add-to-kompass';
    }
    if (isConfirmRemoveFromCompassOpen) {
      return 'send-request-to-remove-from-kompass';
    }
    return 'send-other-request';
  };

  const getConfirmTextButton = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      return 'Ja, Entfernen';
    }
    if (isConfirmRemoveFromCompassOpen) {
      return 'Ja, Entfernen';
    }
    if (isConfirmWithdrawKompassRequestOpen) {
      return 'Ja, Zurückziehen';
    }
    return 'Fondsporträt anfordern';
  };

  const toggleCommentsModal = () => {
    isCommentsPlansecurModalOpen && setIsCommentsPlansecurModalOpen(false);
    isCommentsFondsconsultModalOpen &&
      setIsCommentsFondsconsultModalOpen(false);
  };

  const clearFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    customNavigate(`${pathname}${tabSearchParam ? `?${newSearchParams}` : ''}`);
  };

  const getFilters = (filtersOptions: PlansecurFiltersProps[]) => {
    const filters: ({
      value: string;
      name: string;
      category: string;
    } & FilterProps)[] = [];

    const addFilter = (
      value: string,
      name: string,
      label: string,
      id: string,
      categoryName: string,
    ) => {
      filters.push({
        value,
        name: toTitleCase(name) || '',
        label,
        id,
        category: categoryName,
      });
    };

    const processFilterValue = (
      filterValue: string[],
      id: string,
      type: string,
      innerCategoryName: string,
      options: any[],
      categoryName: string,
    ) => {
      if (type === 'search' || type === 'star-rating') {
        addFilter(
          filterValue[0],
          filterValue[0],
          innerCategoryName,
          id,
          categoryName,
        );
        return;
      }

      if (type === 'range' || type === 'date-range') {
        if (filterValue && filterValue[0]) {
          const [min, max] = filterValue[0].split('_');
          const formatRange = (
            min?: string | null,
            max?: string | null,
          ): string => {
            if (min && max) return `${min} - ${max}`;
            if (min) return `${min}`;
            if (max) return `${max}`;
            return '';
          };
          addFilter(
            `${formatRange(min, max)}`,
            `${formatRange(min, max)}`,
            innerCategoryName,
            id,
            categoryName,
          );
        }
      }

      const result = options.filter((option) =>
        filterValue.includes(option.value.toLowerCase()),
      );
      result.forEach((item) => {
        const value = item?.value;
        const name =
          type === 'chips-article' ? `Artikel ${item?.name}` : item?.name;
        addFilter(value, name, innerCategoryName, id, categoryName);
      });
    };

    Object.values(filtersOptions).forEach((filter) =>
      Array.isArray(filter.data)
        ? filter.data.map(({ id, type, innerCategoryName, options }) => {
            const filterValue = searchParams.getAll(id.toString());
            if (filterValue) {
              processFilterValue(
                filterValue,
                id.toString(),
                type,
                innerCategoryName,
                options,
                filter.categoryName,
              );
            }
          })
        : null,
    );

    return filters;
  };

  return (
    <>
      <ConfirmModal
        isWarning={
          isConfirmRemoveFromPortfolioOpen ||
          isConfirmRemoveFromCompassOpen ||
          isConfirmWithdrawKompassRequestOpen
        }
        title={getConfirmModalTitle()}
        subtitle={getConfirmModalSubtitle()}
        isShown={
          isConfirmRemoveFromPortfolioOpen ||
          isConfirmRemoveFromCompassOpen ||
          isConfirmRequestFundPortraitOpen ||
          isConfirmWithdrawKompassRequestOpen
        }
        toggle={toogleConfirmModal}
        onSubmit={handleSubmitConfirmModal}
        confirmButtonText={getConfirmTextButton()}
      />
      <PlansecurFiltersModal
        isShow={openFilters}
        closeModal={() => setOpenFilters(false)}
        clearFilters={clearFilters}
        filters={filtersConfig}
        selectedFilters={getFilters(filtersConfig).filter(
          (item) => !!item.value,
        )}
        isLoading={isLoadingFilters}
      />
      <ConfirmWithCommentModal
        isShown={
          isConfirmRemoveFromCompassOpen ||
          isConfirmAddToCompassOpen ||
          isConfirmSendOtherFundRequest
        }
        variant={getConfirmWithCommentModalVariant()}
        isWarning={isConfirmRemoveFromCompassOpen}
        title={getConfirmWithCommentModalTitle()}
        subtitle={getConfirmWithCommentModalSubtitle()}
        confirmButtonText={getConfirmWithCommentModalButton()}
        fundName={currentFund.name}
        isin={currentFund.isin}
        fundId={currentFund.id}
        toggle={toogleConfirmWithCommentModal}
        handleSubmitSend={() =>
          !isConfirmSendOtherFundRequest && getTableData()
        }
      />
      <CommentsModal
        isShown={
          isCommentsPlansecurModalOpen || isCommentsFondsconsultModalOpen
        }
        variant={isCommentsPlansecurModalOpen ? 'plansecur' : 'fondsconsult'}
        fundName={currentFund.name}
        isin={currentFund.isin}
        fundId={currentFund.id}
        closeModal={toggleCommentsModal}
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        portfoliosOptions={portfoliosOptions}
        selectedPortfolio={selectedPortfolio}
        setSelectedPortfolio={setSelectedPortfolio}
        activeFiltersAmount={0}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        onExportClick={() => {}}
      >
        <SelectedFilters
          filters={getFilters(filtersConfig)}
          clearFilters={clearFilters}
        />
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
            />
          )}
          <PortfolioCollapseTable
            isLastColumnsFixed={isFondsPateUser}
            loading={loading}
            tableData={{
              rows,
              columns: [
                columns[0],
                statusColumn,
                sfbColumn,
                ...columns.slice(1),
              ],
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination pagesNumber={getPagesNumber()} />
      </ModuleLayout>
    </>
  );
};
