import { styled as MUIStyled } from '@mui/material/styles';
import {
  FormControl,
  Select,
  MenuItem,
  outlinedInputClasses,
} from '@mui/material';
import { COLORS } from 'theme/colors';
import styled from 'styled-components';
import { pxToRem } from 'utils/common';

export const StyledFormControl = MUIStyled(FormControl)(() => ({
  position: 'relative',
}));

export const StyledSelect = MUIStyled(Select)(() => ({
  '& .MuiSelect-select': {
    padding: `${pxToRem(16)} ${pxToRem(16)}`,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLORS.stroke.main}`,

    '&:enabled': {
      '&:hover': {
        border: `1px solid ${COLORS.typography.caption}`,
      },
    },
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: `${COLORS.stroke.main}`,
  },
  [`& .${outlinedInputClasses.focused}`]: {
    borderWidth: 4,
    borderColor: `${COLORS.info.selectedBorder}`,
  },
}));

export const StyledMenuItemContainer = MUIStyled(MenuItem)(() => ({
  color: COLORS.typography.description,
  '&:hover': {
    backgroundColor: `${COLORS.background.neutral} !important`,
    color: COLORS.accent.primary,
  },
  '&.Mui-selected': {
    backgroundColor: `${COLORS.background.neutral} !important`,
    color: `${COLORS.accent.primary} !important`,
  },
}));

export const StyledInput = styled.input`
  border: none;
  outline: none;
  padding: 2px;
  background: transparent;
  min-width: 50px;
  flex-grow: 1;
  font: inherit;
`;
