import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ModuleLayoutProps } from './ModuleLayout.d';
import { SearchContainer, StyledContainer } from './ModuleLayout.styled';
import { Box } from 'components/common/Box';
import { Search } from 'components/common/Search';
import { FiltersButton } from 'components/common/Buttons/FiltersButton';
import { createQueryParams } from 'utils/common';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { useLocation } from 'react-router-dom';
import { ItemsListModal } from 'components/common/Modals/ItemsListModal';
import { ReactComponent as FiltersIcon } from 'assets/Icons/filters.svg';
import { ReactComponent as ArrowIcon } from 'assets/Icons/collapse-arrow.svg';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { COLORS } from 'theme/colors';

export const ModuleLayout: FC<PropsWithChildren<ModuleLayoutProps>> = ({
  children,
  searchValue,
  searchPlaceholder,
  activeFiltersAmount,
  onSubmitSearch,
  onToggleFilters,
  withTabs = false,
  filterPresetOptions,
  presetFilterValue,
  onPresetFilterChange,
  onSaveCurrentFilters,
  getFilterPresetsOptions,
  clearFilters,
  editFilterPreset,
  deleteFilterPreset,
  ...props
}) => {
  const modalRef = useRef(null);
  const { pathname, search } = useLocation();
  const { customNavigate } = useNavigation();
  const [currentPresets, setCurrentPresets] = useState<string | null>(null);
  const [isPresetModalOpen, setIsPresetModalOpen] = useState(false);

  const getPresetIdFromUrl = (searchStr: string) => {
    const urlParams = new URLSearchParams(searchStr);
    return urlParams.get('preset');
  };

  const [selectedPreset, setSelectedPreset] = useState<{
    id: string;
    name: string;
    itemsCount?: number;
  } | null>(() => {
    const presetId = getPresetIdFromUrl(search);
    return presetId && presetFilterValue && presetId === presetFilterValue.value
      ? {
          id: presetFilterValue.value,
          name: presetFilterValue.name,
          itemsCount: undefined,
        }
      : null;
  });

  const transformObject = (input: {
    [key: string]: string | string[];
  }): {
    [key: string]: string | string[];
  } => {
    const result: { [key: string]: string | string[] } = {};

    for (const key in input) {
      if (key.endsWith('_max') || key.endsWith('_min')) {
        const baseKey = key.replace(/_(max|min)$/, '');
        const minKey = `${baseKey}_min`;
        const maxKey = `${baseKey}_max`;

        if (input[minKey] && input[maxKey]) {
          result[baseKey] = `${input[minKey]}_${input[maxKey]}`;
        }
      } else {
        result[key] = input[key];
      }
    }

    return result;
  };

  const handleEditPreset = async (itemId: string, newName: string) => {
    if (editFilterPreset) {
      const response = await editFilterPreset(itemId, newName);

      if (
        response.ok &&
        presetFilterValue &&
        presetFilterValue.value === itemId
      ) {
        onPresetFilterChange &&
          onPresetFilterChange({
            value: itemId,
            name: newName,
          });
      }

      return response;
    }
    return { ok: false };
  };

  const handlePresetFilterChange = (
    preset: { id: string; name: string; itemsCount?: number } | null,
  ) => {
    setSelectedPreset(preset);

    if (!preset) {
      setCurrentPresets(null);
      onPresetFilterChange && onPresetFilterChange(null);
      return;
    }

    if (filterPresetOptions) {
      const newFilter = filterPresetOptions.find(
        (item) => item.id === preset.id,
      );

      if (newFilter) {
        onPresetFilterChange &&
          onPresetFilterChange({
            value: preset.id,
            name: preset.name,
          });

        if (newFilter?.query_params) {
          const tranformedQueryParams = transformObject(newFilter.query_params);
          const newSearchParams = createQueryParams({
            ...tranformedQueryParams,
            preset: preset.id, 
          });
          const newSearchString = `?${newSearchParams.toString()}`;
          setCurrentPresets(newSearchString);

          customNavigate(`${pathname}${newSearchString}`);
          setIsPresetModalOpen(false);
        }
      }
    }
  };

  const handleDeletePreset = async (presetId: string) => {
    if (deleteFilterPreset) {
      const response = await deleteFilterPreset(presetId);

      if (response.ok && selectedPreset && selectedPreset.id === presetId) {
        setSelectedPreset(null);
        setCurrentPresets(null);
        onPresetFilterChange && onPresetFilterChange(null);
        clearFilters && clearFilters();
      }

      return response;
    }
  };

  useEffect(() => {
    const hasPresetInQuery = search.includes('preset=');
    const shouldResetPreset = !hasPresetInQuery && selectedPreset !== null;

    if (shouldResetPreset && onPresetFilterChange) {
      setSelectedPreset(null);
      setCurrentPresets(null);
      onPresetFilterChange(null);
    }
  }, [search]);

  useEffect(() => {
    if (
      selectedPreset &&
      presetFilterValue &&
      selectedPreset.id === presetFilterValue.value &&
      selectedPreset.name !== presetFilterValue.name
    ) {
      onPresetFilterChange &&
        onPresetFilterChange({
          value: selectedPreset.id,
          name: selectedPreset.name,
        });
    }
  }, [selectedPreset]);

  useEffect(() => {
    if (presetFilterValue && filterPresetOptions?.length) {
      const preset = filterPresetOptions.find(
        (option) => option.id === presetFilterValue.value,
      );
      if (preset) {
        setSelectedPreset({
          id: preset.id,
          name: preset.name,
          itemsCount: preset.query_params
            ? Object.keys(preset.query_params).length
            : 0,
        });
      }
    } else if (!presetFilterValue) {
      setSelectedPreset(null);
    }
  }, [presetFilterValue, filterPresetOptions]);

  useEffect(() => {
    if (!selectedPreset) {
      onPresetFilterChange && onPresetFilterChange(null);
    }
  }, [selectedPreset]);

  useEffect(() => {
    const presetId = getPresetIdFromUrl(search);

    if (presetId && !selectedPreset && filterPresetOptions?.length) {
      const presetFromUrl = filterPresetOptions.find(
        (item) => item.id === presetId,
      );

      if (presetFromUrl) {
        setSelectedPreset({
          id: presetFromUrl.id,
          name: presetFromUrl.name,
          itemsCount: presetFromUrl.query_params
            ? Object.keys(presetFromUrl.query_params).length
            : 0,
        });

        onPresetFilterChange &&
          onPresetFilterChange({
            value: presetFromUrl.id,
            name: presetFromUrl.name,
          });
      }
    }
    else if (!presetId && selectedPreset) {
      setSelectedPreset(null);
      setCurrentPresets(null);
      onPresetFilterChange && onPresetFilterChange(null);
    }
  }, [search, filterPresetOptions]);

  return (
    <StyledContainer
      withTabs={withTabs}
      mx={2.5}
      mb={5}
      py={5}
      display="flex"
      flexDirection="column"
      gap={3}
      {...props}
    >
      {filterPresetOptions &&
        editFilterPreset &&
        deleteFilterPreset &&
        getFilterPresetsOptions && (
          <ItemsListModal
            ref={modalRef}
            isShown={isPresetModalOpen}
            toggle={() => setIsPresetModalOpen(!isPresetModalOpen)}
            items={filterPresetOptions?.map((item) => ({
              id: item.id,
              name: item.name,
              itemsCount: null,
            }))}
            selectedItem={selectedPreset}
            setSelectedItem={(preset) =>
              preset
                ? handlePresetFilterChange({
                    id: preset.id,
                    name: preset.name,
                    itemsCount:
                      preset.itemsCount === null
                        ? undefined
                        : preset.itemsCount,
                  })
                : handlePresetFilterChange(null)
            }
            getItems={getFilterPresetsOptions}
            icon={
              <FiltersIcon
                stroke={COLORS.typography.primary}
                width={24}
                height={24}
              />
            }
            modalTitle="Filter-Presets"
            listTitle="Meine Filter-Presets"
            createButtonLabel="Preset erstellen"
            searchPlaceholder="Suche Presets"
            emptyStateMessage="Keine Filter-Presets gefunden"
            deleteItemTitle="Filter-Preset löschen"
            deleteItemSubtitle="Sind Sie sicher, dass Sie dieses Preset löschen möchten"
            confirmButtonText="Ja, Löschen"
            editSuccessMessage="Filter-Preset erfolgreich bearbeitet."
            deleteSuccessMessage="Filter-Preset erfolgreich gelöscht."
            listsHeader="Meine Presets"
            editItem={handleEditPreset}
            deleteItem={handleDeletePreset}
          />
        )}
      <Box
        px={2.2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchContainer>
          <Search
            initialValue={searchValue}
            onSubmit={onSubmitSearch}
            placeholder={searchPlaceholder}
          />
        </SearchContainer>
        <Box display="flex" gap={1} alignItems="center">
          {!!filterPresetOptions?.length && onPresetFilterChange && (
            <Button
              variant={ButtonVariant.OUTLINED}
              rightIcon={
                <ArrowIcon
                  stroke={COLORS.typography.main}
                  style={{ transform: 'rotate(90deg)' }}
                />
              }
              onClick={() => setIsPresetModalOpen(true)}
            >
              <Box pr={1}>
                {presetFilterValue ? presetFilterValue.name : 'Meine Filter'}
              </Box>
            </Button>
          )}
          {activeFiltersAmount !== null && (
            <FiltersButton
              activeFiltersAmount={activeFiltersAmount}
              onClick={onToggleFilters}
            />
          )}
        </Box>
      </Box>
      {children}
    </StyledContainer>
  );
};
