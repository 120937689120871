import {
  ColumnProps,
  RowProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { NameColumn } from '../NameColumn';
import { StarRatingsColumn } from '../StarRatingsColumn';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { CheckStatusIndicator } from 'components/common/Icons/CheckStatusIndicator';
import { Option } from 'components/common/Select/Select.d';
import { Box } from 'components/common/Box';
import { TableChip } from 'components/common/TableChip/TableChip';
import { StyledTableChipContainer } from './CustomerOverview.styled';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { formatNumberWithComma, isValidLink } from 'utils/common';
import { morningstarMedalistRatingOptions } from './options';
import { ReactComponent as FNGIcon } from 'assets/Icons/fng.svg';

export const getDefaultFilterValue = (value: string, options: Option[]) => {
  const [result] = options.filter((option) => option.value === value);
  return result;
};

export const statusOptions: Option[] = [
  {
    value: 'In Kompass aufnehmen',
    name: 'In Kompass aufnehmen',
    chipVariant: TableChipVariant.GREEN,
  },
  {
    value: 'Aus Kompass streichen',
    name: 'Aus Kompass streichen',
    chipVariant: TableChipVariant.RED,
  },
];

export const createRow = (tableData: PlansecurFundProps): RowProps => {
  return {
    id: tableData.id,
    scope_rating: tableData.scope_rating,
    appropriation_of_earnings: tableData.appropriation_of_earnings ?? '-',
    fund_id: tableData.fund_id,
    isin: tableData.isin,
    wkn: tableData.wkn ?? '-',
    name: tableData.name,
    currency: tableData.currency ?? '-',
    category: tableData.category ?? '-',
    aa_percentage: tableData.aa_percentage
      ? formatNumberWithComma(tableData.aa_percentage)
      : '-',
    performance_current_year: tableData.performance_current_year
      ? formatNumberWithComma(tableData.performance_current_year)
      : '-',
    performance_1_year: tableData.performance_1_year
      ? formatNumberWithComma(tableData.performance_1_year)
      : '-',
    performance_3_years_pa: tableData.performance_3_years_pa
      ? formatNumberWithComma(tableData.performance_3_years_pa)
      : '-',
    performance_5_years_pa: tableData.performance_5_years_pa
      ? formatNumberWithComma(tableData.performance_5_years_pa)
      : '-',
    performance_10_years_pa: tableData.performance_10_years_pa
      ? formatNumberWithComma(tableData.performance_10_years_pa)
      : '-',
    sharpe_ratio_1_year: tableData.sharpe_ratio_1_year
      ? formatNumberWithComma(tableData.sharpe_ratio_1_year)
      : '-',
    sharpe_ratio_3_years: tableData.sharpe_ratio_3_years
      ? formatNumberWithComma(tableData.sharpe_ratio_3_years)
      : '-',
    sharpe_ratio_5_years: tableData.sharpe_ratio_5_years
      ? formatNumberWithComma(tableData.sharpe_ratio_5_years)
      : '-',
    volatility_1_year: tableData.volatility_1_year
      ? formatNumberWithComma(tableData.volatility_1_year)
      : '-',
    volatility_3_years: tableData.volatility_3_years
      ? formatNumberWithComma(tableData.volatility_3_years)
      : '-',
    volatility_5_years: tableData.volatility_5_years
      ? formatNumberWithComma(tableData.volatility_5_years)
      : '-',
    fww_fundstars_rating: tableData.fww_fundstars_rating,
    morningstar_medalist_rating: tableData.morningstar_medalist_rating,
    index_fund: tableData.index_fund,
    vl_fund: tableData.vl_fund,
    price_fixing_sale_offset: tableData.price_fixing_sale_offset,
    custody_fee: tableData.custody_fee,
    savings_plan_eligible: tableData.savings_plan_eligible,
    buy: tableData.buy,
    sell: tableData.sell,
    payment_plan: tableData.payment_plan,
    volume_in_million_eur: tableData.volume_in_million_eur
      ? formatNumberWithComma(tableData.volume_in_million_eur)
      : '-',
    fng_label: tableData.fng_label ?? '-',
    sustainability_star_rating: tableData.sustainability_star_rating ?? '-',
    eet_template_version: tableData.eet_template_version ?? '-',
    pornography_exclusion: tableData.pornography_exclusion,
    exclusion_threshold: tableData.exclusion_threshold ?? '-',
    sustainability_preference_sfdr:
      tableData.sustainability_preference_sfdr ?? '-',
    ungc_oecd_violations_considered: tableData.ungc_oecd_violations_considered,
    is_compass_design_request_sent: false,
    is_in_my_portfolio: false,
    present_in_kompass: tableData.present_in_kompass,
    is_watchlisted: tableData.is_watchlisted ?? null,
    user__name: tableData.user__name ?? '-',
    created_at: tableData.created_at ? tableData.created_at : '-',
    requested_by_comment: tableData.requested_by_comment ?? '-',
    requestType: tableData.requestType ?? null,
    requestId: tableData.request_id ?? '-',
    highlightColor: tableData.highlightColor ?? null,
    highlightTooltip: tableData.highlightTooltip ?? null,
    gradientColor: tableData.gradientColor ?? null,
    sfb_related_fund__peer_group:
      tableData?.sfb_related_fund?.peer_group ?? '-',
    sfb_related_fund__rank__rank:
      tableData?.sfb_related_fund?.rank?.rank ?? null,
    sfb_related_fund__volume: tableData.sfb_related_fund?.volume ?? '-',
    sfb_related_fund__track_record:
      tableData.sfb_related_fund?.track_record ?? '-',
    sfb_related_fund__total_active_management_score:
      tableData.sfb_related_fund?.total_active_management_score || null,
    sfb_related_fund__total_risk_score:
      tableData.sfb_related_fund?.total_risk_score || null,
    sfb_related_fund__esg_scores__credibility_score:
      tableData?.sfb_related_fund?.esg_scores &&
      tableData.sfb_related_fund.esg_scores?.credibility_score
        ? tableData.sfb_related_fund.esg_scores?.credibility_score?.toString()
        : null,
    nr_of_funds: tableData?.sfb_related_fund?.rank
      ? tableData?.sfb_related_fund.rank.nr_of_funds
      : null,
    esg_actions: '-',
    sfb_related_fund__return_score__total_score:
      tableData.sfb_related_fund?.return_score.total_score?.label || null,
    status: tableData.status?.status ?? null,
    status_timestamp: tableData.status?.timestamp ?? null,
    status_request_id: tableData.status?.request_id ?? null,
    status_email: tableData.status?.email ?? null,
    source: tableData.source ?? '',
    plansecur_asset_class: tableData.plansecur_asset_class ?? '-',
    quintile_2020: tableData.quintile_2020 ?? null,
    quintile_2021: tableData.quintile_2021 ?? null,
    quintile_2022: tableData.quintile_2022 ?? null,
    quintile_2023: tableData.quintile_2023 ?? null,
    quintile_2024: tableData.quintile_2024 ?? null,
    quintile_sum: tableData.quintile_sum ?? null,
    management_fee_percentage: tableData.management_fee_percentage
      ? formatNumberWithComma(tableData.management_fee_percentage)
      : '-',
    performance_fee_percentage: tableData.performance_fee_percentage
      ? formatNumberWithComma(tableData.performance_fee_percentage)
      : '-',
    broker_notes: tableData.broker_notes ?? null,
    best_in_class_approach: tableData.best_in_class_approach ?? null,
    norm_based_screening: tableData.norm_based_screening ?? null,
    trail_commission: tableData.trail_commission ?? null,
    allocation: tableData.allocation ?? '-',
    recommendation_for_action: tableData.recommendation_for_action ?? '-',
    assessment: tableData.assessment ?? '-',
    adjustments: tableData.adjustments ?? '-',
    reviewer_notes: tableData.reviewer_notes ?? '-',
    abs_net_flow_in_million_eur_1_year:
      tableData.abs_net_flow_in_million_eur_1_year
        ? formatNumberWithComma(tableData.abs_net_flow_in_million_eur_1_year)
        : '-',
    abs_net_flow_in_million_eur_3_months:
      tableData.abs_net_flow_in_million_eur_3_months
        ? formatNumberWithComma(tableData.abs_net_flow_in_million_eur_3_months)
        : '-',
    abs_net_flow_in_million_eur_6_months:
      tableData.abs_net_flow_in_million_eur_6_months
        ? formatNumberWithComma(tableData.abs_net_flow_in_million_eur_6_months)
        : '-',
    relative_net_flow_percentage_1_year:
      tableData.relative_net_flow_percentage_1_year
        ? formatNumberWithComma(tableData.relative_net_flow_percentage_1_year)
        : '-',
    relative_net_flow_percentage_3_months:
      tableData.relative_net_flow_percentage_3_months
        ? formatNumberWithComma(tableData.relative_net_flow_percentage_3_months)
        : '-',
    relative_net_flow_percentage_6_months:
      tableData.relative_net_flow_percentage_6_months
        ? formatNumberWithComma(tableData.relative_net_flow_percentage_6_months)
        : '-',
    sri_value: tableData.sri_value ?? '-',
    investor_required_knowledge: tableData.investor_required_knowledge ?? '-',
    time_horizon: tableData.time_horizon ?? '-',
    rank: tableData.rank ?? '-',
  };
};

export const commonColumns: ColumnProps[] = [
  {
    id: 'name',
    label: 'Name',
    tooltip: 'The name of the item',
    sortable: true,
    renderCell: ({ row, value }) => {
      return <NameColumn variant="small" name={value} type={row.isin} />;
    },
    subColumns: [],
  },
  {
    id: 'stammdaten',
    label: 'Stammdaten',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'plansecur_asset_class',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Plansecur-Assetklasse',
        sortable: true,
        minWidth: '182px',
        maxWidth: '182px',
      },
      {
        id: 'category',
        headAlign: 'left',
        cellAlign: 'left',
        label: 'Fondskategorie / Asset-Schwerpunkt',
        sortable: true,
        minWidth: '169px',
        maxWidth: '169px',
      },
      {
        id: 'wkn',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'WKN',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondswährung',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
      },
    ],
  },
  {
    id: 'preis',
    label: 'Preis und Kosten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'aa_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausgabeaufschlag in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'management_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Managementgebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Performancegebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'rendite',
    label: 'Rendite',
    visibleSubColumnsNumber: 5,
    subColumns: [
      {
        id: 'performance_current_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Laufendes Jahr',
        sortable: true,
        minWidth: '130px',
        maxWidth: '130px',
      },
      {
        id: 'performance_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_5_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_10_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre p.a.',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
    ],
  },
  {
    id: 'sharpe_ratio',
    label: 'Sharpe Ratio',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'sharpe_ratio_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'sharpe_ratio_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'sharpe_ratio_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
    ],
  },
  {
    id: 'volatility',
    label: 'Volatilität',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'volatility_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'externe_ratings',
    label: 'Externe Ratings',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'fww_fundstars_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FWW FundStars(R)',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
        renderCell: ({ value }) => <StarRatingsColumn value={value} />,
      },
      {
        id: 'morningstar_medalist_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Morningstar Medalist Rating',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            morningstarMedalistRatingOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'scope_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Scope Mutual Fund Rating',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <LetterBadge value={value} />,
      },
    ],
  },
  {
    id: 'abwicklung',
    label: 'Fondsstruktur & Abwicklung',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'appropriation_of_earnings',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ertragsverwendung',
        sortable: true,
        minWidth: '158px',
        maxWidth: '158px',
      },
      {
        id: 'sri_value',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'SRI',
        sortable: true,
        minWidth: '158px',
        maxWidth: '158px',
      },
      {
        id: 'investor_required_knowledge',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Erforderliche Kenntnisse des Anlegers',
        sortable: true,
        minWidth: '220px',
        maxWidth: '220px',
      },
      {
        id: 'time_horizon',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Erforderlicher Anlagehorizont des Anlegers',
        sortable: true,
        minWidth: '220px',
        maxWidth: '220px',
      },
      {
        id: 'index_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Indexfonds',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'vl_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'VL-Fonds',
        sortable: true,
        minWidth: '90px',
        maxWidth: '90px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'custody_fee',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verwahrentgelt',
        sortable: true,
        minWidth: '129px',
        maxWidth: '129px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'savings_plan_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sparplanfähig',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'buy',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'sell',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verkauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'payment_plan',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Auszahlplan',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
    ],
  },
  {
    id: 'volume_and_net_inflows',
    label: 'Fondsvolumen und Nettomiitelzuflüsse',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'volume_in_million_eur',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Volumen umgerechnet\n in Mio. EUR',
        sortable: true,
        minWidth: '200px',
        maxWidth: '200px',
      },
      {
        id: 'abs_net_flow_in_million_eur_3_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 3 Monate (absolut)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'relative_net_flow_percentage_3_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 3 Monate (relativ)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'abs_net_flow_in_million_eur_6_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 6 Monate (absolut)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'relative_net_flow_percentage_6_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 6 Monate (relativ)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'abs_net_flow_in_million_eur_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 12 Monate (absolut)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'relative_net_flow_percentage_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 12 Monate (relativ)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
    ],
  },
  {
    id: 'nachhaltigkeit',
    label: 'Nachhaltigkeit',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'fng_label',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FNG-Nachhaltigkeitsprofil',
        tooltip:
          'Die FNG-Nachhaltigkeitsprofile bieten eine Übersicht zu nachhaltigen Anlagestrategien und relevanten Eckdaten von nachhaltigen Investmentfonds. Liegt ein FNG-Nachhaltigkeitsprofil vor, kann dies über einen Klick auf das FNG-Logo geöffnet werden.',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => (
          <>
            {value && isValidLink(value) ? (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <FNGIcon width={24} height={24} />
              </a>
            ) : (
              <CheckStatusIndicator value={false} />
            )}
          </>
        ),
      },
      {
        id: 'sustainability_star_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FNG-Sterne 2025',
        tooltip:
          'Das FNG-Siegel ist der SRI-Qualitätsstandard auf dem deutschsprachigen Finanzmarkt. Die ganzheitliche Methodik des FNG-Siegels basiert auf einem Mindeststandard. Dazu zählen Transparenzkriterien und die Berücksichtigung von Arbeits- & Menschenrechten, Umweltschutz und Korruptionsbekämpfung wie sie im weltweit anerkannten UN Global Compact zusammengefasst sind.',
        tooltip2:
          'Je mehr und intensiver ein Fonds auf den verschiedenen Ebenen im Sinne der Nachhaltigkeit aktiv ist, umso qualitativ hochwertiger ist er. Die Sterne sind ein Ausdruck des Potentials, inwieweit der Fonds echte Wirkung im Sinne von (mehr) Nachhaltigkeit erzielen kann. Jeder Stern ist damit eine Würdigung einer bereits umgesetzten höheren Qualität als nur das Nötigste. Je mehr Sterne, desto nachhaltiger der Fonds.',
        sortable: true,
        minWidth: '140px',
        maxWidth: '140px',
      },
      {
        id: 'eet_template_version',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'EET Template Version',
        tooltip:
          'Dieser Datenpunkt gibt Auskunft über die Aktualität der über Morningstar abgegriffenen EET-Datenfelder. Seit März 2024 ist das EET in der Version 1.1.2 bereitzustellen. Die Version 1.1.3 kann ab dem 1. Januar 2025 verwendet werden. Es ist eine Übergangsphase bis 1. Juli 2025 vorgesehen. Ältere Versionen sind entsprechend mit 1.1.1, 1.1 oder 1 (mit 1 als die erste Version) gekennzeichnet.',
        sortable: true,
        minWidth: '140px',
        maxWidth: '140px',
      },
      {
        id: 'pornography_exclusion',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausschluss Pornographie',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'exclusion_threshold',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausschlussschwelle Pornographie',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_preference_sfdr',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'NH-Präferenz gem. SFDR',
        tooltip:
          'Dieses Feld enthält Angaben über die vom Hersteller mitgeteilten Nachhaltigkeitsmerkmale des Finanzinstruments und gibt Auskunft darüber, ob dieser Bestandteil der Nachhaltigkeitspräferenzen gem. der MiFID II- Delegierten Verordnung (EU) 2017/565 (Ausprägung P-V) ist oder (Ausprägung O-E) nicht. Mit folgender Reihenfolge steigt der Grad an Nachhaltigkeit: P, R, T, U, V und S als nachhaltigste Ausprägung.',
        sortable: true,
        minWidth: '140px',
        maxWidth: '140px',
      },
      {
        id: 'ungc_oecd_violations_considered',
        headAlign: 'center',
        cellAlign: 'center',
        label:
          'PAI - Verstoß gegen Grundsätze der UN Global Compact Prinzipien und OECD-Richtlinien für multinationale Unternehmen',
        tooltip:
          'Die PAIs beinhalten eine Liste wichtigster nachteiliger Nachhaltigkeitsauswirkungen, die von einem Investment ausgehen können. Da es sich um eine Negativliste handelt, ist das Ziel nachhaltiger Anlagestrategien in der Regel eine Berücksichtigung im Sinne einer Reduktion dieser negativen Auswirkungen.',
        sortable: true,
        minWidth: '350px',
        maxWidth: '350px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
    ],
  },
];
