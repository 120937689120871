import { SelectProps } from '@mui/material';
import {
  StyledMenuItem,
  StyledSpan,
  StyledInputContainer,
  StyledSelect,
  StyledInput,
  StyledFormControl,
  StyledMenuItemContainer,
  StyledPlaceholder,
  HiddenFirstMenuItem,
} from './Select.styled';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow.svg';
import { Option, SelectorProps } from './Select.d';
import { COLORS } from 'theme/colors';

export const CustomSelect = <K, T extends object>({
  label = '',
  value,
  name = '',
  action,
  options,
  required = false,
  disabled,
  placeholder,
  selectVariant = 'standart',
  ...props
}: SelectorProps<SelectProps<T>>) => {
  return (
    <StyledInputContainer>
      <StyledFormControl variant="outlined">
        {placeholder && value === null && (
          <StyledPlaceholder selectVariant={selectVariant}>
            {placeholder}
          </StyledPlaceholder>
        )}
        <StyledSelect
          label={label}
          variant="outlined"
          selectVariant={selectVariant}
          name={name}
          disabled={disabled}
          labelId={label}
          value={value || ''}
          onChange={action}
          IconComponent={(props) => (
            <ArrowIcon
              {...props}
              stroke={
                selectVariant === 'filled'
                  ? COLORS.background.secondary
                  : COLORS.stroke.secondary
              }
            />
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: '4px',
                maxHeight: '240px',
                width: 'auto',
                maxWidth: '300px',
                '& .MuiMenu-list': {
                  maxHeight: '240px',
                },
              },
            },
            MenuListProps: {
              sx: {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          input={
            <StyledInput
              selectVariant={selectVariant}
              value={value ? value.value : ''}
            />
          }
          required={required}
          renderValue={(valueP: any) => (
            <span>
              <StyledSpan selectVariant={selectVariant}>
                {selectVariant === 'filter' ? placeholder : valueP.name}
              </StyledSpan>
            </span>
          )}
          {...props}
        >
          {/* Without this line first item becomes focused after openning menu */}
          {/* https://github.com/mui/material-ui/issues/23747 */}
          <HiddenFirstMenuItem key="hidden" />
          {options.map((option: Option, idx: number) => (
            <StyledMenuItemContainer
              value={option as any}
              key={`${option}-${idx}`}
              selected={option.value === value?.value}
            >
              <StyledMenuItem selected={option.value === value?.value}>
                {option.name}
              </StyledMenuItem>
            </StyledMenuItemContainer>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </StyledInputContainer>
  );
};
