import { useState, FC } from 'react';
import { TableTabsType } from './TableTabs.d';
import {
  TabItem,
  TabsList,
  TabContent,
  TabSeparator,
  StyledTabLabelContainer,
} from './TableTabs.styled';
import { slugify } from 'utils/common';
import { ReactComponent as ArrowIcon } from 'assets/Icons/raise-arrow.svg';
import { ReactComponent as MinusIcon } from 'assets/Icons/minus-circle.svg';
import { ReactComponent as WarningIcon } from 'assets/Icons/crisis.svg';
import { Box } from '../Box';
import { useLocation } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { COLORS } from 'theme/colors';

export const TableTabs: FC<TableTabsType> = ({ tabs, onTabChange }) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [activeTab, setActiveTab] = useState(slugify(tabs[0].label));

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    newActiveTab: string,
  ) => {
    const originalSearchParams = new URLSearchParams(search);
    const newSearchParams = new URLSearchParams();

    e.preventDefault();
    setActiveTab(slugify(newActiveTab));
    onTabChange && onTabChange(newActiveTab);

    if (originalSearchParams.get('table-tab') !== newActiveTab) {
      originalSearchParams.delete('table-tab');

      newSearchParams.append('table-tab', newActiveTab);
    }

    originalSearchParams.forEach((value, key) => {
      newSearchParams.append(key, value);
    });

    customNavigate(pathname + '?' + newSearchParams.toString());
  };

  const getIcon = (variant?: 'neutral' | 'good' | 'bad' | 'warning') => {
    switch (variant) {
      case 'good':
        return <ArrowIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'neutral':
        return <></>;
      default:
        return <MinusIcon fill={COLORS.typography.placeholder} />;
    }
  };

  return (
    <>
      <TabsList tabsNumber={tabs.length - 1}>
        {tabs.map((tab) => {
          const { label, variant } = tab;
          const active = slugify(label) === activeTab;
          return (
            <TabItem key={label} onClick={(e) => handleClick(e, label)}>
              <Box display="flex" flexDirection="column">
                <StyledTabLabelContainer
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                  px={2.5}
                  py={1.5}
                  active={active}
                  variant={variant}
                >
                  {getIcon(tab.variant)}
                  {label}
                </StyledTabLabelContainer>
                <TabSeparator active={active} variant={variant} />
              </Box>
            </TabItem>
          );
        })}
      </TabsList>
      {tabs.map((tab) => {
        if (slugify(tab.label) === activeTab) {
          return (
            <TabContent
              pb={3}
              pt={4.5}
              px={3}
              key={tab.label}
              tabVariant={tab.variant}
            >
              {tab.content}
            </TabContent>
          );
        } else return '';
      })}
    </>
  );
};
