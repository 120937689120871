import { FC } from 'react';
import { TableHeadProps } from './TableHead.d';
import { TableRow } from '@mui/material';
import {
  StyledFirstCell,
  StyledFirstTableCellContainer,
  StyledHeaderRow,
  StyledSelectedFundsCell,
  StyledTableCell,
  StyledTableCellContainer,
  StyledTableHead,
} from './TableHead.styled';
import { TableHeaderWithActions } from 'components/Modules/ComparisonListContent/TableHeaderWithActions';

export const TableHead: FC<TableHeadProps> = ({
  columns,
  requestReport,
  showAddFeedbackModal,
  showConfirmRemoveFromFundListModal,
  changeFundListStatus,
  showConfirmRemoveFromComparisonListModal,
  pinnedColumn,
  changePinnedColumn,
  moveColumn,
}) => {
  return (
    <StyledTableHead>
      <StyledHeaderRow>
        <StyledFirstCell width="234px" />
        {columns.map((_, index) => (
          <StyledTableCell
            key={`header-row-${index}`}
            align="left"
            width="325px"
          >
            <StyledFirstTableCellContainer />
          </StyledTableCell>
        ))}
      </StyledHeaderRow>
      <TableRow>
        <StyledSelectedFundsCell width="234px" />
        {columns.map((item, index) => (
          <StyledTableCell key={index} align="left" width="325px">
            <StyledTableCellContainer>
              <TableHeaderWithActions
                data={item}
                index={index}
                columnsLength={columns.length}
                requestReport={requestReport}
                showConfirmRemoveFromFundListModal={
                  showConfirmRemoveFromFundListModal
                }
                changeFundListStatus={changeFundListStatus}
                showAddFeedbackModal={showAddFeedbackModal}
                showConfirmRemoveFromComparisonListModal={
                  showConfirmRemoveFromComparisonListModal
                }
                pinnedColumn={pinnedColumn}
                changePinnedColumn={changePinnedColumn}
                moveColumn={moveColumn}
              />
            </StyledTableCellContainer>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
