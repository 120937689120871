import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { commonColumns, createRow, getDefaultFilterValue } from '../config';
import {
  fetchKompassFund,
  getCompassFilterChoices,
  PlansecurFundRequest,
  sendRequestFundPortfolio,
} from 'services/Plansecur';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { useProfile } from 'context/Profile';
import { ConfirmModal } from 'components/common/Modals/ConfirmModal';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { CommentsModal } from '../Modals/CommentsModal';
import { StyledTableChipContainer } from '../CustomerOverview.styled';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import {
  activeManagementOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  volumeOptions,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';
import { Score } from 'components/Modules/Score';
import { Rank } from 'components/Modules/Rank';
import { Vote } from 'components/Modules/Vote';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { ExpandableText } from 'components/common/ExpandableText';
import { MaintainAdditionalInfoModal } from '../Modals/MaintainAdditionalInfoModal/MaintainAdditionalInfoModal';
import { PlansecurFiltersModal } from 'components/Modules/Filters/PlansecurFiltersModal/PlansecurFiltersModal';
import { createPlansecurFilters, plansecurFilters } from '../filters.config';
import { PlansecurFiltersProps } from '../CustomerOverview';
import { FilterProps } from 'components/Modules/Filters/SelectedFilters/SelectedFilters.d';
import { convertDateFormat, toTitleCase } from 'utils/common';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { CompassProps } from './Compass.d';
import {
  BackToCurrentVersionButton,
  WarningMessage,
  WarningMessageContainer,
} from './Compass.styled';
import { ReactComponent as DangerIcon } from 'assets/Icons/danger-outlined.svg';

export const Compass: FC<CompassProps> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const profile = useProfile();
  const { customNavigate } = useNavigation();
  const profileInfo = profile?.profileInfo;
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const isPlansecurMessageReadAccess =
    profileInfo?.plansecur_message_read_access;
  const isPlansecurMessageWriteAccess =
    profileInfo?.plansecur_message_write_access;
  const isFondsconsultMessageReadAccess =
    profileInfo?.fondsconsult_message_read_access;
  const isFondsconsultMessageWriteAccess =
    profileInfo?.fondsconsult_message_write_access;
  const { search, pathname } = useLocation();
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '50', 10);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [
    isConfirmSendRequestOfFundPortrait,
    setIsConfirmSendRequestOfFundPortrait,
  ] = useState(false);
  const [isCommentsPlansecurModalOpen, setIsCommentsPlansecurModalOpen] =
    useState(false);
  const [isCommentsFondsconsultModalOpen, setIsCommentsFondsconsultModalOpen] =
    useState(false);
  const [
    isMaintainAdditionalInfoModalOpen,
    setIsMaintainAdditionalInfoModalOpen,
  ] = useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    isin: '',
    broker_notes: '',
    best_in_class_approach: '',
    norm_based_screening: '',
    trail_commission: '',
  });
  const [filtersConfig, setFiltersConfig] = useState(plansecurFilters);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const sfbColumn: ColumnProps = {
    id: 'fonds_consult_daten',
    label: 'FondsConsult Daten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'sfb_related_fund__peer_group',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'FCR Peergroup',
        sortable: true,
        minWidth: '148px',
        maxWidth: '148px',
      },
      {
        id: 'sfb_related_fund__rank__rank',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rang',
        sortable: true,
        sortKey: 'sfb_related_fund__rank__rank',
        tooltip:
          'Im Rahmen unserer proprietären Quant-Auswertung erzielter Rang des Fonds innerhalb seiner Vergleichsgruppe. Die Platzierung basiert auf diversen Rendite-/Risikokennzahlen über einen Zeitraum von einem bzw. drei Jahren.',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ row }) => (
          <Rank
            value={row.sfb_related_fund__rank__rank}
            maxValue={row.nr_of_funds}
          />
        ),
      },
      {
        id: 'recommendation_for_action',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Handlungs- \nempfehlung',
        tooltip:
          'Auf Grundlage quantitativer und qualitativer Analysen erarbeitete und beschlossene Empfehlungen des FondsConsult Investment Committees. (Mögliche Empfehlungen: BUY (Kaufen), HOLD (Halten), SWITCH (Verkaufen)).',
        sortable: true,
        minWidth: '110px',
        maxWidth: '110px',
        renderCell: ({ value }) => (
          <>{value === null ? '-' : <Vote value={value} />}</>
        ),
      },
      {
        id: 'assessment',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Begründung der abweichenden Einschätzung',
        sortable: true,
        minWidth: '300px',
        maxWidth: '300px',
        renderCell: ({ value }) => <ExpandableText text={value} />,
      },
      {
        id: 'sfb_related_fund__volume',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondsvolumen',
        sortable: true,
        tooltip:
          'Fondsvolumen eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Hoch (Top 30%), Mittel, Niedrig (Untere 30%).',
        minWidth: '156px',
        maxWidth: '156px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, volumeOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__track_record',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Track Record',
        sortable: true,
        tooltip:
          'Laufzeit eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Lang (Top 30%), Mittel, Kurz (Untere 30%).',
        minWidth: '115px',
        maxWidth: '115px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, trackRecordOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__return_score__total_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rendite',
        sortable: true,
        tooltip:
          'Historisch erzielte Rendite des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, returnOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_risk_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Risiko',
        sortable: true,
        tooltip:
          'Gemessen über die Volatilität, den max. Verlust, das Beta sowie die eigens entwickelte Downside Protection des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht, Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, riskOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_active_management_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Aktives Management',
        sortable: true,
        tooltip:
          'Gemessen über das zur Benchmark erzielte Alpha, sowie die Information und Appraisal Ratio des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            activeManagementOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__esg_scores__credibility_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Credibility',
        sortable: true,
        tooltip:
          'Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen wird.',
        minWidth: '127px',
        maxWidth: '127px',
        renderCell: ({ value }) => <Score value={value} />,
      },
      {
        id: 'esg_actions',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Actions (Fonds)',
        tooltip:
          'Bewertung der auf Fondsebene tatsächlich umgesetzten Nachhaltigkeitskriterien (u.a. PAIs). Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Berücksichtigung nachhaltiger Kriterien gesprochen wird. Mehr Informationen dazu finden Sie im Bereich ESG Credibility & Action Score.',
        sortable: true,
        minWidth: '127px',
        maxWidth: '127px',
      },
    ],
  };

  const plansecurColumn: ColumnProps = {
    id: 'plansecur',
    label: 'Plansecur',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'allocation',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Zuordnung',
        sortable: true,
        minWidth: '100px',
        maxWidth: '100px',
        renderCell: ({ value }) => {
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer style={{ width: 100 }}>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={TableChipVariant.GREY}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'adjustments',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Anpassung',
        sortable: true,
        minWidth: '135px',
        maxWidth: '135px',
      },
      {
        id: 'reviewer_notes',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Anmerkung',
        sortable: true,
        minWidth: '170px',
        maxWidth: '180px',
      },
      {
        id: 'broker_notes',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Hinweis für Vermittler',
        sortable: true,
        minWidth: '300px',
        maxWidth: '300px',
        renderCell: ({ value }) => (
          <>{value ? <ExpandableText text={value} /> : '-'}</>
        ),
      },
      {
        id: 'best_in_class_approach',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'B-i-C-/B-o-C-Ansatz',
        sortable: true,
        minWidth: '300px',
        maxWidth: '300px',
        renderCell: ({ value }) => (
          <>{value ? <ExpandableText text={value} /> : '-'}</>
        ),
      },
      {
        id: 'norm_based_screening',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Normbasiertes Screening',
        sortable: true,
        minWidth: '300px',
        maxWidth: '300px',
        renderCell: ({ value }) => (
          <>{value ? <ExpandableText text={value} /> : '-'}</>
        ),
      },
      {
        id: 'trail_commission',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Bestands-prov.',
        sortable: true,
        minWidth: '300px',
        maxWidth: '300px',
        renderCell: ({ value }) => (
          <>{value ? <ExpandableText text={value} /> : '-'}</>
        ),
      },
    ],
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    ...(!!selectedDate
      ? []
      : [
          {
            id: 'actions',
            label: '',
            tooltip: 'Actions',
            renderCell: ({ row }: { row: RowProps }) => (
              <Actions
                handlers={{
                  onFundPortraitHandle: () => {
                    setIsConfirmSendRequestOfFundPortrait(true);
                    setCurrentFund({
                      id: row.id,
                      name: row.name,
                      isin: row.isin,
                      broker_notes: '',
                      best_in_class_approach: '',
                      norm_based_screening: '',
                      trail_commission: '',
                    });
                  },
                  ...(isAdmin || isPlansecurAdmin
                    ? {
                        onMaintainAdditionalInfoChange: (
                          e: React.BaseSyntheticEvent,
                        ) => {
                          e.stopPropagation();
                          showMaintainAdditionalInfoModal({
                            name: row.name,
                            id: row.id,
                            isin: row.isin,
                            broker_notes: row.broker_notes ?? '',
                            best_in_class_approach:
                              row.best_in_class_approach ?? '',
                            norm_based_screening:
                              row.norm_based_screening ?? '',
                            trail_commission: row.trail_commission ?? '',
                          });
                        },
                      }
                    : {}),
                  ...(isPlansecurMessageReadAccess ||
                  isPlansecurMessageWriteAccess ||
                  isFondsconsultMessageWriteAccess ||
                  isFondsconsultMessageReadAccess
                    ? {
                        onPlansecurCommentsHandle:
                          isPlansecurMessageReadAccess ||
                          isPlansecurMessageWriteAccess
                            ? () => {
                                setIsCommentsPlansecurModalOpen(true);
                                setCurrentFund({
                                  id: row.id,
                                  name: row.name,
                                  isin: row.isin,
                                  broker_notes: '',
                                  best_in_class_approach: '',
                                  norm_based_screening: '',
                                  trail_commission: '',
                                });
                              }
                            : undefined,
                        onFundUpdateHandle:
                          isFondsconsultMessageWriteAccess ||
                          isFondsconsultMessageReadAccess
                            ? () => {
                                setIsCommentsFondsconsultModalOpen(true);
                                setCurrentFund({
                                  id: row.id,
                                  name: row.name,
                                  isin: row.isin,
                                  broker_notes: '',
                                  best_in_class_approach: '',
                                  norm_based_screening: '',
                                  trail_commission: '',
                                });
                              }
                            : undefined,
                      }
                    : {}),
                }}
                justifyContent="center"
                mr={0}
              />
            ),
            width: '120px',
            subColumns: [],
          },
        ]),
  ];

  const formatDataToSend = () => {
    const filters: PlansecurFundRequest = {};
    for (const filterCategory of plansecurFilters) {
      for (const filter of filterCategory.data) {
        if (!!filter.id) {
          const filterValues = searchParams.getAll(filter.id.toString());
          if (!!filterValues.length) {
            if (filter.type === 'range') {
              const [min, max] = filterValues[0].split('_');
              filters[`${String(filter.id)}_min`] = min;
              filters[`${String(filter.id)}_max`] = max;
            } else if (filter.type === 'date-range') {
              const [after, before] = filterValues[0].split('_');
              if (after && after.length > 0)
                filters[`${String(filter.id)}_after`] = after;
              if (before && before.length > 0)
                filters[`${String(filter.id)}_before`] = before;
            } else if (filter.type === 'chips-article') {
              filters[String(filter.id)] = filterValues.map(
                (item) => `Artikel ${item}`,
              );
            } else if (filter.type === 'chips-rating') {
              filters[String(filter.id)] = filterValues.map((item) =>
                item.toUpperCase(),
              );
            } else if (filter.type === 'checkboxes-others') {
              filterValues.forEach((item) => {
                filters[item] = 'true';
              });
            } else {
              filters[String(filter.id)] = filterValues;
            }
          }
        }
      }
    }
    return filters;
  };

  const getTableData = async () => {
    sortModel === null && setLoading(true);
    const filters = formatDataToSend();
    const data = {
      page,
      page_size: itemsPerPage,
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
      ...(selectedDate ? { historic_data: selectedDate } : {}),
    };
    const response = await fetchKompassFund({ ...data, ...filters });
    if (response.ok) {
      const tableData = response.parsedBody.results.funds;
      setResultsNumber(response.parsedBody.count);
      setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [itemsPerPage, searchValue, sortModel, selectedDate, searchParams]);

  useEffect(() => {
    const loadFilterOptions = async () => {
      try {
        setIsLoadingFilters(true);
        const response = await getCompassFilterChoices();
        const options = response.parsedBody;
        setFiltersConfig(createPlansecurFilters(options));
      } catch (error) {
        console.error('Failed to load filter options:', error);
      } finally {
        setIsLoadingFilters(false);
      }
    };

    loadFilterOptions();
  }, []);

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toogleConfirmModal = () => {
    setIsConfirmSendRequestOfFundPortrait(!isConfirmSendRequestOfFundPortrait);
  };

  const sendFundPortraitRequest = async () => {
    const response = await sendRequestFundPortfolio(currentFund.id);
    if (response.ok) {
      getTableData();
      isConfirmSendRequestOfFundPortrait &&
        setIsConfirmSendRequestOfFundPortrait(false);
      raiseToast.send(
        'Die Anfrage für das Fondsporträt wurde erfolgreich gesendet.',
      );
    }
  };

  const toggleCommentsModal = () => {
    isCommentsPlansecurModalOpen && setIsCommentsPlansecurModalOpen(false);
    isCommentsFondsconsultModalOpen &&
      setIsCommentsFondsconsultModalOpen(false);
  };

  const showMaintainAdditionalInfoModal = ({
    name,
    id,
    isin,
    broker_notes,
    best_in_class_approach,
    norm_based_screening,
    trail_commission,
  }: {
    name: string;
    id: string;
    isin: string;
    broker_notes: string;
    best_in_class_approach?: string;
    norm_based_screening?: string;
    trail_commission?: string;
  }) => {
    setIsMaintainAdditionalInfoModalOpen(true);
    setCurrentFund({
      id,
      name,
      isin,
      broker_notes,
      best_in_class_approach: best_in_class_approach || '',
      norm_based_screening: norm_based_screening || '',
      trail_commission: trail_commission || '',
    });
  };

  const getFilters = (filtersOptions: PlansecurFiltersProps[]) => {
    const filters: ({
      value: string;
      name: string;
      category: string;
    } & FilterProps)[] = [];

    const addFilter = (
      value: string,
      name: string,
      label: string,
      id: string,
      categoryName: string,
    ) => {
      filters.push({
        value,
        name: toTitleCase(name) || '',
        label,
        id,
        category: categoryName,
      });
    };

    const processFilterValue = (
      filterValue: string[],
      id: string,
      type: string,
      innerCategoryName: string,
      options: any[],
      categoryName: string,
    ) => {
      if (type === 'search' || type === 'star-rating') {
        addFilter(
          filterValue[0],
          filterValue[0],
          innerCategoryName,
          id,
          categoryName,
        );
        return;
      }

      if (type === 'range' || type === 'date-range') {
        if (filterValue && filterValue[0]) {
          const [min, max] = filterValue[0].split('_');
          const formatRange = (
            min?: string | null,
            max?: string | null,
          ): string => {
            if (min && max) return `${min} - ${max}`;
            if (min) return `${min}`;
            if (max) return `${max}`;
            return '';
          };
          addFilter(
            `${formatRange(min, max)}`,
            `${formatRange(min, max)}`,
            innerCategoryName,
            id,
            categoryName,
          );
        }
      }

      const result = options.filter((option) =>
        filterValue.includes(option.value.toLowerCase()),
      );
      result.forEach((item) => {
        const value = item?.value;
        const name =
          type === 'chips-article' ? `Artikel ${item?.name}` : item?.name;
        addFilter(value, name, innerCategoryName, id, categoryName);
      });
    };

    Object.values(filtersOptions).forEach((filter) =>
      Array.isArray(filter.data)
        ? filter.data.map(({ id, type, innerCategoryName, options }) => {
            const filterValue = searchParams.getAll(id.toString());
            if (filterValue) {
              processFilterValue(
                filterValue,
                id.toString(),
                type,
                innerCategoryName,
                options,
                filter.categoryName,
              );
            }
          })
        : null,
    );

    return filters;
  };

  const clearFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    customNavigate(`${pathname}${tabSearchParam ? `?${newSearchParams}` : ''}`);
  };

  return (
    <>
      <ConfirmModal
        title="Fondsporträt herunterladen"
        subtitle="Leider ist der Fondsbericht derzeit nicht verfügbar. Möchten Sie ihn anfordern? Lassen Sie es uns wissen, und wir senden ihn Ihnen, sobald er verfügbar ist!"
        isShown={isConfirmSendRequestOfFundPortrait}
        toggle={toogleConfirmModal}
        onSubmit={sendFundPortraitRequest}
        confirmButtonText="Fondsporträt anfordern"
      />
      <PlansecurFiltersModal
        isShow={openFilters}
        closeModal={() => setOpenFilters(false)}
        clearFilters={clearFilters}
        filters={filtersConfig}
        selectedFilters={getFilters(filtersConfig).filter(
          (item) => !!item.value,
        )}
        isLoading={isLoadingFilters}
      />
      <CommentsModal
        isShown={
          isCommentsPlansecurModalOpen || isCommentsFondsconsultModalOpen
        }
        variant={isCommentsPlansecurModalOpen ? 'plansecur' : 'fondsconsult'}
        fundName={currentFund.name}
        isin={currentFund.isin}
        fundId={currentFund.id}
        closeModal={toggleCommentsModal}
      />
      <MaintainAdditionalInfoModal
        isShown={isMaintainAdditionalInfoModalOpen}
        fundName={currentFund.name}
        isin={currentFund.isin}
        fundId={currentFund.id}
        initialValues={{
          broker_notes: currentFund.broker_notes,
          best_in_class_approach: currentFund.best_in_class_approach,
          norm_based_screening: currentFund.norm_based_screening,
          trail_commission: currentFund.trail_commission,
        }}
        title="Zusatzinfos pflegen"
        confirmButtonText="Speichern"
        toggle={() =>
          setIsMaintainAdditionalInfoModalOpen(
            !isMaintainAdditionalInfoModalOpen,
          )
        }
        handleSubmitSend={getTableData}
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={0}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        onExportClick={() => {}}
      >
        {!!selectedDate && (
          <WarningMessageContainer>
            <DangerIcon />
            <Box display="flex">
              <WarningMessage variant="body">
                Sie sehen eine historische Version vom{' '}
                <span>{convertDateFormat(selectedDate)}</span>. Diese Version
                ist schreibgeschützt. Wechseln Sie zur aktuellen Version, um
                Änderungen vorzunehmen.
              </WarningMessage>
              <BackToCurrentVersionButton onClick={() => setSelectedDate(null)}>
                Zurück zur aktuellen version.
              </BackToCurrentVersionButton>
            </Box>
          </WarningMessageContainer>
        )}
        <SelectedFilters
          filters={getFilters(filtersConfig)}
          clearFilters={clearFilters}
        />
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
            />
          )}
          <PortfolioCollapseTable
            isLastColumnsFixed={!selectedDate}
            loading={loading}
            tableData={{
              rows,
              columns: [
                columns[0],
                sfbColumn,
                plansecurColumn,
                ...columns.slice(1),
              ],
            }}
            selectedRows={selectedRows}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination pagesNumber={getPagesNumber()} />
      </ModuleLayout>
    </>
  );
};
