import { COLORS } from 'theme/colors';
import { GradeColorProps } from './LetterBadge.d';
import { hexToRGB } from 'utils/common';

export const defaultGradeColors: GradeColorProps[] = [
  {
    grade: 'A',
    backgroundColor: COLORS.success.bulletPoint,
    borderColor: COLORS.success.bulletPoint,
    textColor: COLORS.background.secondary,
  },
  {
    grade: 'B',
    backgroundColor: hexToRGB(COLORS.success.bulletPoint, '0.15'),
    borderColor: hexToRGB(COLORS.success.dark, '0.15'),
    textColor: COLORS.success.dark,
  },
  {
    grade: 'C',
    backgroundColor: COLORS.background.module,
    borderColor: COLORS.stroke.primary,
    textColor: COLORS.typography.description,
  },
  {
    grade: 'D',
    backgroundColor: hexToRGB(COLORS.error.background, '0.2'),
    borderColor: hexToRGB(COLORS.error.background, '0.2'),
    textColor: COLORS.error.dark,
  },
  {
    grade: 'E',
    backgroundColor: COLORS.error.background,
    borderColor: COLORS.error.background,
    textColor: COLORS.background.secondary,
  },
  {
    grade: '1',
    backgroundColor: hexToRGB(COLORS.success.bulletPoint, '0.15'),
    borderColor: hexToRGB(COLORS.success.dark, '0.15'),
    textColor: COLORS.success.dark,
  },
  {
    grade: '2',
    backgroundColor: hexToRGB(COLORS.success.bulletPoint, '0.15'),
    borderColor: hexToRGB(COLORS.success.dark, '0.15'),
    textColor: COLORS.success.dark,
  },
  {
    grade: '3',
    backgroundColor: hexToRGB(COLORS.warning.primary, '0.2'),
    borderColor: hexToRGB(COLORS.warning.primary, '0.2'),
    textColor: COLORS.warning.chip,
  },
  {
    grade: '4',
    backgroundColor: hexToRGB(COLORS.error.background, '0.2'),
    borderColor: hexToRGB(COLORS.error.background, '0.2'),
    textColor: COLORS.error.dark,
  },
];
