import React, { forwardRef, useEffect, useState } from 'react';
import { DateInputProps } from './DateInput.d';
import { Input } from '../Input';

const MIN_VALID_YEAR = 1900;

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ label, maxDate, minDate, value, onDateChange, error, ...props }, ref) => {
    const [dateError, setDateError] = useState<string | undefined>(error);
    const [internalValue, setInternalValue] = useState(value || '');

    const formatDateForInput = (dateStr?: string | null): string => {
      if (!dateStr) return '';

      try {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) return '';

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
      } catch {
        return '';
      }
    };

    const parseInputDate = (inputValue: string): Date | null => {
      if (!inputValue) return null;

      const [year, month, day] = inputValue.split('-').map(Number);
      const date = new Date(year, month - 1, day, 12, 0, 0);

      return isNaN(date.getTime()) ? null : date;
    };

    const validateDate = (inputValue: string) => {
      if (!inputValue) {
        setDateError(undefined);
        onDateChange?.(null);
        return;
      }

      if (!/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
        setDateError(undefined);
        return;
      }

      const date = parseInputDate(inputValue);

      if (!date) {
        setDateError('Ungültiges Datum');
        return;
      }

      if (maxDate && date > maxDate) {
        setDateError(
          `Datum darf nicht später als ${maxDate.toLocaleDateString(
            'de-DE',
          )} sein`,
        );
        return;
      }

      if (minDate && date < minDate) {
        setDateError(
          `Datum darf nicht früher als ${minDate.toLocaleDateString(
            'de-DE',
          )} sein`,
        );
        return;
      }

      setDateError(undefined);
      onDateChange?.(date);
    };

    const isCompleteDate = (dateStr: string) => {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return false;
      }

      const date = new Date(dateStr);
      const year = date.getFullYear();

      return !isNaN(date.getTime()) && year >= MIN_VALID_YEAR && year <= 9999;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setInternalValue(newValue);
      if (isCompleteDate(newValue)) {
        validateDate(newValue);
        props.onChange?.(e);
      }
    };

    useEffect(() => {
      setInternalValue(value || '');
      if (!value) {
        setDateError(undefined);
      }
    }, [value]);

    return (
      <Input
        type="date"
        label={label}
        error={dateError || error}
        ref={ref}
        value={internalValue || formatDateForInput(value)}
        {...props}
        onChange={handleChange}
      />
    );
  },
);
