import { useState, FC, useEffect } from 'react';
import { TabsType } from './Tabs.d';
import { TabDivider, TabItem, TabSeparator, TabsList } from './Tabs.styled';
import { slugify } from 'utils/common';
import { Box } from 'components/common/Box';
import { useSearchParams } from 'react-router-dom';

export const Tabs: FC<TabsType> = ({
  tabs,
  rightHeaderChildren,
  onTabChange,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || '';
  const [activeTab, setActiveTab] = useState(slugify(tabs[0].label));

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    newActiveTab: string,
  ) => {
    e.preventDefault();
    setActiveTab(slugify(newActiveTab));
    onTabChange && onTabChange(newActiveTab);
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('tab', newActiveTab);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (tab.length !== 0) {
      setActiveTab(slugify(tab));
    }
  }, [tab]);

  return (
    <div>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <TabsList>
          {tabs.map((tab, index) => {
            const { label } = tab;
            const active = slugify(label) === activeTab;
            return (
              <TabItem
                key={label}
                onClick={(e) => handleClick(e, label)}
                active={slugify(label) === activeTab}
              >
                <Box display="flex" gap={3}>
                  <Box display="flex" flexDirection="column">
                    {label}
                    <TabSeparator mt={0.9} active={active} />
                  </Box>
                  {index !== tabs.length - 1 && <TabDivider />}
                </Box>
              </TabItem>
            );
          })}
        </TabsList>
        {rightHeaderChildren}
      </Box>
      {tabs.map((tab) => {
        if (slugify(tab.label) === activeTab)
          return <Box key={tab.label}>{tab.content}</Box>;
      })}
    </div>
  );
};
