import { FC, useEffect, useState } from 'react';
import { DashboardCardItemProps, DashboardCardProps } from './DashboardCard.d';
import { Box } from 'components/common/Box';
import { StyledContainer } from './DashboardCard.styled';
import { ModeSwitcher } from 'components/common/ModeSwitcher';
import { Typography } from 'components/common/Typography';
import { DashboardCardItem } from './DashboardCardItem';
import { fetchKompassFund } from 'services/Plansecur';

const ITEMS_TO_SHOW_COUNT = 15;
const DEFAULT_MAX_VALUE = 20;

export const DashboardCard: FC<DashboardCardProps> = ({
  title,
  link,
  isFlop,
}) => {
  const [modeSwitcherValue, setModeSwitcherValue] = useState({
    value: 'sharpe_ratio_5_years',
    name: '5 Jahre',
    shortName: '5 J',
  });
  const [funds, setFunds] = useState<DashboardCardItemProps[]>([]);
  const modeSwitcherOptions = [
    { value: 'sharpe_ratio_1_year', name: '1 Jahr', shortName: '1 J' },
    { value: 'sharpe_ratio_3_years', name: '3 Jahre', shortName: '3 J' },
    { value: 'sharpe_ratio_5_years', name: '5 Jahre', shortName: '5 J' },
  ];
  const [maxValue, setMaxValue] = useState(DEFAULT_MAX_VALUE);

  const getCurrentValue = (value: DashboardCardItemProps) => {
    const key = modeSwitcherValue.value;
    return value.name ? value[key as keyof DashboardCardItemProps] : null;
  };

  const getFundsData = async () => {
    const response = await fetchKompassFund({
      page: 1,
      page_size: ITEMS_TO_SHOW_COUNT,
      sort_by: modeSwitcherValue.value,
      sort_order: isFlop ? 'desc' : 'asc',
    });
    if (response.ok) {
      const data = response.parsedBody.results.funds;
      const newFunds = data.map((item) => ({
        id: item.id,
        name: item.name,
        sharpe_ratio_1_year: item.sharpe_ratio_1_year || 0,
        sharpe_ratio_3_years: item.sharpe_ratio_3_years || 0,
        sharpe_ratio_5_years: item.sharpe_ratio_5_years || 0,
      }));
      setFunds(newFunds);
      const [newMaxValue] = newFunds.map((item) => getCurrentValue(item));
      newMaxValue && setMaxValue(Math.abs(Number(newMaxValue)));
    }
  };

  useEffect(() => {
    getFundsData();
  }, [modeSwitcherValue, isFlop]);

  return (
    <StyledContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h6">
          {title || ''} {modeSwitcherValue.name}
        </Typography>
        <Box>
          <ModeSwitcher
            value={modeSwitcherValue}
            setValue={setModeSwitcherValue}
            options={modeSwitcherOptions}
            disabled={funds.length === 0}
          />
        </Box>
      </Box>
      {funds.length === 0 ? (
        <Box pt={3}>
          <Typography variant="body" textAlign="center">
            Keine Daten gefunden
          </Typography>
        </Box>
      ) : (
        <Box pt={3} display="flex" flexDirection="column" gap={1}>
          {funds.map((item) => (
            <DashboardCardItem
              isWarning={isFlop}
              maxValue={maxValue}
              key={item.id}
              id={item.id}
              name={item.name}
              value={Number(getCurrentValue(item))}
            />
          ))}
          {funds.length < ITEMS_TO_SHOW_COUNT && (
            <DashboardCardItem
              isWarning={isFlop}
              maxValue={maxValue}
              key="random"
              id="random"
              name="..."
              value={null}
            />
          )}
        </Box>
      )}
    </StyledContainer>
  );
};
