import { FC } from 'react';
import { NameColumnProps } from './NameColumn.d';
import { StyledType, StyledContainer, StyledName } from './NameColumn.styled';

export const NameColumn: FC<NameColumnProps> = ({
  variant,
  name,
  type,
  isin,
  category,
}) => {
  const isSmallVariant = variant === 'small';

  const getContent = () => {
    if (isin) {
      return isin;
    }
    if (type && category) return `${type} / ${category}`;
    return type !== undefined ? type : category !== undefined ? category : '';
  };

  return (
    <StyledContainer
      isSmallVariant={isSmallVariant}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={1}
      py={isSmallVariant ? 0 : 1}
    >
      <StyledName
        isSmallVariant={isSmallVariant}
        variant="subtitle"
        weight="semibold"
      >
        {name}
      </StyledName>
      <StyledType isSmallVariant={isSmallVariant} variant="body">
        {getContent()}
      </StyledType>
    </StyledContainer>
  );
};
