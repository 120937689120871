import { forwardRef } from 'react';
import { TextareaProps } from './Textarea.d';
import { StyledTextarea, StyledErrorText } from './Textarea.styled';
import { Box } from '../Box';
import { Label } from '../Label';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ error, label, id, disabled, required, name = id, ...props }, ref) => {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        {label && (
          <Label
            htmlFor={id}
            disabled={disabled}
            required={required}
            error={!!error}
          >
            {label}
          </Label>
        )}
        <StyledTextarea
          id={id}
          name={name}
          disabled={disabled}
          error={!!error}
          ref={ref}
          required={required}
          {...props}
        />
        {error && <StyledErrorText>{error}</StyledErrorText>}
      </Box>
    );
  },
);

Textarea.displayName = 'Textarea';
