import { FC } from 'react';
import { OverviewDetailProps } from 'views/MainPage/MainPage.d';
import {
  StyledContainer,
  LabelContainer,
  StyledArrowIcon,
  StyledChip,
  StyledTypography,
  StyledExternalArrowIcon,
} from './OverviewItem.styled';
import { Box } from 'components/common/Box';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { ReactComponent as ExternalArrowIcon } from 'assets/Icons/external-link.svg';
import { COLORS } from 'theme/colors';

export const OverviewItem: FC<OverviewDetailProps> = ({
  label,
  link,
  chips,
  hoverImageLink,
  backgroundColor,
  chipStyles,
  fullWidth,
  externalLink,
  disabled,
  onClick,
}) => {
  const { customNavigate } = useNavigation();

  const chipsComponent = (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {chips.map((chip) => (
        <StyledChip
          label={chip}
          customColor={chipStyles?.textColor}
          customBackground={chipStyles?.backgroundColor}
          customBorderBolor={chipStyles?.borderColor}
        />
      ))}
    </Box>
  );

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    customNavigate(link);
    onClick && onClick();
  };

  return (
    <StyledContainer
      to={link}
      hoverImageLink={hoverImageLink}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
      onClick={handleClick}
      disabled={disabled}
    >
      <Box
        display="flex"
        alignItems={fullWidth ? 'center' : 'flex-start'}
        justifyContent={fullWidth ? 'flex-end' : 'space-between'}
        style={{ ...(fullWidth ? { flex: '1' } : {}) }}
      >
        {!fullWidth && chipsComponent}
        {externalLink ? (
          <StyledExternalArrowIcon>
            <ExternalArrowIcon stroke={COLORS.typography.primary} />
          </StyledExternalArrowIcon>
        ) : (
          <StyledArrowIcon to={link} />
        )}
      </Box>
      <LabelContainer>
        <Box display="flex" gap={2} alignItems="center">
          <StyledTypography variant="h4">{label}</StyledTypography>
          {fullWidth && chipsComponent}
        </Box>
      </LabelContainer>
    </StyledContainer>
  );
};
