import { FC, useState } from 'react';
import { TableHeaderWithActionsProps } from './TableHeaderWithActions.d';
import { Box } from 'components/common/Box';
import {
  StyledArrow,
  StyledArrowContainer,
  StyledContainer,
  StyledMoreActionsButton,
  StyledName,
  StyledPinButton,
  StyledPoint,
} from './TableHeaderWithActions.styled';
import { ReactComponent as MoreActionsIcon } from 'assets/Icons/more-actions.svg';
import { ReactComponent as PinIcon } from 'assets/Icons/pin-icon.svg';
import { ReactComponent as UnPinIcon } from 'assets/Icons/pinned-icon.svg';
import { COLORS } from 'theme/colors';
import { ExpandedActions } from 'components/Modules/Actions/ExpandedActions';
import { CustomTooltip } from 'components/common/Tooltip';
import { ReactComponent as ArrowIcon } from 'assets/Icons/collapse-arrow.svg';
import { AddFundToExactWatchlistModal } from 'components/common/Modals/AddFundToExactWatchlistModal';

export const TableHeaderWithActions: FC<TableHeaderWithActionsProps> = ({
  data,
  index,
  columnsLength,
  requestReport,
  showConfirmRemoveFromFundListModal,
  changeFundListStatus,
  showAddFeedbackModal,
  showConfirmRemoveFromComparisonListModal,
  pinnedColumn,
  changePinnedColumn,
  moveColumn,
}) => {
  const isSecondColumn = index === 1;
  const isLastColumn = index === columnsLength - 1;
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);
  const [
    isAddingFundToWatchlistModalOpen,
    setIsAddingFundToWatchlistModalOpen,
  ] = useState(false);

  const handleMouseEnter = () => setIsHeaderHovered(true);
  const handleMouseLeave = () => setIsHeaderHovered(false);

  const openMoreActions = Boolean(moreActionsAnchorEl);
  const isColumnPinned = pinnedColumn?.id === data?.id;

  const handleClickMoreActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const showAddFundToWatchlistModal = () => {
    setIsAddingFundToWatchlistModalOpen(true);
  };

  return (
    <StyledContainer
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={1.5}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AddFundToExactWatchlistModal
        isShown={isAddingFundToWatchlistModalOpen}
        toggle={() =>
          setIsAddingFundToWatchlistModalOpen(!isAddingFundToWatchlistModalOpen)
        }
        currentFund={{
          id: data.id,
          name: data.name,
          watchlists: data.watchlists,
        }}
        variant="smart-benchmarking"
      />
      {isHeaderHovered && !isColumnPinned && columnsLength > 2 && (
        <StyledArrowContainer display="flex" gap={1} alignItems="center">
          <StyledArrow
            disabled={isSecondColumn}
            onClick={() => moveColumn(data.id, 'left')}
          >
            <ArrowIcon
              stroke={
                isSecondColumn
                  ? COLORS.stroke.disabled
                  : COLORS.background.secondary
              }
              style={{ transform: 'rotate(180deg)' }}
            />
          </StyledArrow>
          <StyledArrow
            disabled={isLastColumn}
            onClick={() => moveColumn(data.id, 'right')}
          >
            <ArrowIcon
              stroke={
                isLastColumn
                  ? COLORS.stroke.disabled
                  : COLORS.background.secondary
              }
            />
          </StyledArrow>
        </StyledArrowContainer>
      )}
      <Box display="flex" gap={1.5}>
        <StyledPoint color={data?.color || COLORS.background.neutral} />
        <StyledName variant="body">{data?.name || '-'}</StyledName>
      </Box>
      <Box display="flex" gap={1.5}>
        <StyledPinButton
          isPinned={isColumnPinned}
          disabled={isColumnPinned}
          onClick={() =>
            isColumnPinned ? undefined : changePinnedColumn(data)
          }
        >
          {isColumnPinned ? (
            <UnPinIcon />
          ) : (
            <CustomTooltip title="Sperren als erstes" placement="top">
              <PinIcon stroke={COLORS.typography.placeholder} />
            </CustomTooltip>
          )}
        </StyledPinButton>
        <StyledMoreActionsButton onClick={handleClickMoreActions}>
          <MoreActionsIcon
            stroke={
              openMoreActions ? COLORS.accent.primary : COLORS.typography.main
            }
          />
        </StyledMoreActionsButton>
        <ExpandedActions
          open={openMoreActions}
          isInMyFundList={false}
          isInComparisonList
          setAnchorEl={setMoreActionsAnchorEl}
          anchorEl={moreActionsAnchorEl}
          onRequestReportHandler={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            requestReport(data.id, data.name);
          }}
          onChangeFundListHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            showAddFundToWatchlistModal();
          }}
          onFeedbackHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            showAddFeedbackModal(data.id, data.name);
          }}
          onChangeComparisonListHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            showConfirmRemoveFromComparisonListModal(data.id, data.name);
          }}
        />
      </Box>
    </StyledContainer>
  );
};
