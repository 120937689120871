import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const WarningMessageContainer = styled(Box)`
  background-color: ${COLORS.info.selectedBorder};
  border-radius: 4px;
  margin: 8px 0;
  padding: 2.5px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SIZES.spacing(2)};
`;

export const WarningMessage = styled(Typography)`
  color: ${COLORS.background.neutral};
  font-size: 12px;

  & span {
    font-weight: 700;
  }
`;

export const BackToCurrentVersionButton = styled.button`
  border: none;
  color: ${COLORS.background.neutral};
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 12px;
  transition-duration: 0.2s;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:enabled {
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: ${COLORS.background.secondary};
    }
  }
`;
