import { forwardRef, useEffect, useState } from 'react';
import { ItemsListModalProps, ListItem } from './ItemsListModal.d';
import { Modal } from 'components/common/Modal/Modal';
import {
  StyledContainer,
  StyledContent,
  StyledLeftContainer,
  StyledItemsTypography,
  StyledSearchContainer,
} from './ItemsListModal.styled';
import { Search } from 'components/common/Search';
import { ButtonWithPlus } from 'components/common/Buttons/ButtonWithPlus';
import { Box } from 'components/common/Box';
import { ConfirmModal } from '../ConfirmModal';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Typography } from 'components/common/Typography';
import { ItemsListTable } from './ItemsListTable';
import { CreateItemModal } from './CreateItemModal';

export const ItemsListModal = forwardRef<HTMLDivElement, ItemsListModalProps>(
  (
    {
      isShown,
      items,
      getItems,
      selectedItem,
      setSelectedItem,
      toggle,
      icon,
      modalTitle = 'Items',
      listTitle = 'My Items',
      createButtonLabel = 'Create Item',
      searchPlaceholder = 'Search items',
      emptyStateMessage = 'No data found',
      createItemTitle = 'Create item',
      deleteItemTitle = 'Delete item',
      deleteItemSubtitle = 'Are you sure you want to delete',
      confirmButtonText = 'Yes, Remove',
      createSuccessMessage = 'Item successfully created.',
      editSuccessMessage = 'Item successfully edited.',
      deleteSuccessMessage = 'Item successfully deleted.',
      itemsCountHeader,
      listsHeader = 'Erstellte Listen',
      addItem,
      editItem,
      deleteItem,
    },
    ref,
  ) => {
    const [isCreateItemModalShown, toggleCreateItemModal] = useState(false);
    const [isConfirmRemoveModalShown, toggleConfirmRemoveModal] =
      useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentItem, setCurrentItem] = useState<ListItem>();
    const [filteredItems, setFilteredItems] = useState<ListItem[] | null>(
      items,
    );
    const [stableSelectedItem, setStableSelectedItem] = useState(selectedItem);

    const openConfirmationModal = (item: ListItem) => {
      setCurrentItem(item);
      toggleConfirmRemoveModal(true);
    };

    useEffect(() => {
      if (selectedItem !== stableSelectedItem) {
        setStableSelectedItem(selectedItem);
      }
    }, [selectedItem]);

    useEffect(() => {
      items &&
        setFilteredItems(
          items.filter((item) =>
            item.name.toLowerCase().includes(searchValue.toLowerCase()),
          ),
        );
    }, [searchValue, items]);

    const createNewItem = async (name: string) => {
      if (addItem) {
        const response = await addItem(name);
        if (response.ok) {
          toggleCreateItemModal(false);
          toggle();
          raiseToast.success(createSuccessMessage);
          getItems();
        }
      }
    };

    const editItemName = async (itemId: string, newName: string) => {
      const response = await editItem(itemId, newName);
      if (response.ok) {
        raiseToast.success(editSuccessMessage);

        if (selectedItem && selectedItem.id === itemId) {
          const updatedItem = { ...selectedItem, name: newName };
          setSelectedItem(updatedItem);
        }

        getItems();
      }
    };

    const removeItem = async () => {
      if (!currentItem) return;
      const isDeletingSelectedItem =
        selectedItem && currentItem.id === selectedItem.id;

      const response = await deleteItem(currentItem?.id);
      if (response.ok) {
        toggleConfirmRemoveModal(false);
        toggle();
        raiseToast.success(deleteSuccessMessage);
        if (isDeletingSelectedItem) {
          setSelectedItem(null);
        }
        getItems();
      }
    };

    return (
      <>
        {addItem && (
          <CreateItemModal
            isShown={isCreateItemModalShown}
            toggle={() => {
              toggleCreateItemModal(false);
              toggle();
            }}
            onSubmit={createNewItem}
            title={createItemTitle}
          />
        )}
        <ConfirmModal
          title={deleteItemTitle}
          isWarning
          subtitle={`${deleteItemSubtitle} ${currentItem?.name}?`}
          isShown={isConfirmRemoveModalShown}
          toggle={() => {
            toggleConfirmRemoveModal(!isConfirmRemoveModalShown);
            toggle();
          }}
          onSubmit={removeItem}
          confirmButtonText={confirmButtonText}
        />
        <Modal
          modalRef={ref}
          isShown={isShown}
          onClose={toggle}
          withCloseButton
          title={modalTitle}
          p={0}
          headerPadding="17px 32px"
          borderRadius="8px"
        >
          <StyledContainer>
            <StyledSearchContainer>
              <Search
                initialValue={searchValue}
                onSubmit={setSearchValue}
                placeholder={searchPlaceholder}
                withoutBorder
              />
            </StyledSearchContainer>
            <StyledContent>
              <StyledLeftContainer>
                <Box display="flex" gap={1} alignItems="center">
                  {icon && icon}
                  <StyledItemsTypography variant="body">
                    {listTitle}
                  </StyledItemsTypography>
                </Box>
                {addItem && (
                  <ButtonWithPlus
                    variant="primary"
                    label={createButtonLabel}
                    onClick={() => {
                      toggleCreateItemModal(true);
                      toggle();
                    }}
                  />
                )}
              </StyledLeftContainer>
              <Box>
                {filteredItems ? (
                  <ItemsListTable
                    rows={filteredItems}
                    selectedItem={stableSelectedItem}
                    itemsCountHeader={itemsCountHeader}
                    listsHeader={listsHeader}
                    openConfirmationModal={openConfirmationModal}
                    handleItemEdit={editItemName}
                    handleSelectedItemChange={setSelectedItem}
                  />
                ) : (
                  <Box pt={4} display="flex" justifyContent="center">
                    <Typography variant="body">{emptyStateMessage}</Typography>
                  </Box>
                )}
              </Box>
            </StyledContent>
          </StyledContainer>
        </Modal>
      </>
    );
  },
);
