import { useEffect } from 'react';

const TIDIO_CHAT_SCRIPT_URL =
  '//code.tidio.co/diyonry54nlwftbioqpjrcrxogzt6jdi.js';

export const TidioChat = () => {
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const isProduction = env === 'production';

  useEffect(() => {
    if (isProduction) {
      const script = document.createElement('script');
      script.src = TIDIO_CHAT_SCRIPT_URL;
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      if (isProduction) {
        const script = document.querySelector(
          `script[src="${TIDIO_CHAT_SCRIPT_URL}"]`,
        );
        if (script) {
          document.body.removeChild(script);
        }
      }
    };
  }, []);

  return null;
};
