import { FC, useEffect, useState } from 'react';
import { HelpBoxProps } from './HelpBox.d';
import {
  HelpBoxWrapper,
  StyledAnalystDeskButton,
  StyledArrowIcon,
  StyledIconContainer,
  StyledLeftContainer,
} from './HelpBox.styled';
import { COLORS } from 'theme/colors';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ChatCategory,
  ChatCloseAction,
  ChatOpenAction,
} from 'utils/google-analytics/events/chat';
import { Box } from '../Box';
import { ConsultantItem } from 'components/Overview/AnalystServiceDeskSection/ConsultantItem';
import { network } from './config';
import { Typography } from '../Typography';
import { ChatIconButton } from 'components/common/IconButtons/ChatIconButton';

export const HelpBox: FC<HelpBoxProps> = ({
  helpBoxText,
  variant = 'small',
}) => {
  const isSmallVariant = variant === 'small';
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const isProduction = env === 'production';
  const [isChatOpen, setIsChatOpen] = useState(false);

  const onTidioChatApiReady = () => {
    window.tidioChatApi.hide();
    setIsChatOpen(false);
  };

  useEffect(() => {
    if (isProduction && window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
      window.tidioChatApi.on('close', onTidioChatApiReady);
    }
  }, [window.tidioChatApi]);

  const toggleChat = () => {
    if (isChatOpen) {
      window.tidioChatApi.hide();
      setIsChatOpen(false);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatCloseAction,
        window.location.pathname,
      );
    } else {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
      setIsChatOpen(true);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatOpenAction,
        window.location.pathname,
      );
    }
  };

  return (
    <HelpBoxWrapper
      isSmallVariant={isSmallVariant}
      mt={3}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <StyledLeftContainer display="flex" gap={4} alignItems="center">
        {!isSmallVariant && (
          <Box display="inline-flex" pr={0.5} pl={1.5} alignItems="center">
            {network.map((consultant) => (
              <ConsultantItem
                key={consultant.fullName}
                fullName={consultant.fullName}
                imageLink={consultant.imageLink}
                position={consultant.position}
                variant="large"
              />
            ))}
            {!isSmallVariant && (
              <StyledIconContainer
                key="chat"
                display="flex"
                justifyContent="center"
              >
                <ChatIconButton
                  onClick={isProduction ? toggleChat : undefined}
                />
              </StyledIconContainer>
            )}
          </Box>
        )}
        <Typography
          variant={isSmallVariant ? 'h6' : 'h5'}
          color={
            isSmallVariant
              ? COLORS.background.secondary
              : COLORS.typography.main
          }
        >
          {helpBoxText ||
            'Bitte kontaktieren Sie uns, falls Sie mehr Informationen oder Hilfe benötigen.'}
        </Typography>
      </StyledLeftContainer>
      <StyledAnalystDeskButton
        rightIcon={<StyledArrowIcon />}
        isSmallVariant={isSmallVariant}
        onClick={toggleChat}
      >
        Analyst Service Desk
      </StyledAnalystDeskButton>
    </HelpBoxWrapper>
  );
};
