import {
  ADD_FUNDS_TO_FAVORITES_ESG,
  CHANGE_FUND_LIST_STATUS_ESG_AND_ACTION_SCORE,
  ESG_AND_ACTION_SCORE,
  ESG_AND_ACTION_SCORE_FILTER_PRESET,
  FAVORITES_LIST,
  FEEDBACK_ESG_AND_ACTION_SCORE,
  REPORT_ERROR_ESG,
  REQUEST_ATTRIBUTION_ESG,
  REQUEST_PRODUCT_PRESENTATION_ESG,
  REQUEST_REPORT_ESG_AND_ACTION_SCORE,
} from 'urls/api';
import fetchAPI from './fetchApi';
import { ESGAndActionScoreItemProps } from 'components/Modules/Tables/ESGCredibilityActionScoreTable/ESGCredibilityActionScoreTable.d';
import { createQueryParams } from 'utils/common';

type ESGAndActionScoreFunds = {
  funds: ESGAndActionScoreItemProps[];
};

interface ESGAndActionScoreResponse extends Response {
  results: ESGAndActionScoreFunds;
  count: number;
}

interface ESGAndActionScoreRequest {
  watchlistId?: string;
  page?: number;
  page_size?: number;
  credibility_min?: string;
  credibility_max?: string;
  action_min?: string;
  action_max?: string;
  article_8_min?: string;
  article_8_max?: string;
  article_9_min?: string;
  article_9_max?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
  name?: string;
}

interface FeedbackESGRequest {
  id: string;
  text: string;
}

interface FilterPresetOptionResponse extends Response {
  name: string;
  id: string;
  query_params: {
    [key: string]: string;
  };
}

export const fetchESGAndActionScore = ({
  page,
  page_size,
  credibility_min,
  credibility_max,
  action_min,
  action_max,
  article_8_min,
  article_8_max,
  article_9_min,
  article_9_max,
  sort_by,
  sort_order,
  search,
}: ESGAndActionScoreRequest) => {
  const fetchESGAndActionScore = fetchAPI<ESGAndActionScoreResponse>(
    `${ESG_AND_ACTION_SCORE}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${action_min ? `&action_min=${action_min}` : ''}${
      action_max ? `&action_max=${action_max}` : ''
    }${article_8_min ? `&article_8_min=${article_8_min}` : ''}${
      article_8_max ? `&article_8_max=${article_8_max}` : ''
    }${article_9_min ? `&article_9_min=${article_9_min}` : ''}${
      article_9_max ? `&article_9_max=${article_9_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchESGAndActionScore;
};

export const fetchFavoritesESGAndActionScore = ({
  watchlistId,
  page,
  page_size,
  credibility_min,
  credibility_max,
  action_min,
  action_max,
  article_8_min,
  article_8_max,
  article_9_min,
  article_9_max,
  sort_by,
  sort_order,
  search,
}: ESGAndActionScoreRequest) => {
  const fetchESGAndActionScore = fetchAPI<ESGAndActionScoreResponse>(
    `${FAVORITES_LIST}?module=action_score${
      watchlistId ? `&watchlist_id=${watchlistId}` : ''
    }${page ? `&page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${action_min ? `&action_min=${action_min}` : ''}${
      action_max ? `&action_max=${action_max}` : ''
    }${article_8_min ? `&article_8_min=${article_8_min}` : ''}${
      article_8_max ? `&article_8_max=${article_8_max}` : ''
    }${article_9_min ? `&article_9_min=${article_9_min}` : ''}${
      article_9_max ? `&article_9_max=${article_9_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchESGAndActionScore;
};

export const fetchUpdateFundListStatusESG = (
  fundId: string,
  watchlistId: string,
) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_ESG_AND_ACTION_SCORE(fundId), {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ watchlist_id: watchlistId }),
  });

export const sendRequestReportESGFund = (id: string) =>
  fetchAPI(REQUEST_REPORT_ESG_AND_ACTION_SCORE(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistEsg = (
  watchlist_id: string,
  ids: string[],
) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_ESG, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ watchlist_id, funds: ids }),
  });

export const fetchSendFundFeedbackESG = ({ id, text }: FeedbackESGRequest) =>
  fetchAPI(FEEDBACK_ESG_AND_ACTION_SCORE(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text }),
  });

export const fetchFilterPresetOptions = () =>
  fetchAPI<FilterPresetOptionResponse[]>(ESG_AND_ACTION_SCORE_FILTER_PRESET);

export const fetchAddFilterPreset = ({
  name,
  credibility_min,
  credibility_max,
  action_min,
  action_max,
  article_8_min,
  article_8_max,
  article_9_min,
  article_9_max,
}: ESGAndActionScoreRequest) => {
  const params = {
    credibility_min,
    credibility_max,
    action_min,
    action_max,
    article_8_min,
    article_8_max,
    article_9_min,
    article_9_max,
  };
  const queryString = createQueryParams(params);

  return fetchAPI<FilterPresetOptionResponse>(
    `${ESG_AND_ACTION_SCORE_FILTER_PRESET}?${queryString}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name }),
    },
  );
};

export const sendRequestProductPresentationESG = (id: string) =>
  fetchAPI(REQUEST_PRODUCT_PRESENTATION_ESG(id), {
    method: 'PATCH',
  });

export const sendRequestAttributionESG = (id: string) =>
  fetchAPI(REQUEST_ATTRIBUTION_ESG(id), {
    method: 'PATCH',
  });

export const sendErrorReportRequestESG = (id: string, text: string) =>
  fetchAPI(REPORT_ERROR_ESG(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text }),
  });

export const editESGFilterPreset = (id: string, name: string) => {
  return fetchAPI<FilterPresetOptionResponse>(
    `${ESG_AND_ACTION_SCORE_FILTER_PRESET}${id}/`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name }),
    },
  );
};

export const deleteESGFilterPreset = (id: string) =>
  fetchAPI(`${ESG_AND_ACTION_SCORE_FILTER_PRESET}${id}/`, {
    method: 'DELETE',
  });
