import {
  REPORT_ERROR_PRIVATE_MARKETS,
  REQUEST_ATTRIBUTION_PRIVATE_MARKETS,
  REQUEST_PRODUCT_PRESENTATION_PRIVATE_MARKETS,
} from 'urls/api';
import fetchAPI from './fetchApi';

export const sendRequestProductPresentationPrivateMarkets = (id: string) =>
  fetchAPI(REQUEST_PRODUCT_PRESENTATION_PRIVATE_MARKETS(id), {
    method: 'PATCH',
  });

export const sendRequestAttributionPrivateMarkets = (id: string) =>
  fetchAPI(REQUEST_ATTRIBUTION_PRIVATE_MARKETS(id), {
    method: 'PATCH',
  });

export const sendErrorReportRequestPrivateMarkets = (
  id: string,
  text: string,
) =>
  fetchAPI(REPORT_ERROR_PRIVATE_MARKETS(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text }),
  });
