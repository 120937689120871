import { FC, useEffect, useRef, useState } from 'react';
import { RowProps } from './Row.d';
import {
  StyledIconButton,
  StyledTableCell,
  StyledTableRow,
  StyledTextField,
} from './Row.styled';
import { Box } from 'components/common/Box';
import { CustomTooltip } from 'components/common/Tooltip';
import { ReactComponent as EditIcon } from 'assets/Icons/edit-outlined.svg';
import { ReactComponent as BinIcon } from 'assets/Icons/trash.svg';
import { COLORS } from 'theme/colors';

export const Row: FC<RowProps> = ({
  name,
  id,
  fundsNumber,
  selected,
  openConfirmationModal,
  onRowEdit,
  onRowSelect,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [itemName, setItemName] = useState(name);

  const textFieldRef = useRef(null);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => !isEditMode && setIsHovered(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      setIsEditMode(false);
      onRowEdit(id, itemName);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    if (
      textFieldRef &&
      textFieldRef.current &&
      !(textFieldRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setIsEditMode(false);
      onRowEdit(id, itemName);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [itemName, id]);

  return (
    <StyledTableRow
      selected={selected || false}
      isEditMode={isEditMode}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        e.stopPropagation();
        onRowSelect({ id, name, fundsNumber });
      }}
    >
      <StyledTableCell
        component="th"
        scope="row"
        width={360}
        selected={selected || false}
        isEditMode={isEditMode}
      >
        <Box display="flex" alignItems="center" gap={1} style={{ width: 330 }}>
          {isEditMode ? (
            <StyledTextField
              ref={textFieldRef}
              value={itemName}
              onChange={(event) => {
                event.stopPropagation();
                setItemName(event.target.value);
                setIsEditMode(true);
              }}
              onKeyDown={handleKeyDown}
              autoFocus
              onClick={(event) => event.stopPropagation()}
            />
          ) : (
            itemName
          )}
          {!isEditMode && isHovered && (
            <CustomTooltip title="Umbenennen" placement="top">
              <StyledIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIsEditMode(true);
                }}
              >
                <EditIcon
                  stroke={
                    selected
                      ? COLORS.background.secondary
                      : COLORS.typography.primary
                  }
                />
              </StyledIconButton>
            </CustomTooltip>
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell
        align="left"
        width={40}
        selected={selected || false}
        isEditMode={isEditMode}
      >
        {fundsNumber}
      </StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        width={30}
        selected={selected || false}
        isEditMode={isEditMode}
      >
        <Box style={{ width: 30 }}>
          {!isEditMode && isHovered && (
            <CustomTooltip title="Löschen" placement="top">
              <StyledIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  openConfirmationModal({ name, id, fundsNumber });
                }}
              >
                <BinIcon
                  stroke={
                    selected
                      ? COLORS.background.secondary
                      : COLORS.typography.primary
                  }
                />
              </StyledIconButton>
            </CustomTooltip>
          )}
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};
