import { FC } from 'react';
import { VoteModificationColumnProps } from './VoteModificationColumn.d';
import {
  StyledValueBefore,
  StyledValueAfter,
  StyledPoint,
} from './VoteModificationColumn.styled';
import { Box } from 'components/common/Box';
import { ReactComponent as ArrowIcon } from 'assets/Icons/narrow-arrow.svg';
import { voteOptions } from './config';

export const VoteModificationColumn: FC<VoteModificationColumnProps> = ({
  valueBefore,
  valueAfter,
}) => {
  const currentAfterValue = valueAfter
    ? voteOptions.filter(
        (option) =>
          option.value.toLowerCase() === valueAfter.toString().toLowerCase(),
      )[0]
    : undefined;

  return (
    <Box display="flex" gap={2.5} alignItems="center" mx={1}>
      {valueBefore ? (
        <StyledValueBefore variant="body">{valueBefore}</StyledValueBefore>
      ) : valueAfter && !!currentAfterValue ? (
        <Box display="flex" gap={1} alignItems="center">
          <StyledPoint color={currentAfterValue.color} />
          <StyledValueAfter variant="body">{`Neuaufnahme (${valueAfter})`}</StyledValueAfter>
        </Box>
      ) : null}
      {valueBefore && valueAfter && <ArrowIcon />}
      <Box display="flex" gap={1} alignItems="center">
        {valueAfter && !!currentAfterValue && valueBefore && (
          <StyledPoint color={currentAfterValue.color} />
        )}
        {valueAfter && valueBefore && (
          <StyledValueAfter variant="body">{valueAfter}</StyledValueAfter>
        )}
      </Box>
    </Box>
  );
};
