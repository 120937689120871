import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(2)} ${SIZES.spacing(3)} ${SIZES.spacing(3)};
  background-color: ${COLORS.background.secondary};
  border-radius: 16px;
`;
