import { FC } from 'react';
import { ExpandedActionsProps } from './ExpandedActions.d';
import { StyledMenu, StyledMenuItem } from './ExpandedActions.styled';
import { ReactComponent as CompareIcon } from 'assets/Icons/add-to-compare.svg';
import { ReactComponent as DownloladIcon } from 'assets/Icons/download.svg';
import { COLORS } from 'theme/colors';
import { ReactComponent as AddToFundIcon } from 'assets/Icons/add-fund.svg';
import { ReactComponent as RemoveFromFundIcon } from 'assets/Icons/remove-from-fund-list.svg';
import { ReactComponent as FeedbackIcon } from 'assets/Icons/feedback.svg';
import { ReactComponent as RefreshIcon } from 'assets/Icons/refresh-filled.svg';
import { ReactComponent as MessageIcon } from 'assets/Icons/messages.svg';
import { ReactComponent as AnalysisIcon } from 'assets/Icons/favorite-chart-filled.svg';
import { ReactComponent as PencilIcon } from 'assets/Icons/edit.svg';
import { ReactComponent as MessageEditIcon } from 'assets/Icons/message-edit.svg';
import { ReactComponent as FolderIcon } from 'assets/Icons/folder-cross.svg';
import { ReactComponent as FolderAddIcon } from 'assets/Icons/folder-add.svg';
import { ReactComponent as RemoveRequestIcon } from 'assets/Icons/remove-request.svg';
import { ReactComponent as PresentationIcon } from 'assets/Icons/presention-chart.svg';
import { ReactComponent as AttributionIcon } from 'assets/Icons/note-2.svg';
import { ReactComponent as ReportIconIcon } from 'assets/Icons/report-error.svg';
import { useProfile } from 'context/Profile';
import { MAX_COMPARIOSON_LIST_SIZE } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';

export const ExpandedActions: FC<ExpandedActionsProps> = ({
  open,
  menuWidth,
  isInMyFundList = false,
  isInComparisonList = false,
  isInMyPortfolio = false,
  isAllowToWithdrawRequest,
  disabledToAddRemoveToCompass,
  disabledToRemoveFromPortfolio,
  isPresentInKompass,
  anchorEl,
  requestReportText,
  requestTypeInCompassDesign,
  setAnchorEl,
  onRequestReportHandler,
  onChangeFundListHandle,
  onChangeComparisonListHandle,
  onFeedbackHandle,
  onFundUpdateHandle,
  onRequestFundUpdateHandle,
  onPlansecurCommentsHandle,
  onFundPortraitHandle,
  onRequestCompetitiveAnalysisHandle,
  onOtherRequestHandle,
  onChangePortfolioHandle,
  onRequestProductPresentation,
  onRequestAttribution,
  onChangeRequestStatusInCompassDesign,
  onReportError,
  onMaintainAdditionalInfoChange,
  onChangeCompasStatusHandle,
}) => {
  const profile = useProfile();
  const comparisonListSize = profile?.profileInfo?.comparison_list_size || 0;

  const allowToAddToComparisonList =
    comparisonListSize < MAX_COMPARIOSON_LIST_SIZE;
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      id="actions-menu"
      anchorEl={anchorEl}
      open={open}
      width={menuWidth}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            mt: 1,
          },
        },
      }}
    >
      {onChangeFundListHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onChangeFundListHandle(e);
          }}
          disableRipple
        >
          {isInMyFundList ? (
            <RemoveFromFundIcon fill={COLORS.error.background} />
          ) : (
            <AddToFundIcon fill={COLORS.typography.description} />
          )}
          {isInMyFundList
            ? 'Aus meiner Fondsliste entfernen'
            : 'Zu Meine Fondsliste hinzufügen'}
        </StyledMenuItem>
      )}
      {onChangePortfolioHandle && (
        <StyledMenuItem
          isWarningOnHover={isInMyPortfolio}
          onClick={(e) => {
            handleClose(e);
            onChangePortfolioHandle(e);
          }}
          disableRipple
          disabled={disabledToRemoveFromPortfolio}
        >
          {disabledToRemoveFromPortfolio ? (
            <RemoveFromFundIcon fill={COLORS.stroke.primary} />
          ) : isInMyPortfolio ? (
            <RemoveFromFundIcon fill={COLORS.error.background} />
          ) : (
            <AddToFundIcon fill={COLORS.accent.primary} />
          )}
          {isInMyFundList
            ? 'Von der Watchlist löschen'
            : 'Zur Watchlist hinzufügen'}
        </StyledMenuItem>
      )}
      {requestTypeInCompassDesign && onChangeRequestStatusInCompassDesign && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onChangeRequestStatusInCompassDesign(e, requestTypeInCompassDesign);
          }}
          disableRipple
        >
          <PencilIcon fill={COLORS.typography.description} />
          {requestTypeInCompassDesign === 'Addition'
            ? 'Antrag zur Aufnahme in den Kompass'
            : 'Antrag zur Streichung vom Kompass'}
        </StyledMenuItem>
      )}
      {onRequestReportHandler && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestReportHandler(e);
          }}
          disableRipple
        >
          <DownloladIcon fill={COLORS.typography.description} />
          {requestReportText || 'FondsConsult Fondsporträt anfordern'}
        </StyledMenuItem>
      )}
      {onFeedbackHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onFeedbackHandle(e);
          }}
          disableRipple
        >
          <FeedbackIcon fill={COLORS.typography.description} />
          Feedback geben
        </StyledMenuItem>
      )}
      {onChangeComparisonListHandle && (
        <StyledMenuItem
          onClick={(e) => {
            e.preventDefault();
            handleClose(e);
            onChangeComparisonListHandle(e);
          }}
          disableRipple
          isWarning={isInComparisonList}
          disabled={!isInComparisonList && !allowToAddToComparisonList}
        >
          <CompareIcon
            fill={
              isInComparisonList
                ? COLORS.error.background
                : COLORS.typography.description
            }
          />
          {isInComparisonList
            ? 'Aus meiner Vergleichsliste entfernen'
            : 'Zu Meiner Vergleichsliste hinzufügen'}
          {!isInComparisonList &&
            !allowToAddToComparisonList &&
            '. (Sie haben bereits 5 Fonds in Ihrem Mein Fondsvergleich.)'}
        </StyledMenuItem>
      )}
      {onFundPortraitHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onFundPortraitHandle(e);
          }}
          disableRipple
        >
          <DownloladIcon fill={COLORS.typography.description} />
          Fondsporträt herunterladen
        </StyledMenuItem>
      )}
      {onMaintainAdditionalInfoChange && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onMaintainAdditionalInfoChange(e);
          }}
          disableRipple
        >
          <MessageEditIcon fill={COLORS.typography.description} />
          Zusatzinfos pflegen
        </StyledMenuItem>
      )}
      {onChangeCompasStatusHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onChangeCompasStatusHandle(e);
          }}
          disableRipple
          disabled={!isAllowToWithdrawRequest && disabledToAddRemoveToCompass}
        >
          {isAllowToWithdrawRequest ? (
            <RemoveRequestIcon fill={COLORS.typography.description} />
          ) : isPresentInKompass ? (
            <FolderIcon
              fill={
                disabledToAddRemoveToCompass
                  ? COLORS.stroke.primary
                  : COLORS.error.background
              }
            />
          ) : (
            <FolderAddIcon
              fill={
                disabledToAddRemoveToCompass
                  ? COLORS.stroke.primary
                  : COLORS.typography.description
              }
            />
          )}
          {isAllowToWithdrawRequest
            ? 'Antrag abbrechen'
            : isPresentInKompass
            ? 'Antrag auf Streichung vom Kompass stellen'
            : 'Antrag auf Aufnahme in den Kompass stellen'}
        </StyledMenuItem>
      )}
      {onFundUpdateHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onFundUpdateHandle(e);
          }}
          disableRipple
        >
          <FeedbackIcon fill={COLORS.typography.description} />
          Fondsnews
        </StyledMenuItem>
      )}
      {onRequestFundUpdateHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestFundUpdateHandle(e);
          }}
          disableRipple
        >
          <RefreshIcon fill={COLORS.typography.description} />
          Fondsupdate anfordern
        </StyledMenuItem>
      )}
      {onRequestCompetitiveAnalysisHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestCompetitiveAnalysisHandle(e);
          }}
          disableRipple
        >
          <AnalysisIcon fill={COLORS.typography.description} />
          Wettbewerbsanalyse anfordern
        </StyledMenuItem>
      )}
      {onPlansecurCommentsHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onPlansecurCommentsHandle(e);
          }}
          disableRipple
        >
          <MessageIcon fill={COLORS.typography.description} />
          Plansecur Kommentare
        </StyledMenuItem>
      )}
      {onOtherRequestHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onOtherRequestHandle(e);
          }}
          disableRipple
        >
          <FeedbackIcon fill={COLORS.typography.description} />
          Sonstige Anfragen/Anmerkungen
        </StyledMenuItem>
      )}
      {onRequestProductPresentation && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestProductPresentation(e);
          }}
          disableRipple
        >
          <PresentationIcon fill={COLORS.typography.description} />
          Produktpräsentation anfordern
        </StyledMenuItem>
      )}
      {onRequestAttribution && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestAttribution(e);
          }}
          disableRipple
        >
          <AttributionIcon fill={COLORS.typography.description} />
          Attribution anfordern
        </StyledMenuItem>
      )}
      {onReportError && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onReportError(e);
          }}
          disableRipple
        >
          <ReportIconIcon fill={COLORS.typography.description} />
          Fehler melden
        </StyledMenuItem>
      )}
    </StyledMenu>
  );
};
