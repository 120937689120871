import { FC } from 'react';
import { RecommendationsProps } from './Recommendations.d';
import { CollapseTableTabs } from 'components/common/Tabs/CollapseTableTabs';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { StyledRecommendationForAction } from './Recommendations.styled';
import { ReactComponent as RecommendationIcon } from 'assets/Icons/favorite-chart.svg';
import { Box } from 'components/common/Box';

export const Recommendations: FC<RecommendationsProps> = ({
  recommendations,
}) => {
  const { recommendations_for_action, investment_approach, manager_profile } =
    recommendations;
  const recommendationTabs = [
    {
      label: 'Einschätzung',
      content: (
        <StyledRecommendationForAction
          pt={2}
          pb={2.9}
          px={2.5}
          display="flex"
          gap={2}
          alignItems="stretch"
        >
          <RecommendationIcon fill={COLORS.info.selectedBorder} />
          <Box pt={0.4} flex={1}>
            <Typography variant="body" weight="medium">
              {recommendations_for_action}
            </Typography>
          </Box>
        </StyledRecommendationForAction>
      ),
    },
    {
      label: 'Investmentansatz',
      content: (
        <Typography variant="body" color={COLORS.typography.description}>
          {investment_approach}
        </Typography>
      ),
    },
    {
      label: 'Management Profil',
      content: (
        <Typography variant="body" color={COLORS.typography.description}>
          {manager_profile}
        </Typography>
      ),
    },
  ];

  return (
    <CollapseTableTabs
      tabs={recommendationTabs.map((item) => ({
        label: item.label,
        content: item.content,
      }))}
      variant="secondary"
    />
  );
};
