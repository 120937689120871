import { FC } from 'react';
import { StarRatingsColumnProps } from './StarRatingsColumn.d';
import { ReactComponent as StarIcon } from 'assets/Icons/rating-star.svg';
import { COLORS } from 'theme/colors';
import { Rating } from '@mui/material';
import { Box } from 'components/common/Box';

export const StarRatingsColumn: FC<StarRatingsColumnProps> = ({ value }) => {
  return (
    <Box display="flex" justifyContent="center" gap={1} py={1}>
      {value ? (
        <Rating
          readOnly
          value={Number(value)}
          name="rating"
          precision={1}
          icon={<StarIcon fill={COLORS.warning.primary} />}
          emptyIcon={<StarIcon fill={COLORS.background.module} />}
        />
      ) : (
        '-'
      )}
    </Box>
  );
};
