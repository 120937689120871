export const crashesInfo = {
  crash_2007_2009: {
    label: 'Globale Finanz- und Bankenkrise (2007-2009)',
    period: '14.10.2007 – 11.03.2009',
    events:
      'Die Krise fand ihren Ursprung durch eine Kombination von übermäßiger Verschuldung und Risikobereitschaft von Banken und Haushalten, insbesondere im Zusammenhang mit dem Immobilienmarkt in den USA. Viele Banken erlitten enorme Verluste, da sie nicht in der Lage waren ihre Schulden zu tilgen, was zu einer weltweiten Vertrauenskrise im Bankensektor und enormen Liquiditätsengpässen für Haushalte führte.',
    consequences:
      'Die Krise hatte langfristige Auswirkungen auf die Weltwirtschaft, eine Erhöhung der Staatsverschuldung sowie etwaige Einführungen von strengeren Finanzregulierungen als Folge.',
  },
  crash_2011: {
    label: 'Eurokrise (2011)',
    period: '20.02.2011 – 24.09.2011',
    events:
      'Die sich bereits Ende 2010 anbahnende Staatsschuldenkrise betraf viele Länder der Europäischen Währungsunion und machte es für einige Länder, insbesondere Griechenland, schwierig bis unmöglich, ihre Staatsschulden ohne Hilfe von Dritten zu tilgen. Der Ursprung entsprang der Finanzkrise 2008 und endete mit einer expansiven Geldpolitik seitens der EZB, welche insbesondere italienische und spanische Staatsanleihen aufkaufen musste, um deren Kurs zu stützen.',
    consequences:
      'Dies führte zu steigenden Risikoaufschlägen für europäische Staatsanleihen, Ankaufprogrammen und sinkenden Leitzinsen im Euroraum.',
  },
  crash_2015_2016: {
    label: 'China Crash (2015/2016)',
    period: '13.04.2015 – 16.02.2016',
    events:
      'Grund für die Mitte 2015 platzende Aktienblase und des herbeiführenden China Crashs war die Ermutigung der chinesischen Regierung in dessen Aktienmarkt zu investieren, um die Wirtschaft zu stärken. Dies führte zu einer Verdopplung der Kapitalisierung des Shanghai Stock Exchange und schuf so eine spekulative Blase.',
    consequences:
      'Der Crash führte zu einem Vertrauensverlust in die chinesischen Märkte und in die Fähigkeit der Regierung die Wirtschaft zu stabilisieren. Lieferkettenengpässe und weltwirtschaftliche Unsicherheiten waren weitere Folgen.',
  },
  crash_2018: {
    label: 'Handelskrieg US-China (2018)',
    period: '03.10.2018 – 31.12.2018',
    events:
      'Der Handelskonflikt zwischen China und der USA fand Ende 2018 seinen damaligen Höhepunkt. Beide Länder verhängten gegenseitige Strafzölle auf Waren in Milliardenhöhe. Dies führte zu enormen Unsicherheiten an den Märkten und beeinflusste die Handelsbeziehungen weltweit (u.a. zwischen EU und USA bzw. Brexit Verhandlungen).',
    consequences:
      'Als Folge der Anspannungen im Welthandel ergaben sich fallende Aktienkurse, was es für Investoren in einem Umfeld mit niedrigen Zinsen schwierig machte, eine positive Rendite zu erzielen.',
  },
  crash_2020: {
    label: 'Corona-Krise (2020)',
    period: '19.02.2020 – 25.03.2020',
    events:
      'Die Anfang 2020 startende Corona-Pandemie betraf weltweit Volkswirtschaften in unterschiedlichem Maße. Die Reaktion und die dementsprechenden Maßnahmen variierten von Land zu Land: Einige setzten auf drastische Lockdowns, während andere weniger strikte Kurse wählten. Der angestrebte Rückgang an Corona-Infektionen ging mit Betriebsschließungen und wirtschaftlichen Einbußen einher. Die zunehmende Unsicherheit führte schließlich zu Panikverkäufen und löste mit einer Dauer von rund einem Monat den „schnellsten Börsencrash der Geschichte“ aus.',
    consequences:
      'Die Politik spielte in dieser Krise damit eine gewichtige Rolle. Expansive Geld- und Fiskalpolitik in den Industrieländern milderten die unmittelbaren wirtschaftlichen Krisenfolgen. Die Aktienmärkte konnten vor allem in den USA durch die geschürten Geldpakete profitieren. Langfristige Folgen, insbesondere der Verschuldungsgrad der Länder und Aufhebung der Maastricht Richtlinien für EU-Staaten, sind weiterhin spürbar.',
  },
  crash_2022: {
    label: 'Beginn Russland-Ukraine Krieg (2022)',
    period: '04.01.2022 – 01.10.2022',
    events:
      'Der durch den russischen Überfall ausgelöste Krieg in der Ukraine hat nicht nur weitreichende wirtschaftliche Folgen für die betroffenen Regionen, sondern auch auf die internationalen Märkte und Handelsbeziehungen. Vor allem in Deutschland waren Gas- und Ölpreise stark durch die Handelssanktionen gegenüber Russland betroffen. Nicht nur steigende Energie- sondern auch Nahrungsmittelpreise führten nach und nach zu einem raschen Anstieg der Inflationsrate.',
    consequences:
      'Zwar konnte den hohen Inflationsraten im Folgejahr durch entsprechende Zinserhöhungen seitens der Notenbanken zum Teil entgegengesteuert werden, wie weitreichend jedoch die wirtschaftlichen und politischen Folgen des Krieges sind, lässt sich aktuell schwer voraussagen und wird vom weiteren Kriegsgeschehen bestimmt.',
  },
};
