import React, { FC } from 'react';
import { UserMenuProps } from './UserMenu.d';
import {
  StyledContainer,
  StyledMenu,
  StyledMenuItem,
  HiddenFirstMenuItem,
} from './UserMenu.styled';
import { ReactComponent as ProfileIcon } from 'assets/Icons/profile-circle.svg';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow.svg';
import { ReactComponent as LogoutIcon } from 'assets/Icons/logout.svg';
import { ReactComponent as ProfileInfoIcon } from 'assets/Icons/user.svg';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { AppPaths } from 'urls/frontend';
import { useAuth } from 'context/Auth';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const UserMenu: FC<UserMenuProps> = ({ email, variant }) => {
  const { customNavigate } = useNavigation();
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isPrimary = variant === 'primary';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = async () => {
    await auth?.logout();
    customNavigate(AppPaths.login);
    handleClose();
  };

  const onProfileInfoClick = () => {
    customNavigate(AppPaths.profile);
  };

  return (
    <>
      <StyledContainer
        display="flex"
        alignItems="center"
        gap={1}
        onClick={handleClick}
        variant={variant}
      >
        <ProfileIcon
          stroke={
            isPrimary
              ? COLORS.typography.placeholder
              : COLORS.background.secondary
          }
        />
        <Typography
          variant="body"
          color={
            isPrimary ? COLORS.typography.primary : COLORS.background.secondary
          }
        >
          {email}
        </Typography>
        <ArrowIcon
          stroke={
            isPrimary ? COLORS.typography.primary : COLORS.background.secondary
          }
          style={{
            transform: open ? 'rotate(-180deg)' : 'none',
          }}
        />
      </StyledContainer>
      <StyledMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.1))',
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* Without this line first item becomes focused after openning menu */}
        {/* https://github.com/mui/material-ui/issues/23747 */}
        <HiddenFirstMenuItem />
        <StyledMenuItem key="profile" onClick={onProfileInfoClick}>
          <ProfileInfoIcon stroke={COLORS.typography.description} />
          Kontoeinstellungen
        </StyledMenuItem>
        <StyledMenuItem key="logout" onClick={onLogoutClick}>
          <LogoutIcon
            stroke={COLORS.typography.description}
            style={{ width: '20px', height: '20px' }}
          />
          Abmelden
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};
