import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';

export const StyledTopContainer = styled(Box)`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 2fr;
  gap: 22px;
`;

export const StyledSectionName = styled(Typography)`
  font-size: 32px;
  line-height: 40px;
  color: ${COLORS.typography.primary};
`;

export const StyledNameColumnContainer = styled(Box)`
  height: 100%;
  min-width: 100px;
  width: 100%;
  max-width: 22vw;

  ${SIZES.media.extraLargeDesktop} {
    max-width: 32vw;
  }
`;

export const StyledFondName = styled(Typography)`
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 17px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  min-height: 18px;
`;

export const StyledIsin = styled(Typography)`
  font-size: 10px;
  line-height: 12px;
  color: ${COLORS.typography.description};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
`;

export const StyledButton = styled(Button)`
  padding: 11px 15px;
  min-width: 136px;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${COLORS.typography.main};
  width: 16px;
  height: 16px;
  transform: rotate(135deg);
`;
