import { FC, useState } from 'react';
import { ActionsProps } from './Actions.d';
import {
  StyledActionsButton,
  StyledContainer,
  StyledMoreActionsButton,
} from './Actions.styled';
import { ReactComponent as MoreActionsIcon } from 'assets/Icons/more-actions.svg';
import { ReactComponent as ActionsIcon } from 'assets/Icons/menu-dots.svg';
import { COLORS } from 'theme/colors';
import { ExpandedActions } from './ExpandedActions';

export const Actions: FC<ActionsProps> = ({
  menuWidth,
  variant = 'full',
  isInMyFundList,
  isInComparisonList = false,
  isInMyPortfolio = false,
  isPresentInKompass = false,
  isAllowToWithdrawRequest = false,
  requestTypeInCompassDesign,
  disabledToRemoveFromPortfolio,
  disabledToAddRemoveToCompass,
  requestReportText,
  fundUpdateText,
  handlers,
  ...props
}) => {
  const isFullVariant = variant === 'full';
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(moreActionsAnchorEl);

  const handleClickMoreActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setMoreActionsAnchorEl(event.currentTarget);
  };

  return (
    <StyledContainer
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      {...props}
    >
      {handlers && (
        <>
          {isFullVariant ? (
            <StyledActionsButton
              onClick={handleClickMoreActions}
              rightIcon={<ActionsIcon />}
            >
              Aktionen
            </StyledActionsButton>
          ) : (
            <StyledMoreActionsButton onClick={handleClickMoreActions}>
              <MoreActionsIcon
                stroke={
                  openMoreActions
                    ? COLORS.accent.primary
                    : COLORS.typography.main
                }
              />
            </StyledMoreActionsButton>
          )}
        </>
      )}
      <ExpandedActions
        menuWidth={menuWidth}
        isInMyFundList={isInMyFundList}
        isInComparisonList={isInComparisonList}
        isInMyPortfolio={isInMyPortfolio}
        isAllowToWithdrawRequest={isAllowToWithdrawRequest}
        isPresentInKompass={isPresentInKompass}
        disabledToAddRemoveToCompass={disabledToAddRemoveToCompass}
        requestReportText={requestReportText}
        fundUpdateText={fundUpdateText}
        open={openMoreActions}
        setAnchorEl={setMoreActionsAnchorEl}
        anchorEl={moreActionsAnchorEl}
        disabledToRemoveFromPortfolio={disabledToRemoveFromPortfolio}
        requestTypeInCompassDesign={requestTypeInCompassDesign}
        {...handlers}
      />
    </StyledContainer>
  );
};
