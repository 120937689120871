import styled from 'styled-components';
import { Button } from '../Buttons/Button';
import { COLORS } from 'theme/colors';
import { Typography } from '../Typography';

export const StyledShowMoreButton = styled(Button)`
  &:enabled {
    &:hover {
      & div {
        & svg {
          stroke: ${COLORS.info.selectedBorder};
        }
      }
    }
  }
`;

export const StyledTypography = styled(Typography)`
  line-height: 20px;
`;
