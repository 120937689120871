import { FC, useEffect, useState } from 'react';
import { ContactFormProps } from './ContactUsForm.d';
import {
  StyledContainer,
  StyledForm,
  StyledLink,
} from './ContactUsForm.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Buttons/Button';
import { Textarea } from 'components/common/Textarea';
import { FormCheckbox } from 'components/common/FormCheckbox';
import { Box } from 'components/common/Box';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactUsSchema } from './validators';
import { isValidForm } from 'utils/isValidForm';
import { contactUs } from 'services/ContactUs';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Profile, useProfile } from 'context/Profile';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactFormCategory,
  ContactFormCompletedAction,
} from 'utils/google-analytics/events/contactForm';
import { AppPaths } from 'urls/frontend';

export const ContactUsForm: FC = () => {
  const profile = useProfile();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ContactFormProps>({
    resolver: yupResolver(contactUsSchema),
    mode: 'onBlur',
  });
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const toggleAgreedToTerms = () => {
    if (agreedToTerms) {
      setValue('agreedToTerms', false, { shouldValidate: true });
    } else {
      setValue('agreedToTerms', true, { shouldValidate: true });
    }
    setAgreedToTerms(!agreedToTerms);
  };

  const assignMainData = (profile: Profile) => {
    setValue('sender_email', profile.profileInfo?.email ?? '');
  };

  const onContactFormSubmit = async (data: ContactFormProps) => {
    const { agreedToTerms, ...formData } = data;
    const response = await contactUs(formData);

    if (response.ok) {
      raiseToast.success('Formular erfolgreich gesendet.');
      reset();
      setAgreedToTerms(false);
      TrackGoogleAnalyticsEvent(
        ContactFormCategory,
        ContactFormCompletedAction,
        window.location.pathname,
      );
    }
  };

  useEffect(() => {
    if (profile) {
      assignMainData(profile);
    }
  }, [profile]);

  return (
    <StyledContainer py={5} px={4}>
      <Typography variant="h4" color={COLORS.typography.primary}>
        Kontaktieren Sie uns
      </Typography>
      <StyledForm onSubmit={handleSubmit(onContactFormSubmit)}>
        <Input
          placeholder="Ihr Name"
          {...register('sender_name', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.sender_name?.message}
        />
        <Input
          placeholder="E-Mail-Adresse"
          {...register('sender_email', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.sender_email?.message}
        />
        <Input
          placeholder="Betreff"
          {...register('subject', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.subject?.message}
        />
        <Input
          placeholder="Unternehmen"
          {...register('company', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.company?.message}
        />
        <Input
          placeholder="Position"
          {...register('position', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.position?.message}
        />
        <Textarea
          placeholder="Nachricht"
          {...register('body', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.body?.message}
        />
        <FormCheckbox
          {...register('agreedToTerms', {
            required: true,
          })}
          options={[
            {
              value:
                'Durch die Nutzung dieses Kontaktformulars akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen',
              label: (
                <>
                  Durch Klicken stimmen Sie unseren{' '}
                  <StyledLink to={AppPaths.termsAndConditions}>
                    Nutzungsbedingungen
                  </StyledLink>{' '}
                  sowie der{' '}
                  <StyledLink to={AppPaths.privacyPolicy}>
                    Datenschutzerklärung nach DSGVO
                  </StyledLink>{' '}
                  zu.
                </>
              ),
              checked: agreedToTerms,
            },
          ]}
          onSelect={toggleAgreedToTerms}
          error={!isValidForm(errors) && errors.agreedToTerms?.message}
        />
        <Box mt={2}>
          <Button type="submit">Nachricht senden</Button>
        </Box>
      </StyledForm>
    </StyledContainer>
  );
};
