import { forwardRef, useEffect } from 'react';
import {
  CreateWatchlistModalProps,
  CreateWatchlistFields,
} from './CreateWatchlistModal.d';
import { Modal } from 'components/common/Modal/Modal';
import { StyledContainer } from './CreateWatchlistModal.styled';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Input } from 'components/common/Input';
import { useForm } from 'react-hook-form';
import { isValidForm } from 'utils/isValidForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { createWatchlistSchema } from './validators';

export const CreateWatchlistModal = forwardRef<
  HTMLDivElement,
  CreateWatchlistModalProps
>(({ isShown, toggle, onSubmit }, ref) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateWatchlistFields>({
    resolver: yupResolver(createWatchlistSchema),
    mode: 'onBlur',
  });

  const onCreateWatchlist = ({ name }: CreateWatchlistFields) => {
    onSubmit(name);
  };

  useEffect(() => {
    if (!isShown) {
      reset();
    }
  }, [isShown]);

  return (
    <Modal
      modalRef={ref}
      isShown={isShown}
      onClose={toggle}
      withCloseButton
      borderRadius="8px"
      title="Neue Liste"
      px={4}
      pb={5}
      pt={4}
    >
      <StyledContainer pt={4}>
        <form onSubmit={handleSubmit(onCreateWatchlist)}>
          <Input
            label="Fondsliste-Name"
            placeholder="Benutzerdefinierten Titel eingeben"
            {...register('name')}
            error={!isValidForm(errors) && errors.name?.message}
          />

          <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
            <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
              Abbrechen
            </Button>
            <Button type="submit">Speichern</Button>
          </Box>
        </form>
      </StyledContainer>
    </Modal>
  );
});
