import { FC, useEffect, useState } from 'react';
import { Box } from 'components/common/Box';
import { ModuleLayout } from 'components/Modules/ModuleLayout';
import { Table } from 'components/common/Table';
import {
  GridRowsProp,
  GridColDef,
  GridSortModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {
  ESGAndActionScoreItemProps,
  ESGCredibilityActionScoreTableProps,
} from './ESGCredibilityActionScoreTable.d';
import { NameColumn } from 'components/Modules/NameColumn';
import { Rank } from 'components/Modules/Rank';
import { Score } from 'components/Modules/Score';
import { Actions } from 'components/Modules/Actions';
import { Pagination } from 'components/common/Pagination';
import {
  deleteESGFilterPreset,
  editESGFilterPreset,
  fetchAddFilterPreset,
  fetchESGAndActionScore,
  fetchFavoritesESGAndActionScore,
  fetchUpdateFundListStatusESG,
  sendRequestAttributionESG,
  sendRequestProductPresentationESG,
  sendRequestReportESGFund,
} from 'services/ESG';
import { useLocation, useSearchParams } from 'react-router-dom';
import { esgFilters, rangeOptions } from './config';
import { SelectedRow } from 'components/common/Table/SelectedRow/SelectedRow';
import { RemoveFromFundListModal } from 'components/common/Modals/RemoveFromFundListModal';
import { AddFeedbackModal } from 'components/common/Modals/AddFeedbackModal';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import { TableCellDetailModal } from 'components/common/Modals/TableCellDetailModal';
import { TableCellDetailProps } from 'components/common/Modals/TableCellDetailModal/TableCellDetailModal.d';
import {
  StyledArticle,
  StyledCell,
  StyledHeadCellContainer,
  StyledInfoIcon,
} from './ESGCredibilityActionScoreTable.styled';
import { Typography } from 'components/common/Typography';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { ESGFiltersModal } from 'components/Modules/Filters/ESGFiltersModal';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Option } from 'components/common/Select/Select.d';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { fetchFilterPresetOptions } from 'services/ESG';
import { SaveFiltersPresetModal } from 'components/common/Modals/SaveFiltersPresetModal';
import { FilterPresetProps } from 'types/Modules.d';
import { AddFundToExactWatchlistModal } from 'components/common/Modals/AddFundToExactWatchlistModal';
import { WatchlistItem } from 'types/Watchlist';
import { ConfirmWithCommentModal } from 'components/Modules/CustomerOverview/Modals/ConfirmWithCommentModal';

export const ESGCredibilityActionScoreTable: FC<
  ESGCredibilityActionScoreTableProps
> = ({ variant, selectedWatchlist }) => {
  const isStandartVariant = variant === 'standart';
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const [openFilters, setOpenFilters] = useState(false);
  const [presetFilterValue, setPresetFilterValue] = useState<Option | null>(
    null,
  );
  const [filterPresetOptions, setFilterPresetOptions] = useState<
    FilterPresetProps[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
    undefined,
  );
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [isConfirmRemoveFromFundOpen, setIsConfirmRemoveFromFundOpen] =
    useState(false);
  const [
    isAddingFundToWatchlistModalOpen,
    setIsAddingFundToWatchlistModalOpen,
  ] = useState(false);
  const [isAddingFeedbackModalOpen, setIsAddingFeedbackModalOpen] =
    useState(false);
  const [isTableCellDetailModalOpen, setIsTableCellDetailModalOpen] =
    useState(false);
  const [isSaveFiltersPresetModalOpen, setIsSaveFiltersPresetModalOpen] =
    useState(false);
  const [isReportErrorModalOpen, setIsReportErrorModalOpen] = useState(false);
  const [currentFund, setCurrentFund] = useState<{
    id: string;
    name: string;
    watchlists: WatchlistItem[] | null;
  }>({
    id: '',
    name: '',
    watchlists: [],
  });
  const [tableCellDetails, setTableCellDetail] = useState<
    TableCellDetailProps[]
  >([]);
  const query = new URLSearchParams(search);
  const page = parseInt(query.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '10', 10);
  const credibilityScore = searchParams.get('credibility') || '';
  const actionScore = searchParams.get('action') || '';
  const article_8 = searchParams.get('article_8') || '';
  const article_9 = searchParams.get('article_9') || '';
  const [watchlist, setWatchlist] = useState(selectedWatchlist);

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
    resetPage();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const getTableData = async () => {
    const [credibility_min, credibility_max] = credibilityScore.split('_');
    const [action_min, action_max] = actionScore.split('_');
    const [article_8_min, article_8_max] = article_8.split('_');
    const [article_9_min, article_9_max] = article_9.split('_');
    const data = {
      page,
      page_size: itemsPerPage,
      ...(selectedWatchlist ? { watchlistId: selectedWatchlist.id } : {}),
      ...(credibility_min ? { credibility_min } : {}),
      ...(credibility_max ? { credibility_max } : {}),
      ...(action_min ? { action_min } : {}),
      ...(action_max ? { action_max } : {}),
      ...(article_8_min ? { article_8_min } : {}),
      ...(article_8_max ? { article_8_max } : {}),
      ...(article_9_min ? { article_9_min } : {}),
      ...(article_9_max ? { article_9_max } : {}),
      ...(sortModel &&
      sortModel.length !== 0 &&
      sortModel[0].field &&
      sortModel[0].sort
        ? { sort_by: sortModel[0].field, sort_order: sortModel[0].sort }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    };
    const response = isStandartVariant
      ? await fetchESGAndActionScore(data)
      : selectedWatchlist
      ? await fetchFavoritesESGAndActionScore(data)
      : null;
    if (response && response.ok) {
      const tableData = response.parsedBody.results.funds;
      setRows(tableData.map((p: ESGAndActionScoreItemProps) => createRow(p)));
      setResultsNumber(response.parsedBody.count);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [
    page,
    itemsPerPage,
    searchParams,
    credibilityScore,
    actionScore,
    article_8,
    article_9,
    sortModel,
    searchValue,
    selectedWatchlist,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const presetId = urlParams.get('preset');

    if (presetId && filterPresetOptions?.length) {
      const presetFromUrl = filterPresetOptions.find(
        (option) => option.id === presetId,
      );
      if (presetFromUrl) {
        setPresetFilterValue({
          value: presetFromUrl.id,
          name: presetFromUrl.name,
        });
      }
    }
  }, []);

  const getFilterPresetOptions = async () => {
    const response = await fetchFilterPresetOptions();
    if (response.ok) {
      setFilterPresetOptions(response.parsedBody);
    }
  };

  const addFilterPreset = async (name: string) => {
    const [credibility_min, credibility_max] = credibilityScore.split('_');
    const [action_min, action_max] = actionScore.split('_');
    const [article_8_min, article_8_max] = article_8.split('_');
    const [article_9_min, article_9_max] = article_9.split('_');

    const response = await fetchAddFilterPreset({
      name,
      credibility_min,
      credibility_max,
      action_min,
      action_max,
      article_8_min,
      article_8_max,
      article_9_min,
      article_9_max,
    });
    if (response.ok) {
      getFilterPresetOptions();
      setIsSaveFiltersPresetModalOpen(false);
      raiseToast.success('Filterpreset wurde erfolgreich hinzugefügt.');
      setPresetFilterValue({ value: response.parsedBody.id, name });
    }
  };

  useEffect(() => {
    getFilterPresetOptions();
  }, []);

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  useEffect(() => {
    setWatchlist(selectedWatchlist);
  }, [selectedWatchlist]);

  const changeFundListStatus = async (id: string, name: string) => {
    if (watchlist) {
      const response = await fetchUpdateFundListStatusESG(id, watchlist?.id);
      if (response.ok) {
        getTableData();
        isConfirmRemoveFromFundOpen && setIsConfirmRemoveFromFundOpen(false);
        isConfirmRemoveFromFundOpen
          ? raiseToast.removeFromFundList(
              <Toast
                title="Fonds aus Ihrer Fondsliste entfernt"
                content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
              />,
            )
          : raiseToast.addToFundList(
              <Toast
                title="Fonds zu Ihrer Fondsliste hinzugefügt"
                content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
              />,
            );
      }
    }
  };

  const requestReport = async (id: string, name: string) => {
    const response = await sendRequestReportESGFund(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den FondsConsult Nachhaltigkeitsbericht wurde erfolgreich gesendet"
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const requestProductPresetnation = async (id: string, name: string) => {
    const response = await sendRequestProductPresentationESG(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für die FondsConsult Produktpräsentation wurde erfolgreich gesendet."
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const onRequestAttribution = async (id: string, name: string) => {
    const response = await sendRequestAttributionESG(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für die FondsConsult Attribution wurde erfolgreich gesendet."
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const showAddFeedbackModal = (
    name: string,
    id: string,
    watchlists: WatchlistItem[] | null,
  ) => {
    if (name) {
      setIsAddingFeedbackModalOpen(true);
      setCurrentFund({ id, name, watchlists });
    }
  };

  const showConfirmRemoveFromFundListModal = (
    name: string,
    id: string,
    watchlists: WatchlistItem[] | null,
  ) => {
    setIsConfirmRemoveFromFundOpen(true);
    setCurrentFund({ id, name, watchlists });
  };

  const showAddFundToWatchlistModal = (
    name: string,
    id: string,
    watchlists: WatchlistItem[] | null,
  ) => {
    setIsAddingFundToWatchlistModalOpen(true);
    setCurrentFund({ id, name, watchlists });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 226,
      flex: 2,
      sortable: true,
      renderCell: ({ row }) => <NameColumn name={row.name} />,
    },
    {
      field: 'rank',
      headerName: 'Rang Credibility Score',
      align: 'center',
      minWidth: 100,
      maxWidth: 100,
      sortable: true,
      renderCell: ({ row }) => <Rank value={row.rank} maxValue={row.rankAll} />,
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip title="Rang des Credibility Scores." placement="top">
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Rang
          </Typography>
        </Box>
      ),
    },
    {
      field: 'credibility_score',
      headerName: 'Credibility Score',
      minWidth: 180,
      align: 'center',
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen werden kann."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Credibility Score
          </Typography>
        </Box>
      ),
      renderCell: ({ row, value }) => (
        <StyledCell
          onClick={(e: React.BaseSyntheticEvent) => {
            const esgCredibilityTableCellDetails = [
              {
                categoryName: 'ESG Credibility',
                withMark: false,
                data: [
                  {
                    label: 'Gesamtscore',
                    value: value || null,
                  },
                  {
                    label:
                      'Organisationsstruktur und vertrauensbildende Maßnahmen',
                    value: row.organizational_structure?.toString() || null,
                  },
                  {
                    label: 'Unternehmenspolitik und -umsetzung',
                    value: row.corporate_policy?.toString() || null,
                  },
                  {
                    label: 'Auswahl und Überwachung (externer) Manager',
                    value: row.external_managers_monitoring?.toString() || null,
                  },
                  {
                    label: 'Aktien',
                    value: row.equities?.toString() || null,
                  },
                  {
                    label: 'Anleihen',
                    value: row.bonds?.toString() || null,
                  },
                  {
                    label: 'Immobilien',
                    value: row.real_estate?.toString() || null,
                  },
                  {
                    label: 'Infrastruktur',
                    value: row.infrastructure?.toString() || null,
                  },
                  {
                    label: 'Private Equity',
                    value: row.private_equity?.toString() || null,
                  },
                  {
                    label: 'Hedgefonds',
                    value: row.hedge_funds?.toString() || null,
                  },
                  {
                    label: 'Transparenz',
                    value: row.transparency?.toString() || null,
                  },
                ],
              },
            ];
            e.stopPropagation();
            setIsTableCellDetailModalOpen(true);
            setTableCellDetail(esgCredibilityTableCellDetails);
            setCurrentFund({
              id: row.id,
              name: row.name,
              watchlists: row.watchlists,
            });
          }}
        >
          <Score value={row.credibility_score} />
        </StyledCell>
      ),
    },
    {
      field: 'action_score',
      headerName: 'Action Score',
      minWidth: 155,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Bewertung der auf Fondsebene tatsächlich umgesetzten Nachhaltigkeitskriterien (u.a. PAIs). Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Berücksichtigung nachhaltiger Kriterien gesprochen werden kann."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Action Score
          </Typography>
        </Box>
      ),
      renderCell: ({ row }) => <Score value={row.action_score} />,
    },
    {
      field: 'article_8',
      headerName: 'Anteil Artikel 8 gemäß SFDR',
      minWidth: 180,
      align: 'center',
      renderCell: ({ value }) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <StyledArticle variant="body">
            {value !== undefined && value !== null ? `${value}%` : '-'}
          </StyledArticle>
        </Box>
      ),
      renderHeader: () => (
        <StyledHeadCellContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Volumengewichteter Anteil aller in Europa (ex UK) zugelassenen Fonds im UCITS-Mantel."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
            textAlign="center"
          >
            Anteil Artikel 8 gemäß SFDR
          </Typography>
        </StyledHeadCellContainer>
      ),
    },
    {
      field: 'article_9',
      headerName: 'Anteil Artikel 9 gemäß SFDR',
      minWidth: 180,
      align: 'center',
      renderCell: ({ value }) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <StyledArticle variant="body">
            {value !== undefined && value !== null ? `${value}%` : '-'}
          </StyledArticle>
        </Box>
      ),
      renderHeader: () => (
        <StyledHeadCellContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Volumengewichteter Anteil aller in Europa (ex UK) zugelassenen Fonds im UCITS-Mantel."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
            textAlign="center"
          >
            Anteil Artikel 9 gemäß SFDR
          </Typography>
        </StyledHeadCellContainer>
      ),
    },
    {
      field: 'assessment',
      headerName: 'Einschätzung',
      minWidth: 130,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip title="Bald verfügbar." placement="top">
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Einschätzung
          </Typography>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      minWidth: 135,
      sortable: false,
      headerAlign: 'right',
      renderCell: ({ row }) => (
        <Actions
          isInMyFundList={!isStandartVariant}
          requestReportText="FondsConsult Nachhaltigkeitsreport anfordern"
          handlers={{
            onChangeFundListHandle: () =>
              !isStandartVariant
                ? showConfirmRemoveFromFundListModal(
                    row.name,
                    row.id,
                    row.watchlists,
                  )
                : showAddFundToWatchlistModal(row.name, row.id, row.watchlists),
            onRequestReportHandler: () => requestReport(row.id, row.name),
            onFeedbackHandle: () =>
              showAddFeedbackModal(row.name, row.id, row.watchlists),
            onRequestProductPresentation: (e: React.BaseSyntheticEvent) => {
              e.stopPropagation();
              requestProductPresetnation(row.id, row.name);
            },
            onRequestAttribution: (e: React.BaseSyntheticEvent) => {
              e.stopPropagation();
              onRequestAttribution(row.id, row.name);
            },
            onReportError: (e: React.BaseSyntheticEvent) => {
              e.stopPropagation();
              setIsReportErrorModalOpen(true);
              setCurrentFund({
                id: row.id,
                name: row.name,
                watchlists: row.watchlists || [],
              });
            },
          }}
        />
      ),
    },
  ];

  const createRow = (tableData: ESGAndActionScoreItemProps) => {
    return {
      id: tableData.id,
      name: tableData.name,
      rank: tableData.rank,
      rankAll: tableData.rank_all,
      credibility_score: tableData.credibility_score,
      action_score: tableData.action_score,
      article_8: tableData.article_8,
      article_9: tableData.article_9,
      actions: tableData,
      assessment: tableData.assessment || '-',
      organizational_structure: tableData.organizational_structure,
      corporate_policy: tableData.corporate_policy,
      external_managers_monitoring: tableData.external_managers_monitoring,
      equities: tableData.equities,
      bonds: tableData.bonds,
      real_estate: tableData.real_estate,
      infrastructure: tableData.infrastructure,
      private_equity: tableData.private_equity,
      hedge_funds: tableData.hedge_funds,
      transparency: tableData.transparency,
    };
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const getActiveFiltersAmount = () =>
    [credibilityScore, actionScore, article_8, article_9].filter(
      (el) => el.length !== 0,
    ).length;

  const clearFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    customNavigate(`${pathname}${tabSearchParam ? `?${newSearchParams}` : ''}`);
  };

  const getFilters = () =>
    esgFilters.map((filter) => {
      let value;
      const filterValue = searchParams.get(filter.id);
      if (filterValue) {
        const [firstValue, secondValue] = filterValue.split('_');
        value = `${firstValue} - ${secondValue}`;
      } else {
        value = filterValue;
      }
      return {
        ...filter,
        name: value || '',
        value: filterValue || '',
        category: 'ESG',
      };
    });

  const onPresetFilterChange = (newValue: Option | null) => {
    setPresetFilterValue(newValue);
  };

  return (
    <>
      <RemoveFromFundListModal
        isShown={isConfirmRemoveFromFundOpen}
        toggle={() => setIsConfirmRemoveFromFundOpen(false)}
        onSubmit={() => changeFundListStatus(currentFund.id, currentFund.name)}
      />
      <AddFeedbackModal
        variant="esg"
        isShown={isAddingFeedbackModalOpen}
        currentFund={currentFund}
        toggle={() => setIsAddingFeedbackModalOpen(false)}
      />
      <AddFundToExactWatchlistModal
        isShown={isAddingFundToWatchlistModalOpen}
        toggle={() =>
          setIsAddingFundToWatchlistModalOpen(!isAddingFundToWatchlistModalOpen)
        }
        currentFund={currentFund}
        variant="esg"
        funds={rowSelectionModel.map((item) => item.toString())}
        setSelectedRows={setRowSelectionModel}
        getTableData={getTableData}
      />
      <TableCellDetailModal
        isShown={isTableCellDetailModalOpen}
        currentFund={currentFund}
        toggle={() => setIsTableCellDetailModalOpen(false)}
        details={tableCellDetails}
      />
      <ESGFiltersModal
        isShow={openFilters}
        closeModal={() => setOpenFilters(false)}
        clearFilters={clearFilters}
        filters={esgFilters.map((filter) => {
          return {
            ...filter,
            options: rangeOptions,
          };
        })}
      />
      <SaveFiltersPresetModal
        isShown={isSaveFiltersPresetModalOpen}
        toggle={() =>
          setIsSaveFiltersPresetModalOpen(!isSaveFiltersPresetModalOpen)
        }
        onSubmit={addFilterPreset}
      />
      <ConfirmWithCommentModal
        isShown={isReportErrorModalOpen}
        variant="send-error-report-esg"
        isWarning
        title="Fehler melden"
        confirmButtonText="Senden"
        fundName={currentFund.name}
        fundId={currentFund.id}
        toggle={() => setIsReportErrorModalOpen(false)}
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={getActiveFiltersAmount()}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        filterPresetOptions={filterPresetOptions}
        clearFilters={clearFilters}
        presetFilterValue={presetFilterValue}
        onPresetFilterChange={onPresetFilterChange}
        getFilterPresetsOptions={getFilterPresetOptions}
        editFilterPreset={editESGFilterPreset}
        deleteFilterPreset={deleteESGFilterPreset}
      >
        <Box px={2.2}>
          <SelectedFilters
            filters={getFilters()}
            openSaveFiltersPresetModal={() =>
              setIsSaveFiltersPresetModalOpen(true)
            }
            clearFilters={clearFilters}
            presetFilterValue={presetFilterValue}
            onPresetFilterChange={onPresetFilterChange}
          />
        </Box>
        <Box px={2.2} display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            flexDirection="column"
            style={{ height: loading ? 'fit-content' : 'unset' }}
          >
            {rowSelectionModel.length !== 0 && (
              <SelectedRow
                variant={variant}
                selectedItemsNumber={rowSelectionModel.length}
                isAddMultipleFundsButtonEnabled
                handleAddToWatchlist={() =>
                  setIsAddingFundToWatchlistModalOpen(
                    !isAddingFundToWatchlistModalOpen,
                  )
                }
                handleCancelSelectingRows={() => setRowSelectionModel([])}
              />
            )}
            <Table
              rows={rows}
              columns={columns}
              loading={loading}
              sortingMode="server"
              paginationMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              keepNonExistentRowsSelected
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
            />
          </Box>
          <Pagination pagesNumber={getPagesNumber()} />
        </Box>
      </ModuleLayout>
    </>
  );
};
