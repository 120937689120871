import styled, { keyframes } from 'styled-components';
import { COLORS } from 'theme/colors';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export const SkeletonWrapper = styled.div`
  animation: ${shimmer} 1.2s linear infinite forwards;
  background: linear-gradient(
    to right,
    ${COLORS.background.secondary} 8%,
    ${COLORS.background.primary} 18%,
    ${COLORS.background.secondary} 33%
  );
  background-size: 1000px 100%;
`;

export const AccordionSkeleton = styled(SkeletonWrapper)`
  height: 67px;
  box-sizing: border-box;
  border-top: 1px solid ${COLORS.stroke.main};
  padding: 23.5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    border-top: 0;
  }
`;

export const TitleSkeleton = styled.div`
  width: 180px;
  height: 20px;
  background: ${COLORS.background.secondary};
  border-radius: 4px;
`;

export const IconSkeleton = styled.div`
  width: 24px;
  height: 24px;
  background: ${COLORS.background.secondary};
  border-radius: 50%;
`;
