import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledPointProps } from './VoteModificationColumn.d';

export const StyledValueBefore = styled(Typography)`
  color: ${COLORS.typography.placeholder};
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  min-width: 40px;
`;

export const StyledValueAfter = styled(Typography)`
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
`;

export const StyledPoint = styled(Box)<StyledPointProps>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${({ color }) => color};
`;
