import { ReactComponent as LetterPIcon } from 'assets/Icons/Plansecur/letter-p.svg';
import { ReactComponent as LetterLIcon } from 'assets/Icons/Plansecur/letter-l.svg';
import { ReactComponent as LetterAIcon } from 'assets/Icons/Plansecur/letter-a.svg';
import { ReactComponent as LetterNIcon } from 'assets/Icons/Plansecur/letter-n.svg';
import { ReactComponent as LetterSIcon } from 'assets/Icons/Plansecur/letter-s.svg';
import { ReactComponent as LetterEIcon } from 'assets/Icons/Plansecur/letter-e.svg';
import { ReactComponent as LetterCIcon } from 'assets/Icons/Plansecur/letter-c.svg';
import { ReactComponent as LetterUIcon } from 'assets/Icons/Plansecur/letter-u.svg';
import { ReactComponent as LetterRIcon } from 'assets/Icons/Plansecur/letter-r.svg';
import { PlansecurFundProps } from 'types/Plansecur';

export const customerOverviewLogoIcons = [
  <LetterPIcon />,
  <LetterLIcon />,
  <LetterAIcon />,
  <LetterNIcon />,
  <LetterSIcon />,
  <LetterEIcon />,
  <LetterCIcon />,
  <LetterUIcon />,
  <LetterRIcon />,
];

export const getMaxDataState = (funds: PlansecurFundProps[]): string => {
  if (!funds || !funds.length) {
    return '';
  }

  const validDates = funds
    .map((fund) => fund.data_state)
    .filter(
      (dateStr): dateStr is string =>
        !!dateStr && typeof dateStr === 'string' && !isNaN(Date.parse(dateStr)),
    );

  if (!validDates.length) {
    return '';
  }

  const mostRecentDate = new Date(
    validDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0],
  );

  return mostRecentDate.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
