import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/my-watchlist.svg';
import { Box } from 'components/common/Box';
import { MyWatchlistContent } from 'components/Modules/MyWatchlistContent';

export const MyWatchlist: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={2.5}>
        <Header title="Meine Fondsliste" icon={<HeaderIcon />} />
      </Box>
      <MyWatchlistContent />
    </Layout>
  );
};
