import { Option } from 'components/common/Select/Select.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';

export const morningstarMedalistRatingOptions: Option[] = [
  {
    value: 'Gold',
    name: 'Gold',
    chipVariant: TableChipVariant.GOLD,
    priority: 5,
  },
  {
    value: 'Silver',
    name: 'Silber',
    chipVariant: TableChipVariant.SILVER,
    priority: 4,
  },
  {
    value: 'Bronze',
    name: 'Bronze',
    chipVariant: TableChipVariant.BRONZE,
    priority: 3,
  },
  {
    value: 'Negative',
    name: 'Negative',
    chipVariant: TableChipVariant.VIBRANT_RED,
    priority: 2,
  },
  {
    value: 'Neutral',
    name: 'Neutral',
    chipVariant: TableChipVariant.BLACK,
    priority: 1,
  },
];
