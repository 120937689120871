import { Box } from 'components/common/Box';
import { Layout } from 'components/common/Layout';
import { FC } from 'react';
import {
  StyledHeaderContainer,
  StyledHeader,
  StyledContnentContainer,
  LeftContainer,
  RightContainer,
  StyledLink,
} from './ContactUs.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { ContactUsForm } from 'components/ContactUs/ContactUsForm';
import { AnalystServiceDeskSection } from 'components/Overview/AnalystServiceDeskSection';
import { Footer } from 'components/common/Footer';

export const ContactUs: FC = () => {
  return (
    <Layout headerVariant="secondary">
      <Box px={5} pt={2.5} pb={18}>
        <StyledHeaderContainer>
          <StyledHeader
            variant="h1"
            textAlign="center"
            color={COLORS.background.secondary}
          >
            Mehrwert durch unabhängige Fondsanalyse
          </StyledHeader>
          <Typography variant="body" color={COLORS.background.secondary}>
            Kontaktieren Sie uns über das untenstehende Formular und wir melden
            uns umgehend bei Ihnen zurück
          </Typography>
        </StyledHeaderContainer>
        <StyledContnentContainer
          display="flex"
          flexWrap="nowrap"
          justifyContent="flex-end"
        >
          <LeftContainer>
            <ContactUsForm />
          </LeftContainer>
          <RightContainer mt={8}>
            <Box display="flex" flexDirection="column" gap={5} mb={7}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="subtitle"
                  weight="semibold"
                  color={COLORS.typography.primary}
                >
                  E-Mail
                </Typography>
                <StyledLink href="mailto:research@fondsconsult.de">
                  research@fondsconsult.de
                </StyledLink>
              </Box>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="subtitle"
                  weight="semibold"
                  color={COLORS.typography.primary}
                >
                  Telefon
                </Typography>
                <StyledLink href="tel:+4989244180230">
                  +49 (89) 24 41 80230
                </StyledLink>
              </Box>
            </Box>
            <AnalystServiceDeskSection variant="small" />
          </RightContainer>
        </StyledContnentContainer>
      </Box>
      <Footer />
    </Layout>
  );
};
