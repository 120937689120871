import { Box, Table, TableBody, TableCell, styled } from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledHeadTableCellContainerProps } from './ExpandableRow.d';

export const StyledTable = styled(Table)(() => ({
  tableLayout: 'fixed',
  width: 'unset',
}));

export const StyledTableBody = styled(TableBody)(() => ({
  '& tr:first-child': {
    '& td': {
      borderTop: 0,
    },
    '& th': {
      borderTop: 0,
    },
  },
  '& td:nth-child(2)': {
    position: 'sticky',
    left: 248,
    zIndex: 1,
  },
}));

export const StyledHeadTableCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
  padding: '0 4px 0 0',
  borderBottom: 0,
  width: 244,
  position: 'sticky',
  left: 0,
  zIndex: 1,
}));

export const StyledHeadTableCellContainer = styled(Box)(() => ({
  backgroundColor: COLORS.background.secondary,
  borderRight: `1px solid ${COLORS.stroke.primary}`,
  borderBottom: `1px solid ${COLORS.stroke.primary}`,
  borderLeft: `1px solid ${COLORS.stroke.primary}`,
  height: '47px',
  fontFamily: '"Inter", sans-serif',
  fontSize: '12px',
  fontWeight: 600,
  color: COLORS.typography.description,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
}));

export const StyledTableCell = styled(TableCell)(() => ({
  padding: '0 4px',
  width: 325,
  border: 0,
  '&:last-child': {
    paddingRight: 0,
  },
}));

export const StyledTableCellContainer = styled(
  Box,
)<StyledHeadTableCellContainerProps>(({ color }) => ({
  backgroundColor: color,
  borderRight: `1px solid ${COLORS.stroke.primary}`,
  borderBottom: `1px solid ${COLORS.stroke.primary}`,
  borderLeft: `1px solid ${COLORS.stroke.primary}`,
  height: '47px',
  fontFamily: '"Inter", sans-serif',
  fontSize: '12px',
  fontWeight: 600,
  color: COLORS.typography.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const StyledExpandableRowTableCell = styled(TableCell)(() => ({
  padding: 0,
  borderBottom: 0,
}));
