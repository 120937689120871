import styled from 'styled-components';
import { StyledFieldBasic } from '../Form/common.styled';
import { FieldBasic } from '../Form/common';
import { StyledItemProps } from './Search.d';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 150px;
  position: relative;
  width: 100%;

  @media (max-width: 686px) {
    width: 100%;
    max-width: 686px;
  }
`;

export const StyledInput = styled.input<FieldBasic & StyledItemProps>`
  ${StyledFieldBasic}
  padding-left: 50px;
  ${({ withoutLeftBorderRadius }) =>
    withoutLeftBorderRadius &&
    'border-top-left-radius: 0; border-bottom-left-radius: 0;'}
`;

export const StyledInputIcon = styled.span`
  bottom: 9px;
  left: 16px;
  position: absolute;
`;
