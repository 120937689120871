import { FC } from 'react';
import {
  AccordionSkeleton,
  TitleSkeleton,
  IconSkeleton,
} from './FilterAccordionSkeleton.styled';
import { FilterAccordionSkeletonProps } from './FilterAccordionSkeleton.d';

export const FilterAccordionSkeleton: FC<FilterAccordionSkeletonProps> = ({
  count = 3,
}) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <AccordionSkeleton key={index}>
          <TitleSkeleton />
          <IconSkeleton />
        </AccordionSkeleton>
      ))}
    </>
  );
};
