import { FC } from 'react';
import {
  CreatedRowDashboardCard,
  DashboardCardWithTableProps,
} from './DashboardCardWithTable.d';
import { Box } from 'components/common/Box';
import {
  StyledContainer,
  StyledName,
  StyledPaper,
  StyledTableCell,
} from './DashboardCardWithTable.styled';
import { Table, TableBody, TableContainer, TableRow } from '@mui/material';

export const DashboardCardWithTable: FC<DashboardCardWithTableProps> = ({
  name,
  tableData,
  loading,
  maxTableHeight = 266,
}) => {
  return (
    <StyledContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        mb={2}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <StyledName variant="h2">{name}</StyledName>
        </Box>
      </Box>
      <TableContainer
        component={StyledPaper}
        sx={{ maxHeight: maxTableHeight }}
      >
        <Table>
          <TableBody>
            {!loading &&
              !!tableData.rows.length &&
              tableData.rows.map((row: CreatedRowDashboardCard) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {tableData.columns.map((column) => (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align={column.cellAlign}
                      width={column.maxWidth}
                    >
                      {column.renderCell
                        ? column.renderCell({ row, value: row[column.id] })
                        : row[column.id]}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledContainer>
  );
};
