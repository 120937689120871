import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { ReactComponent as RefreshIcon } from 'assets/Icons/refresh.svg';
import { ReactComponent as ArrowIcon } from 'assets/Icons/collapse-arrow.svg';

export const StyledRefreshIcon = styled(RefreshIcon)`
  stroke: ${COLORS.typography.placeholder};
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${COLORS.typography.main};
  transform: rotate(90deg);
`;

export const StyledButton = styled.button`
  box-sizing: border-box;
  color: ${COLORS.typography.placeholder};
  font-size: 14px;
  line-height: 17px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  padding: 8px 16px;
  border: none;
  border-radius: 100px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${SIZES.spacing(1)};
  transition-duration: 0.2s;

  &:disabled {
    pointer-events: none;
    color: ${COLORS.typography.disabled};
    background-color: COLORS.accent.disabled;
    border: none;
    text-decoration: none;
    svg {
      stroke: ${COLORS.typography.disabled};
    }
  }

  &:enabled {
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: ${COLORS.typography.main};
      background-color: ${COLORS.stroke.primary};
      border: none;
      text-decoration-color: none;

      & ${StyledRefreshIcon} {
        stroke: ${COLORS.typography.main};
      }
    }

    &:active {
      color: ${COLORS.typography.body};

      & ${StyledRefreshIcon} {
        stroke: ${COLORS.typography.body};
      }
    }
  }
`;

export const StyledDate = styled.span`
  color: ${COLORS.typography.main};
`;
