import { FC, useEffect } from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from 'react-router-dom';
import { Accessibility, routes } from './config/routes';
import { AppPaths } from './urls/frontend';
import { useAuth } from 'context/Auth';
import { StyledToastContainer } from 'components/common/Toast/Toast.styled';
import { useProfile } from 'context/Profile';
import InitializeGoogleAnalytics from 'utils/google-analytics';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import { NavigationProvider } from 'context/NavigationProvider/NavigationProvider';
import { TidioChat } from 'components/common/TidioChat/TidioChat';

declare global {
  interface Window {
    tidioChatApi: any;
  }
}

const RedirectOnLoginSuccess = () => (
  <Navigate to={{ pathname: AppPaths.login }} />
);

export const App = () => {
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const isProduction = env === 'production';
  const auth = useAuth();
  const profile = useProfile();
  const isLoggedIn = !!auth?.user;

  useEffect(() => {
    isLoggedIn && profile?.fetchProfileData();
  }, [auth?.user]);

  const getPublicRoute = (path: string, Component: FC) => {
    return <Route key={path} path={path} element={<Component />} />;
  };

  const getPrivateRoute = (path: string, Component: FC) => {
    const element = !isLoggedIn ? <RedirectOnLoginSuccess /> : <Component />;
    return <Route key={path} path={path} element={element} />;
  };

  const getMlpDepotsRoute = (path: string, Component: FC) => {
    const hasAccess = profile?.profileInfo?.mlp_depots_access;
    const element = !isLoggedIn ? (
      <RedirectOnLoginSuccess />
    ) : hasAccess ? (
      <Component />
    ) : (
      <Navigate to={AppPaths.main} />
    );
    return <Route key={path} path={path} element={element} />;
  };

  const getPlansecurRoute = (path: string, Component: FC) => {
    const hasAccess = profile?.profileInfo?.plansecur_fonds_pate_access;
    const element = !isLoggedIn ? (
      <RedirectOnLoginSuccess />
    ) : hasAccess ? (
      <Component />
    ) : (
      <Navigate to={AppPaths.main} />
    );
    return <Route key={path} path={path} element={element} />;
  };

  const getLoggedOutOnlyRoute = (path: string, Component: FC) => {
    const element = !isLoggedIn ? (
      <Component />
    ) : auth?.redirectPath ? (
      <Navigate to={auth.redirectPath} />
    ) : (
      <Navigate to={{ pathname: AppPaths.main }} />
    );

    return <Route key={path} path={path} element={element} />;
  };

  useEffect(() => {
    isProduction && InitializeGoogleAnalytics();
  }, []);

  const getRoute = (
    path: string,
    Component: FC,
    accessibility: Accessibility,
  ) => {
    switch (accessibility) {
      case 'PUBLIC':
        return getPublicRoute(path, Component);
      case 'PRIVATE':
        return getPrivateRoute(path, Component);
      case 'MLP DEPOTS':
        return getMlpDepotsRoute(path, Component);
      case 'PLANSECUR':
        return getPlansecurRoute(path, Component);
      case 'LOGGED OUT ONLY':
        return getLoggedOutOnlyRoute(path, Component);
    }
  };

  return (
    <>
      <StyledToastContainer />
      <TidioChat />
      <ThemeProvider theme={theme}>
        <Router>
          <NavigationProvider>
            <Routes>
              {routes.map(({ path, component, accessibility }) =>
                getRoute(path, component, accessibility),
              )}
            </Routes>
          </NavigationProvider>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
