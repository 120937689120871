import { FC, useEffect, useState } from 'react';
import { Box } from 'components/common/Box';
import { DashboardCard } from '../DashboardCard';
import { AppPaths } from 'urls/frontend';
import { HelpBox } from 'components/common/HelpBox';
import { DashboardCardWithTable } from '../DashboardCardWithTable';
import {
  StyledArrowIcon,
  StyledButton,
  StyledFondName,
  StyledIsin,
  StyledNameColumnContainer,
  StyledSectionName,
  StyledTopContainer,
} from './Dashboard.styled';
import {
  CreatedRowDashboardCard,
  DashboardCardColumnsType,
} from '../DashboardCardWithTable/DashboardCardWithTable.d';
import { VoteModificationColumn } from 'components/Modules/VoteModificationColumn/VoteModificationColumn';
import { RequestedByColumn } from 'components/Modules/RequestedByColumn';
import { RequestStatusColumn } from 'components/Modules/RequestStatusColumn';
import {
  ButtonSize,
  ButtonVariant,
} from 'components/common/Buttons/Button/Button.enums';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { Switch } from 'components/common/Switch';
import { FundVolumeAndNetInflows } from '../FundVolumeAndNetInflows';
import { getDashboardData } from 'services/Plansecur';
import { DashboardType, FCAssessments, RequestChanges } from 'types/Plansecur';

export const Dashboard: FC = () => {
  const { customNavigate } = useNavigation();
  const [isFlopFonds, setIsFlopFonds] = useState(false);
  const [voteTableRows, setVoteTableRows] = useState<CreatedRowDashboardCard[]>(
    [],
  );
  const [kompassTableRows, setKompassTableRows] = useState<
    CreatedRowDashboardCard[]
  >([]);

  const createVoteTableRow = (
    tableData: FCAssessments,
  ): CreatedRowDashboardCard => {
    return {
      id: tableData.id,
      name: tableData.name,
      isin: tableData.isin,
      date: tableData.edition_date,
      voteBefore: tableData.previous_recommendation_for_action,
      voteAfter: tableData.recommendation_for_action,
    };
  };
  const createKompassTableRow = (
    tableData: RequestChanges,
  ): CreatedRowDashboardCard => {
    return {
      id: tableData.id,
      name: tableData.fund.name,
      isin: tableData.fund.isin,
      date: tableData.edition_date,
      userName: `${tableData.user.name ?? ''} ${
        tableData.user.last_name ?? ''
      }`,
      role: '',
      createdAt: tableData.edition_date,
      requestStatus: tableData.status,
      requestType: tableData.type,
    };
  };

  const getTablesData = async () => {
    const response = await getDashboardData();
    if (response.ok) {
      const parsedData = response.parsedBody as DashboardType;
      parsedData.fc_assessments &&
        setVoteTableRows(
          parsedData.fc_assessments.map((row) => createVoteTableRow(row)),
        );
      parsedData.request_changes &&
        setKompassTableRows(
          parsedData.request_changes.map((row) => createKompassTableRow(row)),
        );
    }
  };

  useEffect(() => {
    getTablesData();
  }, []);

  const voteTableColumns: DashboardCardColumnsType[] = [
    {
      id: 'name',
      headAlign: 'left',
      cellAlign: 'left',
      label: 'Name',
      minWidth: '290px',
      maxWidth: '290px',
      renderCell: ({ row, value }) => {
        return (
          <StyledNameColumnContainer
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={0.3}
          >
            <StyledFondName variant="subtitle" weight="semibold">
              {value}
            </StyledFondName>
            <StyledIsin variant="body">{row.isin}</StyledIsin>
          </StyledNameColumnContainer>
        );
      },
    },
    {
      id: 'date',
      headAlign: 'center',
      cellAlign: 'center',
      label: 'Date',
      maxWidth: '220px',
    },
    {
      id: 'voteBefore',
      headAlign: 'left',
      cellAlign: 'left',
      label: 'Date',
      maxWidth: '200px',
      renderCell: ({ row }) => {
        return (
          <VoteModificationColumn
            valueAfter={row.voteAfter ?? ''}
            valueBefore={row.voteBefore ?? null}
          />
        );
      },
    },
  ];

  const kompassTableColumns: DashboardCardColumnsType[] = [
    {
      id: 'name',
      headAlign: 'left',
      cellAlign: 'left',
      label: 'Name',
      minWidth: '300px',
      maxWidth: '280px',
      renderCell: ({ row, value }) => {
        return (
          <StyledNameColumnContainer
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={0.3}
          >
            <StyledFondName variant="subtitle" weight="semibold">
              {value}
            </StyledFondName>
            <StyledIsin variant="body">{row.isin}</StyledIsin>
          </StyledNameColumnContainer>
        );
      },
    },
    {
      id: 'date',
      headAlign: 'left',
      cellAlign: 'left',
      label: 'Date',
      minWidth: '215px',
      maxWidth: '120px',
    },
    {
      id: 'userName',
      headAlign: 'left',
      cellAlign: 'left',
      label: 'Username',
      minWidth: '215px',
      maxWidth: '205px',
      renderCell: ({ value, row }) => (
        <RequestedByColumn name={value} role={row.role} />
      ),
    },
    {
      id: 'requestStatus',
      headAlign: 'left',
      cellAlign: 'left',
      label: 'Request status',
      minWidth: '150px',
      maxWidth: '150px',
      renderCell: ({ value, row }) => (
        <RequestStatusColumn status={value} type={row.requestType || null} />
      ),
    },
  ];

  const handleSwitchFlopFondsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsFlopFonds(event.target.checked);
  };

  return (
    <Box pt={1} display="flex" flexDirection="column" gap={4}>
      <HelpBox variant="large" />
      <StyledTopContainer display="flex" gap={3}>
        <Box display="flex" flexDirection="column" gap={2}>
          <StyledSectionName variant="h2">Newsfeed</StyledSectionName>
          {(!!voteTableRows.length || !!kompassTableRows.length) && (
            <Box
              display="flex"
              flexDirection="column"
              gap={2.5}
              style={{ height: '100%', maxWidth: '70vw' }}
            >
              {!!voteTableRows.length && (
                <DashboardCardWithTable
                  name="FondsConsult Handlungsempfehlungen"
                  tableData={{
                    columns: voteTableColumns,
                    rows: voteTableRows,
                  }}
                  maxTableHeight={!!kompassTableRows.length ? 266 : 640}
                />
              )}
              {!!kompassTableRows.length && (
                <DashboardCardWithTable
                  name="Anpassungen Kompas und Entwurf Kompass"
                  tableData={{
                    columns: kompassTableColumns,
                    rows: kompassTableRows,
                  }}
                  maxTableHeight={!!voteTableRows.length ? 266 : 640}
                />
              )}
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2.5}>
              <StyledSectionName variant="h2">Top & Flop</StyledSectionName>
              <Switch
                color="warning"
                checked={isFlopFonds}
                onChange={handleSwitchFlopFondsChange}
                label={`${isFlopFonds ? 'Flop' : 'Top'} fonds`}
              />
            </Box>
            <StyledButton
              variant={ButtonVariant.OUTLINED}
              size={ButtonSize.SMALL}
              rightIcon={<StyledArrowIcon />}
              onClick={() =>
                customNavigate(`${AppPaths.customerOverview}?tab=Kompass`)
              }
            >
              Alle anzeigen
            </StyledButton>
          </Box>
          <DashboardCard
            title="Sharpe Ratio"
            link={`${AppPaths.customerOverview}?tab=Kompass`}
            isFlop={isFlopFonds}
          />
        </Box>
      </StyledTopContainer>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={2.5}>
          <StyledSectionName variant="h2">
            Fondsvolumen und Nettomittelzuflüsse
          </StyledSectionName>
        </Box>
        <FundVolumeAndNetInflows />
      </Box>
    </Box>
  );
};
