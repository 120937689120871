import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { Typography } from 'components/common/Typography';
import { Paper, TableCell, styled as MUIStyled } from '@mui/material';

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(2)} ${SIZES.spacing(3)} 0 ${SIZES.spacing(3)};
  background-color: ${COLORS.background.secondary};
  border-radius: 16px;
  height: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
      to bottom,
      transparent,
      ${COLORS.background.secondary}
    );
    pointer-events: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export const StyledName = styled(Typography)`
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  color: ${COLORS.typography.primary};
`;

export const StyledPaper = MUIStyled(Paper)(() => ({
  boxShadow: 'none',
  backgroundColor: 'inherit',
  // maxHeight: 266,
  overflowY: 'scroll',
}));

export const StyledTableCell = MUIStyled(TableCell)(({ width }) => ({
  padding: '12px 0',
  minWidth: width,
  borderBottom: `1px solid ${COLORS.stroke.primary}`,
  fontFamily: '"Inter", sans-serif',
  fontSize: '14px',
  lineHeight: '17px',
}));
