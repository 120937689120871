import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { StyledPointProps } from './RequestStatusColumn.d';

export const StyledStatus = styled(Typography)`
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
`;

export const StyledPoint = styled(Box)<StyledPointProps>`
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${({ color }) => color};
`;
