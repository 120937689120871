import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  &:hover {
    background-color: ${COLORS.background.neutral};
  }
`;

export const StyledName = styled(Typography)`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
`;

export const StyledPlusIconContainer = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 32px;
  background-color: ${COLORS.info.selectedBorder};

  margin: 0;
  padding: 0;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    stroke: ${COLORS.background.secondary};
  }

  &:hover {
    cursor: pointer;
  }
`;
