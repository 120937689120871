import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { COLORS } from 'theme/colors';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  '& .Mui-selected': {
    backgroundColor: `${COLORS.info.selectedBorder} !important`,
    color: `${COLORS.background.secondary} !important`,
    borderColor: `${COLORS.info.selectedBorder} !important`,
  },
});

export const StyledToggleButton = styled(ToggleButton)({
  borderRadius: '4px',
  textTransform: 'none',
  fontFamily: '"Inter", sans-serif',
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: 500,
  color: COLORS.typography.main,
  padding: '5px 10px',
  minWidth: '42px',
  width: 'auto',
});
