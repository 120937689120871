import { FC, useEffect, useState } from 'react';
import { ModeSwitcherProps } from './ModeSwitcher.d';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from './ModeSwitcher.styled';
import { useSearchParams } from 'react-router-dom';

export const ModeSwitcher: FC<ModeSwitcherProps> = ({
  options,
  value,
  disabled,
  setValue,
}) => {
  const [searchParams, _] = useSearchParams();
  const filterValue = searchParams.get('mode');
  const [alignment, setAlignment] = useState(filterValue || value.value || '');

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    const newOption = options.find(({ value }) => value === newValue);
    setValue(newOption || options[0]);
  };

  useEffect(() => {
    if (value) {
      setAlignment(value.value);
    }
  }, [value]);

  return (
    <StyledToggleButtonGroup
      color="primary"
      value={disabled ? null : alignment}
      exclusive
      onChange={handleChange}
      aria-label="toggle"
      disabled={disabled}
    >
      {options.map(({ value, shortName }) => (
        <StyledToggleButton selected={value === alignment} value={value}>
          {shortName}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};
