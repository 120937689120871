import styled from 'styled-components';
import {
  styled as MUIStyled,
  TableCell,
  TableRow,
  tableRowClasses,
  TextField,
  outlinedInputClasses,
} from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledItemProps } from './Row.d';
import { hexToRGB } from 'utils/common';

export const StyledTableCell = MUIStyled(TableCell)<StyledItemProps>(
  ({ selected }) => ({
    padding: '0 8px 0 9px',
    fontSize: 14,
    lineHeight: '33px',
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    borderBottom: 0,
    height: 15,
    color: selected ? COLORS.background.secondary : COLORS.typography.main,
    '&:first-child': {
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    '&:last-child': {
      padding: '0 16px 0 9px',
    },
  }),
);

export const StyledTableRow = MUIStyled(TableRow)<StyledItemProps>(
  ({ selected, isEditMode }) => ({
    transition: 'background-color 0.3s',
    backgroundColor: isEditMode
      ? COLORS.background.neutral
      : COLORS.background.secondary,
    [`&.${tableRowClasses.selected}`]: {
      backgroundColor: COLORS.info.selectedBorder,
      '&:hover': {
        backgroundColor: COLORS.info.selectedBorder,
      },
    },

    '&:hover': {
      backgroundColor: selected
        ? COLORS.background.secondary
        : COLORS.background.neutral,
    },
  }),
);

export const StyledTextField = MUIStyled(TextField)({
  fontSize: 14,
  fontFamily: '"Inter", sans-serif',
  flex: '1 0 auto',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },
  [`& .${outlinedInputClasses.input}`]: {
    padding: '3px 8px',
    backgroundColor: COLORS.background.secondary,
    borderRadius: 4,
    border: 0,
  },
});

export const StyledIconButton = styled.button`
  border: none;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  width: 28px;
  height: 28px;

  &:enabled {
    cursor: pointer;

    &:hover {
      background-color: ${hexToRGB(COLORS.typography.main, '0.1')};
    }
  }
`;
