import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SlideOutModalProps } from './CommentsModal.d';
import { Typography } from 'components/common/Typography';

export const StyledSlideOutModal = styled.div<SlideOutModalProps>`
  position: fixed;
  top: 0;
  right: -725px;
  width: 720px;
  height: 100vh;
  background-color: ${COLORS.background.secondary};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.4s ease;
  z-index: 20;
  display: flex;
  flex-direction: column;
  ${({ isShown }) =>
    isShown &&
    `
    right: 0;
  `}
`;

export const StyledModalHeader = styled.div`
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseButton = styled.button`
  width: 20px;
  height: 20px;
  background-color: unset;
  border: none;
  justify-self: end;

  &:hover {
    cursor: pointer;

    svg {
      stroke: ${COLORS.typography.caption};
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledModalContent = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: 12px 20px;
`;

export const StyledFooter = styled.div`
  padding: 20px;
  background-color: ${COLORS.background.module};
  display: flex;
  justify-content: space-between;
`;

export const StyledIsin = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.typography.placeholder};
`;
