import { ColumnProps } from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { CheckStatusIndicator } from 'components/common/Icons/CheckStatusIndicator';
import { Option } from 'components/common/Select/Select.d';
import { Box } from 'components/common/Box';
import { TableChip } from 'components/common/TableChip/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { NameColumn } from 'components/Modules/NameColumn';
import { StarRatingsColumn } from 'components/Modules/StarRatingsColumn';
import { morningstarMedalistRatingOptions } from '../options';
import { StyledTableChipContainer } from '../CustomerOverview.styled';
import { Score } from 'components/Modules/Score';
import {
  activeManagementOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  volumeOptions,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';
import { Rank } from 'components/Modules/Rank';
import { quantileGradeColors } from './options.config';
import { ReactComponent as FNGIcon } from 'assets/Icons/fng.svg';
import { isValidLink } from 'utils/common';

export const rankingFilters: {
  value: string;
  label: string;
}[] = [
  { value: ' >= 30 Mio. EUR', label: ' Fondsvolumen' },
  { value: 'nein', label: 'Indexfonds' },
];

export const getDefaultFilterValue = (value: string, options: Option[]) => {
  const [result] = options.filter((option) => option.value === value);
  return result;
};

export const statusOptions: Option[] = [
  {
    value: 'In Kompass aufnehmen',
    name: 'In Kompass aufnehmen',
    chipVariant: TableChipVariant.GREEN,
  },
  {
    value: 'Aus Kompass streichen',
    name: 'Aus Kompass streichen',
    chipVariant: TableChipVariant.RED,
  },
];

export const commonColumns: ColumnProps[] = [
  {
    id: 'name',
    label: 'Name',
    tooltip: 'The name of the item',
    sortable: true,
    renderCell: ({ row, value }) => {
      return <NameColumn variant="small" name={value} type={row.isin} />;
    },
    subColumns: [],
  },
  {
    id: 'fonds_consult_daten',
    label: 'FondsConsult Daten',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'sfb_related_fund__peer_group',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'FCR Peergroup',
        sortable: true,
        minWidth: '148px',
        maxWidth: '148px',
      },
      {
        id: 'sfb_related_fund__rank__rank',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rang',
        sortable: true,
        sortKey: 'sfb_related_fund__rank__rank',
        tooltip:
          'Im Rahmen unserer proprietären Quant-Auswertung erzielter Rang des Fonds innerhalb seiner Vergleichsgruppe. Die Platzierung basiert auf diversen Rendite-/Risikokennzahlen über einen Zeitraum von einem bzw. drei Jahren.',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ row }) => (
          <Rank
            value={row.sfb_related_fund__rank__rank}
            maxValue={row.nr_of_funds}
          />
        ),
      },
      {
        id: 'sfb_related_fund__volume',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondsvolumen',
        sortable: true,
        tooltip:
          'Fondsvolumen eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Hoch (Top 30%), Mittel, Niedrig (Untere 30%).',
        minWidth: '156px',
        maxWidth: '156px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, volumeOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__track_record',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Track Record',
        sortable: true,
        tooltip:
          'Laufzeit eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Lang (Top 30%), Mittel, Kurz (Untere 30%).',
        minWidth: '115px',
        maxWidth: '115px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, trackRecordOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__return_score__total_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rendite',
        sortable: true,
        tooltip:
          'Historisch erzielte Rendite des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, returnOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_risk_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Risiko',
        sortable: true,
        tooltip:
          'Gemessen über die Volatilität, den max. Verlust, das Beta sowie die eigens entwickelte Downside Protection des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht, Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, riskOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_active_management_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Aktives Management',
        sortable: true,
        tooltip:
          'Gemessen über das zur Benchmark erzielte Alpha, sowie die Information und Appraisal Ratio des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            activeManagementOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__esg_scores__credibility_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Credibility',
        sortable: true,
        tooltip:
          'Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen wird.',
        minWidth: '127px',
        maxWidth: '127px',
        renderCell: ({ value }) => <Score value={value} />,
      },
      {
        id: 'esg_actions',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Actions (Fonds)',
        tooltip:
          'Bewertung der auf Fondsebene tatsächlich umgesetzten Nachhaltigkeitskriterien (u.a. PAIs). Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Berücksichtigung nachhaltiger Kriterien gesprochen wird. Mehr Informationen dazu finden Sie im Bereich ESG Credibility & Action Score.',
        sortable: true,
        minWidth: '127px',
        maxWidth: '127px',
      },
    ],
  },
  {
    id: 'stammdaten',
    label: 'Stammdaten',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'plansecur_asset_class',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Plansecur-Assetklasse',
        sortable: true,
        minWidth: '182px',
        maxWidth: '182px',
      },
      {
        id: 'category',
        headAlign: 'left',
        cellAlign: 'left',
        label: 'Fondskategorie / Asset-Schwerpunkt',
        sortable: true,
        minWidth: '169px',
        maxWidth: '169px',
      },
      {
        id: 'wkn',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'WKN',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondswährung',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
      },
    ],
  },
  {
    id: 'preis',
    label: 'Preis und Kosten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'aa_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausgabeaufschlag in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'management_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Managementgebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Performancegebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'rendite',
    label: 'Rendite',
    visibleSubColumnsNumber: 5,
    subColumns: [
      {
        id: 'performance_current_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Laufendes Jahr',
        sortable: true,
        minWidth: '130px',
        maxWidth: '130px',
      },
      {
        id: 'performance_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_5_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_10_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre p.a.',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'quintile_2020',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2020',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'quintile_2021',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2021',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'quintile_2022',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2022',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'quintile_2023',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2023',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'quintile_2024',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2024',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'quintile_sum',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil-Summe',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
    ],
  },
  {
    id: 'sharpe_ratio',
    label: 'Sharpe Ratio',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'sharpe_ratio_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'sharpe_ratio_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'sharpe_ratio_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
    ],
  },
  {
    id: 'volatility',
    label: 'Volatilität',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'volatility_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'externe_ratings',
    label: 'Externe Ratings',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'fww_fundstars_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FWW FundStars®',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
        renderCell: ({ value }) => <StarRatingsColumn value={value} />,
      },
      {
        id: 'morningstar_medalist_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Morningstar Medalist Rating',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            morningstarMedalistRatingOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'scope_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Scope Mutual Fund Rating',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <LetterBadge value={value} />,
      },
    ],
  },
  {
    id: 'abwicklung',
    label: 'Fondsstruktur & Abwicklung',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'appropriation_of_earnings',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ertragsverwendung',
        sortable: true,
        minWidth: '158px',
        maxWidth: '158px',
      },
      {
        id: 'sri_value',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'SRI',
        sortable: true,
        minWidth: '158px',
        maxWidth: '158px',
      },
      {
        id: 'investor_required_knowledge',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Erforderliche Kenntnisse des Anlegers',
        sortable: true,
        minWidth: '220px',
        maxWidth: '220px',
      },
      {
        id: 'time_horizon',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Erforderlicher Anlagehorizont des Anlegers',
        sortable: true,
        minWidth: '220px',
        maxWidth: '220px',
      },
      {
        id: 'index_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Indexfonds',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'vl_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'VL-Fonds',
        sortable: true,
        minWidth: '90px',
        maxWidth: '90px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'custody_fee',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verwahrentgelt',
        sortable: true,
        minWidth: '129px',
        maxWidth: '129px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'savings_plan_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sparplanfähig',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'buy',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'sell',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verkauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'payment_plan',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Auszahlplan',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
    ],
  },
  {
    id: 'volume_and_net_inflows',
    label: 'Fondsvolumen und Nettomiitelzuflüsse',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'volume_in_million_eur',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Volumen umgerechnet\n in Mio. EUR',
        sortable: true,
        minWidth: '200px',
        maxWidth: '200px',
      },
      {
        id: 'abs_net_flow_in_million_eur_3_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 3 Monate (absolut)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'relative_net_flow_percentage_3_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 3 Monate (relativ)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'abs_net_flow_in_million_eur_6_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 6 Monate (absolut)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'relative_net_flow_percentage_6_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 6 Monate (relativ)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'abs_net_flow_in_million_eur_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 12 Monate (absolut)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
      {
        id: 'relative_net_flow_percentage_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Nettomittelzuflüsse\n 12 Monate (relativ)',
        sortable: true,
        minWidth: '188px',
        maxWidth: '188px',
      },
    ],
  },
  {
    id: 'nachhaltigkeit',
    label: 'Nachhaltigkeit',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'fng_label',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FNG-Nachhaltigkeitsprofil',
        tooltip:
          'Die FNG-Nachhaltigkeitsprofile bieten eine Übersicht zu nachhaltigen Anlagestrategien und relevanten Eckdaten von nachhaltigen Investmentfonds. Liegt ein FNG-Nachhaltigkeitsprofil vor, kann dies über einen Klick auf das FNG-Logo geöffnet werden.',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => (
          <>
            {value && isValidLink(value) ? (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <FNGIcon width={24} height={24} />
              </a>
            ) : (
              <CheckStatusIndicator value={false} />
            )}
          </>
        ),
      },
      {
        id: 'sustainability_star_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FNG-Sterne 2025',
        tooltip:
          'Das FNG-Siegel ist der SRI-Qualitätsstandard auf dem deutschsprachigen Finanzmarkt. Die ganzheitliche Methodik des FNG-Siegels basiert auf einem Mindeststandard. Dazu zählen Transparenzkriterien und die Berücksichtigung von Arbeits- & Menschenrechten, Umweltschutz und Korruptionsbekämpfung wie sie im weltweit anerkannten UN Global Compact zusammengefasst sind.',
        tooltip2:
          'Je mehr und intensiver ein Fonds auf den verschiedenen Ebenen im Sinne der Nachhaltigkeit aktiv ist, umso qualitativ hochwertiger ist er. Die Sterne sind ein Ausdruck des Potentials, inwieweit der Fonds echte Wirkung im Sinne von (mehr) Nachhaltigkeit erzielen kann. Jeder Stern ist damit eine Würdigung einer bereits umgesetzten höheren Qualität als nur das Nötigste. Je mehr Sterne, desto nachhaltiger der Fonds.',
        sortable: true,
        minWidth: '140px',
        maxWidth: '140px',
      },
      {
        id: 'eet_template_version',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'EET Template Version',
        tooltip:
          'Dieser Datenpunkt gibt Auskunft über die Aktualität der über Morningstar abgegriffenen EET-Datenfelder. Seit März 2024 ist das EET in der Version 1.1.2 bereitzustellen. Die Version 1.1.3 kann ab dem 1. Januar 2025 verwendet werden. Es ist eine Übergangsphase bis 1. Juli 2025 vorgesehen. Ältere Versionen sind entsprechend mit 1.1.1, 1.1 oder 1 (mit 1 als die erste Version) gekennzeichnet.',
        sortable: true,
        minWidth: '140px',
        maxWidth: '140px',
      },
      {
        id: 'pornography_exclusion',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausschluss Pornographie',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'exclusion_threshold',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausschlussschwelle Pornographie',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_preference_sfdr',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'NH-Präferenz gem. SFDR',
        tooltip:
          'Dieses Feld enthält Angaben über die vom Hersteller mitgeteilten Nachhaltigkeitsmerkmale des Finanzinstruments und gibt Auskunft darüber, ob dieser Bestandteil der Nachhaltigkeitspräferenzen gem. der MiFID II- Delegierten Verordnung (EU) 2017/565 (Ausprägung P-V) ist oder (Ausprägung O-E) nicht. Mit folgender Reihenfolge steigt der Grad an Nachhaltigkeit: P, R, T, U, V und S als nachhaltigste Ausprägung.',
        sortable: true,
        minWidth: '140px',
        maxWidth: '140px',
      },
      {
        id: 'ungc_oecd_violations_considered',
        headAlign: 'center',
        cellAlign: 'center',
        label:
          'PAI - Verstoß gegen Grundsätze der UN Global Compact Prinzipien und OECD-Richtlinien für multinationale Unternehmen',
        tooltip:
          'Die PAIs beinhalten eine Liste wichtigster nachteiliger Nachhaltigkeitsauswirkungen, die von einem Investment ausgehen können. Da es sich um eine Negativliste handelt, ist das Ziel nachhaltiger Anlagestrategien in der Regel eine Berücksichtigung im Sinne einer Reduktion dieser negativen Auswirkungen.',
        sortable: true,
        minWidth: '300px',
        maxWidth: '300px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
    ],
  },
];
