import {
  RECOMMENDATIONS_FOR_ACTION,
  SMART_BENCHMARKING_FILTER_PRESET,
  SMART_BENCHMARKING_FUND_DETAILS,
  SMART_BENCHMARKING_PEER_GROUP,
  SMART_BENCHMARKING_PEER_GROUPS,
} from 'urls/api';
import fetchAPI from './fetchApi';
import {
  SmartBenchmarkingFund,
  SmartBenchmarkingPeerGroup,
} from 'types/SmartBenchmarking.d';
import { SmartFundBenchmarkingItemProps } from 'types/Modules.d';
import { capitalizeWords } from 'utils/common';

interface FilterPresetOptionResponse extends Response {
  name: string;
  id: string;
  query_params: {
    [key: string]: string;
  };
}

export const fetchSmartBenchmarkingFund = (id: string) => {
  const fetchFundDetails = fetchAPI<SmartBenchmarkingFund>(
    SMART_BENCHMARKING_FUND_DETAILS(id),
  );
  return fetchFundDetails;
};

export interface PeerGroupsRequest {
  search?: string;
  asset_class?: string;
}

export const fetchSmartBenchmarkingPeerGroups = ({
  search,
  asset_class,
}: PeerGroupsRequest) => {
  const params = new URLSearchParams();

  if (search) params.append('search', search);
  if (asset_class) params.append('asset_class', asset_class);

  const queryString = params.toString();
  const fetchPeerGroups = fetchAPI<SmartBenchmarkingPeerGroup[]>(
    `${SMART_BENCHMARKING_PEER_GROUPS}/${queryString ? `?${queryString}` : ''}`,
  );
  return fetchPeerGroups;
};

export interface SmartBenchmarkingRequest {
  [key: string]: string | number | string[];
}

type SmartBenchmarkingFunds = {
  funds: SmartFundBenchmarkingItemProps[];
};

interface SmartBenchmarkingResponse extends Response {
  results: SmartBenchmarkingFunds;
  count: number;
}

export const fetchSmartBenchmarkingPeerGroup = ({
  page,
  page_size,
  sort_by,
  sort_order,
  id,
}: SmartBenchmarkingRequest) => {
  if (!id) return null;

  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }

  const queryString = params.toString();
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${SMART_BENCHMARKING_PEER_GROUP(id.toString())}/${
      queryString ? `?${queryString}` : ''
    }`,
  );
  return fetchSFB;
};

export const fetchRecommendationsForAction = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: SmartBenchmarkingRequest) => {
  const params = new URLSearchParams();

  if (page) params.append('page', String(page));
  if (page_size) params.append('page_size', String(page_size));
  if (sort_by && sort_order) {
    params.append('sort_by', `${sort_order === 'asc' ? '-' : ''}${sort_by}`);
  }
  if (search) params.append('search', String(search));

  Object.entries(restValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        params.append(key, capitalizeWords(String(v)));
      });
    } else {
      params.append(key, capitalizeWords(String(value)));
    }
  });

  const queryString = params.toString();
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${RECOMMENDATIONS_FOR_ACTION}/${queryString ? `?${queryString}` : ''}`,
  );
  return fetchSFB;
};

export const fetchFilterPresetOptions = () =>
  fetchAPI<FilterPresetOptionResponse[]>(SMART_BENCHMARKING_FILTER_PRESET);

export const fetchAddFilterPreset = ({
  name,
  ...params
}: SmartBenchmarkingRequest) => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        searchParams.append(key, capitalizeWords(String(v)));
      });
    } else {
      searchParams.append(key, capitalizeWords(String(value)));
    }
  });

  const queryString = searchParams.toString();

  return fetchAPI<FilterPresetOptionResponse>(
    `${SMART_BENCHMARKING_FILTER_PRESET}${
      queryString ? `?${queryString}` : ''
    }`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name }),
    },
  );
};

export const editFilterPreset = ({
  id,
  name,
  ...params
}: SmartBenchmarkingRequest) => {
  return fetchAPI<FilterPresetOptionResponse>(
    `${SMART_BENCHMARKING_FILTER_PRESET}${id}/`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, query_params: params }),
    },
  );
};

export const deleteFilterPreset = (id: string) =>
  fetchAPI(`${SMART_BENCHMARKING_FILTER_PRESET}${id}/`, {
    method: 'DELETE',
  });
