import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
  }
`;

export const StyledIsin = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.typography.placeholder};
`;
