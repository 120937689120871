import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
} from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledCellProps } from './VerticalCollapseTable.d';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';

export const StyledPaper = styled(Paper)(() => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
}));

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'separate',
  paddingRight: '40px',
  tableLayout: 'fixed',
  minWidth: '1400px',
}));

export const StyledEmptyCell = styled(TableCell)<StyledCellProps>(
  ({ isOpen }) => ({
    padding: 0,
    backgroundColor: COLORS.background.secondary,
    width: 325,
    borderRight: `1px solid ${COLORS.stroke.primary}`,
    borderColor: COLORS.stroke.primary,
    borderTop: `1px solid ${COLORS.stroke.primary}`,
    borderBottom: isOpen ? `1px solid ${COLORS.stroke.primary}` : 0,
  }),
);

export const StyledTableBody = styled(TableBody)(() => ({
  '& > tr:nth-child(2) tr:last-child div': {
    borderBottom: 0,
  },
  '& tr:nth-child(4) tr:last-child div': {
    borderBottom: 0,
  },
  '& > tr:nth-child(6) tr:last-child table table tr:last-child div': {
    borderBottom: 0,
  },
  '& tr:nth-child(4) tr:last-child table div': {
    borderBottom: `1px solid ${COLORS.stroke.primary}`,
  },
  '& > tr:last-child tr:last-child div': {
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  '& > tr:nth-last-child(2) th': {
    borderBottom: `1px solid ${COLORS.stroke.primary}`,
  },
  '& > tr:nth-last-child(2) td': {
    borderBottom: `1px solid ${COLORS.stroke.primary}`,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({}));

export const StyledTableCell = styled(TableCell)<StyledCellProps>(
  ({ isOpen }) => ({
    position: 'sticky',
    left: 0,
    zIndex: 1,
    fontWeight: 'bold',
    padding: 0,
    borderColor: COLORS.stroke.primary,
    borderLeft: `1px solid ${COLORS.stroke.primary}`,
    borderTop: `1px solid ${COLORS.stroke.primary}`,
    borderBottom: isOpen ? `1px solid ${COLORS.stroke.primary}` : 0,
    width: 183,
  }),
);

export const StyledCellContainer = styled(Box)<StyledCellProps>(
  ({ isOpen }) => ({
    backgroundColor: COLORS.background.secondary,
    color: isOpen ? COLORS.info.selectedBorder : COLORS.typography.main,
    borderRight: 0,
    padding: '13.5px 16px',
    fontFamily: '"Inter", sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transitionDuration: '0.2s',
  }),
);

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-right: 8px;
  width: 20px;
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
`;
