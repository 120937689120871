import { forwardRef } from 'react';
import { ConfirmModalProps } from './ConfirmModal.d';
import { Modal } from 'components/common/Modal/Modal';
import { StyledContainer } from './ConfirmModal.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';

export const ConfirmModal = forwardRef<HTMLDivElement, ConfirmModalProps>(
  (
    {
      isShown,
      toggle,
      onSubmit,
      title,
      subtitle,
      isWarning = false,
      confirmButtonText = 'Ja',
      cancelButtonText = 'Abbrechen',
    },
    ref,
  ) => {
    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        title={title}
        px={4}
        pb={5}
        pt={4}
      >
        <StyledContainer pt={2.5}>
          {!!subtitle?.length && (
            <Typography variant="body" color={COLORS.typography.description}>
              {subtitle}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
            <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
              {cancelButtonText}
            </Button>
            <Button
              variant={
                isWarning ? ButtonVariant.WARNING : ButtonVariant.PRIMARY
              }
              onClick={onSubmit}
            >
              {confirmButtonText}
            </Button>
          </Box>
        </StyledContainer>
      </Modal>
    );
  },
);
