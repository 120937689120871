import { COLORS } from 'theme/colors';

export const backgroundColors = [
  COLORS.info.selectedBorder,
  COLORS.warning.primary,
  COLORS.error.background,
  COLORS.typography.placeholder,
  COLORS.success.bulletPoint,
];

export const chartLabels = [
  '6 Monate',
  '1 Jahr',
  '3 Jahre',
  '5 Jahre',
  '7 Jahre',
  '10 Jahre',
];

export const chartLabelsNew = [
  { value: 'month_6', name: '6 Monate' },
  { value: 'year_1', name: '1 Jahr' },
  { value: 'year_3', name: '3 Jahre' },
  { value: 'year_5', name: '5 Jahre' },
  { value: 'year_7', name: '7 Jahre' },
  { value: 'year_10', name: '10 Jahre' },
];
