import { AppPaths } from 'urls/frontend';
import { handleResponseErrors } from './handleResponseErrors';
import { parseJwt } from 'utils/parseJwt';
import authService from './Auth';
import { raiseToast } from 'components/common/Toast/raiseToast';

export interface ResponseWithBody<T> extends Response {
  parsedBody: T;
}

let isRefreshing = false;

let refreshSubscribers: Array<(token: string) => void> = [];

const subscribeTokenRefresh = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

const refreshAccessToken = async () => {
  if (isRefreshing) {
    return new Promise<string | null>((resolve) => {
      subscribeTokenRefresh((token) => {
        resolve(token);
      });
    });
  }

  isRefreshing = true;

  try {
    const rawRefreshToken = localStorage.getItem('refresh');
    if (!rawRefreshToken) {
      isRefreshing = false;
      return null;
    }
    const refreshToken = JSON.parse(rawRefreshToken);

    const parsedRefreshToken = parseJwt(refreshToken);
    const exp = parsedRefreshToken.exp;

    if (exp && exp < Date.now() / 1000) {
      localStorage.clear();
      window.location.href = `${window.location.origin}${AppPaths.login}`;
      isRefreshing = false;
      return null;
    }

    const response = await authService.refresh(refreshToken);
    if (!response?.parsedBody?.access) {
      isRefreshing = false;
      return null;
    }

    const newAccessToken = response.parsedBody.access;
    const newRefresToken = response.parsedBody.refresh;

    localStorage.setItem('token', JSON.stringify(newAccessToken));
    localStorage.setItem('refresh', JSON.stringify(newRefresToken));
    localStorage.setItem('user', JSON.stringify(parseJwt(newAccessToken)));

    isRefreshing = false;
    onRefreshed(newAccessToken);
    return newAccessToken;
  } catch (error) {
    isRefreshing = false;
    localStorage.clear();
    window.location.href = `${window.location.origin}${AppPaths.login}`;
    return null;
  }
};

const tryToRefreshAndLogoutIfTokensExpired = async () => {
  const user = localStorage.getItem('user');
  if (user) {
    const parsedUser = JSON.parse(user);
    const exp = parsedUser.exp;
    if (exp && exp >= Date.now() / 1000) return false;

    return await refreshAccessToken();
  }
  return false;
};

const fetchAPI = async <T>(url: string, options?: RequestInit) => {
  const token = localStorage.getItem('token');

  let headers = { ...options?.headers };

  if (token) {
    const parsedToken = JSON.parse(token);
    const authHeaders = {
      'Jwt-Authorization': `Bearer ${parsedToken}`,
    };
    headers = { ...headers, ...authHeaders };
  }
  let response = await fetch(url, {
    ...options,
    headers: headers,
  });

  if (response.status === 204) {
    let responseWithBody: ResponseWithBody<T> = {
      ...response,
      ok: true,
      parsedBody: {} as T,
      status: response.status,
    };
    return responseWithBody as ResponseWithBody<T>;
  }

  let parsedResponse = await response.json();

  if (response.status === 401) {
    if (
      parsedResponse.detail &&
      parsedResponse.detail === 'Token is blacklisted'
    ) {
      localStorage.clear();
      window.location.href = `${window.location.origin}${AppPaths.login}`;
    } else {
      const token = await tryToRefreshAndLogoutIfTokensExpired();
      if (token) {
        const authHeaders = {
          'Jwt-Authorization': `Bearer ${token}`,
        };
        headers = { ...headers, ...authHeaders };

        response = await fetch(url, {
          ...options,
          headers: headers,
        });

        parsedResponse = await response.json();
      }
    }
  }

  if (!response.ok && parsedResponse.code !== 'token_not_valid') {
    handleResponseErrors(parsedResponse);
  }

  if (
    !response.ok &&
    parsedResponse.code === 'token_not_valid' &&
    parsedResponse.detail ===
      'No active account found with the given credentials'
  ) {
    raiseToast.error('No active account found with the given credentials');
  }

  const responseWithBody: ResponseWithBody<T> = {
    ...response,
    ok: response.ok,
    parsedBody: parsedResponse,
    status: response.status,
  };

  return responseWithBody as ResponseWithBody<T>;
};

export default fetchAPI;
