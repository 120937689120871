import { Layout } from 'components/common/Layout';
import { FC, useEffect, useState } from 'react';
import { StyledContainer, StyledIconContainer } from './MLPDepots.styles';
import { ChatIconButton } from 'components/common/IconButtons/ChatIconButton';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ChatCategory,
  ChatCloseAction,
  ChatOpenAction,
} from 'utils/google-analytics/events/chat';

export const MLPDepots: FC = () => {
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const isProduction = env === 'production';
  const [isChatOpen, setIsChatOpen] = useState(false);

  const onTidioChatApiReady = () => {
    window.tidioChatApi.hide();
    setIsChatOpen(false);
  };

  useEffect(() => {
    if (isProduction && window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
      window.tidioChatApi.on('close', onTidioChatApiReady);
    }
  }, [window.tidioChatApi]);

  const toggleChat = () => {
    if (isChatOpen) {
      window.tidioChatApi.hide();
      setIsChatOpen(false);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatCloseAction,
        window.location.pathname,
      );
    } else {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
      setIsChatOpen(true);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatOpenAction,
        window.location.pathname,
      );
    }
  };

  return (
    <Layout headerVariant="secondary">
      <StyledContainer>
        <iframe
          style={{ border: 'none' }}
          title="MLP Depots"
          width="100%"
          height="100%"
          src="https://embed.figma.com/proto/EaoQPbfoNgT5AZjNdUTL8X/MLP-Depots?page-id=0%3A1&node-id=1-481&node-type=frame&viewport=745%2C571%2C0.1&scaling=scale-down-width&content-scaling=fixed&embed-host=share"
        />
        <StyledIconContainer key="chat" display="flex" justifyContent="center">
          <ChatIconButton onClick={isProduction ? toggleChat : undefined} />
        </StyledIconContainer>
      </StyledContainer>
    </Layout>
  );
};
