import { Option } from 'components/common/Select/Select.d';

export const assetClassOptions: Option[] = [
  {
    value: 'Aktien',
    name: 'Aktien',
  },
  {
    value: 'Renten',
    name: 'Renten',
  },
  {
    value: 'Mischfonds',
    name: 'Mischfonds',
  },
  {
    value: 'Geldmarkt',
    name: 'Geldmarkt',
  },
  {
    value: 'Andere',
    name: 'Andere',
  },
];

export const andereOptions: Option[] = [
  {
    value: 'vl_fund',
    name: 'VL Fonds',
  },
  {
    value: 'index_fund',
    name: 'Indexfonds',
  },
  {
    value: 'savings_plan_eligible',
    name: 'Sparplanfähig',
  },
];

export const yearsOptions: Option[] = [
  {
    value: '2022',
    name: '2022',
  },
  {
    value: '2023',
    name: '2023',
  },
  {
    value: '2024',
    name: '2024',
  },
  {
    value: '2025',
    name: '2025',
  },
];

export const scopeRatingOptions: Option[] = [
  {
    value: 'A',
    name: 'A',
  },
  {
    value: 'B',
    name: 'B',
  },
  {
    value: 'C',
    name: 'C',
  },
  {
    value: 'D',
    name: 'D',
  },
  {
    value: 'E',
    name: 'E',
  },
];

export const sharpeRatioRangeOptions: Option[] = [
  { value: '-7', name: 'Min', step: 0.5 },
  { value: '7', name: 'Max', step: 0.5 },
];

export const perfomanceRangeOptions: Option[] = [
  { value: '-100', name: 'Min', step: 10 },
  { value: '100', name: 'Max', step: 10 },
];
