import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.module};
  border-radius: 16px;
`;
export const StyledHeadline = styled(Typography)`
  font-size: 40px;
  line-height: 48px;
  color: ${COLORS.typography.primary};
`;
