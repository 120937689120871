import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { StyledItem } from './NameColumn.d';

export const StyledContainer = styled(Box)<StyledItem>`
  height: 100%;
  min-width: 100px;
  width: 100%;
  max-width: ${({ isSmallVariant }) => (isSmallVariant ? '13vw' : '8.5vw')};

  ${SIZES.media.largeDesktop} {
    max-width: 18vw;
  }

  ${SIZES.media.ultraWideDesktop} {
    max-width: ${({ isSmallVariant }) => (isSmallVariant ? '14vw' : '25vw')};
  }

  ${SIZES.media.extraLargeDesktop} {
    max-width: 32vw;
  }
`;

export const StyledType = styled(Typography)<StyledItem>`
  font-size: ${({ isSmallVariant }) => (isSmallVariant ? 12 : 13)}px;
  line-height: ${({ isSmallVariant }) => (isSmallVariant ? 10 : 15)}px;
  color: ${COLORS.typography.description};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
`;

export const StyledName = styled(Typography)<StyledItem>`
  white-space: ${({ isSmallVariant }) =>
    isSmallVariant ? 'normal' : 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${({ isSmallVariant }) => (isSmallVariant ? 16 : 17)}px;
  line-height: 18px;

  ${({ isSmallVariant }) => isSmallVariant && 'display: -webkit-box;'}
  ${({ isSmallVariant }) => isSmallVariant && '-webkit-box-orient: vertical;'}
  ${({ isSmallVariant }) => isSmallVariant && '-webkit-line-clamp: 2;'}

  ${({ isSmallVariant }) => !isSmallVariant && 'min-height: 18px;'}
`;
