import { FC } from 'react';
import { SectorsRegionsProps } from './SectorsRegions.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { StyledContainer, StyledLabel } from './SectorsRegions.styled';
import { Header } from '../Header';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow-bg.svg';
import { COLORS } from 'theme/colors';

export const SectorsRegions: FC<SectorsRegionsProps> = ({
  description,
  regionOverweight,
  sectorOverweight,
  countryOverweight,
  regionUnderweight,
  sectorUnderweight,
  countryUnderweight,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <BoxWithBookmark>
        <StyledContainer
          px={2}
          pt={2}
          pb={3}
          display="grid"
          gridAutoFlow="column"
        >
          <Box>
            <Header title="Übergewichtung" icon={<ArrowIcon />} />
            <Box pt={3} display="flex" justifyContent="space-between" gap={1}>
              <Box px={2} display="flex" flexDirection="column" gap={1}>
                <StyledLabel
                  variant="overline"
                  color={COLORS.typography.description}
                >
                  Länder
                </StyledLabel>
                <Typography variant="body" weight="medium">
                  {countryOverweight || '-'}
                </Typography>
              </Box>
              <Box px={2} display="flex" flexDirection="column" gap={1}>
                <StyledLabel
                  variant="overline"
                  color={COLORS.typography.description}
                >
                  Regionen
                </StyledLabel>
                <Typography variant="body" weight="medium">
                  {regionOverweight || '-'}
                </Typography>
              </Box>
            </Box>
            <Box pt={2} px={2} display="flex" flexDirection="column" gap={1}>
              <StyledLabel
                variant="overline"
                color={COLORS.typography.description}
              >
                Sektoren
              </StyledLabel>
              <Typography variant="body" weight="medium">
                {sectorOverweight || '-'}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Header
              title="Untergewichtung"
              icon={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
            />
            <Box pt={3} display="flex" justifyContent="space-between" gap={1}>
              <Box px={2} display="flex" flexDirection="column" gap={1}>
                <StyledLabel
                  variant="overline"
                  color={COLORS.typography.description}
                >
                  Länder
                </StyledLabel>
                <Typography variant="body" weight="medium">
                  {countryUnderweight || '-'}
                </Typography>
              </Box>
              <Box px={2} display="flex" flexDirection="column" gap={1}>
                <StyledLabel
                  variant="overline"
                  color={COLORS.typography.description}
                >
                  Regionen
                </StyledLabel>
                <Typography variant="body" weight="medium">
                  {regionUnderweight || '-'}
                </Typography>
              </Box>
            </Box>
            <Box pt={2} px={2} display="flex" flexDirection="column" gap={1}>
              <StyledLabel
                variant="overline"
                color={COLORS.typography.description}
              >
                Sektoren
              </StyledLabel>
              <Typography variant="body" weight="medium">
                {sectorUnderweight || '-'}
              </Typography>
            </Box>
          </Box>
        </StyledContainer>
      </BoxWithBookmark>
    </Box>
  );
};
