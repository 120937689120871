import { FC } from 'react';
import { SelectedFiltersProps } from './SelectedFilters.d';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { useLocation } from 'react-router-dom';
import { Box } from 'components/common/Box';
import { FilterChip } from 'components/common/Chip/FilterChip';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { Button } from 'components/common/Buttons/Button';
import { Typography } from 'components/common/Typography';

export const SelectedFilters: FC<SelectedFiltersProps> = ({
  filters,
  filterResultsNumber,
  resultsNumber,
  clearFilters,
  openSaveFiltersPresetModal,
  presetFilterValue,
  onPresetFilterChange,
}) => {
  const { customNavigate } = useNavigation();
  const { pathname, search } = useLocation();
  const selectedFilters = filters.filter((item) => !!item.value);

  const normalizeString = (str: string): string => {
    return str.toLowerCase().replace(/[\s._-]/g, '');
  };

  const onDeleteFilter = (id: string, value: string) => {
    const params = new URLSearchParams(search);
    if (params.has(id)) {
      for (const [paramKey, paramValue] of params) {
        if (
          paramKey === id &&
          normalizeString(paramValue) === normalizeString(value)
        ) {
          const newParams = params
            .getAll(paramKey)
            .filter(
              (item) =>
                normalizeString(item) !== normalizeString(value.toLowerCase()),
            );
          params.delete(id);
          newParams.forEach((item) => params.append(id, item.toLowerCase()));
        }
      }
    }
    params.delete('page');
    customNavigate(pathname + '?' + params.toString());
  };

  return (
    <>
      {selectedFilters.length !== 0 && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            gap={2}
          >
            <Box display="flex" gap={1} flexWrap="wrap">
              {selectedFilters.map((filter) => (
                <FilterChip
                  key={`${filter.id}-${filter.value}`}
                  label={filter.label}
                  value={filter.name}
                  handleDelete={() => {
                    onDeleteFilter(filter.id, filter.value);
                    if (presetFilterValue !== null && onPresetFilterChange) {
                      onPresetFilterChange(null);
                    }
                  }}
                />
              ))}
            </Box>
            <Box display="flex" gap={2} alignItems="center">
              <Button variant={ButtonVariant.OUTLINED} onClick={clearFilters}>
                Alle Filter löschen
              </Button>
              {presetFilterValue === null && (
                <Button
                  variant={ButtonVariant.PRIMARY}
                  onClick={openSaveFiltersPresetModal}
                >
                  Als Vorlage speichern
                </Button>
              )}
            </Box>
          </Box>
          <>
            {!!resultsNumber && !!filterResultsNumber && (
              <Typography variant="body">
                {filterResultsNumber} von {resultsNumber} Fonds ausgewählt
              </Typography>
            )}
          </>
        </Box>
      )}
    </>
  );
};
