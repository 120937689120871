import { FC } from 'react';
import { TableHeadSwitchProps } from './TableHeadSwitch.d';
import { TableRow } from '@mui/material';
import {
  BlankTableCell,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from './TableHeadSwitch.styled';
import { HighlightedCell, StyledInfoIcon } from '../EnhancedTableHead.styled';
import { Box } from 'components/common/Box';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { selectedViewOptions } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';

export const TableHeadSwitch: FC<TableHeadSwitchProps> = ({
  highlightedCellTooltipText,
  selectedViewOption = 'Smart Fund Benchmarking',
  setSelectedViewOption,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null && setSelectedViewOption) {
      setSelectedViewOption(newValue);
    }
  };

  return (
    <TableRow>
      <BlankTableCell colSpan={5} />
      <HighlightedCell colSpan={5}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1.2}
        >
          <StyledToggleButtonGroup
            value={selectedViewOption}
            exclusive
            onChange={handleChange}
            aria-label="table view options"
            size="small"
          >
            <StyledToggleButton
              value={selectedViewOptions.smartFundBenchmarking}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1.2}
              >
                {selectedViewOptions.smartFundBenchmarking}
                <CustomTooltip
                  title={highlightedCellTooltipText}
                  placement="top"
                >
                  <StyledInfoIcon
                    stroke={
                      selectedViewOption ===
                      selectedViewOptions.smartFundBenchmarking
                        ? COLORS.info.selectedBorder
                        : COLORS.stroke.main
                    }
                  />
                </CustomTooltip>
              </Box>
            </StyledToggleButton>
            <StyledToggleButton value={selectedViewOptions.return}>
              {selectedViewOptions.return}
            </StyledToggleButton>
            <StyledToggleButton value={selectedViewOptions.volatility}>
              {selectedViewOptions.volatility}
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box>
        {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1.2}
        >
          Smart Fund Benchmarking
          <CustomTooltip title={highlightedCellTooltipText} placement="top">
            <StyledInfoIcon stroke={COLORS.stroke.main} />
          </CustomTooltip>
        </Box> */}
      </HighlightedCell>
    </TableRow>
  );
};
