import { forwardRef, useEffect, useState } from 'react';
import {
  AddFundToWatchlistModalProps,
  Fund,
  ItemProps,
} from './AddFundToWatchlistModal.d';
import { Modal } from 'components/common/Modal/Modal';
import {
  StyledContainer,
  StyledContent,
  StyledLeftContainer,
  StyledList,
  StyledMyWatchlistsTypography,
  StyledSearchContainer,
} from './AddFundToWatchlistModal.styled';
import { Search } from 'components/common/Search';
import { ButtonWithPlus } from 'components/common/Buttons/ButtonWithPlus';
import { Box } from 'components/common/Box';
import { ReactComponent as MyWatchlistsIcon } from 'assets/Icons/comparison-list-outlined-grey.svg';
import { TabsWithDivider } from 'components/common/Tabs/TabsWithDivider';
import { esgCredibility, fcFonds, privateMarkets, tabs } from './config';
import { FundItem } from './FundItem';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  fetchPrivateMarkets,
  fetchSmartBenchmarking,
  fetchUpdateFundListStatusPrivateMarkets,
  fetchUpdateFundListStatusSmartBenchmarking,
} from 'services/Modules';
import {
  fetchESGAndActionScore,
  fetchUpdateFundListStatusESG,
} from 'services/ESG';
import { slugify } from 'utils/common';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { WatchlistItem } from 'types/Watchlist';

const TAB_NAME = 'modal-tab';

export const AddFundToWatchlistModal = forwardRef<
  HTMLDivElement,
  AddFundToWatchlistModalProps
>(({ isShown, selectedWatchlist, toggle, onAddFund }, ref) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchParams] = useSearchParams();
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const modalTab = searchParams.get(TAB_NAME) || '';
  const [funds, setFunds] = useState<ItemProps[]>([]);

  const getFundsData = async () => {
    const data = {
      page: 1,
      ...(searchValue ? { search: searchValue } : {}),
    };
    let response;
    switch (modalTab) {
      case slugify(fcFonds):
        response = await fetchSmartBenchmarking(data);
        break;
      case slugify(esgCredibility):
        response = await fetchESGAndActionScore(data);
        break;
      case slugify(privateMarkets):
        response = await fetchPrivateMarkets(data);
        break;
      default:
        break;
    }
    if (response && response.ok) {
      const fondsData: Fund[] = response.parsedBody.results.funds;
      const fundsNotInWatchlist = fondsData.filter((fund) => {
        const watchlistIds =
          fund.watchlists?.map((watchlist: WatchlistItem) => watchlist.id) ||
          [];
        return !watchlistIds.includes(selectedWatchlist.id);
      });

      const simplifiedFunds = fundsNotInWatchlist.map(({ id, name }) => ({
        id,
        name,
      }));

      setFunds(simplifiedFunds);
    }
  };

  const addFondToWatchlist = async (id: string, name: string) => {
    let response;
    switch (modalTab) {
      case slugify(fcFonds):
        response = await fetchUpdateFundListStatusSmartBenchmarking(
          id,
          selectedWatchlist?.id,
        );
        break;
      case slugify(esgCredibility):
        response = await fetchUpdateFundListStatusESG(
          id,
          selectedWatchlist?.id,
        );
        break;
      case slugify(privateMarkets):
        response = await fetchUpdateFundListStatusPrivateMarkets(
          id,
          selectedWatchlist?.id,
        );
        break;
      default:
        break;
    }
    if (response && response.ok) {
      getFundsData();
      onAddFund();
      raiseToast.addToFundList(
        <Toast
          title="Fonds zu Ihrer Fondsliste hinzugefügt"
          content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
        />,
      );
    }
  };

  useEffect(() => {
    getFundsData();
  }, [modalTab, searchValue]);

  const toggleModal = () => {
    if (isShown) {
      const newSearchParams = new URLSearchParams(search);
      newSearchParams.delete(TAB_NAME);
      customNavigate(pathname + '?' + newSearchParams.toString());

      setSearchValue('');
    }
    toggle();
  };

  return (
    <>
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggleModal}
        withCloseButton
        title="Fonds hinzufügen"
        p={0}
        headerPadding="17px 32px"
        borderRadius="8px"
      >
        <StyledContainer>
          <StyledSearchContainer>
            <Search
              initialValue={searchValue}
              onSubmit={setSearchValue}
              placeholder="Fondssuche über ISIN oder Name..."
              withoutBorder
            />
          </StyledSearchContainer>
          <Box pt={2} px={1.8}>
            <TabsWithDivider
              searchParamItem={TAB_NAME}
              tabs={tabs.map((item) => ({
                label: item,
                content: (
                  <StyledList>
                    {funds.map((item) => (
                      <FundItem
                        key={item.id}
                        onItemClick={addFondToWatchlist}
                        {...item}
                      />
                    ))}
                    <Box p={1} pt={2}>
                      <Typography
                        variant="body"
                        weight="regular"
                        color={COLORS.typography.caption}
                        textAlign="center"
                      >
                        Verwenden Sie die Suche für weitere Ergebnisse
                      </Typography>
                    </Box>
                  </StyledList>
                ),
              }))}
            />
          </Box>
        </StyledContainer>
      </Modal>
    </>
  );
});
